import React from 'react';
import { Props } from './roomLayout.types';
import { ApplyCardMenu } from './applyCardMenu';
import { BlocksDirection } from '../../../helpers/player';
import { getOtherPlayers } from '../../game/gameContextHandler/utils/player';
import { Player } from '../../player/player.types';
import { OtherPlayersInTheRoom } from '../room.types';
import { playerNumberToEnum } from '../../game/gameTransformers';
import { NumberOfPlayers } from '../../game/gameTypes';
import { GameContext } from '../../game/gameContext';
import { useCurrentSize } from '../../card/hooks/useCurrentSize';
import { OtherPlayer } from '../../player/otherPlayer';
import { getAllowedCardsInHandCount } from '../../game/rules/rules';
import styles from './roomLayout.module.css';
import cn from 'classnames';
import { useDroppedToPlayer } from './useDroppedToPlayer';
import { useApplyCard } from '../../player/otherPlayer/useApplyCard';
import { ChooseEnergySourceModal } from '../chooseEnergySourceModal';
import { DraggedCardData } from '../../../components/draggable';
import { EngagementZone } from '../engagementZone';

export const RoomLayout: React.FC<Props> = ({ onCardShow, closeCardDrawer }) => {
  const { gameState } = React.useContext(GameContext);

  const [anchor, setAnchor] = React.useState<null | HTMLElement>(null);
  const { droppedItem, onDroppedToPlayer, addDroppedItemToEngagementZone, clearDroppedItem } = useDroppedToPlayer();
  const { availableMenuOptions, clearAvailableMenuOptions, applyCard } = useApplyCard({ droppedItem: droppedItem });

  const closeUseCardMenu = () => {
    clearDroppedItem();
    clearAvailableMenuOptions();
  };

  const playerLocations = locateOtherPlayers(getOtherPlayers(gameState));

  const onDropToPlayerWithRef = (
    playerRef: React.RefObject<HTMLDivElement>,
    item: DraggedCardData,
    droppedToId: string
  ) => {
    setAnchor(playerRef.current);
    onDroppedToPlayer(item, droppedToId);
  };

  const otherPlayerSize = useCurrentSize();
  const otherPlayer = (player: Player, position: BlocksDirection) => (
    <OtherPlayer
      player={player}
      allowedCardInHandCount={getAllowedCardsInHandCount(player, gameState.playersOrdered)}
      onCardShow={onCardShow}
      blocksDirection={position}
      size={otherPlayerSize}
      onDroppedToPlayer={onDropToPlayerWithRef}
      applyCardMenuOptions={availableMenuOptions}
    />
  );

  const arePlayerAbove5 = gameState.allPlayers.length > NumberOfPlayers.FIVE;
  const playersCountCn = arePlayerAbove5 ? 'moreThanFive' : 'fiveAndLess';
  const playersColumnClass = arePlayerAbove5 ? styles.playersColumn : '';

  return (
    <>
      <div className={cn(styles.container, styles[playersCountCn])}>
        <div className={styles.topRow}>
          {playerLocations.topLeft && otherPlayer(playerLocations.topLeft, BlocksDirection.LEFT_TO_RIGHT)}
          {playerLocations.topCenter && otherPlayer(playerLocations.topCenter, BlocksDirection.RIGHT_TO_LEFT)}
          {playerLocations.topRight && otherPlayer(playerLocations.topRight, BlocksDirection.RIGHT_TO_LEFT)}
        </div>
        <div className={styles.middleBottomRow}>
          <div className={playersColumnClass}>
            {playerLocations.middleLeft && (
              <div>{otherPlayer(playerLocations.middleLeft, BlocksDirection.LEFT_TO_RIGHT)}</div>
            )}
            {playerLocations.bottomLeft && (
              <div className={styles.bottom}>
                {otherPlayer(playerLocations.bottomLeft, BlocksDirection.LEFT_TO_RIGHT)}
              </div>
            )}
          </div>
          <EngagementZone onCardShow={onCardShow} setDroppedItem={addDroppedItemToEngagementZone} />
          <div className={playersColumnClass}>
            {playerLocations.middleRight && (
              <div className={styles.rightColumn}>
                {otherPlayer(playerLocations.middleRight, BlocksDirection.RIGHT_TO_LEFT)}
              </div>
            )}
            {playerLocations.bottomRight && (
              <div className={cn(styles.rightColumn, styles.bottom)}>
                {otherPlayer(playerLocations.bottomRight, BlocksDirection.RIGHT_TO_LEFT)}
              </div>
            )}
          </div>
        </div>
      </div>
      <ChooseEnergySourceModal
        applyCard={applyCard}
        onClose={closeCardDrawer}
        onCardClick={onCardShow}
        droppedItem={droppedItem}
        clearDroppedItem={clearDroppedItem}
      />
      <ApplyCardMenu anchor={anchor} menuOptions={availableMenuOptions} onClose={closeUseCardMenu} />
    </>
  );
};

const locateOtherPlayers = (players: Player[]): OtherPlayersInTheRoom => {
  const allPlayersNumber = playerNumberToEnum(players.length + 1);
  switch (allPlayersNumber) {
    case NumberOfPlayers.THREE:
      return { middleLeft: players[0], middleRight: players[1] };
    case NumberOfPlayers.FOUR:
      return { middleLeft: players[0], topCenter: players[1], middleRight: players[2] };
    case NumberOfPlayers.FIVE:
      return { bottomLeft: players[0], topLeft: players[1], topRight: players[2], bottomRight: players[3] };
    case NumberOfPlayers.SIX:
      return {
        bottomLeft: players[0],
        middleLeft: players[1],
        topCenter: players[2],
        middleRight: players[3],
        bottomRight: players[4],
      };
    case NumberOfPlayers.SEVEN:
      return {
        bottomLeft: players[0],
        middleLeft: players[1],
        topLeft: players[2],
        topRight: players[3],
        middleRight: players[4],
        bottomRight: players[5],
      };
    case NumberOfPlayers.EIGHT:
      return {
        bottomLeft: players[0],
        middleLeft: players[1],
        topLeft: players[2],
        topCenter: players[3],
        topRight: players[4],
        middleRight: players[5],
        bottomRight: players[6],
      };
  }
};
