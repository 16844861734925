import React from 'react';
import { Props } from './cardDragLayer.types';
import { useDragLayer } from 'react-dnd';
import { DraggedCardData } from '../../../../components/draggable';
import { Card } from '../../../../components/card';
import { cardToComponent } from '../../../../helpers/transformers';
import './cardDragLayer.module.css';
import styles from './cardDragLayer.module.css';
import { isEmpty } from '../../../../helpers/arrays';

export const CardDragLayer: React.FC<Props> = ({ size }) => {
  const itemRef = React.useRef<HTMLDivElement>(null);

  const { isDragging, item, initialOffset, currentOffset } = useDragLayer((monitor) => ({
    item: monitor.getItem<DraggedCardData>(),
    itemType: monitor.getItemType(),
    initialOffset: monitor.getInitialSourceClientOffset(),
    currentOffset: monitor.getSourceClientOffset(),
    isDragging: monitor.isDragging(),
  }));

  if (!isDragging || !item || !item.cards || isEmpty(item.cards)) return null;

  const resolveItemClass = (): string => {
    if (!initialOffset || !currentOffset || !itemRef.current) return styles.itemHidden;

    const { x, y } = currentOffset;
    itemRef.current.style.setProperty('--item-translate-x', `${x}px`);
    itemRef.current.style.setProperty('--item-translate-y', `${y}px`);

    return styles.itemActive;
  };

  const renderItem = () => <Card {...cardToComponent(item.cards[0], size)} />;

  return (
    <div className={styles.container}>
      <div ref={itemRef} className={resolveItemClass()}>
        {renderItem()}
      </div>
    </div>
  );
};
