import React from 'react';
import { Props } from './modal.types';
import { Modal as MuiModal } from '@mui/material';
import styles from './modal.module.css';
import { CloseModalButton } from './closeModalButton';
import cn from 'classnames';
import { isMobile } from 'react-device-detect';

export const Modal: React.FC<Props> = ({ isOpen, onClose, variant = 'default', children }) => {
  return (
    <MuiModal open={isOpen} onClose={onClose} className={styles.modal}>
      <div className={cn(styles.content, styles[variant], { [styles.isMobile]: isMobile })}>
        <CloseModalButton onClose={onClose} />
        {children}
      </div>
    </MuiModal>
  );
};
