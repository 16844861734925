import React from 'react';
import { Props } from './appContextProvider.types';
import { AppContext, AppContextType } from './appContext';
import { useWindowSize } from '../../helpers/utils';
import { MOBILE_WIDTH, SMALL_SIZE_HEIGHT } from '../../constants/components';
import InternalSocketClient, { SocketIoClient } from '../../socketIo/SocketClient';
import { PlayingCard } from '../../features/card/cardTypes';
import CardApi from '../../features/card/cardApi';
import { dtoToPlayCards } from '../../features/card/cardTransformers';
import { shuffle } from '../../helpers/arrays';
import RuleBookRetriever from '../../features/ruleBook/ruleBookRetriever';
import { Profile } from '../../features/auth/authTypes';
import { parseProfile } from '../../helpers/player';
import { PROFILE_STORAGE_KEY } from '../../constants/login';
import { useErrorHandling } from '../../helpers/hooks/useErrorHandling';
import { calculateScreenSizeModifier } from '../../helpers/styles';
import { isMobile as isMobileDevice } from 'react-device-detect';
import { GAME_ID_KEY, GAME_TOKEN_STORAGE_KEY } from '../../constants/delarationApi';

export const AppContextProvider: React.FC<Props> = ({ children }) => {
  const window = useWindowSize();

  const socket: SocketIoClient = {
    socket: InternalSocketClient.getSocket(),
  };

  const isMobile = () => window.width < MOBILE_WIDTH || window.height < SMALL_SIZE_HEIGHT || isMobileDevice;

  const [playCards, setPlayCards] = React.useState<PlayingCard[]>([]);
  const [profile, setProfile] = React.useState<Profile | null>(null);

  const fillCards = async () => {
    //TODO try catch of errors from API. We should not be loading forever, if no result
    const retrievedCards = await CardApi.getAllActiveCards();
    const mappedCards = dtoToPlayCards(retrievedCards);
    shuffle(mappedCards);
    setPlayCards(mappedCards);
  };

  const storyDetails = React.useMemo(() => {
    return RuleBookRetriever.getStory();
  }, []);

  const rulesDetails = React.useMemo(() => {
    return RuleBookRetriever.getRules();
  }, []);

  const cardDetails = React.useMemo(() => {
    return RuleBookRetriever.getCardDetails();
  }, []);
  const screenSizeModifier = React.useMemo(() => {
    return calculateScreenSizeModifier(window);
  }, [window]);

  const refreshProfile = () => {
    setProfile(parseProfile());
  };

  React.useEffect(() => {
    void fillCards();
    refreshProfile();
  }, []);

  const logout = () => {
    localStorage.removeItem(PROFILE_STORAGE_KEY);
    localStorage.removeItem(GAME_ID_KEY);
    localStorage.removeItem(GAME_TOKEN_STORAGE_KEY);
    refreshProfile();
  };

  useErrorHandling({ logout });

  const initializeContext = (): AppContextType => ({
    isMobile: isMobile(),
    windowSize: window,
    socketClient: socket,
    allPlayingCards: playCards,
    story: storyDetails,
    rules: rulesDetails,
    cardDetails: cardDetails,
    profile: profile,
    refreshProfile: refreshProfile,
    logout: logout,
    screenSizeModifier: screenSizeModifier,
  });

  return <AppContext.Provider value={initializeContext()}>{children}</AppContext.Provider>;
};
