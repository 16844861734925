import { Page, Rule } from '../ruleBook.types';
import { Role } from '../../card/cardTypes';
import { getAllRoleCards } from '../../game/rules/roles';

export type Props = {
  page: Page;
  rules: Rule[];
  searched: string;
};

type RoleRuleCard = {
  description: string;
  images: string[];
};

export const getMappedRoleCards: Map<Role, RoleRuleCard> = getAllRoleCards().reduce((mapped, roleCard) => {
  const roleRuleCard: RoleRuleCard = mapped.get(roleCard.role) || { description: '', images: [] };
  roleRuleCard.description = roleCard.description;
  roleRuleCard.images = roleRuleCard.images.concat(roleCard.image);
  mapped.set(roleCard.role, roleRuleCard);
  return mapped;
}, new Map<Role, RoleRuleCard>());
