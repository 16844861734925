import React, { useEffect, useState } from 'react';
import { Props, setDefaultVolumeLevelFlag } from './audioSettingsBlock.types';
import {
  Box,
  Checkbox,
  Divider,
  FormControlLabel,
  IconButton,
  MenuItem,
  Switch,
  Tooltip,
  Typography,
} from '@mui/material';
import { PlayArrow, Stop, VolumeDown, VolumeMute, VolumeOff, VolumeUp } from '@mui/icons-material';
import { AppContext } from '../../../context/appContext';
import {
  DEFAULT_THEME_VOLUME_LEVEL,
  MAXIMUM_VOLUME_LEVEL,
  MINIMUM_VOLUME_LEVEL,
  VOLUME_CHANGE_STEP,
} from '../../../../constants/components';
import { SoundSettingsBlock } from '../../../../components/soundSettingsBlock';
import { convertToCamelCase } from '../../../../helpers/utils';
import { getNumberValue, isFlagOn, isFlagSet, setFlag, setValue } from '../../../../helpers/featureFlags';
import {
  MAIN_SOUND_THEME_FLAG,
  MAIN_SOUND_THEME_VOLUME,
  MY_SOUNDS_FLAG,
  MY_SOUNDS_VOLUME,
  OTHER_PLAYERS_SOUNDS_FLAG,
  OTHER_PLAYERS_SOUNDS_VOLUME,
} from '../../../../constants/featureFlags';
import { tooltipStyles } from '../../../../helpers/styles';
import { sxInit } from './audioSettingsWidget.styles';
import { MainThemeAudioContext } from '../../mainThemeAudio/mainThemeAudioContext';
import { DEFAULT_THEME_TYPE, SECOND_THEME_TYPE } from '../../mainThemeAudio';
import { StyledMenu } from '../styledMenu/styledMenu';

export const AudioSettingsWidget: React.FC<Props> = ({ 'data-testid': dataTestId }) => {
  const { isMobile } = React.useContext(AppContext);
  const audio = React.useContext(MainThemeAudioContext);

  useEffect(() => {
    setDefaultVolumeLevelFlag(MY_SOUNDS_VOLUME);
    setDefaultVolumeLevelFlag(OTHER_PLAYERS_SOUNDS_VOLUME);
  }, []);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleOpenSettings = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const [isThemeMuted, setIsThemeMuted] = useState(
    isFlagSet(MAIN_SOUND_THEME_FLAG) && !isFlagOn(MAIN_SOUND_THEME_FLAG)
  );
  const [themeVolume, setThemeVolume] = useState(getNumberValue(MAIN_SOUND_THEME_VOLUME) ?? DEFAULT_THEME_VOLUME_LEVEL);
  const [areMySoundsOn, setAreMySoundsOn] = React.useState(isFlagSet(MY_SOUNDS_FLAG) ? isFlagOn(MY_SOUNDS_FLAG) : true);
  const [areOtherPlayersSoundsOn, setAreOtherPlayersSoundsOn] = React.useState(
    isFlagSet(OTHER_PLAYERS_SOUNDS_FLAG) ? isFlagOn(OTHER_PLAYERS_SOUNDS_FLAG) : true
  );

  React.useEffect(() => {
    setFlag(MY_SOUNDS_FLAG, areMySoundsOn);
    setFlag(OTHER_PLAYERS_SOUNDS_FLAG, areOtherPlayersSoundsOn);
  }, [areMySoundsOn, areOtherPlayersSoundsOn]);

  useEffect(() => {
    setValue(MAIN_SOUND_THEME_VOLUME, themeVolume.toString());
    audio.setVolume(themeVolume / 100);
  }, [themeVolume]);

  useEffect(() => {
    setFlag(MAIN_SOUND_THEME_FLAG, !isThemeMuted);

    if (isThemeMuted) {
      audio.setVolume(0);
      return;
    }

    audio.setVolume(themeVolume / 100);
  }, [isThemeMuted]);

  const toggleIsPlaying = () => {
    if (audio.isPlaying) {
      audio.pause();
      audio.setCurrentTime(0);
    } else {
      void audio.play();
    }
  };

  const toggleMute = () => {
    setIsThemeMuted(!isThemeMuted);
  };

  const increaseVolume = () => {
    setThemeVolume((prevVolume) => Math.min(prevVolume + VOLUME_CHANGE_STEP, MAXIMUM_VOLUME_LEVEL));
  };

  const decreaseVolume = () => {
    setThemeVolume((prevVolume) => Math.max(prevVolume - VOLUME_CHANGE_STEP, MINIMUM_VOLUME_LEVEL));
  };

  const soundSettings = (setIsOn: (isOn: boolean) => void, isOn: boolean, label: string) => (
    <MenuItem sx={sx.menuItem.item}>
      <FormControlLabel
        label={label}
        control={<Checkbox checked={isOn} onChange={() => setIsOn(!isOn)} sx={sx.menuItem.checkbox} />}
        sx={sx.menuItem.controlLabel}
      />
      <SoundSettingsBlock settingsLabel={convertToCamelCase(label) + 'Volume'} />
    </MenuItem>
  );

  const handleChange = () => {
    audio.setThemeType(audio.themeType === DEFAULT_THEME_TYPE ? SECOND_THEME_TYPE : DEFAULT_THEME_TYPE);
  };

  const sx = sxInit(audio.themeType === SECOND_THEME_TYPE);

  const mainThemeSettings = () => (
    <MenuItem>
      <Typography sx={sx.themeItemTypography}>Main theme</Typography>
      <Tooltip
        title={<Typography sx={tooltipStyles}>Choose one of our 2 awesome galactic themes</Typography>}
        disableInteractive
      >
        <Switch
          checked={audio.themeType === SECOND_THEME_TYPE}
          onChange={handleChange}
          inputProps={{ 'aria-label': 'customized switch' }}
          sx={sx.themeSwitcher}
        />
      </Tooltip>
      <IconButton onClick={toggleIsPlaying}>{audio.isPlaying ? <Stop /> : <PlayArrow />}</IconButton>
      {!isMobile && <IconButton onClick={toggleMute}>{isThemeMuted ? <VolumeOff /> : <VolumeMute />}</IconButton>}
      <IconButton disabled={themeVolume <= MINIMUM_VOLUME_LEVEL} onClick={decreaseVolume}>
        <VolumeDown />
      </IconButton>
      <IconButton disabled={themeVolume >= MAXIMUM_VOLUME_LEVEL} onClick={increaseVolume}>
        <VolumeUp />
      </IconButton>
    </MenuItem>
  );

  const openMenuButtonId = 'open-audio-settings-menu';
  const settingsMenu = (
    <StyledMenu
      id="basic-menu"
      anchorEl={anchorEl}
      open={open}
      onClose={handleClose}
      MenuListProps={{
        'aria-labelledby': openMenuButtonId,
      }}
    >
      {mainThemeSettings()}
      <Divider variant="middle" />
      {soundSettings(setAreMySoundsOn, areMySoundsOn, 'My sounds')}
      {soundSettings(setAreOtherPlayersSoundsOn, areOtherPlayersSoundsOn, 'Other Players sounds')}
    </StyledMenu>
  );

  const tooltipTitle = 'Audio settings';

  return (
    <Box data-testid={dataTestId}>
      <React.Fragment>
        <Tooltip title={<Typography sx={tooltipStyles}>{tooltipTitle}</Typography>} disableInteractive>
          <IconButton onClick={handleOpenSettings} sx={sx.button.main}>
            <VolumeUp sx={sx.button.icon} />
          </IconButton>
        </Tooltip>
      </React.Fragment>
      {settingsMenu}
    </Box>
  );
};
