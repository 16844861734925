import React from 'react';
import { UseDeEssencerEffect } from './useDeEssencerEffect.types';
import { GameContext } from '../../../../game/gameContext';
import { last } from '../../../../../helpers/arrays';
import { filterIgnoredDefendingActions } from '../../../../game/rules/rules';
import { getCurrentPlayer } from '../../../../game/gameContextHandler/utils/player';
import {
  addTurnActionWithStateUpdateIfNeeded,
  cardsToAppliedInProgressActionsMap,
} from '../../../../game/utils/turnActions';
import { DE_ESSENCER_CARDS } from '../../../cardTypes';

export const useDeEssencerEffect: UseDeEssencerEffect = () => {
  const { gameState, addTurnAction, withUpdatingState } = React.useContext(GameContext);

  return () => {
    const currentPlayer = getCurrentPlayer(gameState);
    const lastTurnAction = last(filterIgnoredDefendingActions(gameState.turnActions))?.action;

    if (lastTurnAction === undefined || currentPlayer.id !== gameState.activeDefendingPlayer) {
      return;
    }

    const cardInProgressAction = cardsToAppliedInProgressActionsMap(DE_ESSENCER_CARDS).get(lastTurnAction);

    if (cardInProgressAction === undefined) return;

    addTurnActionWithStateUpdateIfNeeded(currentPlayer, cardInProgressAction, withUpdatingState, addTurnAction);
  };
};
