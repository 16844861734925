import React from 'react';
import { Props } from './otherPlayerMobile.types';
import { NORMAL_TO_EXTENDED_SIZE } from '../../../../helpers/sizes';
import { roomItems, useTrackLocation } from '../../../room/hooks/useTrackLocation';
import { DraggedCardData } from '../../../../components/draggable';
import { Role } from './role';
import { PlayerMobile } from '../../mobile/playerMobile';
import { HandCompact } from '../hand/components/handCompact';
import { InteractiveCards } from './interactiveCards/interactiveCards';
import { DisperserLocation, EssenceBar } from '../../../../components/essenceBar';
import styles from './otherPlayerMobile.module.css';
import { MOBILE_PLAYER_ESSENCE_BAR_SX } from '../../../../constants/styles';
import { isEmpty } from '../../../../helpers/arrays';

export const OtherPlayerMobile: React.FC<Props> = (props) => {
  const { player, size, onDroppedToPlayer } = props;
  const { id, essences } = player;
  const { ref } = useTrackLocation({
    items: [roomItems.playerHand(id), roomItems.playerArsenal(id), roomItems.playerRadiances(id)],
  });
  const sizeExtended = NORMAL_TO_EXTENDED_SIZE[size];

  const onDroppedItem = (item: DraggedCardData) => onDroppedToPlayer(ref, item, id);

  return (
    <PlayerMobile {...props} roleComponent={<Role player={player} size={sizeExtended} onDroppedItem={onDroppedItem} />}>
      <div ref={ref}>
        <div className={styles.playerNameBox}>
          <p className={styles.playerName}>{player.playerName}</p>
        </div>
        <div className={styles.essenceBarBox}>
          <EssenceBar
            playerId={id}
            count={essences}
            customSx={MOBILE_PLAYER_ESSENCE_BAR_SX}
            disperserLocation={DisperserLocation.BOTTOM}
          />
        </div>
        <HandCompact player={player} applyCardMenuOptions={props.applyCardMenuOptions} />
        <InteractiveCards player={player} size={sizeExtended} forceHidden={!isEmpty(props.applyCardMenuOptions)} />
      </div>
    </PlayerMobile>
  );
};
