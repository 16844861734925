import React from 'react';
import { useGameModal } from '../../menu/hooks/useGameModal';
import { ActionType } from '../../menu/manageGameModal';
import styles from './playNowButtons.module.css';
import cn from 'classnames';
import { isMobile } from 'react-device-detect';
import { CreateGameButton } from './createGameButton';
import { JoinGameButton } from './joinGameButton';

export type MouseEvent = React.MouseEvent<HTMLButtonElement>;

export const PlayNowButtons: React.FC = () => {
  const { openModal, modal } = useGameModal();

  const onCreateClick = (event: MouseEvent) => openModal('Create', event);
  const onJoinClick = (event: MouseEvent) => openModal('Join', event);

  const resolveButtonName = (base: ActionType) => (isMobile ? base : `${base} game`);

  return (
    <div className={styles.container}>
      <div className={cn(styles.buttonsContainer, { [styles.isMobile]: isMobile })}>
        <CreateGameButton buttonName={resolveButtonName('Create')} onClick={onCreateClick} />
        <JoinGameButton buttonName={resolveButtonName('Join')} onClick={onJoinClick} />
        {modal()}
      </div>
    </div>
  );
};
