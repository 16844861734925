import React from 'react';
import { Props } from './payPalButton.types';
import { CardMedia, Typography } from '@mui/material';
import { ImagePath } from '../../../../../assets/assets';
import Button from '@mui/material/Button';
import { sx } from './payPalButton.styles';

export const PayPalButton: React.FC<Props> = () => {
  const handleButtonClick = () => {
    const popUpWindow = window.open(
      'https://www.paypal.com/donate/?hosted_button_id=EY3LGESYY7W9S',
      '_blank',
      'width=650,height=850'
    );
    if (popUpWindow) popUpWindow.focus();
  };

  return (
    <React.Fragment>
      <Button onClick={handleButtonClick} sx={sx.button}>
        <CardMedia component="img" alt="donate-with-paypal" image={ImagePath.payPalLogo} sx={sx.logo} />
        <Typography>PayPal</Typography>
      </Button>
    </React.Fragment>
  );
};
