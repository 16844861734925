import { canDisintegrate, canTeleport } from '../../../../../game/rules/rules';
import { HandType, UseResolveHand } from './useResolveHand.types';
import React from 'react';
import { GameContext } from '../../../../../game/gameContext';
import { getCurrentPlayer } from '../../../../../game/gameContextHandler/utils/player';
import { last } from '../../../../../../helpers/arrays';
import { ignoreLastAction } from '../../../../../game/utils/turnActions';

export const useResolveHand: UseResolveHand = ({ playerId }) => {
  const { gameState } = React.useContext(GameContext);
  const lastTurnAction = last(ignoreLastAction(gameState.turnActions, ['create log']));

  const shouldExpandHand =
    (canTeleport(gameState.turnActions) || canDisintegrate(gameState.turnActions)) &&
    getCurrentPlayer(gameState).id === lastTurnAction?.player &&
    playerId === lastTurnAction?.appliedTo;

  return {
    handType: shouldExpandHand ? HandType.INTERACTIVE : HandType.STATIC,
  };
};
