import React from 'react';
import { Props } from './logo.types';
import { Box, SxProps } from '@mui/material';
import { sx } from './logo.styles';
import { ImagePath } from '../../../../../assets/assets';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';

export const Logo: React.FC<Props> = ({ isOpen, toggleMenu, isVisible, 'data-testid': dataTestId }) => {
  const arrowIcon = (sxProps: SxProps) => (isOpen ? <ArrowLeftIcon sx={sxProps} /> : <ArrowRightIcon sx={sxProps} />);

  return isVisible ? (
    <Box data-testid={dataTestId} sx={sx.logoBox} onClick={toggleMenu}>
      <Box sx={sx.logo}>
        <img alt="deltaration-logo" src={ImagePath.logoOutline} width={'100%'} height={'100%'} />
      </Box>
      <Box sx={sx.line} />
      {arrowIcon(sx.menuIcon)}
    </Box>
  ) : (
    <></>
  );
};
