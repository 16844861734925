import React from 'react';
import { Props } from './menu.types';
import {
  Box,
  ClickAwayListener,
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  SwipeableDrawer,
  Tooltip,
} from '@mui/material';
import SportsEsportsIcon from '@mui/icons-material/SportsEsports';
import ListAltIcon from '@mui/icons-material/ListAlt';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import { sx } from './menu.styles';
import { Logo } from '../logo';
import { useGameModal } from '../../../../../features/menu/hooks/useGameModal';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import GroupsIcon from '@mui/icons-material/Groups';
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import LoginIcon from '@mui/icons-material/Login';
import HomeIcon from '@mui/icons-material/Home';
import { SignOutButton } from '../../../../../features/menu/signOutButton';
import { AppContext } from '../../../../context/appContext';
import { GoogleButton } from '../../../../../features/menu/googleButton';
import { ActionType } from '../../../../../features/menu/manageGameModal';
import { ABOUT_US_ROUTE, PROFILE_ROUTE, ROOT_ROUTE, RULES_ROUTE } from '../../../../../constants/routes';
import { useNavigate } from 'react-router-dom';
import { DonationWidgetsPanel } from '../../../../../features/menu/widgetsPanel';
import { BackToGameButton } from '../../../../../features/game/backToGameButton';
import { InstallButton } from '../../../../../features/menu/installButton';
import { DiscordLoginButton } from '../../../../../features/menu/discordButtonButton';

export const Menu: React.FC<Props> = ({ isOpen, setIsOpen, 'data-testid': dataTestId }) => {
  const navigate = useNavigate();
  const navigateClosingMenu = (route: string) => {
    navigate(route);
    setIsOpen(false);
  };

  const { profile } = React.useContext(AppContext);
  const toggleMenu = () => setIsOpen(!isOpen);

  const [isLoginTooltipOpen, setIsLoginTooltipOpen] = React.useState(false);
  const toggleLoginTooltip = () => setIsLoginTooltipOpen(!isLoginTooltipOpen);

  const { openModal, modal } = useGameModal();

  const listItemIcon = (icon: React.ReactElement) => <ListItemIcon sx={sx.listItemIcon}>{icon}</ListItemIcon>;

  const loginButtons = (
    <>
      <ListItem>
        {listItemIcon(<LoginIcon />)}
        <ListItemText>Login</ListItemText>
      </ListItem>
      <List component="div" disablePadding>
        <ListItem sx={sx.subItemFlex}>
          <GoogleButton onlyIcon />
          <DiscordLoginButton onlyIcon />
        </ListItem>
      </List>
    </>
  );

  const openModalClosingMenu = (type: ActionType) => {
    openModal(type);
    setIsOpen(false);
  };

  const mustLoginTooltip = () => (
    <ClickAwayListener onClickAway={() => setIsLoginTooltipOpen(false)}>
      <Tooltip title={'Login to play'} disableHoverListener open={isLoginTooltipOpen} placement={'bottom'}>
        <HelpOutlineIcon onClick={toggleLoginTooltip} />
      </Tooltip>
    </ClickAwayListener>
  );

  return (
    <Box data-testid={dataTestId}>
      <SwipeableDrawer
        anchor="left"
        open={isOpen}
        onClose={() => setIsOpen(false)}
        onOpen={() => setIsOpen(true)}
        PaperProps={{ sx: sx.drawer }}
      >
        <Box sx={sx.line} />
        <Box sx={sx.logoRow}>
          <Box sx={sx.logoBox}>
            <Logo isOpen={isOpen} toggleMenu={toggleMenu} isVisible={true} />
          </Box>
        </Box>
        <List sx={sx.menuList}>
          <InstallButton />
          <ListItemButton onClick={() => navigateClosingMenu(ROOT_ROUTE)}>
            {listItemIcon(<HomeIcon />)}
            <ListItemText>Home</ListItemText>
          </ListItemButton>
          <ListItem>
            {listItemIcon(<SportsEsportsIcon />)}
            <ListItemText sx={sx.gameItem}>Game</ListItemText>
            {!profile && mustLoginTooltip()}
          </ListItem>
          <List component="div" disablePadding>
            <ListItemButton sx={sx.subItem} onClick={() => openModalClosingMenu('Create')} disabled={!profile}>
              {listItemIcon(<GroupsIcon />)}
              <ListItemText primary="Create game" />
            </ListItemButton>
            <ListItemButton sx={sx.subItem} onClick={() => openModalClosingMenu('Join')} disabled={!profile}>
              {listItemIcon(<PersonAddAlt1Icon />)}
              <ListItemText primary="Join Game" />
            </ListItemButton>
            <BackToGameButton onClick={() => setIsOpen(false)} />
          </List>
          <ListItemButton onClick={() => navigateClosingMenu(RULES_ROUTE)}>
            {listItemIcon(<ListAltIcon />)}
            <ListItemText>Rules</ListItemText>
          </ListItemButton>
          <ListItemButton onClick={() => navigateClosingMenu(ABOUT_US_ROUTE)}>
            {listItemIcon(<PeopleAltIcon />)}
            <ListItemText>About us</ListItemText>
          </ListItemButton>
          {profile && (
            <ListItemButton onClick={() => navigateClosingMenu(PROFILE_ROUTE)}>
              {listItemIcon(<AccountCircleIcon />)}
              <ListItemText>Profile</ListItemText>
            </ListItemButton>
          )}
          <Box sx={sx.divider}>
            <Divider />
          </Box>
          {profile ? <SignOutButton /> : loginButtons}
          <Box sx={sx.divider}>
            <Divider />
          </Box>
          <DonationWidgetsPanel newSx={sx.donationWidgets} />
        </List>
      </SwipeableDrawer>
      <Box sx={sx.modal}>{modal()}</Box>
    </Box>
  );
};
