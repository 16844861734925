import React from 'react';
import { Props } from './actionDrawerContextProvider.types';
import { ActionDrawerContext, ActionDrawerContextType } from './actionDrawerContext';

export const ActionDrawerContextProvider: React.FC<Props> = ({ children }) => {
  const [name, setName] = React.useState<string>('');
  const [content, setContent] = React.useState<React.ReactElement>();

  const initializeContext = (): ActionDrawerContextType => ({
    name: name,
    setName: setName,
    content: content,
    setContent: setContent,
  });

  return <ActionDrawerContext.Provider value={initializeContext()}>{children}</ActionDrawerContext.Provider>;
};
