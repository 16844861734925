import { ORIGINAL_DRAW_DECK_SIZE } from './drawDeck.types';
import { ProjectColors } from '../../themes/mainTheme';
import { zIndexes } from '../../constants/components';
import { getActiveActionButtonSx } from '../../helpers/styles';
import { DisplayMode } from '../../features/player/player.types';

const mobileIndentation = 10;
const desktopIndentation = 25;
const mobileTopPadding = '5px';
const mobileBottomIndentation = '20px';

export const initSx = (cardDimension: number, isActive: boolean, isMobile: boolean) => {
  const drawDeckSize = ORIGINAL_DRAW_DECK_SIZE * cardDimension * 0.0037;
  const highlightedBoxPadding = isMobile ? `${mobileIndentation}px` : `${desktopIndentation}px`;
  const highlightedBoxMargin = isMobile ? `-${mobileIndentation}px` : `-${desktopIndentation}px`;

  const highlightedBoxPaddingTop = isMobile ? mobileTopPadding : `${desktopIndentation}px`;

  const defaultBoxSx = {
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
    p: isMobile ? highlightedBoxPadding : 0,
    m: isMobile ? highlightedBoxMargin : 0,
    pt: isMobile ? mobileTopPadding : 0,
  };

  return {
    activeDeckAnimationSx: {
      right: highlightedBoxPadding,
      bottom: isMobile ? mobileBottomIndentation : highlightedBoxPadding,
      height: `calc(${drawDeckSize}px - 7%)`,
      width: `calc(${drawDeckSize}px - 7%)`,
      ...getActiveActionButtonSx(5, DisplayMode.MOBILE, ProjectColors.PURPLE),
      position: 'absolute',
      cursor: 'pointer',
      zIndex: zIndexes.one,
    },
    defaultImageSx: {
      height: drawDeckSize,
      width: drawDeckSize,
      filter: isActive ? 'none' : 'grayscale(90%)',
    },
    imageCardSx: {
      height: drawDeckSize,
      width: drawDeckSize,
    },
    outerBox: { ...defaultBoxSx },
    highlightedBox: {
      ...defaultBoxSx,
      p: highlightedBoxPadding,
      m: highlightedBoxMargin,
      pt: highlightedBoxPaddingTop,
      position: isMobile ? 'relative' : 'absolute',
      top: 0,
    },
    defaultBox: { ...defaultBoxSx },
    loading: {
      position: 'fixed',
      bottom: `calc(${cardDimension}px - 75%)`,
      right: `calc(${cardDimension}px - 75%)`,
      color: `${ProjectColors.PURPLE}`,
      zIndex: zIndexes.draggableTableElement.mainBox,
    },
  };
};
