import { SxStyles } from '../../../helpers/styles';
import { ProjectColors } from '../../../themes/mainTheme';

export const sxInit = (isMobile: boolean): SxStyles => {
  const border = `1px solid ${ProjectColors.GREY}`;

  return {
    headerTableCell: {
      border: border,
      textAlign: 'center',
      fontSize: '1rem',
      p: isMobile ? { xs: 1.2, sm: 1.8 } : { lg: 1.4, xl: 2 },
    },
    bodyTableCell: {
      border: border,
      overflowWrap: 'anywhere',
      textWrap: 'wrap',
      px: isMobile ? 1.1 : 1.5,
      py: isMobile ? { xs: 0.8, sm: 1.2 } : 1.8,
      fontSize: isMobile ? { xs: '0.7rem', sm: '0.9rem' } : '0.9rem',
    },
    table: { boarder: border },
    tableHeader: {
      boarder: border,
      backgroundColor: ProjectColors.PLAYER_BACKGROUND_TRANSPARENT,
    },
  };
};
