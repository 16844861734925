import React from 'react';
import { Props } from './card.types';
import { Identity } from '../../helpers/arrays';
import { roomItems, useTrackLocation } from '../../features/room/hooks/useTrackLocation';
import { Card } from './card';

export const CardIdentified: React.FC<Props & Identity> = (props) => {
  const { ref } = useTrackLocation({ items: [roomItems.card(props.id)] });

  return (
    <div ref={ref}>
      <Card {...props} />
    </div>
  );
};
