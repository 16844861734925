import { ProjectColors } from '../../themes/mainTheme';

export const sx = {
  drawer: {
    opacity: '90%',
    border: `2px solid ${ProjectColors.BLACK}`,
    height: '100%',
    width: '100%',
  },
};
