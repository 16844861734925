import { UseApplyCard } from './useApplyCard.types';
import React from 'react';
import { hasOneElement } from '../../../../helpers/arrays';
import { AllowedApplyType, PlayingCard } from '../../../card/cardTypes';
import { DragSource } from '../../../../components/draggable';
import { canPlayerChooseEnergySource, canTeleport, isCardRuleArsenalSlotEmpty } from '../../../game/rules/rules';
import { GameContext } from '../../../game/gameContext';
import { PlayerCardGroup } from '../../../game/gameContextHandler';
import { getCurrentPlayer, getPlayer } from '../../../game/gameContextHandler/utils/player';
import { useMoveCardAction } from '../../../game/actions/hooks/useMoveCardAction';
import { useApplyCardAction } from '../../../game/actions/hooks/useApplyCardAction';
import { ApplyCardMenuOption } from '../../../room/roomLayout/applyCardMenu';

export const useApplyCard: UseApplyCard = ({ droppedItem }) => {
  const { gameState, isDraggable, isDroppable, isAllowedToDrag } = React.useContext(GameContext);
  const currentPlayer = getCurrentPlayer(gameState);

  const { applyCardToOtherPlayer } = useApplyCardAction();
  const { moveCardBetweenPlayers } = useMoveCardAction();

  const [availableMenuOptions, setAvailableMenuOptions] = React.useState<ApplyCardMenuOption[]>([]);
  const clearAvailableMenuOptions = () => setAvailableMenuOptions([]);

  React.useEffect(() => {
    const toPlayerId = droppedItem?.toPlayerId;
    if (!droppedItem || !toPlayerId) return;

    const filteredOption = otherPlayerMenuOptions.filter((option) => option.isSatisfied(toPlayerId));
    if (hasOneElement(filteredOption)) {
      const option = filteredOption[0];
      option.run();
      return;
    }

    setAvailableMenuOptions(filteredOption);
  }, [droppedItem]);

  const isAllowedDragger = droppedItem?.dragger ? isAllowedToDrag(droppedItem.dragger) : false;

  const isApplyButtonVisible = (playerId: string): boolean => {
    const isDefending = gameState.defendingPlayerIds.includes(playerId);
    const card = droppedItem?.cards[0];

    return (
      isAllowedDragger &&
      card !== undefined &&
      card.allowedApplyTypes.includes(AllowedApplyType.OTHER_PLAYER) &&
      droppedItem?.dragSource !== DragSource.PLAYING_AREA &&
      (isDefending || gameState.defendingPlayerIds.length === 0)
    );
  };

  //todo move outside the hook
  const isToArsenalButtonVisible = (playerId: string): boolean => {
    const card = droppedItem?.cards[0];
    const player = getPlayer(gameState.allPlayers, playerId);

    return (
      canTeleport(gameState.turnActions) &&
      isAllowedDragger &&
      !!droppedItem &&
      card !== undefined &&
      card.isOpen &&
      isCardRuleArsenalSlotEmpty(card, player.cardsOnTable) &&
      isDroppable(playerId) &&
      !!droppedItem.fromPlayerId &&
      isDraggable(droppedItem.fromPlayerId)
    );
  };

  const isToHandButtonVisible = (playerId: string): boolean => {
    if (!canTeleport(gameState.turnActions) || !droppedItem) return false;

    switch (droppedItem.dragSource) {
      case DragSource.PLAYER: {
        const fromPlayerId = droppedItem.fromPlayerId;
        return isDroppable(playerId) && !!fromPlayerId && isDraggable(fromPlayerId) && isAllowedDragger;
      }
      case DragSource.PLAYING_AREA:
        return isAllowedDragger;
      default:
        return false;
    }
  };

  const onApply = () => {
    const card = droppedItem?.cards[0];
    if (!card || canPlayerChooseEnergySource(currentPlayer, card)) return;

    applyCard(card);
  };

  const applyCard = (card: PlayingCard, withCard?: PlayingCard) => {
    if (!droppedItem || !droppedItem.fromPlayerId || !droppedItem.toPlayerId) return;
    applyCardToOtherPlayer(droppedItem.fromPlayerId, droppedItem.toPlayerId, card, withCard);
    clearAvailableMenuOptions();
  };

  const cardToUser = (cardGroup: PlayerCardGroup) => {
    if (!droppedItem || !droppedItem.fromPlayerId || !droppedItem.toPlayerId) return;

    const card = droppedItem.cards[0];
    if (!card) return;

    moveCardBetweenPlayers(currentPlayer.id, droppedItem.fromPlayerId, droppedItem.toPlayerId, card, cardGroup);
    clearAvailableMenuOptions();
  };

  const toHand = () => cardToUser('cardsInHand');
  const toArsenal = () => cardToUser('cardsOnTable');

  const otherPlayerMenuOptions: ApplyCardMenuOption[] = [
    { isSatisfied: isApplyButtonVisible, run: onApply, label: 'Apply to player' },
    { isSatisfied: isToHandButtonVisible, run: toHand, label: 'To Hand' },
    { isSatisfied: isToArsenalButtonVisible, run: toArsenal, label: 'To Arsenal' },
  ];

  return {
    availableMenuOptions: availableMenuOptions,
    clearAvailableMenuOptions: clearAvailableMenuOptions,
    applyCard: applyCard,
  };
};
