import { ProjectColors } from '../../../../themes/mainTheme';

export const sx = {
  button: { backgroundColor: ProjectColors.PLAYER_BACKGROUND_TRANSPARENT, p: 1, mr: 0.5 },
  logo: {
    borderRadius: '100%',
    width: '30px',
    height: '30px',
    border: `1px dotted ${ProjectColors.PINK_LOGO}`,
    ':hover': { width: '40px', height: '40px' },
  },
};
