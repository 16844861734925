import { DELTARATION_API_AUTH_URL } from '../../constants/delarationApi';
import { ApiAuthRequest, Profile } from './authTypes';
import axios from 'axios';

class AuthApi {
  private static instance: AuthApi;

  public static getInstance(): AuthApi {
    if (!AuthApi.instance) {
      AuthApi.instance = new AuthApi();
    }

    return AuthApi.instance;
  }

  public async auth(authRequest: ApiAuthRequest): Promise<Profile> {
    const response = await axios.post(DELTARATION_API_AUTH_URL, authRequest, {
      headers: {
        'Content-Type': 'application/json',
      },
    });

    return response.data;
  }
}

export default AuthApi.getInstance();
