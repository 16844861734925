import { SxStyles } from '../../helpers/styles';
import { ProjectColors } from '../../themes/mainTheme';

export const sxInit = (isMobile: boolean): SxStyles => {
  const tabElementsFontSx = { fontSize: isMobile ? '0.9rem' : '1.1rem' };

  return {
    playedGamesInfo: {
      ...tabElementsFontSx,
      display: 'flex',
      justifyContent: 'space-between',
      px: isMobile ? { xs: '5%', sm: '10%' } : '5%',
      alignItems: 'center',
    },
    divider: { my: 2, borderColor: `${ProjectColors.GREY}` },
    leftSpanBox: { textAlign: 'start', mr: '0.5rem', overflowWrap: 'anywhere' },
    rightSpanBox: { textAlign: 'end' },
  };
};
