import { UseShellEffect } from './useShellEffect.types';
import { GameContext } from '../../../../game/gameContext';
import { getAttackingPlayerId, getCurrentPlayer } from '../../../../game/gameContextHandler/utils/player';
import { last } from '../../../../../helpers/arrays';
import { cardsAndActionTypesToActions, toImpactedAction } from '../../../../game/utils/turnActions';
import { ATTACKING_NEBULA_CARDS, CardNames } from '../../../cardTypes';
import { APPLIED, IN_PROGRESS } from '../../../../game/gameTypes';
import { filterIgnoredDefendingActions, getSourceColor } from '../../../../game/rules/rules';
import React from 'react';
import { Log, LogContext } from '../../../../game/logContextProvider';

export const useShellEffect: UseShellEffect = () => {
  const { gameState, performFinishDefending, addTurnAction } = React.useContext(GameContext);
  const { createLog } = React.useContext(LogContext);

  return () => {
    const currentPlayer = getCurrentPlayer(gameState);
    if (currentPlayer.id !== gameState.activeDefendingPlayer) return;
    if (currentPlayer.id === gameState.activePlayer && currentPlayer.id === getAttackingPlayerId(gameState)) return;

    const shell = currentPlayer.cardsOnTable.filter((card) => card.name === CardNames.SHELL)[0];
    if (shell === undefined) return;

    const lastAction = last(filterIgnoredDefendingActions(gameState.turnActions))?.action;
    if (lastAction === undefined) return;

    const actions = cardsAndActionTypesToActions(ATTACKING_NEBULA_CARDS, [APPLIED, IN_PROGRESS]);
    if (!actions.includes(lastAction)) return;

    const sourceColor = getSourceColor(gameState.turnActions, gameState.allPlayers, getAttackingPlayerId(gameState));
    if (sourceColor === undefined) return;

    if (!shell.energyColors.includes(sourceColor)) return;

    const log: Log = { type: 'info', text: `${currentPlayer.playerName}: protected by ${shell.name}` };
    addTurnAction({ player: currentPlayer.id, action: toImpactedAction(CardNames.SHELL), logs: [log] });
    createLog(log);
    performFinishDefending('successfulDefending');
  };
};
