import { ProjectColors } from '../../themes/mainTheme';
import { getActiveActionButtonSx } from '../../helpers/styles';
import { DisplayMode } from '../../features/player/player.types';

const defaultSx = { width: 'fit-contents' };
export const sx = {
  default: defaultSx,
  highlighted: {
    ...defaultSx,
    ...getActiveActionButtonSx(1.2, DisplayMode.MOBILE, ProjectColors.PURPLE),
  },
  hovered: {
    ...defaultSx,
    ...getActiveActionButtonSx(1.2, DisplayMode.MOBILE, ProjectColors.ACTIVE_PLAYER_BACKGROUND),
  },
};
