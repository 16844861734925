import { APPLIED, Action, IMPACTED, IN_PROGRESS, REARRANGED, CardActionType, TurnAction, CANCELED } from '../gameTypes';
import { CardNames, EnergyColors, PlayingCard } from '../../card/cardTypes';
import { GroupedCards, PlayerCardGroup } from '../gameContextHandler';
import { Player } from '../../player/player.types';
import { GameState, WithUpdatingState } from '../gameContextHandler/hooks/useGameState';
import { getAttackingPlayerId, getPlayerAppliedDome, getPlayerAppliedShell } from '../gameContextHandler/utils/player';
import { AddTurnAction } from '../gameContextHandler/hooks/useTurnActions';
import { filterUndefined, findLastIndex, haveSameElements, isEmpty, last } from '../../../helpers/arrays';

export const toAppliedAction = (cardName: CardNames): Action => `${cardName} ${APPLIED}`;
export const toImpactedAction = (cardName: CardNames): Action => `${cardName} ${IMPACTED}`;
export const toCanceledAction = (cardName: CardNames): Action => `${cardName} ${CANCELED}`;
export const toInProgressAction = (cardName: CardNames): Action => `${cardName} ${IN_PROGRESS}`;
export const toRearrangedAction = (cardName: CardNames): Action => `${cardName} ${REARRANGED}`;
export const toAppliedEnergy = (color: EnergyColors): Action => `${APPLIED} ${CardNames.ENERGY} with source ${color}`;
export const toDisintegratedAction = (cardGroup: PlayerCardGroup): Action => `disintegrated from ${cardGroup}`;
export const toTeleportedAction = (cardGroup: PlayerCardGroup): Action => `teleported from ${cardGroup}`;

export const cardsToInProgressImpactedActionsMap = (cards: CardNames[]) =>
  new Map<Action, Action>(cards.map((cardName) => [toInProgressAction(cardName), toImpactedAction(cardName)]));

export const cardsToInProgressCanceledActionsMap = (cards: CardNames[]) =>
  new Map<Action, Action>(cards.map((cardName) => [toInProgressAction(cardName), toCanceledAction(cardName)]));

export const cardsToAppliedInProgressActionsMap = (cards: CardNames[]) =>
  new Map<Action, Action>(cards.map((cardName) => [toAppliedAction(cardName), toInProgressAction(cardName)]));

export const cardsToActionsListMap = (cards: CardNames[], cardActionTypes: CardActionType[]) =>
  new Map<CardNames, Action[]>(
    cards.map((cardName) => {
      const actions: Action[] = [];
      if (cardActionTypes.includes(APPLIED)) {
        actions.push(toAppliedAction(cardName));
      }
      if (cardActionTypes.includes(IMPACTED)) {
        actions.push(toImpactedAction(cardName));
      }
      if (cardActionTypes.includes(IN_PROGRESS)) {
        actions.push(toInProgressAction(cardName));
      }
      if (cardActionTypes.includes(REARRANGED)) {
        actions.push(toRearrangedAction(cardName));
      }
      return [cardName, actions];
    })
  );

export const cardsAndActionTypesToActions = (cards: CardNames[], cardActionTypes: CardActionType[]) =>
  Array.from(cardsToActionsListMap(cards, cardActionTypes).values()).flat();

export const appliedEnergyActionsToColorsMap = () =>
  new Map<Action, EnergyColors>(Object.values(EnergyColors).map((color) => [toAppliedEnergy(color), color]));

export const actionToCardNameByCardActionType = (action: Action, type: CardActionType): CardNames | undefined => {
  const cardNamesPattern = Object.values(CardNames).join('|');
  const pattern = new RegExp(`^(${cardNamesPattern}) ${type}$`);
  const match = action.match(pattern);

  if (!match) return undefined;

  const cardName = match[1];
  return cardName as CardNames;
};

export const addTurnActionWithStateUpdateIfNeeded = (
  player: Player,
  action: Action,
  withUpdatingState: WithUpdatingState,
  addTurnAction: AddTurnAction
) => {
  const turnAction: TurnAction = {
    player: player.id,
    action: action,
  };

  if (isEmpty(filterUndefined([getPlayerAppliedDome(player), getPlayerAppliedShell(player)]))) {
    return withUpdatingState(addTurnAction)(turnAction);
  }

  addTurnAction(turnAction);
};

export const allAppliedCardActions = cardsAndActionTypesToActions(Object.values(CardNames), [APPLIED]);
export const allRearrangedCardActions = cardsAndActionTypesToActions(Object.values(CardNames), [REARRANGED]);

export const getGroupedCards = (turnAction: TurnAction, allPlayingCards: PlayingCard[]): GroupedCards => {
  const groupedCardIds = turnAction.groupedCardIds;
  if (!groupedCardIds) return new Map();

  const groupedCardIdsMap = new Map<PlayerCardGroup, string[]>(groupedCardIds);
  if (!groupedCardIdsMap) return new Map();

  const groupedCards = new Map<PlayerCardGroup, PlayingCard[]>();
  for (const [group, cardIdsInGroup] of groupedCardIdsMap.entries()) {
    groupedCards.set(
      group,
      allPlayingCards.filter((card) => cardIdsInGroup.includes(card.id))
    );
  }

  return groupedCards;
};

export const areSame = (action1: TurnAction, action2: TurnAction): boolean =>
  !(
    !action1 ||
    !action2 ||
    action1.player !== action2.player ||
    action1.action !== action2.action ||
    action1.appliedTo !== action2.appliedTo ||
    !haveSameElements(action1.cardIds ?? [], action2.cardIds ?? [])
  );

export const ignoreLastAction = (turnActions: TurnAction[], actionTypes: Action[]): TurnAction[] => {
  const lastAction = last(turnActions);
  if (!lastAction) return turnActions;

  return actionTypes.includes(lastAction.action) ? turnActions.slice(0, turnActions.length - 1) : turnActions;
};

export const ignoreProvidedActionTypes = (turnActions: TurnAction[], actionTypes: Action[]): TurnAction[] =>
  turnActions.filter((turnAction) => !actionTypes.includes(turnAction.action));

export const filterOutRangeIfStartEndExist = (params: {
  turnActions: TurnAction[];
  start: Action;
  end: Action;
}): TurnAction[] => {
  const { turnActions, start, end } = params;
  const endIndex = findLastIndex(turnActions, ({ action }) => action === end);
  if (endIndex < 0) return turnActions;

  const startIndex = findLastIndex(turnActions, ({ action }) => action === start);
  if (startIndex < 0) return turnActions;

  return [...turnActions.slice(0, startIndex), ...turnActions.slice(endIndex + 1)];
};

export const toImpactedTurnAction = (gameState: GameState, cardName: CardNames): TurnAction => ({
  player: getAttackingPlayerId(gameState),
  appliedTo: gameState.activeDefendingPlayer,
  action: toImpactedAction(cardName),
});

export const PROBABILITY_CAPSULE_ACTIONS: Action[] = [
  toAppliedAction(CardNames.FOGGER),
  toInProgressAction(CardNames.FOGGER),
  toImpactedAction(CardNames.FOGGER),
  toAppliedAction(CardNames.AMNESIA),
  toImpactedAction(CardNames.AMNESIA),
  'probability capsule randomized',
];

export const isActionForAppliedOrInProgressCard = (action: Action | undefined, cardNames: CardNames[]): boolean =>
  action === undefined ? false : cardsAndActionTypesToActions(cardNames, [APPLIED, IN_PROGRESS]).includes(action);
