import { zIndexes } from '../../constants/components';
import { SxStyles } from '../../helpers/styles';
import { keyframes } from '@emotion/react';
import { ProjectColors } from '../../themes/mainTheme';
import { AnimationType } from './movingEssence.types';
import { getRandom } from '../../helpers/arrays';
import { MAX_WIDTH_MODIFIER } from '../card/hooks/useCardDimension';
import { Location } from '../../features/game/locationTrackerContextProvider';

const disperserAnimateIn = keyframes`
  0%, 100% {
    background: radial-gradient(closest-side, transparent 0%, ${ProjectColors.SCARLET} 35%, transparent 70%);
  }
  50% {
    background: radial-gradient(closest-side, transparent 0%, ${ProjectColors.SCARLET} 15%, transparent 60%);
  }
`;

const disperserAnimateOut = keyframes`
  from {
    background: radial-gradient(ellipse at center, transparent 0%, ${ProjectColors.ESSENCE} 30%, transparent 100%);
  }
  to {
    background: radial-gradient(ellipse at center, ${ProjectColors.ESSENCE} 0%, transparent 100%);
  }
`;

const rotateEssence = keyframes`
  0% { transform: scale(0) rotate(-40deg); }
  10% { transform: scale(1.1); }
  50% { transform: scale(${Math.random()}) rotate(40deg); }
  100% { transform: scale(1) rotate(-40deg); }
`;

const zoomIn = keyframes`
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
`;

const zoomOut = keyframes`
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
`;

const ROTATION_DEGREES = [-540, -360, -270, -180, 180, 270, 360, 540, 630, 720];

export const sxInit = (animationType: AnimationType, cardDimension: number, disperserLocation: Location): SxStyles => {
  return {
    disperserMainBox: {
      width: `${cardDimension}px`,
      height: `${cardDimension}px`,
      borderRadius: '50%',
      overflow: 'hidden',
      background: `radial-gradient(closest-side, ${ProjectColors.PURPLE_VERY_DARK}) 0%, ${ProjectColors.DARK_GREY_TRANSPARENT} 60%, ${ProjectColors.GREY_TRANSPARENT}, ${ProjectColors.TRANSPARENT})`,
      animation: `${
        animationType === AnimationType.ADDITION ? disperserAnimateOut : disperserAnimateIn
      } 2s infinite linear`,
      '&:before': {
        content: '""',
        display: 'block',
        position: 'absolute',
        top: '-50%',
        left: '-50%',
        width: '200%',
        height: '200%',
        opacity: '5%',
        background: `radial-gradient(closest-side, ${ProjectColors.PURPLE} 90%, ${ProjectColors.TRANSPARENT})`,
        animation: `${animationType === AnimationType.ADDITION ? zoomIn : zoomOut} 3s infinite forwards`,
      },
      '&:after': {
        content: '""',
        display: 'block',
        position: 'absolute',
        top: '5%',
        left: '5%',
        width: '90%',
        height: '90%',
        opacity: '10%',
        background: `radial-gradient(closest-side, ${ProjectColors.TRANSPARENT} 55%, ${ProjectColors.PURPLE}, ${ProjectColors.TRANSPARENT} 100%)`,
        animation: `${animationType === AnimationType.ADDITION ? zoomIn : zoomOut} 1s infinite forwards`,
      },
    },
    disperserMovingBox: {
      top: `${disperserLocation.y}px`,
      left: `${disperserLocation.x}px`,
      position: 'absolute',
      borderRadius: '50%',
      overflow: 'hidden',
      zIndex: zIndexes.movingEssenceAnimation,
    },
    essenceMovingBox: {
      position: 'absolute',
      zIndex: zIndexes.movingEssenceAnimation,
    },
  };
};

export const generateMovingEssenceBox = (cardDimension: number) => {
  return {
    width: '1.5px',
    height: `${cardDimension * MAX_WIDTH_MODIFIER}px`,
    boxShadow: `0 0 10px 3px ${ProjectColors.ESSENCE}`,
    borderRadius: '15%',
    border: `0.5px ridge ${ProjectColors.PURPLE}`,
    background: `${ProjectColors.ESSENCE}`,
    animation: `${rotateEssence} ${Math.random() + 1}s infinite linear`,
    transformOrigin: 'center',
    position: 'absolute',
    zIndex: zIndexes.movingEssenceAnimation,
  };
};

export const generateDisperserEssenceBox = (cardDimension: number) => {
  return {
    width: `${cardDimension}px`,
    height: `${cardDimension}px`,
    borderRadius: '50%',
    overflow: 'hidden',
    background: `radial-gradient(closest-side, ${ProjectColors.PURPLE_VERY_DARK} 0%, ${ProjectColors.DARK_GREY_TRANSPARENT} 60%, ${ProjectColors.GREY_TRANSPARENT}, ${ProjectColors.TRANSPARENT})`,
    '&:before': generateDisperserChaoticEssence(cardDimension),
    '&:after': generateDisperserChaoticEssence(cardDimension),
  };
};

const generateDisperserChaoticEssence = (cardDimension: number) => {
  const gyroDisperserEssences = keyframes`
    0% {
        transform: scale(1.3) rotate(0deg) translateY(calc(${cardDimension} * 2.3%));
    }
    25% {
        transform: scale(${Math.random()}) rotate(${getRandom(
    ROTATION_DEGREES
  )}deg) translateY(0) translateX(calc(${cardDimension} * 2.8%));
    }
    60% {
        transform: scaleY(${Math.random() + 1}) rotate(${getRandom(
    ROTATION_DEGREES
  )}deg) translateY(calc(${cardDimension} * 0.7%));
    }
    80% {
        transform: scale(${Math.random()}) rotate(${getRandom(
    ROTATION_DEGREES
  )}deg) translateY(0) translateX(calc(${cardDimension} * 1.8%));
    }
    100% {
        transform: scale(0.9) rotate(0deg) translateY(calc(${cardDimension} * 2.8%));
    }
  `;

  return {
    content: '""',
    display: 'block',
    position: 'absolute',
    width: '1.5px',
    height: `${cardDimension * MAX_WIDTH_MODIFIER}px`,
    borderRadius: '15%',
    transformOrigin: 'center',
    top: `${getRandom([20, 30, 40, 50, 60])}%`,
    left: `${getRandom([20, 40, 50, 60, 80])}%`,
    border: `0.5px ridge ${ProjectColors.PURPLE}`,
    background: `${ProjectColors.ESSENCE}`,
    animation: `${gyroDisperserEssences} ${getRandom([9, 10, 11, 12, 13])}s infinite linear`,
  };
};
