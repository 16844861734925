import { UseSocketClient } from './useSocketClient.types';
import React from 'react';
import { AppContext } from '../../../../../global/context/appContext';
import { useGameId } from '../useGameId';
import { GameState as GameStateSocketData } from '../../../../../socketIo/SocketData';
import { gameStateApiDataToApiGameState } from '../../../gameTransformers';
import { GameStateApiData } from '../../../gameTypes';
import { IS_LOG_BLOCK_CLOSED_STORAGE_KEY } from '../../../../../constants/components';
import { isFlagOn } from '../../../../../helpers/featureFlags';
import { Log, LogContext } from '../../../logContextProvider';
import { useActiveSessionDetector } from '../../../activeSessionDetector/hook';

export const useSocketClient: UseSocketClient = ({
  setPlayersAdded,
  setGameStateHash,
  setApiGameState,
  invalidateGameCache,
}) => {
  const { socketClient, profile } = React.useContext(AppContext);
  const { fetchLogs, setNotification } = React.useContext(LogContext);
  const id = useGameId();
  const { onPlayerJoined } = useActiveSessionDetector();

  React.useEffect(() => {
    if (profile !== null) {
      socketClient.socket.emit('joinGame', id, profile.playerId);
    }
  }, [profile]);

  React.useEffect(() => {
    socketClient.socket.on('connect', () => {
      console.log('client reconnected', socketClient.socket.recovered); //TODO remove at some point

      if (profile !== null && !socketClient.socket.recovered) {
        socketClient.socket.emit('joinGame', id, profile.playerId);
      }
    });
    socketClient.socket.on('disconnect', (reason, description) => {
      console.log('client disconnected', reason, description); //TODO remove at some point
    });
    socketClient.socket.io.on('reconnect_attempt', (attempt) => {
      console.log('client is attempting to reconnect', attempt); //TODO remove at some point
    });
    socketClient.socket.io.on('reconnect_error', (error) => {
      console.log('client reconnection ended in error', error); //TODO remove at some point
    });
    socketClient.socket.io.on('reconnect_failed', () => {
      console.log('client reconnection failed'); //TODO remove at some point
    });

    socketClient.socket.on('gameJoined', (gameId: string, playerId: string) => {
      onPlayerJoined(playerId);
      setPlayersAdded((currentCount) => ++currentCount);
      invalidateGameCache(gameId);
    });

    socketClient.socket.on('gameStateUpdated', (gameStateData: GameStateSocketData) => {
      setGameStateHash(gameStateData.dataHash);
      setApiGameState(gameStateApiDataToApiGameState(gameStateData));
    });

    socketClient.socket.on('notificationCreated', (notificationData: Log) => {
      const isLogBlockClosed = isFlagOn(IS_LOG_BLOCK_CLOSED_STORAGE_KEY);
      if (isLogBlockClosed) setNotification(notificationData);
      void fetchLogs();
    });

    socketClient.socket.on('gameStarted', invalidateGameCache);
    socketClient.socket.on('gameFinished', invalidateGameCache);

    return () => {
      socketClient.socket.off('gameJoined');
      socketClient.socket.off('gameStateUpdated');
      socketClient.socket.off('notificationCreated');
    };
  }, []);

  return {
    emitGameStateUpdated: (gameState: GameStateApiData) => {
      socketClient.socket.emit('gameStateUpdated', gameState);
    },
  };
};
