import { DroppedCardData, UseDroppedToPlayer } from './useDroppedToPlayer.types';
import React from 'react';
import { DraggedCardData } from '../../../../components/draggable';
import { canTeleportRadiance } from '../../../game/rules/rules';
import { GameContext } from '../../../game/gameContext';
import { getCurrentPlayer } from '../../../game/gameContextHandler/utils/player';
import { useMoveCardAction } from '../../../game/actions/hooks/useMoveCardAction';

export const useDroppedToPlayer: UseDroppedToPlayer = () => {
  const { gameState } = React.useContext(GameContext);
  const currentPlayer = getCurrentPlayer(gameState);

  const { moveCardBetweenPlayers } = useMoveCardAction();

  const [droppedItem, setDroppedItem] = React.useState<DroppedCardData>();

  const onDroppedToPlayer = (item: DraggedCardData, droppedToId: string) => {
    if (!!item.fromPlayerId && canTeleportRadiance(item, gameState.turnActions)) {
      moveCardBetweenPlayers(currentPlayer.id, item.fromPlayerId, droppedToId, item.cards[0], 'activeRadiances');
      return;
    }

    setDroppedItem({ ...item, toPlayerId: droppedToId });
  };

  const addDroppedItemToEngagementZone = (item: DraggedCardData) => setDroppedItem({ ...item });

  return {
    droppedItem: droppedItem,
    onDroppedToPlayer: onDroppedToPlayer,
    addDroppedItemToEngagementZone: addDroppedItemToEngagementZone,
    clearDroppedItem: () => setDroppedItem(undefined),
  };
};
