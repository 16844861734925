import { SxProps } from '@mui/material';

export type Props = {
  playerId: string;
  count: number;
  disperserLocation?: DisperserLocation;
  customSx?: SxProps;
};

export const DANGEROUS_ESSENCE_LIMIT_START = 1;
export const DANGEROUS_ESSENCE_LIMIT_END = 3;
export const AVERAGE_ESSENCE_LIMIT_START = 4;
export const AVERAGE_ESSENCE_LIMIT_END = 6;

export const ONE_ESSENCE = 1;

export enum DisperserLocation {
  LEFT,
  RIGHT,
  TOP,
  BOTTOM,
}

export const modifyExtremeEssencePercentagePosition = (position: number) => {
  if (position >= 95 || (position >= 35 && position <= 50)) {
    return position - 10;
  }

  if (position <= 5 || (position >= 50 && position <= 65)) {
    return position + 10;
  }

  return position;
};
