import { UseSoundEffect } from './useApplyGameStateActionSounds.types';
import { SoundPath } from '../../../../../assets/assets';
import React from 'react';
import { getCurrentPlayer } from '../../utils/player';
import { isEmpty, last } from '../../../../../helpers/arrays';
import { TurnActionTimeStamped } from '../../../gameTypes';
import { allAppliedCardActions, areSame } from '../../../utils/turnActions';
import { useSoundEffects } from '../../../actions/hooks/useSoundEffects';

export const useApplyGameStateActionSounds: UseSoundEffect = ({ gameState }) => {
  const { addMySound, applySoundByOtherPlayerAction } = useSoundEffects({ gameState: gameState });

  const [currentPlayer, setCurrentPlayer] = React.useState('');
  const [activePlayer, setActivePlayer] = React.useState('');
  const [defendingPlayer, setDefendingPlayer] = React.useState('');
  const [lastAction, setLastAction] = React.useState<TurnActionTimeStamped | undefined>(undefined);

  React.useEffect(() => {
    if (gameState === undefined || gameState.activePlayer === '') return;

    setCurrentPlayer(getCurrentPlayer(gameState).id);
    setActivePlayer(gameState.activePlayer);
    setDefendingPlayer(gameState.activeDefendingPlayer);

    const lastAppliedAction = last(
      gameState.turnActions.filter((action) => allAppliedCardActions.includes(action.action))
    );

    if (
      lastAppliedAction &&
      lastAction &&
      areSame(lastAppliedAction, lastAction) &&
      lastAppliedAction?.timestamp === lastAction?.timestamp
    ) {
      return;
    }

    setLastAction(lastAppliedAction);
  }, [gameState]);

  React.useEffect(() => {
    if (currentPlayer === '' || activePlayer !== currentPlayer || !isEmpty(gameState.turnActions)) return;
    addMySound(SoundPath.startTurn);
  }, [activePlayer]);

  React.useEffect(() => {
    if (currentPlayer === '' || defendingPlayer !== currentPlayer) return;
    addMySound(SoundPath.startDefending);
  }, [defendingPlayer]);

  React.useEffect(() => {
    if (lastAction === undefined || lastAction.player === currentPlayer) return;

    applySoundByOtherPlayerAction(lastAction.action);
  }, [lastAction]);
};
