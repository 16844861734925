import { isFlagSet, setValue } from '../../../../helpers/featureFlags';
import { DEFAULT_PLAYERS_VOLUME_LEVEL } from '../../../../constants/components';
import { WithDataTestId } from '../../../../helpers/types';

export type Props = {
  key: string;
} & WithDataTestId;

export const setDefaultVolumeLevelFlag = (soundLevelFlag: string) => {
  if (!isFlagSet(soundLevelFlag)) setValue(soundLevelFlag, DEFAULT_PLAYERS_VOLUME_LEVEL.toString());
};
