import { ApiGameState } from './gameContextHandler/hooks/useGameState';

export const validateGameStateCards = (gameState: ApiGameState) => {
  const ids = [];
  ids.push(...gameState.cardsInDeck);
  ids.push(...gameState.cardsInDiscardPile);
  ids.push(...gameState.cardsInPlayingArea);
  gameState.playersGameState.forEach((playerState) => {
    ids.push(...playerState.cardsInHand);
    ids.push(...playerState.cardsOnTable);
    ids.push(...playerState.activeRadiances);
  });

  const idsSet = new Set(ids);
  const arraySize = ids.length;
  const setLength = idsSet.size;
  if (arraySize !== setLength) {
    const errMsg = `Card length mismatch. Unique count: ${setLength}, Actual: ${arraySize}`;
    console.error(errMsg);
  }
};

export const validateCount = (allowedCount: number, event: string) => {
  let stateUpdatedCount = 0;
  return () => {
    stateUpdatedCount++;
    if (stateUpdatedCount > allowedCount) {
      console.error(`'${event}' happened ${stateUpdatedCount} times, when expected ${allowedCount} times`);
    }
  };
};
