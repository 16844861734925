import React from 'react';
import { Position, Props } from './radiances.types';
import { sx } from './radiances.styles';
import { PlayingCard } from '../../../../card/cardTypes';
import { Box } from '@mui/material';
import { CardPictogram } from '../../../../../components/card/pictogram';
import { resolveCardImage } from '../../../../card/cardTransformers';
import { sortByDirection } from '../../../../../helpers/player';
import { ActionDrawerContext } from '../../../../room/mobile/contexts/actionDrawerContext/actionDrawerContext';
import { CardLContent } from '../../../../room/mobile/components/actionDrawer/cardLContent';
import { Draggable, DraggedCardData, DragSource } from '../../../../../components/draggable';
import { wrap, WrapperWithProps } from '../../../../../helpers/wrappers';

export const Radiances: React.FC<Props> = ({ activeRadiances, blocksDirection, playerId, playerName, isDraggable }) => {
  const { setContent, setName } = React.useContext(ActionDrawerContext);

  const onCardShow = (cardToShow: PlayingCard) => {
    setContent(<CardLContent card={cardToShow} />);
    setName(`Card from ${playerName}'s radiances`);
  };

  const cardComponent = (card: PlayingCard, position: Position) => {
    const data: DraggedCardData = { dragSource: DragSource.APPLIED_RADIANCE, fromPlayerId: playerId, cards: [card] };
    const wrappers: WrapperWithProps[] = isDraggable ? [[Draggable, { draggableData: data }]] : [];

    return (
      <Box key={`applied-${card.name}-${playerId}`} sx={sx.cardWrapper(position)} onClick={() => onCardShow(card)}>
        {wrap(
          <CardPictogram
            id={card.id}
            isOpen
            imagePath={resolveCardImage(card.name, card.energyColors)}
            content={{ type: card.playingCardType, name: card.name, details: card.details }}
            onClick={() => console.log('radiance card has been clicked')}
          />,
          wrappers
        )}
      </Box>
    );
  };

  const positions: Position[] = sortByDirection(['right', 'left'], blocksDirection);
  const elements = activeRadiances.map((radianceCard, index) => cardComponent(radianceCard, positions[index]));

  return <>{elements}</>;
};
