import React from 'react';
import { Props } from './googleButton.types';
import { CredentialResponse, GoogleLogin } from '@react-oauth/google';
import AuthApi from '../../auth/authApi';
import { ApiAuthRequest, AuthProvider } from '../../auth/authTypes';
import { PROFILE_STORAGE_KEY } from '../../../constants/login';
import { useSnackbar } from 'notistack';
import { AppContext } from '../../../global/context/appContext';

export const GoogleButton: React.FC<Props> = ({ onlyIcon }) => {
  const { enqueueSnackbar } = useSnackbar();
  const { refreshProfile } = React.useContext(AppContext);

  const onFailure = () => {
    enqueueSnackbar('Failed to login', { variant: 'error' });
  };

  const onSuccess = async (credentialResponse: CredentialResponse) => {
    if (!credentialResponse.credential) {
      onFailure();
      return;
    }

    try {
      const request: ApiAuthRequest = {
        credential: credentialResponse.credential,
        authProvider: AuthProvider.GOOGLE,
      };
      const response = await AuthApi.auth(request);
      localStorage.setItem(PROFILE_STORAGE_KEY, JSON.stringify(response));
      refreshProfile();
      enqueueSnackbar('Successfully signed in with Google', { variant: 'success' });
    } catch (e) {
      onFailure();
    }
  };

  return (
    <GoogleLogin theme="filled_black" type={onlyIcon ? 'icon' : 'standard'} onSuccess={onSuccess} onError={onFailure} />
  );
};
