import { MAX_SIZE_CARD_DIMENSION, MAX_WIDTH_MODIFIER, UseCardDimensionType } from './useCardDimension.types';
import React from 'react';
import { AppContext } from '../../../../global/context/appContext';
import { CARD_SIZE_MODIFIERS } from '../../card.types';
import { isPortraitOrientation } from '../../../../helpers/utils';

export const useCardDimension: UseCardDimensionType = ({ size }) => {
  const { windowSize, screenSizeModifier, isMobile } = React.useContext(AppContext);

  const calculateDimension = () => {
    const dimension = screenSizeModifier * CARD_SIZE_MODIFIERS[size] * MAX_SIZE_CARD_DIMENSION;
    if (isMobile && isPortraitOrientation()) return dimension;

    const maxWidth = windowSize.width * MAX_WIDTH_MODIFIER;
    return dimension > maxWidth ? maxWidth : dimension;
  };

  return { dimension: calculateDimension() };
};
