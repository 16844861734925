import { zIndexes } from '../../../../../../constants/components';
import { SxStyles } from '../../../../../../helpers/styles';

export const sx: SxStyles = {
  outerBox: {
    m: 0,
    position: 'absolute',
    width: `100%`,
    borderTop: 'none',
    py: 1,
    px: 1,
    zIndex: zIndexes.expandedOtherPlayerHand,
  },
  innerBox: { px: 0.1 },
};
