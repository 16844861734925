import { ProjectColors } from '../../themes/mainTheme';
import { logoStyles, SxStyles } from '../../helpers/styles';
import { zIndexes } from '../../constants/components';
import { CONTENT_BOX_SX } from '../../constants/styles';

export const sxInit = (isMobile: boolean): SxStyles => {
  const marginSx = isMobile ? { m: '10px', mt: '20px' } : { mx: '35px', mt: '25px' };
  const mainBoxPadding = isMobile ? { p: 2 } : { p: 4 };

  const dataBoxSx = {
    ...mainBoxPadding,
    ...CONTENT_BOX_SX,
    minHeight: 'initial',
    maxHeight: 'initial',
    flex: 1,
  };

  return {
    logoBox: {
      ...logoStyles(),
      top: '35%',
    },
    storyComicsBox: {
      ...dataBoxSx,
      position: 'relative',
      padding: 0,
      aspectRatio: '16 / 9',
    },
    gameStatBox: {
      ...dataBoxSx,
      position: 'relative',
      p: 0,
      mb: 3,
      minHeight: isMobile ? '280px' : '240px',
    },
    playNowBox: {
      ...dataBoxSx,
      position: 'relative',
      p: 1,
      mb: 3,
    },
    titleBox: {
      ...marginSx,
      mt: { xs: 5, lg: 3 },
      border: `0px`,
      zIndex: zIndexes.titleBox,
      position: 'relative',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
    },
    title: {
      textAlign: 'center',
      backgroundColor: ProjectColors.PURPLE_VERY_DARK_TRANSPARENT,
      width: { xs: '100%', lg: '70%' },
      maxWidth: '1000px',
      p: { xs: 2, lg: 5 },
      fontSize: { xs: '0.8rem', md: '0.9rem', lg: '1rem' },
    },
  };
};
