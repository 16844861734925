import { ProjectColors } from '../../../themes/mainTheme';
import { settingsWidgetStyles } from '../../../helpers/styles';
import { zIndexes } from '../../../constants/components';

export const sx = {
  rulesButton: { backgroundColor: ProjectColors.PLAYER_BACKGROUND_TRANSPARENT, p: 1, mr: 0.5 },
  rulesIcon: (iconSize?: string) => (iconSize !== undefined ? { fontSize: iconSize } : settingsWidgetStyles),
  drawer: {
    paper: { width: '50%' },
    box: { zIndex: zIndexes.ruleBookDrawer },
  },
  closeButton: {
    box: { width: '100%', display: 'flex', justifyContent: 'start' },
    item: { borderRadius: 10 },
  },
};
