import React from 'react';
import { SocialNetwork, UseSocialNetworks } from './useSocialNetworks.types';
import { fromTo } from '../../transformers';
import { IconBaseProps } from 'react-icons';
import { FaDiscord, FaFacebook, FaLinkedin, FaReddit, FaTwitter, FaInstagram } from 'react-icons/fa';

export const useSocialNetworks: UseSocialNetworks = () => {
  const SOCIAL_NETWORKS_BASE_URLS: fromTo<SocialNetwork, string> = {
    [SocialNetwork.DISCORD_USER]: 'https://discord.com/users/',
    [SocialNetwork.DISCORD_SERVER]: 'https://discord.gg/',
    [SocialNetwork.TWITTER]: 'https://twitter.com/',
    [SocialNetwork.LINKEDIN]: 'https://www.linkedin.com/in/',
    [SocialNetwork.FACEBOOK]: 'https://www.facebook.com/',
    [SocialNetwork.REDDIT]: 'https://www.reddit.com/r/',
    [SocialNetwork.INSTAGRAM]: 'https://www.instagram.com/',
  };

  const formatUrl = (socialNetwork: SocialNetwork, id: string) => `${SOCIAL_NETWORKS_BASE_URLS[socialNetwork]}${id}`;

  const getIcon = (socialNetwork: SocialNetwork, props?: IconBaseProps) => {
    switch (socialNetwork) {
      case SocialNetwork.TWITTER:
        return <FaTwitter {...props} />;
      case SocialNetwork.DISCORD_USER:
      case SocialNetwork.DISCORD_SERVER:
        return <FaDiscord {...props} />;
      case SocialNetwork.LINKEDIN:
        return <FaLinkedin {...props} />;
      case SocialNetwork.FACEBOOK:
        return <FaFacebook {...props} />;
      case SocialNetwork.REDDIT:
        return <FaReddit {...props} />;
      case SocialNetwork.INSTAGRAM:
        return <FaInstagram {...props} />;
    }
  };

  return { formatUrl: formatUrl, getIcon: getIcon };
};
