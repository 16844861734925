import { Box, Typography } from '@mui/material';
import React from 'react';
import { Props } from './essenceLossOnCardDrawModal.types';
import { sx } from './essenceLossOnCardDrawModal.styles';
import Button from '@mui/material/Button';
import { Modal } from '../modal';

export const EssenceLossOnCardDrawModal: React.FC<Props> = ({ isOpen, onClose, onConfirm }) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <Box sx={sx.mainBox}>
        <Typography id="finished-game-modal-title" variant="h5" component="h2" textAlign={'center'}>
          Lose 1 essence for possibility to draw 2 additional cards?
        </Typography>
        <Box sx={sx.actionBox}>
          <Button variant="contained" disabled={false} onClick={onConfirm} sx={sx.approveButton}>
            Confirm
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};
