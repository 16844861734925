import { SizeExtended } from '../../../../helpers/sizes';
import { fromTo } from '../../../../helpers/transformers';

export type UseCardDimensionResult = {
  dimension: number;
};

export type UseCardDimensionProps = {
  size: SizeExtended;
};

export type UseCardDimensionType = (props: UseCardDimensionProps) => UseCardDimensionResult;

export const MAX_SIZE_CARD_DIMENSION = 403;
export const MAX_WIDTH_MODIFIER = 0.26;

export const CARD_FONT_MODIFIERS: fromTo<SizeExtended, number> = {
  [SizeExtended.L]: 0.06,
  [SizeExtended.M]: 0.11,
  [SizeExtended.S]: 0.11,
  [SizeExtended.XS]: 0.11,
  [SizeExtended.XXS]: 0.11,
};
