import React from 'react';
import { UseDisplayMode } from './useDisplayMode.types';
import { AppContext } from '../../../../global/context/appContext';
import { MEDIUM_SIZE_HEIGHT } from '../../../../constants/components';
import { GameContext } from '../../../game/gameContext';
import { NumberOfPlayers } from '../../../game/gameTypes';
import { getOtherPlayers } from '../../../game/gameContextHandler/utils/player';
import { DisplayMode } from '../../player.types';

export const useDisplayMode: UseDisplayMode = () => {
  const { windowSize, isMobile } = React.useContext(AppContext);
  const { gameState } = React.useContext(GameContext);

  return React.useMemo(() => {
    if (isMobile) {
      return DisplayMode.COMPACT;
    }

    if (windowSize.height < MEDIUM_SIZE_HEIGHT && getOtherPlayers(gameState).length > NumberOfPlayers.FOUR) {
      return DisplayMode.COMPACT;
    }

    return DisplayMode.STANDARD;
  }, [windowSize]);
};
