import { WindowSize } from '../../helpers/utils';
import { getBackgroundBoxSx, roomCommonStyles } from '../../helpers/styles';
import { zIndexes } from '../../constants/components';

export const sxInit = (windowSize: WindowSize, isMobile: boolean) => {
  return {
    mainBox: { ...getBackgroundBoxSx(windowSize, isMobile), overflowY: 'hidden', p: 0 },
    widgets: { position: 'relative', zIndex: zIndexes.roomWidgets },
    ...roomCommonStyles(windowSize),
  } as const;
};
