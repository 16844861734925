import GameApi from '../../../features/game/gameApi/gameApi';
import React from 'react';
import StatsApi from '../../../features/stats/statsApi';
import PlayerApi from '../../../features/player/playerApi';
import PredictionApi from '../../../features/prediction/predictionApi';

export type ApiContextType = {
  gameApi: GameApi;
  statsApi: StatsApi;
  playerApi: PlayerApi;
  predictionApi: PredictionApi;
};

export const ApiContext = React.createContext({} as ApiContextType);
