import React from 'react';
import { Props } from './socialNetworks.types';
import { SocialNetwork, useSocialNetworks } from '../../helpers/hooks/useSocialNetworks';
import styles from './socialNetworks.module.css';

export const SocialNetworks: React.FC<Props> = () => {
  const { formatUrl, getIcon } = useSocialNetworks();

  const iconProps = { size: 30 };

  const iconButton = (sn: SocialNetwork, snUserId: string) => (
    <a
      href={formatUrl(sn, snUserId)}
      target={'_blank'}
      rel={'noreferrer'}
      aria-label={`${sn} link`}
      className={styles.iconButton}
    >
      {getIcon(sn, iconProps)}
    </a>
  );

  return (
    <div className={styles.mainContainer}>
      <p className={styles.notification}>Find us</p>
      <div className={styles.socialButtonsContainer}>
        {iconButton(SocialNetwork.DISCORD_SERVER, 'RseQQutth7')}
        {iconButton(SocialNetwork.INSTAGRAM, 'deltaration')}
        {iconButton(SocialNetwork.FACEBOOK, 'deltaration')}
        {iconButton(SocialNetwork.TWITTER, 'deltaration')}
        {iconButton(SocialNetwork.REDDIT, 'deltaration')}
      </div>
    </div>
  );
};
