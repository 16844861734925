import { UsePreparePlayingCards } from './usePreparePlayingCards.types';
import { CardNames, PlayingCard } from '../../../../card/cardTypes';
import { GameApiData } from '../useGameState';
import { GameMode } from '../../../gameTypes';

export const usePreparePlayingCards: UsePreparePlayingCards = () => {
  const filterByGame = (allCards: PlayingCard[], game: GameApiData): PlayingCard[] => {
    return allCards.filter((card) => {
      const isCardRestricted = game.numberOfPlayers === 3 && card.name === CardNames.CONVERSIONER;

      return card.gameModes.includes(game.mode) && !isCardRestricted;
    });
  };

  const filterByGameMode = (allCards: PlayingCard[], gameMode: GameMode): PlayingCard[] => {
    const playingCards = allCards
      .filter((card) => card.gameModes.includes(gameMode.name.toLowerCase()))
      .map((card) => ({ ...card, isOpen: true }));
    const seenNames = new Set();

    return playingCards.filter((card) => {
      if (seenNames.has(card.name)) {
        return false;
      } else {
        seenNames.add(card.name);
        return true;
      }
    });
  };

  return {
    filterByGame: filterByGame,
    filterByGameMode: filterByGameMode,
  };
};
