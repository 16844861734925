import { ProjectColors } from '../../../../../themes/mainTheme';
import { NumberOfPlayers } from '../../../../game/gameTypes';
import { zIndexes } from '../../../../../constants/components';

export const initSx = (cardDimension: number, otherPlayersNumber: number) => {
  return {
    engagementZone: {
      position: 'relative',
      width: otherPlayersNumber > NumberOfPlayers.FIVE ? '30%' : '40%',
      m: -playersRowPadding(cardDimension),
      zIndex: zIndexes.one,
    },
    probabilityCapsuleBox: {
      top: '10%',
      position: 'absolute',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: '100%',
      height: '1.25rem',
    },
    outerBox: {
      flex: 1,
      backgroundColor: `${ProjectColors.PURPLE_VERY_DARK_TRANSPARENT}`,
      border: `2px solid ${ProjectColors.WHITE}`,
      py: 0.5,
      px: 1,
    },
    textAndCardsBox: {
      borderRadius: 2,
      height: `${cardDimension * 1.5}px`,
      display: 'grid',
      alignContent: 'center',
    },
    cardGroup: { overflow: 'auto', p: 0 },
    placeholder: {
      m: 'auto',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      alignContent: 'center',
      fontSize: { xs: '0.5rem', md: '0.7rem', lg: '1rem' },
    },
  } as const;
};

const playersRowPadding = (cardDimension: number) => cardDimension * 0.03;
