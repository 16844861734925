import React from 'react';
import { Props } from './droppable.types';
import { Droppable as BasicDroppable } from '../../../components/droppable';
import { GameContext } from '../../game/gameContext';
import { APPLY_RULES_BY_GROUP } from '../../card/cardTransformers';
import { DraggedCardData } from '../../../components/draggable';
import { hasOneElement } from '../../../helpers/arrays';

export const Droppable: React.FC<Props> = ({ playerId, applyRuleGroup, onItemDropped, children, canDrop }) => {
  const { isDroppable, isAllowedToDrag, isDraggable } = React.useContext(GameContext);
  const allowedApplyRules = APPLY_RULES_BY_GROUP[applyRuleGroup];

  const basicCanDrop = (item: DraggedCardData) => {
    return (
      hasOneElement(item.cards) &&
      allowedApplyRules.includes(item.cards[0].applyRule) &&
      isDroppable(playerId) &&
      !!item.dragger &&
      isAllowedToDrag(item.dragger) &&
      !!item.fromPlayerId &&
      isDraggable(item.fromPlayerId) &&
      (canDrop ? canDrop(item) : true)
    );
  };

  return (
    <BasicDroppable onItemDropped={onItemDropped} canDrop={basicCanDrop}>
      {children}
    </BasicDroppable>
  );
};
