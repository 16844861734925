import { useWakeLock } from 'react-screen-wake-lock';
import React from 'react';
import { GameContext } from '../../../../game/gameContext';
import { SECOND_IN_MILLISECONDS } from '../../../../../constants/time';

export const useKeepAwake = () => {
  const { gameState } = React.useContext(GameContext);
  const { isSupported, released, request, release } = useWakeLock();

  const restart = async () => {
    if (!released) await release();
    await request();
  };

  React.useEffect(() => {
    if (!isSupported) return;
    void restart();

    const interval = setTimeout(() => {
      if (!released) void release();
    }, SECOND_IN_MILLISECONDS * 60);

    return () => clearTimeout(interval);
  }, [gameState.turnActions]);
};
