import { SizeNormal } from '../../helpers/sizes';
import { WindowSize } from '../../helpers/utils';

export type Props = {
  size: SizeNormal;
};

export type JournalSize = {
  width: number;
  height: number;
};

export type JournalPosition = {
  x: number;
  y: number;
};

export const JOURNAL_POSITION_STORAGE_KEY = 'logJournal-position';
export const JOURNAL_SIZE_STORAGE_KEY = 'logJournal-size';

export const DEFAULT_RESERVED_HEIGHT = 80;
export const DEFAULT_RESERVED_WIDTH = 80;
export const DEFAULT_HEIGHT_MODIFIER = 0.25;
export const DEFAULT_WIDTH_MODIFIER = 0.15;
export const MIN_HEIGHT_MODIFIER = 0.15;
export const MIN_WIDTH_MODIFIER = 0.1;
export const MAX_HEIGHT_MODIFIER = 0.4;
export const MAX_WIDTH_MODIFIER = 0.25;

export const defaultWidth = (windowSize: WindowSize) => windowSize.width * DEFAULT_WIDTH_MODIFIER;
export const defaultHeight = (windowSize: WindowSize) => windowSize.height * DEFAULT_HEIGHT_MODIFIER;
export const minWidth = (windowSize: WindowSize) => windowSize.width * MIN_WIDTH_MODIFIER;
export const minHeight = (windowSize: WindowSize) => windowSize.height * MIN_HEIGHT_MODIFIER;
export const maxWidth = (windowSize: WindowSize) => windowSize.width * MAX_WIDTH_MODIFIER;
export const maxHeight = (windowSize: WindowSize) => windowSize.height * MAX_HEIGHT_MODIFIER;
