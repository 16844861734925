import { SxStyles } from '../../../helpers/styles';
import { ProjectColors } from '../../../themes/mainTheme';

export const sxInit = (isMobile: boolean): SxStyles => {
  const border = `1px solid ${ProjectColors.GREY}`;

  const bodyTableCellSx = {
    border: border,
    overflowWrap: 'anywhere',
    textWrap: 'wrap',
    px: isMobile ? 1.1 : 1.5,
    py: isMobile ? { xs: 0.8, sm: 1.2 } : 1.8,
    fontSize: isMobile ? { xs: '0.7rem', sm: '0.9rem' } : '0.9rem',
  };

  return {
    mainTableBlock: {
      display: 'flex',
    },
    tableBox: {
      display: 'flex',
      flex: 1,
      position: 'relative',
    },
    tableRoleImage: {
      position: 'absolute',
      left: 0,
      top: 0,
      width: '100%',
      height: '100%',
      maskImage: 'radial-gradient(closest-side, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0))',
      opacity: '50%',
      objectFit: 'contain',
    },
    headerTableCell: {
      border: border,
      textAlign: 'center',
      fontSize: '1rem',
      p: isMobile ? { xs: 1.2, sm: 1.8 } : { lg: 1.4, xl: 2 },
    },
    bodyTableCell: {
      ...bodyTableCellSx,
      textAlign: 'center',
    },
    counterBodyTableCell: {
      ...bodyTableCellSx,
      textAlign: 'center',
    },
    table: { border: border, mx: 1, zIndex: 1 },
    tableHeader: {
      boarder: border,
      backgroundColor: ProjectColors.PLAYER_BACKGROUND_TRANSPARENT,
    },
  };
};
