import { Box, Button, Typography } from '@mui/material';
import React from 'react';
import { Props } from './playerName.types';
import { PROFILE_ROUTE } from '../../../../constants/routes';
import { useNavigate } from 'react-router-dom';
import { AppContext } from '../../../../global/context/appContext';
import { Edit } from '@mui/icons-material';
import { SETTINGS_PAGE_HASH } from '../../../../views/profile';
import { sx } from './playerName.styles';

export const PlayerName: React.FC<Props> = ({ onModalClose }) => {
  const navigate = useNavigate();
  const { profile } = React.useContext(AppContext);

  const openProfilePage = () => {
    onModalClose();
    navigate(PROFILE_ROUTE + SETTINGS_PAGE_HASH);
  };

  return (
    <Box sx={sx.nameBlock}>
      <Typography sx={sx.nameAsPart}>as</Typography>
      <Typography sx={sx.namePart}>{profile?.playerName}</Typography>
      <Button sx={sx.editButton} variant="text" onClick={() => openProfilePage()}>
        <Edit fontSize={'small'} />
      </Button>
    </Box>
  );
};
