import { MainGameStatsData } from '../hooks/useGameStats';

export type Props = {
  mainStatsData: MainGameStatsData;
};

export const GAMES_PLAYED_STATS_NAME = 'Games played';
export const MOST_WINS_ROLE_STATS_NAME = 'Role with most wins';
export const FAVORITE_PLAYERS_NUMBER_STATS_NAME = 'Favourite number of players';
export const AVERAGE_GAME_LASTS_STATS_NAME = 'Average game lasts';
