import React from 'react';
import { UseMassEssenceEscapeEffect } from './useMassEssenceEscapeEffect.types';
import { GameContext } from '../../../../game/gameContext';
import { CardNames } from '../../../cardTypes';
import { toAppliedAction, toImpactedAction } from '../../../../game/utils/turnActions';
import { getCurrentPlayer } from '../../../../game/gameContextHandler/utils/player';
import { useChangeEssenceCount } from '../../../../game/gameContextHandler/hooks/useChangeEssenceCount';
import { useDefendByEncloseEssenceCard } from '../../../../game/gameContextHandler/hooks/useDefendByEncloseEssenceCard';

export const useMassEssenceEscapeEffect: UseMassEssenceEscapeEffect = () => {
  const { gameState, addTurnAction, performFinishDefending, withUpdatingState } = React.useContext(GameContext);
  const { addEssenceToPlayers } = useChangeEssenceCount({ gameState: gameState, addTurnAction: addTurnAction });
  const { performFinishDefendingByCard, isDefendingRequired } = useDefendByEncloseEssenceCard({
    gameState,
    addTurnAction,
    performFinishDefending,
  });

  return () => {
    const currentPlayer = getCurrentPlayer(gameState);
    const lastTurnAction = gameState.turnActions.findLast((action) => action.player === currentPlayer.id)?.action;

    if (lastTurnAction !== toAppliedAction(CardNames.MASS_ESSENCE_ESCAPE)) {
      return;
    }

    addTurnAction({ player: currentPlayer.id, action: toImpactedAction(CardNames.MASS_ESSENCE_ESCAPE) });

    if (isDefendingRequired()) {
      const otherPlayerIds = gameState.playersOrdered.filter((playerId) => playerId !== currentPlayer.id);
      addEssenceToPlayers(otherPlayerIds);
      performFinishDefendingByCard(currentPlayer, CardNames.MASS_ESSENCE_ESCAPE);
      return;
    }

    withUpdatingState(addEssenceToPlayers)(gameState.playersOrdered);
  };
};
