import { ProjectColors } from '../../themes/mainTheme';
import { SxStyles } from '../../helpers/styles';

const dot = {
  width: '10px',
  height: '10px',
  margin: '5px',
  borderRadius: '50%',
  backgroundColor: ProjectColors.WHITE,
  cursor: 'pointer',
};

export const sx: SxStyles = {
  videoBox: { pt: 2, px: 2, display: 'flex', justifyContent: 'center', alignItems: 'center', height: '90%' },
  video: { objectFit: 'contain', width: '90%' },
  dotsBox: { mt: 2, display: 'flex', justifyContent: 'center' },
  dot: dot,
  activeDot: { ...dot, backgroundColor: ProjectColors.PINK_LOGO },
};
