import { PlayingCard } from '../../features/card/cardTypes';
import { SizeNormal } from '../../helpers/sizes';
import { WindowSize } from '../../helpers/utils';
import { ElementPosition } from '../draggableTableElement';

export type Props = {
  size: SizeNormal;
  playingCards: PlayingCard[];
};

export const ORIGINAL_DISCARD_PILE_HEIGHT = 258;
export const ORIGINAL_DISCARD_PILE_HEIGHT_MOBILE = 295;
export const ORIGINAL_DISCARD_PILE_WIDTH = 300;
export const ORIGINAL_DISCARD_PILE_WIDTH_MOBILE = 300;
export const DISCARD_PILE_HAS_CARDS_OPACITY = 1;
export const DISCARD_PILE_EMPTY_OPACITY = 0.6;

export const defaultTablePosition = (windowSize: WindowSize): ElementPosition => ({
  x: windowSize.width / 2 + 50,
  y: windowSize.height / 2,
});
