import React from 'react';
import { UseTurnActions } from './useTurnActions.types';
import { DEFAULT_DRAW_CARD_AMOUNT, TurnAction } from '../../../gameTypes';
import { DroppableGroup } from '../useDragAndDrop';
import { getCurrentPlayer, getPlayer } from '../../utils/player';
import { CardNames, PlayingCard } from '../../../../card/cardTypes';
import { getActionCheckProbabilityValue, isDisorientationInProgress } from '../../../rules/rules';
import { PASSING_PROB_VALUES } from '../../gameContextHandler.types';
import { filterUndefined, isEmpty, removeElement } from '../../../../../helpers/arrays';
import { LocationTrackerContext } from '../../../locationTrackerContextProvider';
import { AnimationContext } from '../../../animationContextProvider';
import { toImpactedAction } from '../../../utils/turnActions';
import { Log, LogContext } from '../../../logContextProvider';
import { useRemoveEssenceOnTurnStart } from '../useRemoveEssenceOnTurnStart';

export const useTurnActions: UseTurnActions = ({ gameState, enableDragAndDrop }) => {
  const { createLog } = React.useContext(LogContext);
  const { getItemLocation } = React.useContext(LocationTrackerContext);
  const { queueMovingCardsProps } = React.useContext(AnimationContext);

  const finishTurn = (onRemovalFromGame: boolean = false) => {
    const activePlayer = getPlayer(gameState.allPlayers, gameState.activePlayer);
    const turnFinishedLog: Log = { type: 'info', text: `${activePlayer.playerName}: turn finished` };
    addTurnAction({ player: gameState.activePlayer, action: 'create log', logs: [turnFinishedLog] });
    createLog(turnFinishedLog);

    const currentPlayerOrderedIndex = gameState.playersOrdered.indexOf(gameState.activePlayer);

    if (currentPlayerOrderedIndex < 0 || currentPlayerOrderedIndex >= gameState.playersOrdered.length) {
      throw Error('Player is not in a correct ordered list position');
    }

    let nextActivePlayer: string;

    if (currentPlayerOrderedIndex < gameState.playersOrdered.length - 1) {
      nextActivePlayer = gameState.playersOrdered[currentPlayerOrderedIndex + 1];
    } else {
      nextActivePlayer = gameState.playersOrdered[0];
      gameState.roundNumber += 1;

      const newRoundLog: Log = { type: 'info', text: `Round ${gameState.roundNumber} started` };
      addTurnAction({ player: gameState.activePlayer, action: 'create log', logs: [newRoundLog] });
      createLog(newRoundLog);
    }

    removeEssenceOnTurnStart(nextActivePlayer, onRemovalFromGame ? 3 : 2);

    gameState.activePlayer = nextActivePlayer;
    enableDragAndDrop(gameState.activePlayer, DroppableGroup.ALL, gameState.activePlayer);
    const cardsToDiscard = clearPlayingArea();
    gameState.previousTurnActions = [...gameState.turnActions];
    gameState.turnActions = [];
    gameState.previousDefendingPlayer = '';
    gameState.drawCardAmount = DEFAULT_DRAW_CARD_AMOUNT;

    animateDiscardFromEngagementZone(cardsToDiscard);
  };

  const animateDiscardFromEngagementZone = (cardsToDiscard: PlayingCard[]): void => {
    const stops = filterUndefined([getItemLocation('engagementZone'), getItemLocation('discardPile')]);
    queueMovingCardsProps([{ stops: stops, cards: cardsToDiscard }]);
  };

  const clearPlayingArea = (): PlayingCard[] => {
    const cardsToDiscard = gameState.cardsInPlayingArea;
    if (isEmpty(cardsToDiscard)) return [];

    addTurnAction({
      player: gameState.activePlayer,
      action: 'cardsDiscardedFromEngagementZone',
      cardIds: cardsToDiscard.map((cardToDiscard) => cardToDiscard.id),
    });

    gameState.cardsInDiscardPile.push(...gameState.cardsInPlayingArea);
    gameState.cardsInPlayingArea = [];
    return cardsToDiscard;
  };

  const addTurnAction = (turnAction: TurnAction) => {
    const timestamp = new Date().getTime();
    gameState.turnActions = [...gameState.turnActions, { ...turnAction, timestamp }];
  };

  const { removeEssenceOnTurnStart } = useRemoveEssenceOnTurnStart({
    gameState,
    enableDragAndDrop,
    addTurnAction,
    finishTurn,
  });

  const startDisorientationCheck = () => {
    gameState.drawCardAmount = 0;
    enableDragAndDrop(gameState.activePlayer, DroppableGroup.NONE, gameState.activePlayer);
  };

  const finishDisorientationCheck = () => {
    const currentPlayer = getCurrentPlayer(gameState);
    const activeRadiance = currentPlayer.activeRadiances.filter((card) => card.name === CardNames.DISORIENTATION)[0];
    removeElement(currentPlayer.activeRadiances, (card) => card.name === CardNames.DISORIENTATION);
    gameState.cardsInDiscardPile.push(activeRadiance);

    const probabilityValue = getActionCheckProbabilityValue(gameState, isDisorientationInProgress);
    const isCheckPassed = PASSING_PROB_VALUES.includes(probabilityValue);
    const log: Log = isCheckPassed
      ? { type: 'success', text: `${[currentPlayer.playerName]}: Disorientation check passed` }
      : { type: 'warning', text: `${[currentPlayer.playerName]}: Disorientation check failed` };

    createLog(log);
    addTurnAction({
      player: currentPlayer.id,
      action: toImpactedAction(CardNames.DISORIENTATION),
      groupedCardIds: [['activeRadiances', [activeRadiance.id]]],
      appliedTo: currentPlayer.id,
      logs: [log],
    });

    if (!isCheckPassed) return finishTurn(false);
    gameState.drawCardAmount = DEFAULT_DRAW_CARD_AMOUNT;
    enableDragAndDrop(gameState.activePlayer, DroppableGroup.ALL, gameState.activePlayer);
  };

  return {
    finishTurn: finishTurn,
    addTurnAction: addTurnAction,
    startDisorientationCheck: startDisorientationCheck,
    finishDisorientationCheck: finishDisorientationCheck,
  };
};
