import { io, Socket } from 'socket.io-client';
import { ClientToServerEvents, ServerToClientEvents } from './SocketData';
import { DELTARATION_SOCKET_URL } from '../constants/socketIo';

export type SocketIoClient = {
  socket: Socket<ServerToClientEvents, ClientToServerEvents>;
};

class SocketClient {
  private static instance: SocketClient;
  private readonly socket: Socket<ServerToClientEvents, ClientToServerEvents>;

  private constructor() {
    this.socket = io(DELTARATION_SOCKET_URL, {
      path: '/socket/socket.io',
      reconnection: true,
    });
    this.socket.connect();
  }

  public static getInstance(): SocketClient {
    if (!SocketClient.instance) {
      SocketClient.instance = new SocketClient();
    }

    return SocketClient.instance;
  }

  public getSocket() {
    return this.socket;
  }
}

export default SocketClient.getInstance();
