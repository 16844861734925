import React from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import styles from './notificationItem.module.css';
import cn from 'classnames';
import { Props } from './notificationItem.types';
import { SECOND_IN_MILLISECONDS } from '../../../../../../constants/time';

export const NotificationItem: React.FC<Props> = ({ notification, runAfter, delay = 0.1 }) => {
  const [showNotification, setShowNotification] = React.useState(true);

  const finishNotificationAnimation = () => {
    const timeoutId = setTimeout(() => {
      setShowNotification(false);
    }, 1.5 * SECOND_IN_MILLISECONDS);

    return () => clearTimeout(timeoutId);
  };

  const tickerItemVariants = {
    hidden: { x: '100%' },
    visible: { x: '0%', transition: { duration: 0.6, delay: delay } },
    exit: { x: '-100%', transition: { duration: 0.2 } },
  };

  return (
    <AnimatePresence onExitComplete={runAfter ? runAfter : undefined}>
      {showNotification && (
        <motion.div
          variants={tickerItemVariants}
          initial="hidden"
          animate="visible"
          exit="exit"
          className={cn(styles.tickerItemContainer, styles[notification.type])}
          onAnimationComplete={finishNotificationAnimation}
        >
          {notification.text}
        </motion.div>
      )}
    </AnimatePresence>
  );
};
