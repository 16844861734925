import {
  ApiAuthRequest,
  CommandExecutionApiData,
  CreatedGameData,
  CreateGameData,
  GameLogRequest,
  GameStateApiData,
  GameStateRequest,
  JoinedGameData,
  JoinGameData,
  StartGameRequestApiData,
  FinishGameRequestApiData,
  StateOfGame,
} from '../gameTypes';
import axios from 'axios';
import {
  DELTARATION_API_CREATE_GAME_LOGS_URL,
  DELTARATION_API_CREATE_GAME_STATE_URL,
  DELTARATION_API_CURRENT_GAME_STATE_URL,
  DELTARATION_API_FINISH_GAME_URL,
  DELTARATION_API_GAME_URL,
  DELTARATION_API_GAMES_URL,
  DELTARATION_API_JOIN_GAME_URL,
  DELTARATION_API_START_GAME_URL,
} from '../../../constants/delarationApi';
import { GameApiData } from '../gameContextHandler/hooks/useGameState';
import { parseProfile } from '../../../helpers/player';
import { decryptData, encryptData } from '../../../helpers/encryption';
import { Log } from '../logContextProvider';

class GameApi {
  private withAuthorization(headers: object) {
    return { ...headers, Authorization: `Bearer ${parseProfile()?.appToken}` };
  }

  public async createGame(createGameData: CreateGameData): Promise<CreatedGameData> {
    const response = await axios.post(DELTARATION_API_GAMES_URL, createGameData, {
      headers: this.withAuthorization({
        'Content-Type': 'application/json',
      }),
    });

    return response.data;
  }

  public async joinGame(joinGameData: JoinGameData): Promise<JoinedGameData> {
    const response = await axios.post(
      DELTARATION_API_JOIN_GAME_URL(joinGameData.gameId),
      {},
      {
        headers: this.withAuthorization({
          'Content-Type': 'application/json',
        }),
      }
    );

    return response.data;
  }

  public async startGame(data: StartGameRequestApiData): Promise<CommandExecutionApiData> {
    const { gameId, authRequest, request } = data;
    const response = await axios.post(
      DELTARATION_API_START_GAME_URL(gameId),
      { data: encryptData(JSON.stringify(request), parseProfile()!.appToken) },
      {
        headers: this.withAuthorization({
          'Content-Type': 'application/json',
          ...authRequest,
        }),
      }
    );

    return response.data;
  }

  public async finishGame(data: FinishGameRequestApiData): Promise<CommandExecutionApiData> {
    const { gameId, authRequest, request } = data;
    const response = await axios.post(DELTARATION_API_FINISH_GAME_URL(gameId), request, {
      headers: this.withAuthorization({
        'Content-Type': 'application/json',
        ...authRequest,
      }),
    });

    return response.data;
  }

  public async getAllGames(state: StateOfGame): Promise<GameApiData[]> {
    const url = DELTARATION_API_GAMES_URL + '?state=' + state;

    const response = await axios.get(url, {
      headers: {
        'Content-Type': 'application/json',
      },
    });

    return response.data;
  }

  public async getGame(gameId: string): Promise<GameApiData> {
    const response = await axios.get(DELTARATION_API_GAME_URL(gameId), {
      headers: this.withAuthorization({
        'Content-Type': 'application/json',
      }),
    });

    return response.data;
  }

  public async createGameState(
    gameId: string,
    authRequest: ApiAuthRequest,
    request: GameStateRequest
  ): Promise<GameStateApiData> {
    const response = await axios.post(DELTARATION_API_CREATE_GAME_STATE_URL(gameId), this.encrypt(request), {
      headers: this.withAuthorization({
        'Content-Type': 'application/json',
        ...authRequest,
      }),
    });

    return this.decrypt(response.data);
  }

  public async getCurrentGameState(gameId: string, authRequest: ApiAuthRequest): Promise<GameStateApiData> {
    const response = await axios.get(DELTARATION_API_CURRENT_GAME_STATE_URL(gameId), {
      headers: this.withAuthorization({
        'Content-Type': 'application/json',
        ...authRequest,
      }),
    });

    return this.decrypt(response.data);
  }

  public async createGameLog(
    gameId: string,
    authRequest: ApiAuthRequest,
    request: GameLogRequest
  ): Promise<CommandExecutionApiData> {
    const response = await axios.post(DELTARATION_API_CREATE_GAME_LOGS_URL(gameId), request, {
      headers: this.withAuthorization({
        'Content-Type': 'application/json',
        ...authRequest,
      }),
    });

    return response.data;
  }

  public async getAllGameLogs(gameId: string, authRequest: ApiAuthRequest): Promise<Log[]> {
    const response = await axios.get(DELTARATION_API_CREATE_GAME_LOGS_URL(gameId), {
      headers: this.withAuthorization({
        'Content-Type': 'application/json',
        ...authRequest,
      }),
    });

    return response.data;
  }

  private decrypt(gameStateApiData: GameStateApiData): GameStateApiData {
    return { ...gameStateApiData, data: decryptData(gameStateApiData.data, parseProfile()!.appToken) };
  }

  private encrypt(gameStateRequest: GameStateRequest): GameStateRequest {
    return { ...gameStateRequest, data: encryptData(gameStateRequest.data, parseProfile()!.appToken) };
  }
}

export default GameApi;
