import { UseSoundEffect } from './useSoundEffects.types';
import { CardNames, PlayingCard } from '../../../../card/cardTypes';
import { SoundPath } from '../../../../../assets/assets';
import { getValue, isFlagOn } from '../../../../../helpers/featureFlags';
import {
  MY_SOUNDS_FLAG,
  MY_SOUNDS_VOLUME,
  OTHER_PLAYERS_SOUNDS_FLAG,
  OTHER_PLAYERS_SOUNDS_VOLUME,
} from '../../../../../constants/featureFlags';
import { convertToCamelCase } from '../../../../../helpers/utils';
import { MINIMUM_VOLUME_LEVEL } from '../../../../../constants/components';
import { usePlaySound } from '../../../../../helpers/hooks/usePlaySound';
import { getCurrentPlayer } from '../../../gameContextHandler/utils/player';
import { Action, APPLIED } from '../../../gameTypes';
import { actionToCardNameByCardActionType } from '../../../utils/turnActions';

export const useSoundEffects: UseSoundEffect = ({ gameState }) => {
  const { play } = usePlaySound();

  const getSoundsVolume = (flag: string, volumeValue: string): number => {
    const areSoundsOn = isFlagOn(flag);
    const soundsVolumeValue = getValue(volumeValue);
    if (!areSoundsOn || soundsVolumeValue === null) return MINIMUM_VOLUME_LEVEL;

    const soundVolume = parseInt(soundsVolumeValue);
    return soundVolume > MINIMUM_VOLUME_LEVEL ? soundVolume : MINIMUM_VOLUME_LEVEL;
  };

  const addMySound = (soundPath: SoundPath) => {
    const soundVolume = getSoundsVolume(MY_SOUNDS_FLAG, MY_SOUNDS_VOLUME);
    if (soundVolume <= MINIMUM_VOLUME_LEVEL) return;

    play(soundVolume, soundPath);
  };

  const addOtherPlayerSound = (soundPath: SoundPath) => {
    const soundVolume = getSoundsVolume(OTHER_PLAYERS_SOUNDS_FLAG, OTHER_PLAYERS_SOUNDS_VOLUME);
    if (soundVolume <= MINIMUM_VOLUME_LEVEL) return;

    play(soundVolume, soundPath);
  };

  const applyCardSound = (cardName: CardNames, flag: string, volumeValue: string) => {
    const soundVolume = getSoundsVolume(flag, volumeValue);
    if (soundVolume <= MINIMUM_VOLUME_LEVEL) return;

    const cardAudioFileName = convertToCamelCase(cardName) as keyof typeof SoundPath;
    if (!(cardAudioFileName in SoundPath)) return;

    play(soundVolume, SoundPath[cardAudioFileName as keyof typeof SoundPath]);
  };

  const applySoundByOtherPlayerAction = (action: Action) => {
    const actionCardName = actionToCardNameByCardActionType(action, APPLIED);
    if (actionCardName === undefined) return;

    applyCardSound(actionCardName, OTHER_PLAYERS_SOUNDS_FLAG, OTHER_PLAYERS_SOUNDS_VOLUME);
  };

  const applyCardSoundByCurrentPlayerAction = (card: PlayingCard) => {
    applyCardSound(card.name, MY_SOUNDS_FLAG, MY_SOUNDS_VOLUME);
  };

  const applyDrawCardSound = () => {
    if (gameState.activePlayer === getCurrentPlayer(gameState).id) return addMySound(SoundPath.drawCard);
    addOtherPlayerSound(SoundPath.drawCard);
  };

  const applyDiscardCardSound = () => {
    if (gameState.activePlayer === getCurrentPlayer(gameState).id) return addMySound(SoundPath.discardCard);
    addOtherPlayerSound(SoundPath.discardCard);
  };

  return {
    applyCardSoundByCurrentPlayerAction: applyCardSoundByCurrentPlayerAction,
    applyDrawCardSound: applyDrawCardSound,
    applyDiscardCardSound: applyDiscardCardSound,
    addMySound: addMySound,
    applySoundByOtherPlayerAction: applySoundByOtherPlayerAction,
  };
};
