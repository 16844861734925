import { Box, Typography } from '@mui/material';
import React from 'react';
import { Props, PropsMobile } from './chooseEnergySourceModal.types';
import { sx } from './chooseEnergySourceModal.styles';
import { GameContext } from '../../game/gameContext';
import { getCurrentPlayer, getPlayerEnergySourceCards } from '../../game/gameContextHandler/utils/player';
import Button from '@mui/material/Button';
import { CardGroup } from '../../../components/cardGroup';
import { NORMAL_TO_EXTENDED_SIZE } from '../../../helpers/sizes';
import { PlayingCard } from '../../card/cardTypes';
import { SelectorType } from '../../../components/card';
import { useCurrentSize } from '../../card/hooks/useCurrentSize';
import { DragSource } from '../../../components/draggable';
import { canPlayerChooseEnergySource, canTeleport } from '../../game/rules/rules';
import { useApplyCardAction } from '../../game/actions/hooks/useApplyCardAction';
import { Modal } from '../../../components/modal';

export const ChooseEnergySourceModal: React.FC<Props> = ({
  onClose,
  onCardClick,
  droppedItem,
  clearDroppedItem,
  applyCard,
}) => {
  const [selectedCard, setSelectedCard] = React.useState<PlayingCard>();
  const [chooseEnergySourceModalOpen, setChooseEnergySourceModalOpen] = React.useState(false);
  const { applyCardToEngagementZone } = useApplyCardAction();

  const { gameState } = React.useContext(GameContext);
  const currentPlayer = getCurrentPlayer(gameState);

  React.useEffect(() => {
    const card = droppedItem?.cards[0];
    if (!card || !canPlayerChooseEnergySource(currentPlayer, card) || canTeleport(gameState.turnActions)) return;

    setChooseEnergySourceModalOpen(true);
  }, [droppedItem]);

  const selectAndShowCard = (card: PlayingCard) => {
    setSelectedCard(card);
    onCardClick(card);
  };

  const applyInitialCard = () => {
    if (selectedCard === undefined) {
      return;
    }

    applyCardWithEnergySource(selectedCard);
    setSelectedCard(undefined);
  };

  const closeModal = () => {
    setSelectedCard(undefined);
    setChooseEnergySourceModalOpen(false);
    onClose();
  };

  const applyCardWithEnergySource = (withCard: PlayingCard) => {
    if (!droppedItem) {
      throw Error('Card cannot be applied');
    }

    const card = droppedItem.cards[0];
    if (droppedItem.toPlayerId) {
      applyCard(card, withCard);
    } else {
      //TODO remove "!" after fromPlayerId is not optional
      applyCardToEngagementZone(droppedItem.fromPlayerId!, card, DragSource.PLAYER, withCard);
    }

    closeModal();
    clearDroppedItem();
  };

  const currentSize = useCurrentSize();
  return (
    <Modal isOpen={chooseEnergySourceModalOpen} onClose={closeModal}>
      <Typography id="finished-game-modal-title" variant="h5" component="h2" textAlign={'center'}>
        Choose Energy source
      </Typography>
      <Box sx={sx.cardsBox}>
        <CardGroup
          isActive={true}
          cards={getPlayerEnergySourceCards(getCurrentPlayer(gameState))}
          size={NORMAL_TO_EXTENDED_SIZE[currentSize]}
          placeholdersCount={0}
          onCardClick={selectAndShowCard}
          selectorType={SelectorType.SINGLE}
        />
        <Box sx={sx.actionBox}>
          <Button
            variant="contained"
            disabled={selectedCard === undefined}
            onClick={applyInitialCard}
            sx={sx.approveButton}
          >
            Choose
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export const ChooseEnergySourceModalMobile: React.FC<PropsMobile> = (props) => {
  const emptyFunc = () => undefined;
  return <ChooseEnergySourceModal {...props} onClose={emptyFunc} onCardClick={emptyFunc} />;
};
