import React, { ReactElement } from 'react';
import { AnimatePresence, motion, MotionStyle } from 'framer-motion';
import { Box } from '@mui/material';
import { generateDisperserEssenceBox, generateMovingEssenceBox, sxInit } from './movingEssence.styles';
import {
  AnimationType,
  DISPERSER_ANIMATION_MULTIPLIER,
  fixCapsuleLocationByHeight,
  fixDisperserLocationByHeaderHeight,
  Props,
} from './movingEssence.types';
import { Location, LocationTrackerContext } from '../../features/game/locationTrackerContextProvider';
import { roomItems } from '../../features/room/hooks/useTrackLocation';
import { NORMAL_TO_EXTENDED_SIZE } from '../../helpers/sizes';
import { useCurrentSize } from '../../features/card/hooks/useCurrentSize';
import { useCardDimension } from '../card/hooks/useCardDimension';
import { DisperserLocation } from '../essenceBar';
import { getRandomIntBetween } from '../../helpers/utils';
import { SECOND_IN_MILLISECONDS } from '../../constants/time';
import { AppContext } from '../../global/context/appContext';

export const MovingEssence: React.FC<Props> = ({
  playerId,
  count,
  animationType,
  disperserLocation,
  runEssenceUpdateAction,
  runAfter,
}) => {
  const { isMobile } = React.useContext(AppContext);

  const [showMovingEssence, setShowMovingEssence] = React.useState(false);
  const [showDisperser, setShowDisperser] = React.useState(true);
  const generatedEssencesBox = React.useRef<ReactElement[] | undefined>(undefined);

  const { getItemLocation } = React.useContext(LocationTrackerContext);

  const cardSize = NORMAL_TO_EXTENDED_SIZE[useCurrentSize()];
  const { dimension } = useCardDimension({ size: cardSize });

  const capsuleLocation = getItemLocation(roomItems.playerEssenceCapsule(playerId));

  const startEssenceReductionAnimation = () => {
    if (runEssenceUpdateAction) runEssenceUpdateAction();
  };

  const finishEssenceReductionAnimation = () => {
    setShowMovingEssence(false);

    setTimeout(() => {
      setShowDisperser(false);
    }, DISPERSER_ANIMATION_MULTIPLIER * SECOND_IN_MILLISECONDS);
  };

  const finishEssenceAdditionAnimation = () => {
    setShowMovingEssence(false);
    if (runEssenceUpdateAction) runEssenceUpdateAction();

    setTimeout(() => {
      setShowDisperser(false);
    }, DISPERSER_ANIMATION_MULTIPLIER * SECOND_IN_MILLISECONDS);
  };

  React.useEffect(() => {
    if (!showDisperser) return;

    setTimeout(() => {
      setShowMovingEssence(true);
    }, SECOND_IN_MILLISECONDS);
  }, [showDisperser]);

  if (capsuleLocation === undefined) {
    return <></>;
  }

  const disperserCoordinates = (): Location => {
    switch (disperserLocation) {
      case DisperserLocation.TOP:
        return {
          x: capsuleLocation.x - dimension / 2,
          y: capsuleLocation.y - dimension * 1.5,
        };
      case DisperserLocation.LEFT:
        return {
          x: capsuleLocation.x - dimension * 1.65,
          y: capsuleLocation.y - dimension / 2,
        };
      case DisperserLocation.RIGHT:
        return {
          x: capsuleLocation.x + dimension / 1.5,
          y: capsuleLocation.y - dimension / 2,
        };
      case DisperserLocation.BOTTOM:
        return {
          x: capsuleLocation.x - dimension / 2,
          y: capsuleLocation.y + dimension * 0.5,
        };
    }
  };

  const disperserCenterCoordinates = (): Location => {
    switch (disperserLocation) {
      case DisperserLocation.TOP:
        return {
          x: capsuleLocation.x,
          y: disperserCoordinates().y + dimension / 2,
        };
      case DisperserLocation.LEFT:
        return {
          x: disperserCoordinates().x + dimension / 2,
          y: capsuleLocation.y,
        };
      case DisperserLocation.RIGHT:
        return {
          x: disperserCoordinates().x + dimension / 2,
          y: capsuleLocation.y,
        };
      case DisperserLocation.BOTTOM:
        return {
          x: capsuleLocation.x,
          y: disperserCoordinates().y + dimension * 0.4,
        };
    }
  };

  const fixedCapsuleLocation = fixCapsuleLocationByHeight(capsuleLocation, dimension, isMobile);
  const fixedDisperserCenterCoordinates = fixDisperserLocationByHeaderHeight(disperserCenterCoordinates(), isMobile);

  const initialLocation =
    animationType === AnimationType.ADDITION ? fixedDisperserCenterCoordinates : fixedCapsuleLocation;
  const finalLocation =
    animationType === AnimationType.ADDITION ? fixedCapsuleLocation : fixedDisperserCenterCoordinates;

  const essenceVariants = {
    hidden: {
      opacity: [1, 0.7, 0.5, 0.3, 0],
      scale: [1, 1.2, 1.5, 0.8, 0],
      transition: {
        duration: 1,
      },
    },
  };

  const disperserVariants = {
    hidden: {
      scale: 0,
      transition: { duration: DISPERSER_ANIMATION_MULTIPLIER },
    },
    visible: {
      scale: 1,
      transition: { duration: DISPERSER_ANIMATION_MULTIPLIER },
    },
  };

  const sx = sxInit(animationType, dimension, disperserCoordinates());

  const numberOfDisperserEssences = getRandomIntBetween(2, 4);

  if (generatedEssencesBox.current === undefined) {
    generatedEssencesBox.current = Array.from({ length: numberOfDisperserEssences }, (_, index) => (
      <Box key={index} sx={generateDisperserEssenceBox(dimension)} />
    ));
  }

  const fetchAnimationStartAction = (index: number) => {
    if (index !== count - 1) return undefined;
    if (animationType === AnimationType.REDUCTION) return startEssenceReductionAnimation;
    return undefined;
  };

  const fetchAnimationFinishAction = (index: number) => {
    if (index !== count - 1) return undefined;
    if (animationType === AnimationType.REDUCTION) return finishEssenceReductionAnimation;
    if (animationType === AnimationType.ADDITION) return finishEssenceAdditionAnimation;
    return undefined;
  };

  const essencesBlock = Array.from({ length: count }, (_, index) => {
    const key = `moving-essence-${playerId}-${index}`;

    return (
      <motion.div
        key={key}
        exit="hidden"
        initial={initialLocation}
        animate={finalLocation}
        variants={essenceVariants}
        transition={{ duration: 1, delay: 0.1 * (index + 1) }}
        style={sx.essenceMovingBox as MotionStyle}
        onAnimationStart={fetchAnimationStartAction(index)}
        onAnimationComplete={fetchAnimationFinishAction(index)}
      >
        <Box sx={generateMovingEssenceBox(dimension)}></Box>
      </motion.div>
    );
  });

  const movingEssence = () => {
    const key = `moving-essence-${playerId}`;
    return (
      <Box key={key} data-testid={key} sx={sx.box}>
        <AnimatePresence onExitComplete={runAfter ? runAfter : undefined}>
          {showDisperser && (
            <motion.div
              exit="hidden"
              initial="hidden"
              animate="visible"
              variants={disperserVariants}
              style={sx.disperserMovingBox as MotionStyle}
            >
              <Box sx={sx.disperserMainBox}>{generatedEssencesBox.current}</Box>
            </motion.div>
          )}
        </AnimatePresence>
        <AnimatePresence key={key}>{showMovingEssence && essencesBlock}</AnimatePresence>
      </Box>
    );
  };

  return movingEssence();
};
