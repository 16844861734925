import React from 'react';
import './App.css';
import { CssBaseline, ThemeProvider } from '@mui/material';
import { mainTheme } from '../../themes/mainTheme';
import { Router } from './router';
import { SnackbarProvider } from 'notistack';
import SnackbarNotification from '../../components/snackbarNotification/snackbarNotification';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { GOOGLE_CLIENT_ID } from '../../constants/login';
import { wrap, WrapperWithProps } from '../../helpers/wrappers';
import { BrowserRouter } from 'react-router-dom';
import { AppContextProvider } from '../context';
import { ApiContextProvider } from './api';
import { MainThemeAudioProvider } from './mainThemeAudio';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

export const App: React.FC = () => {
  const queryClient = new QueryClient();

  const wrappers: WrapperWithProps[] = [
    [ThemeProvider, { theme: mainTheme }],
    [GoogleOAuthProvider, { clientId: GOOGLE_CLIENT_ID }],
    [
      SnackbarProvider,
      {
        Components: {
          success: SnackbarNotification,
          info: SnackbarNotification,
          warning: SnackbarNotification,
          error: SnackbarNotification,
        },
        maxSnack: 5,
      },
    ],
    [BrowserRouter, []],
    [AppContextProvider, []],
    [ApiContextProvider, []],
    [MainThemeAudioProvider, []],
    [QueryClientProvider, { client: queryClient }],
  ];

  const rootComponent = (
    <>
      <CssBaseline />
      <Router />
    </>
  );

  return wrap(rootComponent, wrappers);
};
