import { CARD_TYPE_DETAILS, GAME_STORY, RULES } from '../../constants/ruleBook';
import { CardTypeDetails, Rule } from './ruleBook.types';

export class RuleBookRetriever {
  private static instance: RuleBookRetriever;

  public static getInstance(): RuleBookRetriever {
    if (!RuleBookRetriever.instance) {
      RuleBookRetriever.instance = new RuleBookRetriever();
    }

    return RuleBookRetriever.instance;
  }

  public getStory(): string[] {
    return GAME_STORY;
  }

  public getRules(): Rule[] {
    return RULES;
  }

  public getCardDetails(): CardTypeDetails[] {
    return CARD_TYPE_DETAILS;
  }
}

export default RuleBookRetriever.getInstance();
