import React from 'react';
import { AppContext } from '../../../../../global/context/appContext';
import { Location, LocationTrackerContext } from '../../../../game/locationTrackerContextProvider';
import { PlayerCardGroup } from '../../../../game/gameContextHandler';
import { fromTo } from '../../../../../helpers/transformers';
import { Player } from '../../../../player/player.types';
import { PlayingCard } from '../../../../card/cardTypes';
import { Props as MovingCardsProps } from '../../../../../components/movingCards/movingCards.types';
import { filterById, filterUndefined, hasOneElement, isEmpty } from '../../../../../helpers/arrays';
import { roomItems } from '../../../../room/hooks/useTrackLocation';
import { UseMovingCardsPropsTransformers } from './useMovingCardsPropsTransformers.types';
import { closeCards, openCards } from '../../../../card/cardTransformers';
import { getGroupedCards } from '../../../../game/utils/turnActions';
import { TurnAction } from '../../../../game/gameTypes';
import { useSoundEffects } from '../../../../game/actions/hooks/useSoundEffects';

export const useMovingCardsPropsTransformers: UseMovingCardsPropsTransformers = ({ gameState }) => {
  const { allPlayingCards } = React.useContext(AppContext);
  const { getItemLocation } = React.useContext(LocationTrackerContext);
  const { applyDiscardCardSound } = useSoundEffects({ gameState: gameState });

  const cardGroupFuncs: fromTo<
    PlayerCardGroup,
    {
      cardClearingFunc: (player: Player, cardsToClear: PlayingCard[]) => void;
      locationFunc: (playerId: string) => string;
      isOpen: boolean;
    }
  > = {
    cardsInHand: {
      cardClearingFunc: (player, cardsToClear: PlayingCard[]) =>
        (player.cardsInHand = filterById(player.cardsInHand, cardsToClear)),
      locationFunc: roomItems.playerHand,
      isOpen: false,
    },
    cardsOnTable: {
      cardClearingFunc: (player, cardsToClear: PlayingCard[]) =>
        (player.cardsOnTable = filterById(player.cardsOnTable, cardsToClear)),
      locationFunc: roomItems.playerArsenal,
      isOpen: true,
    },
    activeRadiances: {
      cardClearingFunc: (player, cardsToClear: PlayingCard[]) =>
        (player.activeRadiances = filterById(player.activeRadiances, cardsToClear)),
      locationFunc: roomItems.playerRadiances,
      isOpen: true,
    },
  };

  const getDiscardedItemsLocation = (
    cards: PlayingCard[],
    locationFunc: (playerId: string) => string,
    appliedTo?: string
  ): Location | undefined => {
    if (hasOneElement(cards)) {
      const singleCardLocation = getItemLocation(roomItems.card(cards[0].id));
      if (singleCardLocation) return singleCardLocation;
    }

    return appliedTo ? getItemLocation(locationFunc(appliedTo)) : undefined;
  };

  const discardedCardsToMovingCards = (
    cards: PlayingCard[],
    locationFunc: (playerId: string) => string,
    isCardOpen: boolean,
    appliedTo?: string,
    runAfter?: () => void
  ): MovingCardsProps[] => {
    if (isEmpty(cards) || !appliedTo) return [];
    const visibilityStateFunc = isCardOpen ? openCards : closeCards;
    const cardLocation = getDiscardedItemsLocation(cards, locationFunc, appliedTo);
    const stops = filterUndefined([cardLocation, getItemLocation(roomItems.discardPile)]);
    if (isEmpty(stops)) return [];

    return [{ cards: visibilityStateFunc(cards), stops: stops, runAfter: runAfter, runWith: applyDiscardCardSound }];
  };

  const lostCardsActionToMovingCards = (
    turnAction: TurnAction,
    appliedTo?: string,
    runAfter?: () => void
  ): MovingCardsProps[] => {
    const groupedCards = getGroupedCards(turnAction, allPlayingCards);
    if (groupedCards.size === 0) return [];

    const appliedToPlayerIndex = gameState.allPlayers.findIndex((player) => player.id === appliedTo);
    if (appliedToPlayerIndex === -1) return [];

    const movingCardsPropsList: MovingCardsProps[] = [];
    for (const [cardGroup, cards] of groupedCards.entries()) {
      if (isEmpty(cards)) continue;
      const { cardClearingFunc, locationFunc, isOpen } = cardGroupFuncs[cardGroup];
      cardClearingFunc(gameState.allPlayers[appliedToPlayerIndex], cards);
      movingCardsPropsList.push(...discardedCardsToMovingCards(cards, locationFunc, isOpen, appliedTo, runAfter));
    }

    return filterUndefined(movingCardsPropsList);
  };

  const lostCardsCurrentPlayerActionToMovingCards = (
    turnAction: TurnAction,
    currentPlayerId: string
  ): MovingCardsProps[] => {
    const groupedCards = getGroupedCards(turnAction, allPlayingCards);
    if (groupedCards.size === 0) return [];

    const movingCardsPropsList: MovingCardsProps[] = [];
    for (const [cardGroup, cards] of groupedCards.entries()) {
      if (isEmpty(cards)) continue;
      const { locationFunc } = cardGroupFuncs[cardGroup];
      movingCardsPropsList.push(...discardedCardsToMovingCards(cards, locationFunc, true, currentPlayerId));
    }

    return movingCardsPropsList;
  };

  return {
    discardedCardsToMovingCards: discardedCardsToMovingCards,
    lostCardsActionToMovingCards: lostCardsActionToMovingCards,
    lostCardsCurrentPlayerActionToMovingCards: lostCardsCurrentPlayerActionToMovingCards,
  };
};
