import React from 'react';
import { Props } from './playersMainStatsBlock.types';
import { Box, CardMedia, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';
import { AppContext } from '../../../global/context/appContext';
import { sxInit } from './playersMainStatsBlock.styles';
import { ImagePath } from '../../../assets/assets';
import { getMappedRoleCards } from '../../../features/ruleBook/rulesAccordion';
import { Role } from '../../../features/card/cardTypes';
import { convertToCapitalCase, isPortraitOrientation } from '../../../helpers/utils';
import { getRandom } from '../../../helpers/arrays';

export const PlayersMainStatsBlock: React.FC<Props> = ({ playersMainStats }) => {
  const { isMobile } = React.useContext(AppContext);

  const sx = sxInit(isMobile);

  const statsData =
    isMobile && isPortraitOrientation() ? playersMainStats.filter((stats) => stats.type === 'TOTAL') : playersMainStats;

  const allRoles = getMappedRoleCards;

  const playersStatsTables = () => {
    const rows: React.ReactElement[] = [];

    statsData.forEach((statsBlock) => {
      const roleData = allRoles.get(convertToCapitalCase(statsBlock.type) as Role);
      const tableImage = roleData !== undefined ? getRandom(roleData.images) : ImagePath.logoOutline;

      rows.push(
        <Box key={`game-stats-box-${statsBlock.type}`} sx={sx.tableBox}>
          <CardMedia component="img" alt={`card--image`} image={tableImage} sx={sx.tableRoleImage} />
          <Table
            key={`game-stats-table-${statsBlock.type}`}
            sx={sx.table}
            aria-label={`players main stats table ${statsBlock.type}`}
          >
            <TableHead sx={sx.tableHeader}>
              <TableRow>
                <TableCell sx={sx.headerTableCell} key={`game-column-name-${statsBlock.type}`} colSpan={2}>
                  <Typography key={`game-column-content-${statsBlock.type}`}>{statsBlock.type}</Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {statsBlock.stats.map((playerData) => (
                <TableRow key={`game-row-${playerData.player.name}`}>
                  <TableCell sx={sx.bodyTableCell}>{playerData.player.name}</TableCell>
                  <TableCell sx={sx.counterBodyTableCell}>{playerData.wins}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      );
    });

    return rows;
  };

  return <Box sx={sx.mainTableBlock}>{playersStatsTables()}</Box>;
};
