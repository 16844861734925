import React from 'react';
import { UseIdlePlayer } from './useIdlePlayer.types';

export const useIdlePlayer: UseIdlePlayer = () => {
  const [idlePlayer, setIdlePlayer] = React.useState<string>();

  return {
    idlePlayer: idlePlayer,
    setIdlePlayer: setIdlePlayer,
  };
};
