import React from 'react';
import { Props } from './onboardingWidget.types';
import { OnboardingModal } from './onboardingModal';
import { IconButton, Tooltip, Typography } from '@mui/material';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { sx } from './onboardingWidget.styles';
import { tooltipStyles } from '../../../helpers/styles';

export const OnboardingWidget: React.FC<Props> = () => {
  const [isOpen, setIsOpen] = React.useState(false);
  const toggle = () => setIsOpen(!isOpen);

  const tooltipTitle = 'Onboarding videos';

  return (
    <div>
      <React.Fragment>
        <Tooltip title={<Typography sx={tooltipStyles}>{tooltipTitle}</Typography>} disableInteractive>
          <IconButton onClick={toggle} sx={sx.button}>
            <HelpOutlineIcon sx={sx.icon} />
          </IconButton>
        </Tooltip>
        <OnboardingModal isOpen={isOpen} onClose={() => setIsOpen(false)} />
      </React.Fragment>
    </div>
  );
};
