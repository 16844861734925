import React from 'react';
import { DEFAULT_THEME_TYPE, Props } from './mainThemeAudioProvider.types';
import { SoundPath } from '../../../assets/assets';
import { MAIN_SOUND_THEME_TYPE, MAIN_SOUND_THEME_VOLUME } from '../../../constants/featureFlags';
import { MainThemeAudioContext, MainThemeAudioContextType } from './mainThemeAudioContext';
import { getNumberValue, setValue } from '../../../helpers/featureFlags';

export const MainThemeAudioProvider: React.FC<Props> = ({ children }) => {
  const [audio, setAudio] = React.useState<HTMLAudioElement>();
  const [themeType, setThemeType] = React.useState(getNumberValue(MAIN_SOUND_THEME_TYPE) ?? DEFAULT_THEME_TYPE);
  const [isPlaying, setIsPlaying] = React.useState(false);
  const themeVolume = getNumberValue(MAIN_SOUND_THEME_VOLUME) ?? 0;

  const reloadIsPlaying = () => {
    if (!audio) return;
    setIsPlaying(!audio.paused);
  };

  const createAudio = (audioName: string) => {
    const themeAudioFileName = audioName as keyof typeof SoundPath;
    if (!(themeAudioFileName in SoundPath)) return;

    const audioElement = new Audio(SoundPath[themeAudioFileName] as string);
    audioElement.loop = true;
    audioElement.volume = themeVolume / 100;
    setAudio(audioElement);

    return () => {
      audioElement.pause();
      audioElement.currentTime = 0;
    };
  };

  React.useEffect(() => {
    createAudio('background' + themeType);
  }, []);

  React.useEffect(() => {
    setValue(MAIN_SOUND_THEME_TYPE, themeType.toString());

    if (!audio) return;

    if (isPlaying) {
      audio.pause();
      audio.currentTime = 0;
    }
    setIsPlaying(false);
    createAudio('background' + themeType);
  }, [themeType]);

  const play = async () => {
    if (!audio) return;
    await audio.play();
    reloadIsPlaying();
  };

  const pause = () => {
    if (!audio) return;
    audio.pause();
    reloadIsPlaying();
  };

  const setVolume = (value: number) => {
    if (!audio) return;
    audio.volume = value;
  };

  const setCurrentTime = (value: number) => {
    if (!audio) return;
    audio.currentTime = value;
  };

  const initializeContext = (): MainThemeAudioContextType => ({
    isPlaying: isPlaying,
    play: play,
    pause: pause,
    setVolume: setVolume,
    setCurrentTime: setCurrentTime,
    themeType: themeType,
    setThemeType: setThemeType,
  });

  return <MainThemeAudioContext.Provider value={initializeContext()}>{children}</MainThemeAudioContext.Provider>;
};
