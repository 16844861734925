import React from 'react';
import { Props } from './cardsAccordion.types';
import { Accordion, AccordionDetails, AccordionSummary } from '../styledAccordion/styledAccordion';
import { HtmlTypography } from '../../../components/htmlTypography';
import { containsSearched } from '../../../helpers/strings';
import { CardTypeDetails, showFullRulesContent } from '../ruleBook.types';
import { CardMedia, Table, TableBody, TableCell, TableCellProps, TableHead, TableRow } from '@mui/material';
import { styled } from '@mui/material/styles';
import { sx } from './cardsAccordion.styles';
import { CARD_TYPE_TO_BACKGROUND_COLOR } from '../../../helpers/transformers';
import { PlayingCardType } from '../../card/cardTypes';
import { AppContext } from '../../../global/context/appContext';
import { first } from '../../../helpers/arrays';
import { CLASSIC_MODE, GAME_MODES } from '../../game/gameTypes';
import { useGameApi } from '../../game/gameApi/useGameApi';

export const CardsAccordion: React.FC<Props> = ({ page, cardDetails, searched }) => {
  const { windowSize } = React.useContext(AppContext);
  const { game } = useGameApi();
  const [isOpen, setIsOpen] = React.useState(false);

  const showFullCardTable = showFullRulesContent(page, windowSize);
  const gameMode =
    page === 'Game' && game ? GAME_MODES.filter((mode) => mode.name.toLowerCase() === game.mode)[0] : CLASSIC_MODE;

  const cardTypeDetailsToString = (details: CardTypeDetails): string => {
    const cardsString = details.cards.map((card) =>
      [card.details, card.name, card.colors?.join('\n'), card.amountInDeck].join('\n')
    );

    return [details.type, cardsString].join(' ');
  };

  const cardDetailsString = cardDetails.map((item) => cardTypeDetailsToString(item)).join(' ');
  const cardsContainSearched = (): boolean => containsSearched(cardDetailsString, searched);
  React.useEffect(() => setIsOpen(cardsContainSearched()), [searched]);

  const CardTableCell = styled((props: TableCellProps) => <TableCell {...props} />)(() => sx.tableCell);
  const CardTypeTableCell = styled(CardTableCell)<TableCellProps & { type: PlayingCardType }>(({ type }) => ({
    backgroundColor: CARD_TYPE_TO_BACKGROUND_COLOR[type],
  }));

  const cardDetailsTable = () => {
    const rows: React.ReactElement[] = [];
    cardDetails.forEach((cardTypeDetails) =>
      cardTypeDetails.cards.forEach((card, index) => {
        const firstEnergyGroup = card.colors === undefined ? 'undefined' : card.colors[0].join(' / ');
        rows.push(
          <TableRow
            key={`card-${cardTypeDetails.type}-${index}-${firstEnergyGroup}`}
            sx={card.gameModes.includes(gameMode) ? undefined : sx.inactiveCard}
          >
            {showFullCardTable && (
              <CardTableCell
                rowSpan={card.colors === undefined ? 1 : card.colors.length}
                colSpan={card.colors === undefined ? 2 : 1}
              >
                <HtmlTypography searched={searched}>{card.name}</HtmlTypography>
              </CardTableCell>
            )}
            {showFullCardTable && card.colors && card.colors.length > 0 && (
              <CardTableCell>
                <HtmlTypography searched={searched}>{card.colors[0].join(' / ')}</HtmlTypography>
              </CardTableCell>
            )}
            <CardTypeTableCell
              sx={sx.imageTableCell}
              type={cardTypeDetails.type}
              rowSpan={card.colors === undefined ? 1 : undefined}
            >
              <CardMedia component="img" alt={`card-${index}-image`} image={card.imagePaths[0]} sx={sx.cardImage} />
            </CardTypeTableCell>
            {showFullCardTable && (
              <CardTableCell rowSpan={card.colors === undefined ? 1 : card.colors.length}>
                <HtmlTypography searched={searched}>{cardTypeDetails.type}</HtmlTypography>
              </CardTableCell>
            )}
            <CardTableCell rowSpan={card.colors === undefined ? 1 : card.colors.length}>
              {showFullCardTable ? (
                <HtmlTypography searched={searched}>{card.details}</HtmlTypography>
              ) : (
                <HtmlTypography searched={searched}>
                  {'<b>' + card.name + '</b> (<u>' + cardTypeDetails.type + '</u>): ' + card.details}
                </HtmlTypography>
              )}
            </CardTableCell>
            {showFullCardTable && <CardTableCell>{first(card.gameModes)?.name ?? CLASSIC_MODE.name}</CardTableCell>}
            {showFullCardTable && <CardTableCell>{card.amountInDeck}</CardTableCell>}
          </TableRow>
        );

        if (card.colors && card.colors.length > 1) {
          card.colors.slice(1).forEach((colorGroup, colorIndex) => {
            const stringEnergies = colorGroup.join(' / ');
            rows.push(
              <TableRow
                key={`card-${cardTypeDetails.type}-${index}-${stringEnergies}`}
                sx={card.gameModes.includes(gameMode) ? undefined : sx.inactiveCard}
              >
                {showFullCardTable && (
                  <CardTableCell>
                    <HtmlTypography searched={searched}>{stringEnergies}</HtmlTypography>
                  </CardTableCell>
                )}
                <CardTypeTableCell sx={sx.imageTableCell} type={cardTypeDetails.type} rowSpan={undefined}>
                  <CardMedia
                    component="img"
                    alt={`card-${cardTypeDetails.type}-${index}-${stringEnergies}-image`}
                    image={card.imagePaths[colorIndex + 1]}
                    sx={sx.cardImage}
                  />
                </CardTypeTableCell>
                {showFullCardTable && <CardTableCell>{first(card.gameModes)?.name ?? CLASSIC_MODE.name}</CardTableCell>}
                {showFullCardTable && <CardTableCell>{card.amountInDeck}</CardTableCell>}
              </TableRow>
            );
          });
        }
      })
    );

    return (
      <Table sx={sx.table}>
        <TableHead sx={sx.tableHeader}>
          <TableRow>
            {showFullCardTable && <CardTableCell>Card</CardTableCell>}
            {showFullCardTable && <CardTableCell>Energies</CardTableCell>}
            <CardTableCell>Image</CardTableCell>
            {showFullCardTable && <CardTableCell>Type</CardTableCell>}
            <CardTableCell>Description</CardTableCell>
            {showFullCardTable && <CardTableCell>Minimum game mode</CardTableCell>}
            {showFullCardTable && <CardTableCell>In deck</CardTableCell>}
          </TableRow>
        </TableHead>
        <TableBody>{rows}</TableBody>
      </Table>
    );
  };

  return (
    <Accordion expanded={isOpen} onChange={() => setIsOpen(!isOpen)}>
      <AccordionSummary aria-controls="cards-content" id="cards-header">
        <HtmlTypography>Cards</HtmlTypography>
      </AccordionSummary>
      <AccordionDetails>{cardDetailsTable()}</AccordionDetails>
    </Accordion>
  );
};
