import { NumberOfPlayers } from '../gameTypes';
import { CardType, Role, RoleCard } from '../../card/cardTypes';
import { findOccurrenceCount, generateRandomCyclicSequence, shuffle } from '../../../helpers/arrays';
import { ImagePath } from '../../../assets/assets';
import { closeCard } from '../../card/cardTransformers';
import { getRandomIntBetween } from '../../../helpers/utils';

export const getRoleCardsByNumberOfPlayers = (numberOfPlayers: NumberOfPlayers): RoleCard[] => {
  const allRoles: RoleCard[] = [];

  const playerRoleNumbers = generateRoleList(numberOfPlayers);

  const numberOfImmunars = findOccurrenceCount(playerRoleNumbers, Role.IMMUNAR);
  allRoles.push(...generateRandomCyclicSequence(numberOfImmunars, distinctImmunarRoles));

  const numberOfDeltared = findOccurrenceCount(playerRoleNumbers, Role.DELTARED);
  allRoles.push(...generateRandomCyclicSequence(numberOfDeltared, distinctDeltaredRoles));

  const numberOfOrtegonists = findOccurrenceCount(playerRoleNumbers, Role.ORTEGONIST);
  allRoles.push(...generateRandomCyclicSequence(numberOfOrtegonists, [ortegonistRole]));

  return [...allRoles].map(closeCard);
};

export const getRolesByNumberOfPLayers = (numberOfPlayers: NumberOfPlayers): RoleDistribution[] => {
  switch (numberOfPlayers) {
    case NumberOfPlayers.THREE:
      return [
        { min: 1, max: 1, role: Role.DELTARED },
        { min: 1, max: 1, role: Role.IMMUNAR },
        { min: 1, max: 1, role: Role.ORTEGONIST },
      ];
    case NumberOfPlayers.FOUR:
      return [
        { min: 1, max: 2, role: Role.DELTARED },
        { min: 1, max: 2, role: Role.IMMUNAR },
        { min: 1, max: 1, role: Role.ORTEGONIST },
      ];
    case NumberOfPlayers.FIVE:
      return [
        { min: 2, max: 2, role: Role.DELTARED },
        { min: 2, max: 2, role: Role.IMMUNAR },
        { min: 1, max: 1, role: Role.ORTEGONIST },
      ];
    case NumberOfPlayers.SIX:
      return [
        { min: 2, max: 3, role: Role.DELTARED },
        { min: 2, max: 3, role: Role.IMMUNAR },
        { min: 1, max: 1, role: Role.ORTEGONIST },
      ];
    case NumberOfPlayers.SEVEN:
      return [
        { min: 3, max: 3, role: Role.DELTARED },
        { min: 3, max: 3, role: Role.IMMUNAR },
        { min: 1, max: 1, role: Role.ORTEGONIST },
      ];
    case NumberOfPlayers.EIGHT:
      return [
        { min: 3, max: 4, role: Role.DELTARED },
        { min: 3, max: 4, role: Role.IMMUNAR },
        { min: 1, max: 1, role: Role.ORTEGONIST },
      ];
  }
};

type RoleDistribution = {
  role: Role;
  min: number;
  max: number;
};

const generateRoleList = (total: NumberOfPlayers): Role[] => {
  const rawRoles = getRolesByNumberOfPLayers(total);

  const rolesMinTotal = rawRoles.map((role) => role.min).reduce((a, b) => a + b, 0);
  if (rolesMinTotal > total) {
    return [];
  }

  const fixedRoles = rawRoles.filter((role) => role.min === role.max);
  shuffle(fixedRoles);
  const rangedRoles = rawRoles.filter((role) => !fixedRoles.includes(role));
  shuffle(rangedRoles);

  const sortedAllRoles = [...fixedRoles, ...rangedRoles];

  const roleList: Role[] = [];

  let currentPlayerCount = 0;
  let roleIndex = -1;
  let useRangedRoles = false;

  const getNextElement = () => {
    const usedRolesList = useRangedRoles ? rangedRoles : sortedAllRoles;
    roleIndex += 1;

    if (roleIndex >= usedRolesList.length) {
      roleIndex = 0;
      useRangedRoles = true;
      return rangedRoles[roleIndex];
    }

    return usedRolesList[roleIndex];
  };

  const calculateRequiredCount = (existingCount: number, defaultCount: number) =>
    defaultCount - existingCount > 0 ? defaultCount - existingCount : 0;

  while (currentPlayerCount < total) {
    const role = getNextElement();
    const existingCount = findOccurrenceCount(roleList, role.role);

    if (existingCount >= role.max) continue;

    const minCount = calculateRequiredCount(existingCount, role.min);
    const maxCount = calculateRequiredCount(existingCount, role.max);
    const roleCount = getRandomIntBetween(minCount, maxCount);

    if (roleCount < 1 || currentPlayerCount + roleCount > total) continue;

    roleList.push(...Array(roleCount).fill(role.role));
    currentPlayerCount += roleCount;
  }

  return roleList;
};

const getRoleImage = (prefix: string, version: RoleVersions, defaultImage: string): string => {
  const imageKey = `${prefix}${version.toUpperCase()}` as keyof typeof ImagePath;
  let image = defaultImage;
  if (imageKey in ImagePath && typeof ImagePath[imageKey] === 'string') {
    image = ImagePath[imageKey] as string;
  }

  return image;
};

const getImmunarRole = (version: RoleVersions): RoleCard => ({
  cardType: CardType.ROLE,
  role: Role.IMMUNAR,
  details: 'Goal: Deplete all Ortegonists and Deltared',
  description:
    'those, who were not infected by the Delta virus and were vaccinated by Dr. Ortega.' +
    ' They aim to save themselves from de-essencing by depleting Deltared and Ortegonists',
  image: getRoleImage('immunar', version, ImagePath.immunarV1),
  isOpen: false,
});

const getDeltaredRole = (version: RoleVersions): RoleCard => ({
  cardType: CardType.ROLE,
  role: Role.DELTARED,
  details: 'Goal: Deplete all Immunars and Ortegonists',
  description:
    'those, who have been infected by the Delta virus and have become ruthless creatures.' +
    ' Their goal is to deplete all Immunars and Ortegonists',
  image: getRoleImage('deltared', version, ImagePath.deltaredV1),
  isOpen: false,
});

export const ortegonistRole: RoleCard = {
  cardType: CardType.ROLE,
  role: Role.ORTEGONIST,
  details: 'Goal: Deplete all Deltared and Immunars',
  description:
    'the followers of Dr. Ortega who believe that the only way for the planet to thrive is to deplete all' +
    ' Deltared and Immunars',
  image: ImagePath.ortegonist,
  isOpen: false,
};

type RoleVersions = 'v1' | 'v2';
const allRoleVersions: RoleVersions[] = ['v1', 'v2'];

const distinctImmunarRoles = allRoleVersions.map((version) => getImmunarRole(version));
const distinctDeltaredRoles = allRoleVersions.map((version) => getDeltaredRole(version));

export const getAllRoleCards = (): RoleCard[] => [...distinctImmunarRoles, ...distinctDeltaredRoles, ortegonistRole];

export type EssenceCount = {
  min: number;
  max: number;
};

export const getEssenceCountByNumberOfPlayers = (numberOfPlayers: NumberOfPlayers): EssenceCount => {
  switch (numberOfPlayers) {
    case NumberOfPlayers.THREE:
      return { min: 6, max: 7 };
    case NumberOfPlayers.FOUR:
      return { min: 6, max: 6 };
    case NumberOfPlayers.FIVE:
      return { min: 5, max: 6 };
    case NumberOfPlayers.SIX:
      return { min: 5, max: 5 };
    case NumberOfPlayers.SEVEN:
      return { min: 4, max: 5 };
    case NumberOfPlayers.EIGHT:
      return { min: 4, max: 4 };
  }
};

export const MAX_POSSIBLE_ESSENCES = 8;

export const getPlayerInitialEssenceCount = (numberOfPlayers: NumberOfPlayers | undefined): number => {
  if (numberOfPlayers === undefined) return 0;

  const essenceCount = getEssenceCountByNumberOfPlayers(numberOfPlayers);
  return getRandomIntBetween(essenceCount.min, essenceCount.max);
};
