import React from 'react';
import { Props } from './arsenal.types';
import { wrap, WrapperWithProps } from '../../../../helpers/wrappers';
import { Draggable, DraggedCardData, DragSource } from '../../../../components/draggable';
import { sortByDirection } from '../../../../helpers/player';
import { CardNames } from '../../../card/cardTypes';
import styles from './arsenal.module.css';
import { Card, CardIdentified } from '../../../../components/card';
import { cardToComponent, getPlaceholderCardComponent } from '../../../../helpers/transformers';
import { ImagePath } from '../../../../assets/assets';

export const Arsenal: React.FC<Props> = ({
  playerId,
  size,
  cardsOnTable,
  blocksDirection,
  isDraggable,
  onCardClick,
  displayType,
  cardsHaveActiveAnimation,
}) => {
  const cardComponent = (cardName: CardNames) => {
    const card = cardsOnTable.find((cardOnTable) => cardOnTable.name === cardName);
    if (!card) {
      const cardPlaceholderFileName = (cardName.toLowerCase() + 'Placeholder') as keyof typeof ImagePath;
      const placeholderExists = cardPlaceholderFileName in ImagePath;

      return (
        <div key={`placeholder-${cardName}-${playerId}`} className={styles.placeholderCard}>
          <Card
            {...getPlaceholderCardComponent(
              size,
              placeholderExists ? ImagePath[cardPlaceholderFileName as keyof typeof ImagePath].toString() : undefined
            )}
          />
        </div>
      );
    }

    const dragData: DraggedCardData = { dragSource: DragSource.PLAYER, fromPlayerId: playerId, cards: [card] };
    const wrappers: WrapperWithProps[] = isDraggable ? [[Draggable, { draggableData: dragData }]] : [];

    return (
      <div key={`applied-${card.name}-${playerId}`} onClick={() => onCardClick(card)} className={styles.activeCard}>
        {wrap(
          <CardIdentified
            id={card.id}
            {...cardToComponent(card, size)}
            isActive={true}
            hasActiveAnimation={cardsHaveActiveAnimation}
          />,
          wrappers
        )}
      </div>
    );
  };

  const cardPositions = [
    ...sortByDirection([CardNames.GAUNTLET, CardNames.SHELL], blocksDirection),
    ...sortByDirection([CardNames.DOME, CardNames.BACKPACK], blocksDirection),
  ];

  return <div className={styles[displayType]}>{cardPositions.map((name) => cardComponent(name))}</div>;
};
