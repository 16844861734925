import React from 'react';
import { motion } from 'framer-motion';
import { cardToComponent } from '../../helpers/transformers';
import { NORMAL_TO_EXTENDED_SIZE } from '../../helpers/sizes';
import { Card } from '../card';
import { Box } from '@mui/material';
import { sx } from './movingCards.styles';
import { Props, OneMove, durationConfig } from './movingCards.types';
import { useCurrentSize } from '../../features/card/hooks/useCurrentSize';
import { useCardDimension } from '../card/hooks/useCardDimension';
import { useWindowSize } from '../../helpers/utils';
import { Location } from '../../features/game/locationTrackerContextProvider';
import { PlayingCard } from '../../features/card/cardTypes';
import { isEmpty } from '../../helpers/arrays';

export const MovingCards: React.FC<Props> = ({ stops, cards, runAfter, runWith }) => {
  const cardSize = NORMAL_TO_EXTENDED_SIZE[useCurrentSize()];
  const { dimension } = useCardDimension({ size: cardSize });
  const { width, height } = useWindowSize();

  const mapWithCardOffset = (stop: Location): Location => {
    return {
      x: stop.x - dimension / 2,
      y: stop.y - dimension / 2,
    };
  };

  const [stopsToGo, setStepsToGo] = React.useState([...stops].map(mapWithCardOffset));
  const [currentMove, setCurrentMove] = React.useState<OneMove>();

  const offset = dimension;
  const limitValue = (value: number, limit: number) => {
    if (value < 0) return 0;
    return value + offset > limit ? limit - offset : value;
  };

  const setCurrentMoveWithLimits = (move?: OneMove) => {
    const checkedMove =
      move !== undefined
        ? {
            initial: { x: limitValue(move.initial.x, width), y: limitValue(move.initial.y, height) },
            final: { x: limitValue(move.final.x, width), y: limitValue(move.final.y, height) },
          }
        : move;

    setCurrentMove(checkedMove);
  };

  const calculateAnimationDuration = (): number => {
    const { minInSec, maxInSec, stopsForMin, stopsForMax } = durationConfig;
    if (stops.length <= stopsForMin) return minInSec;
    if (stops.length >= stopsForMax) return maxInSec;

    const proportion = (stops.length - stopsForMin) / (stopsForMax - stopsForMin);
    return minInSec + (maxInSec - minInSec) * proportion;
  };

  const animationDuration = calculateAnimationDuration();
  const animationSec = animationDuration * 0.7;
  const delayBeforeDisappearingSec = animationDuration - animationSec;

  const handleMove = () => {
    if (stopsToGo.length === 1) {
      setTimeout(() => {
        setCurrentMoveWithLimits(undefined);

        if (runAfter) runAfter();
      }, delayBeforeDisappearingSec * 1000);
      return;
    }

    setCurrentMoveWithLimits({ initial: stopsToGo[0], final: stopsToGo[1] });
    setStepsToGo(stopsToGo.slice(1));
  };

  React.useEffect(() => {
    if (height === 0) return;
    handleMove();
  }, [height]);

  const moveDuration = animationSec / (stops.length - 1);

  const otherCardsTotalDelay = 0.5;
  const cardsDelays = [...Array(cards.length)].map((_, index) => (index * otherCardsTotalDelay) / (cards.length - 1));

  const movingCard = (cardToAnimate: PlayingCard, delay: number, onAnimationComplete?: () => void) => {
    const key = `moving-card-${cardToAnimate.id}`;
    return currentMove ? (
      <Box key={key} data-testid={key} sx={sx.box}>
        <motion.div
          data-testid={`moving-card-motion-${currentMove.initial.x}-${currentMove.initial.y}-${cardToAnimate.id}`}
          initial={currentMove.initial}
          animate={currentMove.final}
          transition={{ duration: moveDuration, delay: delay }}
          onAnimationComplete={onAnimationComplete ? onAnimationComplete : undefined}
        >
          <Card {...cardToComponent(cardToAnimate, cardSize)} />
        </motion.div>
      </Box>
    ) : (
      <React.Fragment key={key} />
    );
  };

  const canPlayAnimation = () => stops.length > 1 && !isEmpty(cards);
  React.useEffect(() => {
    if (runWith) runWith();
    if (canPlayAnimation() || !runAfter) return;
    runAfter();
  }, []);

  return (
    <>
      {canPlayAnimation() &&
        cards.map((card, index) =>
          movingCard(card, cardsDelays[index], index === cards.length - 1 ? handleMove : undefined)
        )}
    </>
  );
};
