import { UsePulsatingCards } from './usePulsatingCards.types';
import React from 'react';
import { Props as PulsatingCardProps, PulsatingCard } from '../../../../components/pulsatingCard';
import { isEmpty } from '../../../../helpers/arrays';
import { withFunc } from '../../../../helpers/utils';

export const usePulsatingCards: UsePulsatingCards = () => {
  const [pulsatingCardsProps, setPulsatingCardsProps] = React.useState<PulsatingCardProps[]>([]);
  const [animatedPulsatingCard, setAnimatedPulsatingCard] = React.useState<React.ReactElement>();

  const clearAnimatedPulsatingCard = () => setAnimatedPulsatingCard(undefined);

  React.useEffect(() => {
    if (animatedPulsatingCard || isEmpty(pulsatingCardsProps)) return;

    setPulsatingCardsProps((prevState) => {
      const pulsatingCard = <PulsatingCard {...prevState[0]} />;
      setAnimatedPulsatingCard(pulsatingCard);
      return [...prevState.slice(1)];
    });
  }, [animatedPulsatingCard, pulsatingCardsProps.length]);

  const queuePulsatingCardProps = (props: PulsatingCardProps) => {
    const propsToAdd = {
      ...props,
      runAfter: props.runAfter ? withFunc(props.runAfter, clearAnimatedPulsatingCard) : clearAnimatedPulsatingCard,
    };
    setPulsatingCardsProps((prevState) => [...prevState, propsToAdd]);
  };

  return {
    animatedPulsatingCard: animatedPulsatingCard,
    queuePulsatingCardProps: queuePulsatingCardProps,
  };
};
