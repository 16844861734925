import React from 'react';
import { EssenceHuntContext, EssenceHuntContextType, Props } from './essenceHuntContextProvider.types';
import { GameContext } from '../../game/gameContext';
import { getCurrentPlayer } from '../../game/gameContextHandler/utils/player';
import { isEssenceHuntInProgress } from '../../game/rules/rules';

export const EssenceHuntContextProvider: React.FC<Props> = ({ children }) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const { gameState } = React.useContext(GameContext);

  React.useEffect(() => {
    const currentPlayer = getCurrentPlayer(gameState);
    if (!currentPlayer) return;

    if (gameState.activePlayer === currentPlayer.id && !isOpen && isEssenceHuntInProgress(gameState.turnActions)) {
      setIsOpen(true);
    }

    if (isOpen && !isEssenceHuntInProgress(gameState.turnActions)) setIsOpen(false);
  }, [gameState.turnActions]);

  const initializeContext = (): EssenceHuntContextType => ({
    isEssenceHuntModalOpen: isOpen,
    setIsEssenceHuntModalOpen: setIsOpen,
  });

  return <EssenceHuntContext.Provider value={initializeContext()}>{children}</EssenceHuntContext.Provider>;
};
