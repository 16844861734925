import React from 'react';
import { UseEssenceEncapsulationEffect } from './useEssenceEncapsulationEffect.types';
import { GameContext } from '../../../../game/gameContext';
import { last } from '../../../../../helpers/arrays';
import { CardNames } from '../../../cardTypes';
import { toAppliedAction } from '../../../../game/utils/turnActions';
import { getCurrentPlayer } from '../../../../game/gameContextHandler/utils/player';

export const useEssenceEncapsulationEffect: UseEssenceEncapsulationEffect = () => {
  const { gameState, performFinishDefending } = React.useContext(GameContext);

  return () => {
    const lastTurnAction = last(gameState.turnActions)?.action;

    if (
      lastTurnAction === undefined ||
      getCurrentPlayer(gameState).id !== gameState.activeDefendingPlayer ||
      lastTurnAction !== toAppliedAction(CardNames.ESSENCE_ENCAPSULATION)
    ) {
      return;
    }

    performFinishDefending('successfulDefending');
  };
};
