import { UseChangeEssenceCount } from './useChangeEssenceCount.types';
import { getCurrentPlayer, getPlayer } from '../../utils/player';
import { ESSENCE_UPDATE_TYPE_TO_LOG_TEXT, ESSENCE_UPDATE_TYPE_TO_LOG_TYPE } from '../../../../../helpers/transformers';
import { EssenceUpdateType } from '../../../gameContext';
import { MAX_POSSIBLE_ESSENCES } from '../../../rules/roles';
import React from 'react';
import { Log, LogContext } from '../../../logContextProvider';
import { Player } from '../../../../player/player.types';

export const useChangeEssenceCount: UseChangeEssenceCount = ({ gameState, addTurnAction }) => {
  const { createLog } = React.useContext(LogContext);

  const changeEssenceCount = (playerId: string, essenceUpdateType: EssenceUpdateType) => {
    const playerToUpdate = getPlayer(gameState.allPlayers, playerId);

    if (essenceUpdateType === EssenceUpdateType.ADD_ONE && playerToUpdate.essences >= MAX_POSSIBLE_ESSENCES) {
      leaveEssenceLimitReachedLog(playerToUpdate);
      return;
    }

    playerToUpdate.essences += essenceUpdateType;

    const log: Log = {
      type: ESSENCE_UPDATE_TYPE_TO_LOG_TYPE[essenceUpdateType],
      text: `${[playerToUpdate.playerName]}: ${[ESSENCE_UPDATE_TYPE_TO_LOG_TEXT[essenceUpdateType]]}`,
    };
    addTurnAction({ player: playerId, action: 'create log', logs: [log] });
    createLog(log);
  };

  const addEssenceToPlayers = (playerIds: string[]) => {
    gameState.allPlayers
      .filter((player) => playerIds.includes(player.id))
      .forEach((player) => {
        if (player.essences >= MAX_POSSIBLE_ESSENCES) {
          leaveEssenceLimitReachedLog(player);
          return;
        }

        player.essences += EssenceUpdateType.ADD_ONE;
      });

    const currentPlayer = getCurrentPlayer(gameState);
    const log: Log = { type: 'success', text: `${[currentPlayer.playerName]}: essence enclosed for everyone` };
    addTurnAction({ player: gameState.activePlayer, action: 'create log', logs: [log] });
    createLog(log);
  };

  const leaveEssenceLimitReachedLog = (player: Player) => {
    const limitReachedLog: Log = {
      type: 'error',
      text: `${[player.playerName]}: Maximum allowed essence amount is reached`,
    };

    addTurnAction({ player: gameState.activePlayer, action: 'create log', logs: [limitReachedLog] });
    createLog(limitReachedLog);
  };

  return {
    changeEssenceCount: changeEssenceCount,
    addEssenceToPlayers: addEssenceToPlayers,
  };
};
