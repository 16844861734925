import React from 'react';
import { UseProbabilityRollAction } from './useProbabilityRollAction.types';
import { GameContext } from '../../../gameContext';

export const useProbabilityRollAction: UseProbabilityRollAction = () => {
  const { rollProbCapsule, withUpdatingState } = React.useContext(GameContext);

  return {
    rollProbCapsule: withUpdatingState(rollProbCapsule),
  };
};
