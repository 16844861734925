import { UseActiveSessionDetector } from './useActiveSessionDetector.types';
import React from 'react';
import { AppContext } from '../../../../global/context/appContext';
import { GAME_ID_KEY, GAME_TOKEN_STORAGE_KEY } from '../../../../constants/delarationApi';
import { useNavigate } from 'react-router-dom';
import { ROOT_ROUTE } from '../../../../constants/routes';
import { useSnackbar } from 'notistack';

export const useActiveSessionDetector: UseActiveSessionDetector = () => {
  const { profile } = React.useContext(AppContext);
  const [joinedPlayer, setJoinedPlayer] = React.useState<string>();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  React.useEffect(() => {
    if (!profile || !joinedPlayer || profile.playerId !== joinedPlayer) {
      setJoinedPlayer(undefined);
      return;
    }

    localStorage.removeItem(GAME_ID_KEY);
    localStorage.removeItem(GAME_TOKEN_STORAGE_KEY);
    setJoinedPlayer(undefined);

    enqueueSnackbar('Disconnected due to entering the game on another device', {
      variant: 'error',
      persist: true,
      preventDuplicate: true,
    });
    navigate(ROOT_ROUTE);
  }, [profile, joinedPlayer]);

  return {
    onPlayerJoined: setJoinedPlayer,
  };
};
