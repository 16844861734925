import React from 'react';
import {
  DRAGGABLE_ICON_STALL_TIME_MILLISECONDS,
  getDefaultElementPosition,
  Props,
  storeElementPosition,
} from './draggableTableElement.types';
import { Box } from '@mui/material';
import { DraggableData, Rnd, RndDragCallback } from 'react-rnd';
import { AppContext } from '../../global/context/appContext';
import { sx } from './draggableTableElement.styles';
import { OpenWith } from '@mui/icons-material';

export const DraggableTableElement: React.FC<Props> = ({ name, position, size, outerBoxStyles, children }) => {
  const { windowSize } = React.useContext(AppContext);

  const [elementSize, setElementSize] = React.useState(size);
  const [elementPosition, setElementPosition] = React.useState(getDefaultElementPosition(name, position, windowSize));
  const rndRef = React.useRef<Rnd>(null);
  const dragIndicatorTimerRef = React.useRef<NodeJS.Timeout | null>(null);

  const [isDragging, setIsDragging] = React.useState(false);
  const [isDragIndicatorVisible, setIsDragIndicatorVisible] = React.useState(false);

  const handleDragStart = () => {
    setIsDragging(true);
  };

  const handleDragIndicatorMouseDown = () => {
    setIsDragging(true);
  };

  const handleDragIndicatorMouseUp = () => {
    setIsDragging(false);
  };

  const handleMouseEnter = (event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();

    if (dragIndicatorTimerRef.current) {
      clearTimeout(dragIndicatorTimerRef.current);
      dragIndicatorTimerRef.current = null;
    }
    setIsDragIndicatorVisible(true);
  };

  const handleMouseLeave = () => {
    dragIndicatorTimerRef.current = setTimeout(() => {
      setIsDragIndicatorVisible(false);
    }, DRAGGABLE_ICON_STALL_TIME_MILLISECONDS);
  };

  React.useEffect(() => {
    setElementSize(size);
  }, [size]);

  React.useEffect(() => {
    updateRndSize();
  }, [elementSize]);

  const updateRndSize = () => {
    if (rndRef.current) {
      rndRef.current.updateSize({ width: elementSize.width, height: elementSize.height });
    }
  };

  const handleDragEnd: RndDragCallback = (_, data: DraggableData) => {
    setElementPosition(data);
    storeElementPosition(name, { x: data.x, y: data.y });
    setIsDragging(false);
  };

  return (
    <Box sx={sx.mainBox}>
      <Rnd
        ref={rndRef}
        default={{
          x: elementPosition.x,
          y: elementPosition.y,
          width: elementSize.width,
          height: elementSize.height,
        }}
        bounds={'window'}
        enableResizing={false}
        onDragStart={handleDragStart}
        onDragStop={handleDragEnd}
        dragHandleClassName={`${name}-dragging-control`}
      >
        <Box sx={outerBoxStyles} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
          {(isDragIndicatorVisible || isDragging) && (
            <Box
              sx={sx.draggingIndicator}
              className={`${name}-dragging-control`}
              onMouseDown={handleDragIndicatorMouseDown}
              onMouseUp={handleDragIndicatorMouseUp}
            >
              <OpenWith fontSize="medium" />
            </Box>
          )}
          {children}
        </Box>
      </Rnd>
    </Box>
  );
};
