import {
  AllowedApplyType,
  ApplyRule,
  ATTACKING_NEBULA_CARDS,
  CardNames,
  EnergyColors,
  PlayingCard,
  PlayingCardType,
} from '../../card/cardTypes';
import { Action, APPLIED, CANCELED, IN_PROGRESS, REARRANGED, STARTED, TurnAction } from '../gameTypes';
import { findLastIndex, hasOneElement, isEmpty, last } from '../../../helpers/arrays';
import {
  allRearrangedCardActions,
  appliedEnergyActionsToColorsMap,
  cardsAndActionTypesToActions,
  filterOutRangeIfStartEndExist,
  ignoreProvidedActionTypes,
  PROBABILITY_CAPSULE_ACTIONS,
  toAppliedAction,
  toImpactedAction,
  toInProgressAction,
} from '../utils/turnActions';
import { Player } from '../../player/player.types';
import { DraggedCardData, DragSource } from '../../../components/draggable';
import {
  getCurrentPlayer,
  getPlayer,
  getPlayerAppliedGauntlet,
  getPlayerCards,
  getPlayerEnergyCards,
  getPlayerEnergySourceCards,
} from '../gameContextHandler/utils/player';
import { GameState } from '../gameContextHandler/hooks/useGameState';
import { ProbabilityValue } from '../gameContextHandler';
import { ESSENCE_HUNT_CARDS_COUNT } from '../../../constants/rules';

const IGNORED_DEFENDING_ACTIONS: Action[] = [
  toAppliedAction(CardNames.ESSENCE_ENCAPSULATION),
  toImpactedAction(CardNames.ESSENCE_ENCAPSULATION),
  toAppliedAction(CardNames.UNCHAINED_ESSENCE),
  toImpactedAction(CardNames.UNCHAINED_ESSENCE),
  toAppliedAction(CardNames.MASS_ESSENCE_ESCAPE),
  toImpactedAction(CardNames.MASS_ESSENCE_ESCAPE),
  toInProgressAction(CardNames.DOME),
  toImpactedAction(CardNames.DOME),
  toAppliedAction(CardNames.BLACK_HOLE),
  toImpactedAction(CardNames.BLACK_HOLE),
  toAppliedAction(CardNames.SPACE_DISTORTION),
  toImpactedAction(CardNames.SPACE_DISTORTION),
  toImpactedAction(CardNames.SHELL),
  ...Array.from(appliedEnergyActionsToColorsMap().keys()),
  'defendingFinished',
  'lost cards upon removal',
  ...PROBABILITY_CAPSULE_ACTIONS,
  toImpactedAction(CardNames.SQUANDER),
  'create log',
];

const ACTIVE_DRAW_DECK_ALLOWED_ACTIONS: Action[] = [
  'create log',
  'drawCard',
  ...[CANCELED, STARTED].map((status) => `essence hunt ${status}` as Action),
  ...allRearrangedCardActions,
];

const didPlayerPerformActiveActionInTurn = (player: Player, turnActions: TurnAction[]) =>
  !isEmpty(
    turnActions.filter(
      (action) =>
        action.player === player.id &&
        !cardsAndActionTypesToActions(Object.values(CardNames), [REARRANGED]).includes(action.action) &&
        ![
          'cardsDiscarded',
          'probability capsule randomized',
          'create log',
          toInProgressAction(CardNames.DISORIENTATION),
        ].includes(action.action)
    )
  );

export type IsActionActive = (gameState: GameState) => boolean;

export const isOneOfActionsActive = (gameState: GameState, isActionActiveFunctions: IsActionActive[]): boolean => {
  return isActionActiveFunctions.some((isActionActive) => {
    return isActionActive(gameState);
  });
};

export const filterIgnoredDefendingActions = (turnActions: TurnAction[]) =>
  turnActions.filter((turnAction) => !IGNORED_DEFENDING_ACTIONS.includes(turnAction.action));

export const getPlayerLastAttackingEnergyAction = (turnActions: TurnAction[], player: Player) => {
  const actionsToFilter = [
    ...cardsAndActionTypesToActions([...ATTACKING_NEBULA_CARDS, CardNames.SPACE_DISTORTION], [APPLIED]),
  ];
  const playerActions = turnActions.filter((turnAction) => turnAction.player === player.id);
  const lastAttackingActionIndex = findLastIndex(playerActions, (action) => actionsToFilter.includes(action.action));
  return lastAttackingActionIndex > -1 ? playerActions[lastAttackingActionIndex - 1] : undefined;
};

export const isArsenal = (card: PlayingCard): boolean => card.playingCardType === PlayingCardType.ARSENAL;
export const isCardRuleArsenalSlotEmpty = (card: PlayingCard, arsenalCards: PlayingCard[]): boolean =>
  isArsenal(card) && arsenalCards.filter((arsenalCard) => arsenalCard.applyRule === card.applyRule).length === 0;

export const isPlayerRadianceSlotEmpty = (card: PlayingCard, player: Player): boolean =>
  isRadiance(card) && isEmpty(player.activeRadiances.filter((arsenalCard) => arsenalCard.name === card.name));

export const runIfArsenalCardCanBeApplied = (
  playerId: string,
  card: PlayingCard,
  arsenalCards: PlayingCard[],
  turnActions: TurnAction[],
  block: () => void
) => {
  if (!isCardRuleArsenalSlotEmpty(card, arsenalCards)) {
    throw Error(`${ApplyRule[card.applyRule]} slot in Arsenal is taken`);
  }
  if (
    turnActions.find((action) => action.player === playerId && action.action === toAppliedAction(card.name)) !==
    undefined
  ) {
    throw Error(`${card.name} was already updated once during this turn`);
  }

  block();
};

export const canPlayerManageArsenal = (playerId: string, activePlayerId: string, isGameFinished: boolean): boolean =>
  !isGameFinished && activePlayerId === playerId;

export const isRadiance = (card: PlayingCard): boolean => card.playingCardType === PlayingCardType.RADIANCE;

export const canTeleport = (turnActions: TurnAction[]) => {
  const lastAction = last(ignoreProvidedActionTypes(turnActions, ['create log']));
  return !!lastAction && lastAction.action === toImpactedAction(CardNames.TELEPORT);
};

export const canDisintegrate = (turnActions: TurnAction[]) => {
  const lastAction = last(ignoreProvidedActionTypes(turnActions, ['create log']));
  return !!lastAction && lastAction.action === toImpactedAction(CardNames.DISINTEGRATOR);
};

export const canTeleportRadiance = (item: DraggedCardData, turnActions: TurnAction[]) => {
  return (
    !!item.fromPlayerId &&
    item.dragSource === DragSource.APPLIED_RADIANCE &&
    hasOneElement(item.cards) &&
    isRadiance(item.cards[0]) &&
    canTeleport(turnActions)
  );
};

export const getAllowedCardsInHandCount = (player: Player, allPlayers: string[]): number => {
  if (player.cardsOnTable.filter((card) => card.name === CardNames.BACKPACK).length > 0) {
    //TODO maybe in the future 2 should be moved to constant inside the BackPack Card
    return allPlayers.length + 2;
  }

  return allPlayers.length;
};

export const isCardsInHandCountWithinLimits = (player: Player, allPlayers: string[], turnActions: TurnAction[]) =>
  !didPlayerPerformActiveActionInTurn(player, turnActions) ||
  player.cardsInHand.length <= getAllowedCardsInHandCount(player, allPlayers);

export const canPlayerChooseEnergySource = (player: Player, card: PlayingCard): boolean => {
  if (![PlayingCardType.NEBULA, PlayingCardType.RADIANCE].includes(card.playingCardType)) {
    return false;
  }

  if (getPlayerEnergySourceCards(player).length > 1) {
    return true;
  }

  return !isEmpty(getPlayerEnergyCards(player)) && getPlayerAppliedGauntlet(player) === undefined;
};

export const isCardActionActive = (gameState: GameState): boolean => {
  if (gameState.isFinished) {
    return false;
  }

  return getCurrentPlayer(gameState).id === gameState.activePlayer;
};

export const isDrawDeckActive = (gameState: GameState): boolean => {
  if (!isCardActionActive(gameState)) {
    return false;
  }
  if (gameState.drawCardAmount < 1) {
    return false;
  }

  const filteredTurnActions = filterOutRangeIfStartEndExist({
    turnActions: gameState.turnActions,
    start: toInProgressAction(CardNames.DISORIENTATION),
    end: toImpactedAction(CardNames.DISORIENTATION),
  });

  return filteredTurnActions.every(({ action }) => ACTIVE_DRAW_DECK_ALLOWED_ACTIONS.includes(action));
};

export const canPlayerHuntEssence = (gameState: GameState): boolean => {
  const currentPlayer = getCurrentPlayer(gameState);
  const lastAction = last(
    gameState.turnActions.filter(
      (action) => action.player === currentPlayer.id && !allRearrangedCardActions.includes(action.action)
    )
  )?.action;

  return (
    currentPlayer.id === gameState.activePlayer &&
    (lastAction === undefined ||
      [`essence hunt ${CANCELED}`, toImpactedAction(CardNames.DISORIENTATION)].includes(lastAction)) &&
    getPlayerCards(currentPlayer).length >= ESSENCE_HUNT_CARDS_COUNT
  );
};

export const canPlayerExchangeEssenceForCards = (gameState: GameState) => {
  if (!isCardActionActive(gameState)) {
    return false;
  }
  if (getCurrentPlayer(gameState).essences < 2) {
    return false;
  }

  const filteredTurnActions = filterOutRangeIfStartEndExist({
    turnActions: gameState.turnActions,
    start: toInProgressAction(CardNames.DISORIENTATION),
    end: toImpactedAction(CardNames.DISORIENTATION),
  });

  return filteredTurnActions.every(({ action }) => ACTIVE_DRAW_DECK_ALLOWED_ACTIONS.includes(action));
};

export const isEssenceHuntInProgress = (turnActions: TurnAction[]): boolean => {
  const lastAction = last(turnActions.filter((action) => action.action !== 'cardsDiscarded'));
  return !!lastAction && lastAction.action === `essence hunt ${STARTED}`;
};

export const isChallengeActive: IsActionActive = (gameState: GameState): boolean => {
  const lastActiveAction = last(filterIgnoredDefendingActions(gameState.turnActions))?.action;
  return (
    !!lastActiveAction &&
    cardsAndActionTypesToActions([CardNames.CHALLENGE], [APPLIED, IN_PROGRESS]).includes(lastActiveAction)
  );
};

export const getChallengeLostPlayerId = (gameState: GameState): string | undefined => {
  if (!isChallengeActive(gameState)) throw Error('Challenge is not active');

  const attackingPlayerAction = last(
    gameState.turnActions.filter(
      (action) => action.player === gameState.activePlayer && action.action === 'probability capsule randomized'
    )
  );
  if (attackingPlayerAction === undefined) throw Error('No attacking Player action found');

  const attackingPlayerScore = attackingPlayerAction.probValue;
  if (attackingPlayerScore === undefined) throw Error('No attacking Player draw score found');

  const defendingPlayerAction = last(
    gameState.turnActions.filter(
      (action) =>
        action.player === gameState.activeDefendingPlayer && action.action === 'probability capsule randomized'
    )
  );
  if (defendingPlayerAction === undefined) throw Error('No defending Player action found');

  const defendingPlayerScore = defendingPlayerAction.probValue;
  if (defendingPlayerScore === undefined) throw Error('No defending Player draw score found');

  if (defendingPlayerScore === attackingPlayerScore) return undefined;

  return defendingPlayerScore < attackingPlayerScore ? gameState.activeDefendingPlayer : gameState.activePlayer;
};

export const getSourceColor = (
  turnActions: TurnAction[],
  allPlayers: Player[],
  playerId: string
): EnergyColors | undefined => {
  const byPlayer = getPlayer(allPlayers, playerId);
  const lastNonActiveAction = getPlayerLastAttackingEnergyAction(turnActions, byPlayer);
  if (lastNonActiveAction) {
    const energySource = appliedEnergyActionsToColorsMap().get(lastNonActiveAction.action);
    if (energySource !== undefined) return energySource;
  }

  const gauntlet = getPlayerAppliedGauntlet(byPlayer);
  if (gauntlet === undefined) return undefined;
  return gauntlet.energyColors[0];
};

export const isDisorientationInProgress: IsActionActive = (gameState: GameState) => {
  const lastActiveAction = last(filterIgnoredDefendingActions(gameState.turnActions))?.action;
  return !!lastActiveAction && lastActiveAction === toInProgressAction(CardNames.DISORIENTATION);
};

const doesActionSatisfyPlayerCondition = (action: TurnAction, playerId: string, onlyPlayerActions: boolean) => {
  if (!onlyPlayerActions) return true;
  return action.player === playerId;
};

const isDomeCheckLastStateOnAction = (
  gameState: GameState,
  domeAction: Action,
  onlyCurrentPlayer: boolean
): boolean => {
  const currentPlayerId = getCurrentPlayer(gameState).id;
  const lastActiveAction = last(
    gameState.turnActions.filter(
      (action) =>
        doesActionSatisfyPlayerCondition(action, currentPlayerId, onlyCurrentPlayer) &&
        ![...PROBABILITY_CAPSULE_ACTIONS, toImpactedAction(CardNames.SQUANDER)].includes(action.action)
    )
  );
  return !!lastActiveAction && lastActiveAction.action === domeAction;
};

export const isAnyDomeCheckInProgress: IsActionActive = (gameState: GameState): boolean =>
  isDomeCheckLastStateOnAction(gameState, toInProgressAction(CardNames.DOME), false);

export const isDomeCheckInProgress: IsActionActive = (gameState: GameState): boolean =>
  isDomeCheckLastStateOnAction(gameState, toInProgressAction(CardNames.DOME), true);

export const isDomeCheckFinished: IsActionActive = (gameState: GameState): boolean =>
  isDomeCheckLastStateOnAction(gameState, toImpactedAction(CardNames.DOME), true);

export const canPlayerFinishProbabilityAction = (
  playerId: string,
  gameState: GameState,
  isActionActive: IsActionActive
): boolean => {
  if (!isActionActive) return false;

  const lastPlayerAction = last(
    gameState.turnActions.filter(
      (action) => action.player === playerId && action.action !== toImpactedAction(CardNames.FOGGER)
    )
  )?.action;

  return lastPlayerAction === 'probability capsule randomized';
};

export const getActionCheckProbabilityValue = (
  gameState: GameState,
  isActionActive: (gameState: GameState) => boolean
): ProbabilityValue => {
  if (!isActionActive(gameState)) throw Error('Action is not active');

  const currentPlayerId = getCurrentPlayer(gameState).id;
  const playerProbRollAction = last(
    gameState.turnActions.filter(
      (action) => action.player === currentPlayerId && action.action === 'probability capsule randomized'
    )
  );
  if (playerProbRollAction === undefined) throw Error('No probability capsule roll action found');

  const playerProbRollScore = playerProbRollAction.probValue;
  if (playerProbRollScore === undefined) throw Error('No Player probability capsule roll score found');

  return playerProbRollScore;
};

export const canDropDraggedCardToHand = (item: DraggedCardData, playerId: string, gameState: GameState): boolean =>
  hasOneElement(item.cards) &&
  playerId !== gameState.activeDefendingPlayer &&
  !isOneOfActionsActive(gameState, [isChallengeActive, isDomeCheckInProgress, isDisorientationInProgress]);

export const canApplyToOtherPlayer = (item: DraggedCardData, gameState: GameState): boolean => {
  if (canTeleport(gameState.turnActions)) return true;

  return hasOneElement(item.cards) && item.cards[0].allowedApplyTypes.includes(AllowedApplyType.OTHER_PLAYER);
};

export const isUnchainedEssenceApplied = (gameState: GameState) =>
  last(gameState.turnActions)?.action === toAppliedAction(CardNames.UNCHAINED_ESSENCE);
