import React from 'react';
import { Props as MovingCardsProps } from '../../../components/movingCards/movingCards.types';
import { Props as MovingEssenceProps } from '../../../components/movingEssence/movingEssence.types';
import { Props as PredictionTargetProps } from '../../../components/predictionTarget/predictionTarget.types';
import { UsePulsatingCardsResult } from './usePulsatingCards';

export type Props = {
  children?: React.ReactNode;
};

export type AnimationContextType = {
  animatingCards: React.ReactElement[];
  animatingEssences?: React.ReactElement;
  animatingPredictionTargets?: React.ReactElement;
  setAnimatingPredictionTargets: React.Dispatch<React.SetStateAction<React.ReactElement | undefined>>;
  queueMovingCardsProps: (...props: MovingCardsProps[][]) => void;
  queueMovingEssencesProps: (props: MovingEssenceProps) => void;
  queuePredictionTargetProps: (props: PredictionTargetProps) => void;
} & UsePulsatingCardsResult;

export const AnimationContext = React.createContext({} as AnimationContextType);
