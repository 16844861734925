import React from 'react';
import { Props } from './storyAccordion.types';
import { HtmlTypography } from '../../../components/htmlTypography';
import { containsSearched } from '../../../helpers/strings';
import { Accordion, AccordionDetails, AccordionSummary } from '../styledAccordion/styledAccordion';
import { sx } from './storyAccordion.styles';

export const StoryAccordion: React.FC<Props> = ({ story, searched }) => {
  const [isOpen, setIsOpen] = React.useState(false);

  const storyContainsSearched = (): boolean => containsSearched(story.join(' '), searched);
  React.useEffect(() => setIsOpen(storyContainsSearched()), [searched]);

  const storyComponent = story.map((paragraph, index) => (
    <HtmlTypography key={`paragraph-${index}`} searched={searched} sx={sx.paragraph}>
      {paragraph}
    </HtmlTypography>
  ));

  return (
    <Accordion expanded={isOpen} onChange={() => setIsOpen(!isOpen)}>
      <AccordionSummary aria-controls="story-content" id="story-header">
        <HtmlTypography>Story</HtmlTypography>
      </AccordionSummary>
      <AccordionDetails>{storyComponent}</AccordionDetails>
    </Accordion>
  );
};
