import axios from 'axios';
import {
  DELTARATION_API_STATS_FINISHED_GAMES_URL,
  DELTARATION_API_MAIN_STATS_URL,
  DELTARATION_API_PLAYERS_MAIN_STATS_URL,
} from '../../constants/delarationApi';
import { FinishedGameApiData, GamePlayersMainStatsData, MainGameStatsApiData } from './statsTypes';

class StatsApi {
  public async getLastFinishedGames(limit: number): Promise<FinishedGameApiData[]> {
    const url = DELTARATION_API_STATS_FINISHED_GAMES_URL + '?limit=' + limit;

    const response = await axios.get(url, {
      headers: {
        'Content-Type': 'application/json',
      },
    });

    return response.data;
  }

  public async getMainStats(): Promise<MainGameStatsApiData> {
    const response = await axios.get(DELTARATION_API_MAIN_STATS_URL, {
      headers: {
        'Content-Type': 'application/json',
      },
    });

    return response.data;
  }

  public async getPlayersMainStats(limit: number): Promise<GamePlayersMainStatsData[]> {
    const url = DELTARATION_API_PLAYERS_MAIN_STATS_URL + '?limit=' + limit;
    const response = await axios.get(url, {
      headers: {
        'Content-Type': 'application/json',
      },
    });

    return response.data;
  }
}

export default StatsApi;
