import React from 'react';
import { Props } from './flipper.types';
import { motion, AnimatePresence } from 'framer-motion';

export const Flipper: React.FC<Props> = ({ transitionDuration, children }) => {
  return (
    <AnimatePresence>
      <motion.div
        exit={{ opacity: 1, rotateY: 0, scale: 1 }}
        initial={{ opacity: 1, rotateY: -90, scale: 1 }}
        transition={{ ease: 'easeOut', duration: transitionDuration, flip: true }}
        animate={{ opacity: 1, rotateY: 0, scale: 1 }}
      >
        {children}
      </motion.div>
    </AnimatePresence>
  );
};
