import React from 'react';
import { IconButton } from '@mui/material';
import { sx } from '../onboardingWidget.styles';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { ActionDrawerContext } from '../../mobile/contexts/actionDrawerContext/actionDrawerContext';
import { OnboardingVideos } from '../../../../components/onboardingVideos';
import styles from './onboardingVideosMobile.module.css';

export const OnboardingWidgetMobile: React.FC = () => {
  const { setContent, setName, name } = React.useContext(ActionDrawerContext);

  const onboardingName = 'Onboarding videos';

  const closeDrawer = () => {
    setContent(undefined);
    setName('');
  };

  const openDrawer = () => {
    setContent(
      <div className={styles.videos}>
        <OnboardingVideos />
      </div>
    );
    setName(onboardingName);
  };

  const toggle = () => {
    if (!name) {
      openDrawer();
      return;
    }

    closeDrawer();
  };

  return (
    <div>
      <React.Fragment>
        <IconButton onClick={toggle} sx={sx.button}>
          <HelpOutlineIcon sx={sx.icon} />
        </IconButton>
      </React.Fragment>
    </div>
  );
};
