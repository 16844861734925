import { ProjectColors } from '../../../themes/mainTheme';

export const sxInit = (showFullContent: boolean) => {
  const contentBoxSx = showFullContent ? { flexDirection: 'row' } : { flexDirection: 'column' };
  const imageBoxSx = showFullContent ? { width: '200px' } : { alignItems: 'center' };
  const descriptionBoxSx = showFullContent ? { width: '80%' } : {};

  return {
    table: {
      general: { minWidth: 650, border: `1px solid`, mt: 3, mb: 3, borderCollapse: 'separate' },
      head: { backgroundColor: ProjectColors.RULEBOOK_SECTIONS },
    },
    rules: {
      list: {
        general: { listStyleType: 'disc', pl: 4 },
        roles: { listStyleType: 'disc' },
        item: { display: 'list-item' },
      },
    },
    essences: {
      mainRow: {
        backgroundColor: `${ProjectColors.ESSENCE_TRANSPARENT}`,
        outline: `1px solid ${ProjectColors.WHITE}`,
      },
      descriptionCell: { display: 'flex', alignItems: 'center' },
      description: { position: 'relative', zIndex: 1 },
    },
    roles: {
      mainBox: { display: 'flex', flexDirection: 'column', justifyContent: 'center' },
      contentBox: { display: 'flex', justifyContent: 'center', ...contentBoxSx },
      imageBox: { display: 'flex', justifyContent: 'center', ...imageBoxSx },
      descriptionBox: { ...descriptionBoxSx },
      roleImage: { height: '65px', width: '65px', objectFit: 'scale-down', m: 1 },
      divider: { my: 1 },
    },
  };
};
