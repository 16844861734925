import React from 'react';
import { Button } from '@mui/material';
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';
import styles from './../playNowButtons.module.css';
import { Props } from './joinGameButton.types';
import { MouseEvent } from '../playNowButtons';
import cn from 'classnames';

export const JoinGameButton: React.FC<Props> = ({ buttonName, onClick, customStylesClassname }) => {
  const onButtonClick = (event: MouseEvent) => onClick(event, 'Join');

  return (
    <Button
      onClick={onButtonClick}
      variant="outlined"
      className={cn(styles.button, styles.join, customStylesClassname ? styles[customStylesClassname] : undefined)}
    >
      <PersonAddAlt1Icon className={styles.icon} />
      <p className={styles.text}>{buttonName}</p>
    </Button>
  );
};
