import { Box, CardMedia, SxProps, Typography } from '@mui/material';
import React from 'react';
import { Props } from './finishedGameModal.types';
import { sx } from './finishedGameModal.styles';
import { GameContext } from '../../game/gameContext';
import { getCurrentPlayer } from '../../game/gameContextHandler/utils/player';
import { MotionBlock } from '../../../components/motionBlock';
import { useGlitch } from 'react-powerglitch';
import { glitchStatsContentProperties } from '../../../constants/components';
import { Variant } from '@mui/material/styles/createTypography';
import { Modal } from '../../../components/modal';

export const FinishedGameModal: React.FC<Props> = ({ isOpen, onClose }) => {
  const { gameState, getGameWinningRole, gameDurationLog } = React.useContext(GameContext);
  const currentPlayer = getCurrentPlayer(gameState);

  const glitch = useGlitch(glitchStatsContentProperties);

  if (!gameState.isFinished || currentPlayer === undefined) {
    return <div></div>;
  }

  const playersWon = gameState.allPlayers.filter((player) => player.isWinner);
  const winnersNames = playersWon.map((player) => player.playerName);
  const wonPlayersList =
    playersWon.length > 1 ? `Players won: ${winnersNames.join(', ')}` : `Player won: ${winnersNames[0]}`;

  const wrapText = (child: React.ReactNode, variant: Variant, styles: SxProps | undefined = undefined) => (
    <Typography variant={variant} component="h2" sx={styles} ref={glitch.ref}>
      {child}
    </Typography>
  );

  return (
    <Modal isOpen={isOpen} onClose={onClose} variant={currentPlayer.isWinner ? 'green' : 'red'}>
      <Box sx={sx.mainBox}>
        {wrapText('This deltaration game is finished', 'h4', sx.gameFinishedText)}
        <Box sx={sx.mainStatBox}>
          <Box sx={sx.stateBox}>
            <Typography
              id="finished-game-player-state"
              variant="h6"
              component="h2"
              sx={currentPlayer.isWinner ? sx.playerWonText : sx.playerLostText}
            >
              {currentPlayer.isWinner ? 'You won' : 'You lost'}
            </Typography>
          </Box>
          <Box sx={sx.gameStatBox}>
            <Box>
              {wrapText(`Role won: ${getGameWinningRole()}`, 'h6')}
              {wrapText(wonPlayersList, 'h6')}
              {wrapText(`Game duration: ${gameDurationLog}`, 'h6')}
            </Box>
            <Box>
              <MotionBlock transitionDuration={1.5}>
                <CardMedia
                  key={`role-image-${playersWon[0].roleCard.role}`}
                  component="img"
                  src={playersWon[0].roleCard.image}
                  alt={`role-image-${playersWon[0].roleCard.role}`}
                  sx={sx.roleImage}
                />
              </MotionBlock>
            </Box>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};
