export const MAIN_SOUND_THEME_FLAG = 'main-sound-theme-flag';
export const MAIN_SOUND_THEME_VOLUME = 'main-sound-theme-volume';
export const MAIN_SOUND_THEME_TYPE = 'main-sound-theme-type';
export const MY_SOUNDS_FLAG = 'my-sounds-flag';
export const MY_SOUNDS_VOLUME = 'mySoundsVolume';
export const OTHER_PLAYERS_SOUNDS_FLAG = 'other-players-sounds-flag';
export const OTHER_PLAYERS_SOUNDS_VOLUME = 'otherPlayersSoundsVolume';
export const ONBOARDING_COMPLETE_FLAG = 'onboarding-complete-flag';
export const DISABLE_NOTIFICATIONS_FLAG = 'disable-notifications-flag';
export const ENABLE_PREDICTIONS = 'enable-predictions';
