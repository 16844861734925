import React from 'react';
import { Props } from './dynamicCounter.types';
import { Box } from '@mui/material';

export const DynamicCounter: React.FC<Props> = ({ initialValue, finalValue, startDelay, duration }) => {
  const [count, setCount] = React.useState(initialValue);
  const requestIdRef = React.useRef<number | null>(null);
  const startTimeRef = React.useRef<number | null>(null);

  const easeOutCubic = (t: number): number => {
    return 1 - Math.pow(1 - t, 3);
  };

  React.useEffect(() => {
    const animate = (timestamp: number) => {
      startTimeRef.current = startTimeRef.current || timestamp;
      const elapsedTime = timestamp - startTimeRef.current;
      const progress = Math.min(elapsedTime / duration, 1);
      const easedProgress = easeOutCubic(progress);
      const newCount = Math.floor(initialValue + easedProgress * (finalValue - initialValue));
      setCount(newCount);

      if (progress < 1) {
        requestIdRef.current = requestAnimationFrame(animate);
      } else {
        startTimeRef.current = null;
      }
    };

    const timerId = setTimeout(() => {
      requestIdRef.current = requestAnimationFrame(animate);
    }, startDelay);

    return () => {
      clearTimeout(timerId);
      if (requestIdRef.current) {
        cancelAnimationFrame(requestIdRef.current);
      }
    };
  }, [finalValue, initialValue, startDelay, duration]);

  return <Box component="span">{count}</Box>;
};
