import React from 'react';
import { Props } from './router.types';
import { Routes, Route, useNavigate } from 'react-router-dom';
import {
  ABOUT_US_ROUTE,
  ACTIVE_GAME_ROUTE,
  GAME_ROUTE,
  NOT_FOUND_ERROR_ROUTE,
  PROFILE_ROUTE,
  ROOT_ROUTE,
  RULES_ROUTE,
  SPECIFIC_ERROR_ROUTE,
} from '../../../constants/routes';
import { Game } from '../../../views/game';
import { Home } from '../../../views/home';
import { Box } from '@mui/material';
import { Header } from '../header';
import { Rules } from '../../../views/rules';
import { ErrorPage } from '../../../views/errorPage';
import { DonationWidgetsPanel, GameWidgetsPanel, MobileGameWidgetsPanel } from '../../../features/menu/widgetsPanel';
import { AboutUs } from '../../../views/aboutUs';
import { GameWithContext } from './gameWithContext';
import { Profile } from '../../../views/profile';
import { AppContext } from '../../context/appContext';
import { MobileHeader } from '../header/mobile';

export const Router: React.FC<Props> = () => {
  const { isMobile } = React.useContext(AppContext);

  const header = <Header key="header" />;
  const mobileHeader = <MobileHeader key="mobile-header" />;
  const gameWidgets = <GameWidgetsPanel key="game-widgets-panel" />;
  const mobileGameWidgets = <MobileGameWidgetsPanel key="mobile-game-widgets-panel" />;
  const donationWidgets = <DonationWidgetsPanel key="donation-widgets-panel" />;

  const desktopHeadersAndWidgets = [header, gameWidgets, donationWidgets];
  const mobileHeadersAndWidgets = [mobileHeader, mobileGameWidgets];
  const headersAndAllWidgets = isMobile ? mobileHeadersAndWidgets : desktopHeadersAndWidgets;
  const headerForActiveGame = isMobile ? [mobileHeader] : [header, donationWidgets];

  const withComponents = (children: React.ReactNode, components: React.ReactElement[]) => (
    <Box>
      {components}
      {children}
    </Box>
  );

  const RedirectToNotFound: React.FC = () => {
    const navigate = useNavigate();

    React.useEffect(() => {
      navigate(NOT_FOUND_ERROR_ROUTE);
    }, []);

    return <></>;
  };

  return (
    <Routes>
      <Route path={ROOT_ROUTE} element={withComponents(<Home />, headersAndAllWidgets)} />
      <Route path={GAME_ROUTE} element={withComponents(<Game />, headersAndAllWidgets)} />
      <Route path={ACTIVE_GAME_ROUTE()} element={withComponents(<GameWithContext />, headerForActiveGame)} />
      <Route path={RULES_ROUTE} element={withComponents(<Rules />, headersAndAllWidgets)} />
      <Route path={SPECIFIC_ERROR_ROUTE()} element={withComponents(<ErrorPage />, headersAndAllWidgets)} />
      <Route path={ABOUT_US_ROUTE} element={withComponents(<AboutUs />, headersAndAllWidgets)} />
      <Route path={PROFILE_ROUTE} element={withComponents(<Profile />, headersAndAllWidgets)} />
      <Route path={'*'} element={withComponents(<RedirectToNotFound />, headersAndAllWidgets)} />
    </Routes>
  );
};
