export enum AuthProvider {
  GOOGLE = 'google',
  DISCORD = 'discord',
}

export type ApiAuthRequest = GoogleAuthRequest | DiscordAuthRequest;

export type GoogleAuthRequest = {
  credential: string;
  authProvider: AuthProvider;
};

export type DiscordAuthRequest = {
  credential: string;
  authProvider: AuthProvider;
  redirectUrl: string;
};

export type Profile = {
  appToken: string;
  playerId: string;
  playerName?: string;
};
