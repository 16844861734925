import { ProjectColors } from '../../../../../../themes/mainTheme';

export const sx = {
  outerBox: { display: 'flex', justifyContent: 'space-between', alignItems: 'center' },
  detailsText: {
    ml: 1,
    maxWidth: `200px`,
    p: 1,
    backgroundColor: ProjectColors.PURPLE_VERY_DARK_TRANSPARENT,
    border: `1px solid ${ProjectColors.PLAY_CARD_RADIANCE}`,
    textAlign: 'center',
    fontStyle: 'italic',
  },
} as const;
