import React from 'react';
import {
  defaultTablePosition,
  ORIGINAL_DISCARD_PILE_HEIGHT,
  ORIGINAL_DISCARD_PILE_HEIGHT_MOBILE,
  ORIGINAL_DISCARD_PILE_WIDTH,
  ORIGINAL_DISCARD_PILE_WIDTH_MOBILE,
  Props,
} from './discardPile.types';
import { Box, CardMedia } from '@mui/material';
import { ImagePath } from '../../assets/assets';
import { Droppable } from '../droppable';
import { GameContext } from '../../features/game/gameContext';
import { DraggedCardData } from '../draggable';
import { canTeleport, isDisorientationInProgress } from '../../features/game/rules/rules';
import { AppContext } from '../../global/context/appContext';
import { initSx } from './discardPile.styles';
import { DraggableTableElement } from '../draggableTableElement';
import { SxStyles } from '../../helpers/styles';
import { getCurrentPlayer } from '../../features/game/gameContextHandler/utils/player';
import { useCardDimension } from '../card/hooks/useCardDimension';
import { NORMAL_TO_EXTENDED_SIZE } from '../../helpers/sizes';
import { roomItems, useTrackLocation } from '../../features/room/hooks/useTrackLocation';
import { useDiscardPileAction } from '../../features/game/actions/hooks/useDiscardPileAction';
import { isMobile } from 'react-device-detect';

export const DiscardPile: React.FC<Props> = ({ size, playingCards }) => {
  const { gameState, isDraggable, isAllowedToDrag } = React.useContext(GameContext);
  const { moveCardToDiscardPile } = useDiscardPileAction();
  const { windowSize } = React.useContext(AppContext);
  const { dimension: cardDimension } = useCardDimension({ size: NORMAL_TO_EXTENDED_SIZE[size] });

  const sizeModifier = cardDimension * (isMobile ? 0.0044 : 0.006);
  const height = (isMobile ? ORIGINAL_DISCARD_PILE_HEIGHT_MOBILE : ORIGINAL_DISCARD_PILE_HEIGHT) * sizeModifier;
  const width = (isMobile ? ORIGINAL_DISCARD_PILE_WIDTH_MOBILE : ORIGINAL_DISCARD_PILE_WIDTH) * sizeModifier;

  const sx = initSx(playingCards, height, width, isMobile);

  const canDrop = (item: DraggedCardData) =>
    (item.fromPlayerId ? isDraggable(item.fromPlayerId) : true) &&
    (item.dragger ? isAllowedToDrag(item.dragger) : false) &&
    getCurrentPlayer(gameState).id !== gameState.activeDefendingPlayer &&
    !canTeleport(gameState.turnActions) &&
    !isDisorientationInProgress(gameState);

  const { ref } = useTrackLocation({ items: [roomItems.discardPile] });

  const discardPileBlock = (
    <>
      <Droppable
        onItemDropped={moveCardToDiscardPile}
        canDrop={canDrop}
        extraSx={isMobile ? sx.droppableElementMobile : sx.droppableElement}
      >
        <Box ref={ref} sx={sx.droppableMainBox}></Box>
      </Droppable>
      <CardMedia
        component="img"
        alt="discard-pile"
        image={isMobile ? ImagePath.discardPileMobile : ImagePath.discardPile}
        sx={sx.imageSx}
        draggable={false}
      />
    </>
  );

  return isMobile ? (
    <Box sx={{ width: width, height: height, ...sx.outerBox, ...sx.mobileOuterBox }}>{discardPileBlock}</Box>
  ) : (
    <DraggableTableElement
      name={'discardPile'}
      outerBoxStyles={sx.outerBox as SxStyles}
      position={defaultTablePosition(windowSize)}
      size={{ width: width, height: height }}
    >
      {discardPileBlock}
    </DraggableTableElement>
  );
};
