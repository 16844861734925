import React from 'react';
import { Props } from './discordLoginButton.types';
import styles from './discordLoginButton.module.css';
import { FaDiscord } from 'react-icons/fa';
import cn from 'classnames';
import { DISCORD_LOGIN_URL } from '../../../constants/login';

export const DiscordLoginButton: React.FC<Props> = ({ onlyIcon }) => {
  const onClick = React.useCallback(() => {
    window.location.href = DISCORD_LOGIN_URL;
  }, []);

  return (
    <div
      data-testid="discord-login-button"
      className={cn(styles.button, { [styles.isIconOnly]: onlyIcon })}
      onClick={onClick}
    >
      <FaDiscord data-testid="discord-login-button-icon" className={styles.icon} />
      {!onlyIcon && (
        <div data-testid="discord-login-button-text" className={styles.textContainer}>
          <p>Login with discord</p>
        </div>
      )}
    </div>
  );
};
