import React from 'react';
import { Props } from './currentPlayerBoard.types';
import { Box, Tooltip, Typography } from '@mui/material';
import { NORMAL_TO_EXTENDED_SIZE, SizeNormal } from '../../../../helpers/sizes';
import { cardToComponent } from '../../../../helpers/transformers';
import { Card, CardIdentified, SelectorType } from '../../../../components/card';
import { sxInit } from './currentPlayerBoard.styles';
import { Droppable } from '../../droppable';
import { GameContext } from '../../../game/gameContext';
import { ApplyRuleGroup } from '../../../card/cardTypes';
import { canPlayerManageArsenal } from '../../../game/rules/rules';
import { Draggable, DragSource } from '../../../../components/draggable';
import { getPlayerBoxSxFromState, tooltipStyles } from '../../../../helpers/styles';
import { FinishActionButton } from '../../../../components/finishActionButton';
import { EssenceBar } from '../../../../components/essenceBar';
import { useCardDimension } from '../../../../components/card/hooks/useCardDimension';
import { useDisplayMode } from '../../hooks/useDisplayMode';
import { DisplayMode } from '../../player.types';
import { roomItems, useTrackLocation } from '../../../room/hooks/useTrackLocation';
import { Name } from '../../../../components/name';
import { BlocksDirection, definePlayerStateByGameState } from '../../../../helpers/player';
import { handLabel } from '../../../../helpers/labels';
import { useCardDrop } from '../hooks/useCardDrop';
import { Arsenal } from '../../allPlayers/arsenal';
import { Label } from '../../../../components/label';
import { CardGroup } from '../../../../components/cardGroup';
import { NotificationTicker } from '../../../room/mobile/components/notificationTicker';

export const CurrentPlayerBoard: React.FC<Props> = ({ player, allowedCardInHandCount, size, onCardShow }) => {
  const { id, cardsInHand, cardsOnTable, activeRadiances, roleCard, playerName, essences } = player;
  const { gameState, isDraggable, isAllowedToDrag } = React.useContext(GameContext);
  const { canDropToHand, canDropToArsenal, onDropToHand, onDropToArsenal } = useCardDrop({
    playerId: id,
    cardsOnTable: cardsOnTable,
  });

  const extendedSize = NORMAL_TO_EXTENDED_SIZE[size];
  const isDefendingNow = gameState.activeDefendingPlayer === id;
  const isActing = !gameState.isFinished && (gameState.activePlayer === id || isDefendingNow);

  const showLabels = size === SizeNormal.M;

  const { dimension: cardDimension } = useCardDimension({ size: extendedSize });
  const displayMode = useDisplayMode();
  const sx = sxInit(displayMode, cardDimension);
  const isCompact = displayMode === DisplayMode.COMPACT;

  const nameComponent = <Typography sx={tooltipStyles}>{`Name: ${playerName}`}</Typography>;
  const role = (
    <Tooltip title={nameComponent} disableInteractive placement="bottom">
      <Box sx={isCompact ? sx.row.info.roleCompact : sx.row.info.role} onClick={() => onCardShow(roleCard)}>
        <Card {...cardToComponent(roleCard, extendedSize, undefined, undefined)} />
      </Box>
    </Tooltip>
  );

  const { ref: arsenalRef } = useTrackLocation({ items: [roomItems.playerArsenal(id)] });
  const arsenal = (
    <Box ref={arsenalRef} sx={sx.row.info.arsenal}>
      <Droppable
        playerId={id}
        applyRuleGroup={ApplyRuleGroup.ARSENAL}
        onItemDropped={onDropToArsenal}
        canDrop={canDropToArsenal}
      >
        {showLabels && <Label label="Arsenal" />}
        <Arsenal
          playerId={id}
          size={extendedSize}
          cardsOnTable={cardsOnTable}
          blocksDirection={BlocksDirection.LEFT_TO_RIGHT}
          isDraggable={canPlayerManageArsenal(id, gameState.activePlayer, gameState.isFinished)}
          onCardClick={onCardShow}
          displayType={isCompact ? 'compact' : 'standard'}
        />
      </Droppable>
    </Box>
  );

  const { ref: handRef } = useTrackLocation({ items: [roomItems.playerHand(id)] });
  const hand = (
    <Box
      ref={handRef}
      sx={allowedCardInHandCount >= cardsInHand.length ? sx.row.hand.withinLimit : sx.row.hand.overLimit}
    >
      <Droppable playerId={id} applyRuleGroup={ApplyRuleGroup.ALL} onItemDropped={onDropToHand} canDrop={canDropToHand}>
        <CardGroup
          isActive={isAllowedToDrag(id) && isDraggable(id)}
          cards={cardsInHand}
          size={extendedSize}
          label={handLabel(cardsInHand.length, allowedCardInHandCount)}
          placeholdersCount={allowedCardInHandCount}
          onCardClick={onCardShow}
          sourceType={DragSource.PLAYER}
          owningPlayerId={id}
          selectorType={isActing ? SelectorType.MULTIPLE_ON_CTRL : undefined}
        />
      </Droppable>
    </Box>
  );

  const standardMainBox = (
    <Box sx={sx.row.info.box}>
      <Box sx={sx.row.info.mainAction}>
        <Box sx={sx.row.capsuleBox}>
          <EssenceBar playerId={id} count={essences} />
        </Box>
        <FinishActionButton displayMode={DisplayMode.STANDARD} />
        <Box sx={sx.row.capsuleBox}>{<Name playerName={playerName} withTooltip tooltipPlacement={'left'} />}</Box>
      </Box>
      {role}
      {arsenal}
    </Box>
  );

  const compactMainBox = (
    <Box sx={sx.row.info.box}>
      <Box sx={sx.row.info.mainActionCompact}>
        <Box sx={sx.row.capsuleBox}>
          <EssenceBar playerId={id} count={essences} />
        </Box>
        <FinishActionButton displayMode={DisplayMode.COMPACT} />
        <Box sx={sx.row.capsuleBox}>{<Name playerName={playerName} withTooltip tooltipPlacement={'left'} />}</Box>
        {role}
      </Box>
      {arsenal}
    </Box>
  );

  const playerState = definePlayerStateByGameState(id, gameState);

  const { ref: radiancesRef } = useTrackLocation({ items: [roomItems.playerRadiances(id)] });
  return (
    <>
      <NotificationTicker variant={isCompact ? 'desktopCompact' : 'desktopStandard'} />
      <Box sx={sx.outerBox}>
        <Box ref={radiancesRef}>
          {activeRadiances.map((card, index) => (
            <Box
              key={`player-${playerName}-radiance-${card.name}-${index}`}
              sx={sx.radiance}
              onClick={() => onCardShow(card)}
            >
              <Draggable draggableData={{ dragSource: DragSource.APPLIED_RADIANCE, fromPlayerId: id, cards: [card] }}>
                <CardIdentified id={card.id} {...cardToComponent(card, extendedSize)} />
              </Draggable>
            </Box>
          ))}
        </Box>
        <Box
          key={'current-player-animated-box' + id + playerState}
          sx={{
            ...getPlayerBoxSxFromState(playerState, displayMode),
            ...sx.stateBox,
          }}
        />
        <Box key={'current-player-inner-box-' + id} sx={sx.innerBox}>
          {isCompact ? compactMainBox : standardMainBox}
          {hand}
        </Box>
      </Box>
    </>
  );
};
