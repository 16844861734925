import { ProjectColors } from '../../../../../themes/mainTheme';

const border = `1px solid ${ProjectColors.WHITE}`;

const logoHeight = '50px';
export const sx = {
  logoBox: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: logoHeight,
    width: '55px',
    backgroundColor: ProjectColors.ACTION_DRAWER_MOBILE,
    clipPath: 'polygon(0 0, 100% 0%, 80% 100%, 0% 100%)',
    boxShadow: '5px 0px 10px rgba(0, 0, 0, 0.2)',
    transition: 'transform 0.3s ease',
    borderBottom: border,
  },
  line: {
    position: 'absolute',
    height: logoHeight,
    borderRight: border,
    right: 5.5,
    top: 0,
    transform: `rotate(12.4deg)`,
  },
  logo: {
    height: '60%',
  },
  menuIcon: {
    fontSize: '1.5rem',
  },
};
