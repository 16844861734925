import React from 'react';
import {
  GAMES_PLAYED_STATS_NAME,
  MOST_WINS_ROLE_STATS_NAME,
  NOT_AVAILABLE_VALUE,
  Props,
  SETTINGS_PAGE_HASH,
  WINS_STATS_NAME,
} from './profile.types';
import { Box, Button, CardMedia, Typography } from '@mui/material';
import { ProjectColors } from '../../themes/mainTheme';
import { initSx } from './profile.styles';
import { AppContext } from '../../global/context/appContext';
import { MotionBlock } from '../../components/motionBlock';
import { Logo } from '../../components/logo/logo';
import { ContentPageWrapper } from '../../components/contentPageWrapper';
import { InputField } from '../../components/textField';
import { NAME_MAXIMUM_LENGTH, NAME_MINIMUM_LENGTH } from '../../constants/components';
import { TabbedContent, TabElement, TabHeader } from '../../components/tabbedContent';
import { PlayingCard } from '../../features/card/cardTypes';
import { getRandom } from '../../helpers/arrays';
import { ApiContext } from '../../global/app/api/apiContext';
import { useSnackbar } from 'notistack';
import { logResponseErr } from '../../helpers/utils';
import { PROFILE_STORAGE_KEY } from '../../constants/login';
import { StatsBlock, StatsGlitchedContent } from '../../components/statsGlitchedContent';
import { PlayerMainStatsData } from '../../features/player/playerTypes';
import { useLocation, useNavigate } from 'react-router-dom';
import { ACCESS_DENIED_ERROR_ROUTE } from '../../constants/routes';
import { parseProfile } from '../../helpers/player';

export const Profile: React.FC<Props> = () => {
  const location = useLocation();
  const { hash } = location;

  const { allPlayingCards, isMobile, profile, refreshProfile } = React.useContext(AppContext);
  const { playerApi } = React.useContext(ApiContext);
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  React.useEffect(() => {
    const existingProfile = parseProfile();

    if (existingProfile === null) navigate(ACCESS_DENIED_ERROR_ROUTE);
  }, []);

  React.useEffect(() => {
    if (profile === null) return;

    void fetchMainStatsData();

    if (profile.playerName !== undefined && profile.playerName.length > 0) {
      setName(profile.playerName);
      return;
    }

    void updatePlayerProfile();
  }, [profile]);

  const [randomCard1, setRandomCard1] = React.useState<PlayingCard>();
  const [randomCard2, setRandomCard2] = React.useState<PlayingCard>();

  React.useEffect(() => {
    setRandomCard1(getRandom(allPlayingCards));
    setRandomCard2(getRandom(allPlayingCards));
  }, [allPlayingCards]);

  const [isButtonDisabled, setIsButtonDisabled] = React.useState(true);
  const [invalidDataBlock, setInvalidDataBlock] = React.useState<React.ReactElement | undefined>(undefined);
  const [name, setName] = React.useState(profile?.playerName ?? '');
  const [mainStats, setMainStats] = React.useState<PlayerMainStatsData>();

  React.useEffect(() => {
    if (profile !== null && profile.playerName?.toLowerCase() === name.toLowerCase()) {
      setInvalidDataBlock(undefined);
      setIsButtonDisabled(true);
      return;
    }

    if (isNameValid()) {
      setInvalidDataBlock(undefined);
      setIsButtonDisabled(false);
      return;
    }

    setInvalidDataBlock(invalidDataNotificationBlock('Invalid name'));
    setIsButtonDisabled(true);
  }, [name]);

  const updatePlayerProfile = async () => {
    const playerFullProfile = await playerApi.get(profile!.playerId);
    localStorage.setItem(PROFILE_STORAGE_KEY, JSON.stringify(playerFullProfile));
    refreshProfile();
  };

  const fetchMainStatsData = async () => {
    try {
      const mainStatsData = await playerApi.getMainStats(profile?.playerId ?? '');
      setMainStats(mainStatsData);
    } catch (e: any) {
      logResponseErr(e);
    }
  };

  const isNameValid = () => name.length >= NAME_MINIMUM_LENGTH && name.length <= NAME_MAXIMUM_LENGTH;

  const invalidDataNotificationBlock = (message: string) => {
    return (
      <Typography color={ProjectColors.RED} fontSize="0.6rem">
        {message}
      </Typography>
    );
  };

  const sx = initSx(isMobile);

  const performSaving = async () => {
    try {
      if (profile === null) return;

      const updatedPlayer = await playerApi.update(profile.playerId, { name: name });
      localStorage.setItem(PROFILE_STORAGE_KEY, JSON.stringify(updatedPlayer));
      refreshProfile();
      enqueueSnackbar('Successfully updated settings', { variant: 'success' });
      setIsButtonDisabled(true);
    } catch (e: any) {
      logResponseErr(e);
      enqueueSnackbar(`Failed to update settings: ${e.response?.data?.message ?? 'technical error'}`, {
        variant: 'error',
      });
    }
  };

  const editNameBlock = (
    <Box sx={sx.settingsBox}>
      {randomCard1 && <CardMedia component="img" alt="randomCard2" image={randomCard1.image} sx={sx.cardImage} />}
      <Box sx={sx.editNameBox}>
        <Box sx={sx.actionsBox}>
          <Box sx={sx.inputBox}>
            <InputField
              label={'Your name'}
              onChange={(event: string) => setName(event)}
              defaultValue={name}
              inputProps={{ maxLength: NAME_MAXIMUM_LENGTH }}
            />
            <Box sx={sx.notificationBox}>{invalidDataBlock}</Box>
          </Box>
          <Box sx={sx.buttonsBox}>
            <Button variant="contained" disabled={isButtonDisabled} onClick={performSaving} sx={sx.createButton}>
              Save
            </Button>
          </Box>
        </Box>
      </Box>
      {randomCard2 && <CardMedia component="img" alt="randomCard2" image={randomCard2.image} sx={sx.cardImage} />}
    </Box>
  );

  const statsBlocks: StatsBlock[] = [
    {
      name: GAMES_PLAYED_STATS_NAME,
      leftBlock: `${GAMES_PLAYED_STATS_NAME}:`,
      rightBlock: mainStats?.gamesPlayed ?? 0,
    },
    {
      name: WINS_STATS_NAME,
      leftBlock: `${WINS_STATS_NAME}:`,
      rightBlock: mainStats?.wins ?? 0,
    },
    {
      name: MOST_WINS_ROLE_STATS_NAME,
      leftBlock: `${MOST_WINS_ROLE_STATS_NAME}:`,
      rightBlock: mainStats?.mostWinsRole ?? NOT_AVAILABLE_VALUE,
    },
  ];

  const myStatsBlock = (
    <Box sx={sx.statsOuterBox}>
      {randomCard1 && <CardMedia component="img" alt="randomCard2" image={randomCard1.image} sx={sx.cardImage} />}
      <Box sx={sx.statsInnerBox}>
        <StatsGlitchedContent statsBlocks={statsBlocks} useGlitchEffect={true} />
      </Box>
      {randomCard2 && <CardMedia component="img" alt="randomCard2" image={randomCard2.image} sx={sx.cardImage} />}
    </Box>
  );

  const tabHeaders: TabHeader[] = [
    { name: 'My Main Stats', show: true, isDisabled: false },
    { name: 'Settings', show: true, isDisabled: false },
  ];

  const tabElements: TabElement[] = [
    { show: true, element: myStatsBlock },
    { show: true, element: editNameBlock },
  ];

  const outerElements = (
    <Box sx={sx.logoBox}>
      <MotionBlock transitionDuration={1.5}>
        <Logo />
      </MotionBlock>
    </Box>
  );

  const innerElements = (
    <MotionBlock transitionDuration={2}>
      <Box sx={sx.innerBox}>
        <TabbedContent
          defaultTabIndex={hash === SETTINGS_PAGE_HASH ? 1 : 0}
          tabHeaders={tabHeaders}
          tabs={tabElements}
        />
      </Box>
    </MotionBlock>
  );

  return <ContentPageWrapper key={location.key} outerBoxElements={outerElements} innerBoxElements={innerElements} />;
};
