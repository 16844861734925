import { SizeNormal } from '../../helpers/sizes';
import { ElementPosition } from '../draggableTableElement';
import { WindowSize } from '../../helpers/utils';

export type Props = {
  size: SizeNormal;
};

export const ORIGINAL_DRAW_DECK_SIZE = 300;

export const DRAW_DECK_HAS_CARDS_OPACITY = 1;
export const DRAW_DECK_EMPTY_OPACITY = 0.2;

export const ANIMATION_SIZE_MODIFIER = 0.95;
export const ANIMATION_SECONDS = 0.4;

export const DECK_HALF_CARDS_COUNT = 60;
export const DECK_SHORT_CARDS_COUNT = 10;

export const defaultTablePosition = (windowSize: WindowSize): ElementPosition => ({
  x: windowSize.width / 2 - 200,
  y: windowSize.height / 2,
});
