import React from 'react';
import { Props } from './pulsatingCard.types';
import { AnimatePresence, motion } from 'framer-motion';
import { cardToComponent } from '../../helpers/transformers';
import { NORMAL_TO_EXTENDED_SIZE } from '../../helpers/sizes';
import { useCurrentSize } from '../../features/card/hooks/useCurrentSize';
import { Card } from '../card';
import { useCardDimension } from '../card/hooks/useCardDimension';
import { withCardOffset } from '../../helpers/location';
import { secsToMs } from '../../helpers/time';
import styles from './pulsatingCard.module.css';
import { isMobile } from 'react-device-detect';

export const PulsatingCard: React.FC<Props> = ({ card, displayLocation, cardLocation, runAfter }) => {
  const cardSize = NORMAL_TO_EXTENDED_SIZE[useCurrentSize()];
  const { dimension } = useCardDimension({ size: cardSize });
  const [show, setShow] = React.useState(true);

  const checkedDisplayLocation = withCardOffset(displayLocation, dimension);
  const checkedCardLocation = cardLocation ? withCardOffset(cardLocation, dimension) : checkedDisplayLocation;

  const transitionInSec = 0.5;
  const animationInSec = 2;

  const cardBoxVariants = {
    hidden: {
      scale: isMobile ? 0.4 : 1,
      transition: { duration: transitionInSec },
      ...checkedCardLocation,
    },
    visible: {
      scale: isMobile ? 1 : 1.75,
      transition: { duration: transitionInSec },
      ...checkedDisplayLocation,
    },
  };

  const pulseVariants = {
    pulse: {
      scale: [1, 1.1, 1, 1.2, 1, 1],
      transition: { delay: 0.25 },
    },
  };

  const runAfterAnimation = () => {
    setTimeout(() => setShow(false), secsToMs(animationInSec));
    if (runAfter) setTimeout(() => runAfter(), secsToMs(transitionInSec + animationInSec));
  };

  return (
    <div className={styles.container}>
      <AnimatePresence>
        {show && (
          <motion.div
            exit="hidden"
            initial="hidden"
            animate="visible"
            variants={cardBoxVariants}
            onAnimationComplete={runAfterAnimation}
          >
            <motion.div variants={pulseVariants} animate={'pulse'}>
              <Card {...cardToComponent(card, cardSize)} showImageOnly={isMobile} />
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};
