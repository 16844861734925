import React from 'react';
import { UseRevealerEffect } from './useRevealerEffect.types';
import { GameContext } from '../../../../game/gameContext';
import { last } from '../../../../../helpers/arrays';
import { CardNames } from '../../../cardTypes';
import { toAppliedAction } from '../../../../game/utils/turnActions';
import { getCurrentPlayer } from '../../../../game/gameContextHandler/utils/player';

export const useRevealerEffect: UseRevealerEffect = () => {
  const { revealCurrentPlayerRoleBy, gameState, withUpdatingState } = React.useContext(GameContext);

  return () => {
    const currentPlayer = getCurrentPlayer(gameState);
    if (currentPlayer.id !== gameState.activeDefendingPlayer) return;

    const attacking = gameState.allPlayers.find((player) => player.id === gameState.activePlayer);
    const lastAction = last(gameState.turnActions);
    if (attacking === undefined || !lastAction || lastAction.action !== toAppliedAction(CardNames.REVEALER)) return;

    withUpdatingState(revealCurrentPlayerRoleBy)(lastAction.player, lastAction.cardIds?.at(0));
  };
};
