import React from 'react';
import { UseCurrentSize } from './useCurrentSize.types';
import { AppContext } from '../../../../global/context/appContext';
import {
  LARGE_SIZE_WIDTH,
  MEDIUM_SIZE_HEIGHT,
  MOBILE_WIDTH,
  SMALL_SIZE_HEIGHT,
} from '../../../../constants/components';
import { SizeNormal } from '../../../../helpers/sizes';
import { GameContext } from '../../../game/gameContext';
import { getOtherPlayers } from '../../../game/gameContextHandler/utils/player';
import { NumberOfPlayers } from '../../../game/gameTypes';
import { isMobile } from 'react-device-detect';

export const useCurrentSize: UseCurrentSize = () => {
  const { windowSize } = React.useContext(AppContext);
  const { gameState } = React.useContext(GameContext);

  return React.useMemo(() => {
    if (isMobile) return SizeNormal.XS;

    const otherPlayersNumber = getOtherPlayers(gameState).length;

    if (MEDIUM_SIZE_HEIGHT > windowSize.height && otherPlayersNumber > NumberOfPlayers.FOUR) {
      return SizeNormal.S;
    }

    if (
      (SMALL_SIZE_HEIGHT > windowSize.height || windowSize.width < MOBILE_WIDTH) &&
      otherPlayersNumber > NumberOfPlayers.THREE
    ) {
      return SizeNormal.S;
    }

    if (MEDIUM_SIZE_HEIGHT > windowSize.height || windowSize.width < LARGE_SIZE_WIDTH) {
      return SizeNormal.S;
    }

    return SizeNormal.M;
  }, [windowSize]);
};
