import React from 'react';
import { Props } from './cardPictogram.types';
import { CardMedia, Tooltip } from '@mui/material';
import { useCardSelection } from '../hooks/useCardSelection';
import { ImagePath } from '../../../assets/assets';
import { sx } from './cardPictogram.styles';
import { roomItems, useTrackLocation } from '../../../features/room/hooks/useTrackLocation';
import { Identity } from '../../../helpers/arrays';

export const CardPictogram: React.FC<Props & Identity> = (props) => {
  const { isOpen, imagePath, content, onClick, dragProps, selectorProps, id } = props;
  const { handleCardClick } = useCardSelection({ dragProps, selectorProps });
  const { ref } = useTrackLocation({ items: [roomItems.card(id)] });

  const onPictogramClick = (event: React.MouseEvent) => {
    handleCardClick(event);
    onClick();
  };

  return (
    <Tooltip title={content.name} disableInteractive placement="top" ref={ref}>
      <CardMedia
        component="img"
        alt={`card-${content.name}-image`}
        image={isOpen ? imagePath : ImagePath.noCardPlaceholder}
        sx={sx.image}
        onClick={(event) => onPictogramClick(event)}
      />
    </Tooltip>
  );
};
