import React from 'react';
import { Menu, MenuItem } from '@mui/material';
import { MenuProps } from './applyCardMenu.types';

export const ApplyCardMenu: React.FC<MenuProps> = ({ anchor, menuOptions, onClose }) => {
  const isMenuOpen = menuOptions.length > 1;
  const menuItem = (label: string, action: () => void) => (
    <MenuItem key={`apply-card-menu-item-${label}`} onClick={action}>
      {label}
    </MenuItem>
  );

  return (
    <Menu
      anchorEl={anchor}
      open={isMenuOpen}
      onClose={onClose}
      MenuListProps={{
        'aria-labelledby': 'basic-button',
      }}
    >
      {menuOptions.map((option) => menuItem(option.label, () => option.run()))}
    </Menu>
  );
};
