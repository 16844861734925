import { fromTo } from './transformers';

export enum SizeExtended {
  XXS,
  XS,
  S,
  M,
  L,
}

export enum SizeNormal {
  XS,
  S,
  M,
}

export const EXTENDED_TO_NORMAL_SIZE: fromTo<SizeExtended, SizeNormal> = {
  [SizeExtended.XXS]: SizeNormal.S,
  [SizeExtended.XS]: SizeNormal.S,
  [SizeExtended.S]: SizeNormal.S,
  [SizeExtended.M]: SizeNormal.M,
  [SizeExtended.L]: SizeNormal.M,
};

export const NORMAL_TO_EXTENDED_SIZE: fromTo<SizeNormal, SizeExtended> = {
  [SizeNormal.XS]: SizeExtended.XS,
  [SizeNormal.S]: SizeExtended.S,
  [SizeNormal.M]: SizeExtended.M,
};
