import React from 'react';

export type TabHeader = {
  name: string;
  isDisabled: boolean;
  show: boolean;
};

export type TabElement = {
  element: React.ReactElement;
  show: boolean;
};

export type Props = {
  defaultTabIndex: number;
  tabHeaders: TabHeader[];
  tabs: TabElement[];
};

export const tabLabelProps = (index: number) => {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
};
