import { SxStyles } from '../../../helpers/styles';

export const sxInit = (isMobile: boolean): SxStyles => {
  const tabElementSx = { textAlign: 'center' };

  return {
    mainGameStatsBlock: { ...tabElementSx, display: 'flex', alignItems: 'center' },
    cardImage: {
      width: '20%',
      height: '50%',
      m: 1,
      maskImage: 'radial-gradient(closest-side, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0))',
      display: { xs: 'none', md: 'block' },
    },
    statsBlock: { width: isMobile ? '100%' : '60%' },
  };
};
