import { PlayingCard, RoleCard } from '../card/cardTypes';

export type Player = {
  id: string;
  isCurrent: boolean;
  isWinner: boolean;
  cardsInHand: PlayingCard[];
  cardsOnTable: PlayingCard[];
  roleCard: RoleCard;
  roleVisibleTo: string[];
  playerName: string;
  essences: number;
  activeRadiances: PlayingCard[];
  actionSkips: number;
};

export type PlayerProps = {
  allowedCardInHandCount: number;
  onCardShow: (card: PlayingCard | RoleCard) => void;
  player: Player;
};

export enum DisplayMode {
  TRAY,
  STANDARD,
  COMPACT,
  MOBILE,
  OTHER_PLAYER_COMPACT,
  OTHER_PLAYER_MOBILE,
}

export enum PlayerState {
  IN_GAME,
  OUT_OF_GAME,
  WINNER,
  ACTIVE_ATTACKING,
  ACTIVE_DEFENDING,
  ACTIVE_DEFENDING_PENDING,
  DEFENDING_PENDING,
  DEFENDING_NOW,
  INACTIVE_ATTACKING,
}
