import React from 'react';
import { Props } from './statsGlitchedContent.types';
import { Box, Divider, SxProps } from '@mui/material';
import { AppContext } from '../../global/context/appContext';
import { sxInit } from './statsGlitchedContent.styles';
import { convertToCamelCase } from '../../helpers/utils';
import { useGlitch } from 'react-powerglitch';
import { glitchStatsContentProperties } from '../../constants/components';
import { Theme } from '@mui/material/styles';

export const StatsGlitchedContent: React.FC<Props> = ({ statsBlocks, useGlitchEffect }) => {
  const { isMobile } = React.useContext(AppContext);

  const sx = sxInit(isMobile);

  const formatStatsSection = (children: React.ReactNode[], key: string, isLast: boolean = false) => (
    <React.Fragment key={key}>
      <Box key={key} sx={sx.playedGamesInfo}>
        {children}
      </Box>
      {isLast ? <></> : <Divider variant="middle" sx={sx.divider} />}
    </React.Fragment>
  );

  const glitch = useGlitch(glitchStatsContentProperties);

  const wrapInSpanBox = (child: React.ReactNode, key: string, customStyles: SxProps<Theme>) => (
    <Box key={key} component={'span'} ref={useGlitchEffect ? glitch.ref : undefined} sx={customStyles}>
      {child}
    </Box>
  );

  const wrapInLeftSpanBox = (child: React.ReactNode, key: string) => {
    return wrapInSpanBox(child, key, sx.leftSpanBox);
  };
  const wrapInRightSpanBox = (child: React.ReactNode, key: string) => {
    return wrapInSpanBox(child, key, sx.rightSpanBox);
  };

  const statsSections = statsBlocks.map((stats, index) =>
    formatStatsSection(
      [
        wrapInLeftSpanBox(stats.leftBlock, convertToCamelCase(stats.name) + '-key'),
        wrapInRightSpanBox(stats.rightBlock, convertToCamelCase(stats.name) + '-value'),
      ],
      convertToCamelCase(stats.name),
      index === statsBlocks.length - 1
    )
  );

  return <>{statsSections}</>;
};
