import React, { ReactElement, useRef } from 'react';
import { areSameCards, Props } from './cardGroup.types';
import { Box, SxProps, Theme } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { Card, CardIdentified, DragProps, SelectorProps } from '../card';
import { cardToComponent, getPlaceholderCardComponent } from '../../helpers/transformers';
import { horizontalScrollbarStyle } from '../../helpers/styles';
import { EXTENDED_TO_NORMAL_SIZE } from '../../helpers/sizes';
import { AppContext } from '../../global/context/appContext';
import { Draggable } from '../draggable';
import { ACTIVE_ALLOWED_APPLY_TYPES, PlayingCard } from '../../features/card/cardTypes';
import { customSx } from './cardGroup.styles';
import { useCardDimension } from '../card/hooks/useCardDimension';
import { isEmpty } from '../../helpers/arrays';
import { useStateWithDelay } from '../../helpers/hooks/useStateWithDelay';
import { Label } from '../label';

export const CardGroup: React.FC<Props> = ({
  isActive,
  cards,
  size,
  label,
  placeholdersCount,
  onCardClick,
  onCardMouseEnter,
  onCardMouseLeave,
  sourceType,
  owningPlayerId,
  selectorType,
  useCompactGrid,
  cardsHaveActiveAnimation = false,
  styles,
}) => {
  const { dimension: cardDimension } = useCardDimension({ size: size });
  const ref = useRef<HTMLHeadingElement>(null);

  const customCardsSx = (currentCards: PlayingCard[]): SxProps<Theme> => {
    const allRequiredCardsCount = placeholdersCount > currentCards.length ? placeholdersCount : currentCards.length;
    const allCardsWidth = allRequiredCardsCount * cardDimension;
    const allPaddings = allRequiredCardsCount * 2;
    const currentWidth = ref.current ? ref.current.offsetWidth : 0;
    const sizeNormal = EXTENDED_TO_NORMAL_SIZE[size];

    return allCardsWidth + allPaddings > currentWidth
      ? horizontalScrollbarStyle(sizeNormal)
      : { justifyContent: 'center' };
  };

  const [draggingCards, setDraggingCards] = React.useState<PlayingCard[]>([]);
  const [sx, setSx] = React.useState<SxProps<Theme>>(customCardsSx(cards));
  const placeholderCard = <Card {...getPlaceholderCardComponent(size)} />;
  const [isMultiSelected, setIsMultiSelected] = React.useState(false);
  const [cardsToDisplay, setCardsToDisplay] = useStateWithDelay<PlayingCard[]>(cards);
  const [previousCardsToDisplay, setPreviousCardsToDisplay] = React.useState<PlayingCard[]>([]);
  const generatedCardGroup = React.useRef<ReactElement | undefined>(undefined);
  const [cardGroupUpdateRequired, setCardGroupUpdateRequired] = React.useState(false);

  const { windowSize } = React.useContext(AppContext);

  React.useEffect(() => {
    if (areSameCards(previousCardsToDisplay, cards)) return;

    setCardsToDisplay(cards, isEmpty(cardsToDisplay) ? 50 : 0);
    setPreviousCardsToDisplay(cards);
    setDraggingCards([]);
  }, [cards, cards.length]);

  React.useEffect(() => {
    setSx(customCardsSx(cardsToDisplay));
    setCardGroupUpdateRequired(true);
  }, [cardsToDisplay, cardsToDisplay.length, windowSize, isActive, draggingCards]);

  if (
    !cardGroupUpdateRequired &&
    generatedCardGroup.current !== undefined &&
    areSameCards(cards, previousCardsToDisplay)
  ) {
    return generatedCardGroup.current;
  }

  const placeHolderSx = useCompactGrid ? customSx.compactGridCard : {};

  const placeholders = () => {
    const placeholdersToCreate = placeholdersCount - cardsToDisplay.length;
    if (placeholdersToCreate > 0) {
      return [...Array(placeholdersToCreate)].map((_, index) => (
        <Grid key={`placeholder-card-${index}-${owningPlayerId}`} sx={placeHolderSx}>
          {placeholderCard}
        </Grid>
      ));
    }

    return <></>;
  };

  const addToDraggedCards = (card: PlayingCard) => {
    if (draggingCards.map((draggingCard) => draggingCard.id).includes(card.id)) return;
    draggingCards.push(card);
    setDraggingCards([...draggingCards]);
    setIsMultiSelected(true);
  };

  const removeFromDraggedCards = (card: PlayingCard) => {
    setDraggingCards((currentCards) => currentCards.filter((currentCard) => currentCard !== card));
  };

  const clearAllAndAddToDragged = (card: PlayingCard) => {
    setDraggingCards([card]);
    setIsMultiSelected(false);
  };

  const resolveDraggable = (card: PlayingCard): ReactElement => {
    const dragProps: DragProps = {
      addToDragged: () => addToDraggedCards(card),
      removeFromDragged: () => removeFromDraggedCards(card),
      clearAllAndAddToDragged: () => clearAllAndAddToDragged(card),
    };
    const isCardSelected = draggingCards.map((draggingCard) => draggingCard.id).includes(card.id);
    const selectorProps: SelectorProps | undefined =
      selectorType === undefined
        ? undefined
        : {
            isSelected: isCardSelected,
            type: selectorType,
          };

    const isCardActive =
      (isActive && ACTIVE_ALLOWED_APPLY_TYPES.some((applyType) => card.allowedApplyTypes.includes(applyType))) ||
      isEmpty(card.allowedApplyTypes);

    const cardElement = (
      <CardIdentified
        id={card.id}
        {...cardToComponent(card, size, dragProps, selectorProps)}
        isActive={isCardActive}
        hasActiveAnimation={cardsHaveActiveAnimation}
      />
    );

    if (sourceType === undefined || !isActive) {
      return cardElement;
    }

    return (
      <Draggable
        draggableData={{
          dragSource: sourceType,
          fromPlayerId: owningPlayerId,
          cards: isCardSelected && isMultiSelected ? draggingCards : [card],
          isActive: isCardActive,
        }}
      >
        {cardElement}
      </Draggable>
    );
  };

  generatedCardGroup.current = (
    <Box sx={{ ...customSx.mainBox, ...styles?.mainBox }}>
      {label && <Label label={label} />}
      <Box ref={ref} sx={{ ...sx, ...customSx.mainBox }}>
        {useCompactGrid === true ? (
          <Grid container spacing={'0.5%'} wrap="wrap" sx={sx}>
            {cardsToDisplay.map((card, index) => (
              <Grid
                key={`grouped-card-${index}-${owningPlayerId}`}
                onClick={() => onCardClick?.(card)}
                onMouseEnter={() => onCardMouseEnter?.(card)}
                onMouseLeave={() => onCardMouseLeave?.()}
                sx={customSx.compactGridCard}
              >
                {resolveDraggable(card)}
              </Grid>
            ))}
            {placeholders()}
          </Grid>
        ) : (
          <Grid container spacing={'0.5%'} wrap="nowrap" sx={sx}>
            {cardsToDisplay.map((card, index) => (
              <Grid
                key={`grouped-card-${index}-${owningPlayerId}`}
                onClick={() => onCardClick?.(card)}
                onMouseEnter={() => onCardMouseEnter?.(card)}
                onMouseLeave={() => onCardMouseLeave?.()}
                sx={customSx.gridCard}
              >
                {resolveDraggable(card)}
              </Grid>
            ))}
            {placeholders()}
          </Grid>
        )}
      </Box>
    </Box>
  );

  if (areSameCards(cards, previousCardsToDisplay)) {
    setCardGroupUpdateRequired(false);
  }

  return generatedCardGroup.current;
};
