import { ProjectColors } from '../../../../../themes/mainTheme';
import { horizontalScrollbarStyle } from '../../../../../helpers/styles';
import { SizeNormal } from '../../../../../helpers/sizes';

export const sx = {
  gameModeBlock: {
    mt: '0.75rem',
    mx: 'auto',
    width: '400px',
    maxWidth: '95%',
  },
  gameModeDescription: {
    textAlign: 'center',
    mt: '0.75rem',
    mb: '0.5rem',
    fontSize: '0.75rem',
  },
  gameModeCardsBlock: {
    display: 'flex',
    p: '0.5%',
    ...horizontalScrollbarStyle(SizeNormal.S),
  },
  accessModeControlLabel: {
    mr: '-1rem',
  },
  gameModeFormBlock: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    mb: '0.5rem',
  },
  gameModeRadioGroup: {
    display: 'flex',
    flexDirection: 'row',
  },
  gameModeSimpleLabel: {
    display: 'flex',
    flexDirection: 'row-reverse',
    mr: 0,
  },
  switcherIconBlock: {
    borderWidth: '0.13rem',
    borderStyle: 'solid',
    borderRadius: '50%',
    height: '1.5rem',
    width: '1.5rem',
    background: ProjectColors.GREY_TRANSPARENT,
  },
  switcherIconImageBlock: {
    alignContent: 'center',
    height: '100%',
  },
  switcherIconImage: {
    height: '95%',
    objectFit: 'contain',
  },
};
