import React from 'react';
import { Props } from './name.types';
import { Box, Tooltip, Typography } from '@mui/material';
import { sx } from './name.styles';
import { tooltipStyles } from '../../helpers/styles';
import { wrap, WrapperWithProps } from '../../helpers/wrappers';

export const Name: React.FC<Props> = ({ playerName, withTooltip = false, tooltipPlacement = 'bottom' }) => {
  const tooltipWrapper: WrapperWithProps = [
    Tooltip,
    {
      title: <Typography sx={{ ...tooltipStyles }}>{playerName}</Typography>,
      disableInteractive: true,
      placement: tooltipPlacement,
    },
  ];

  return wrap(
    <Box sx={sx.nameBox}>
      <Typography sx={sx.name}>{playerName}</Typography>
    </Box>,
    withTooltip ? [tooltipWrapper] : []
  );
};
