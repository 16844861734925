import React, { type JSX } from 'react';
import {
  DEFAULT_HEIGHT_MODIFIER,
  DEFAULT_RESERVED_HEIGHT,
  DEFAULT_RESERVED_WIDTH,
  DEFAULT_WIDTH_MODIFIER,
  defaultHeight,
  defaultWidth,
  JournalPosition,
  JournalSize,
  JOURNAL_POSITION_STORAGE_KEY,
  JOURNAL_SIZE_STORAGE_KEY,
  maxHeight,
  maxWidth,
  minHeight,
  minWidth,
  Props,
} from './logJournal.types';
import { Button, List, ListItem, SwipeableDrawer } from '@mui/material';
import { sx } from './logJournal.styles';
import { CheckCircleOutline, DangerousOutlined, ErrorOutline, Info } from '@mui/icons-material';
import CloseIcon from '@mui/icons-material/Close';
import { DraggableData, ResizableDelta, Rnd, RndDragCallback, RndResizeCallback } from 'react-rnd';
import { AppContext } from '../../global/context/appContext';
import { Log, LOG_JOURNAL_NAME, LogContext, LogType } from '../../features/game/logContextProvider';
import { ActionDrawerContext } from '../../features/room/mobile/contexts/actionDrawerContext/actionDrawerContext';
import { ProjectColors } from '../../themes/mainTheme';
import { RESOLVE_SCROLLBAR_WIDTH } from '../../helpers/styles';
import { isMobile as isMobileDevice } from 'react-device-detect';
import { WindowSize } from '../../helpers/utils';
import styles from './logJournal.module.css';
import cn from 'classnames';

export const LogJournal: React.FC<Props> = ({ size }) => {
  const { windowSize, isMobile } = React.useContext(AppContext);
  const { logs: logsData, fetchLogs, isLogJournalOpen, setIsLogJournalOpen } = React.useContext(LogContext);
  const { setContent, setName } = React.useContext(ActionDrawerContext);

  const [journalSize, setJournalSize] = React.useState<JournalSize>(getDefaultSize(windowSize));
  const [journalPosition, setJournalPosition] = React.useState(getDefaultPosition(windowSize));
  const [logsCard, setLogsCard] = React.useState<JSX.Element | undefined>(undefined);

  const logsCardRef = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    setLogsCard(formLogsCard(logsData));
  }, [logsData]);

  React.useEffect(() => {
    if (!isLogJournalOpen) return;
    if (isMobileDevice) fillMobileLogsDrawer();

    void fetchLogs();
  }, [isLogJournalOpen]);

  React.useEffect(() => {
    if (!isMobileDevice || !isLogJournalOpen) return;

    fillMobileLogsDrawer();
  }, [logsCard]);

  const fillMobileLogsDrawer = () => {
    setContent(logsCard);
    setName(LOG_JOURNAL_NAME);
  };

  const resolveLogsCardClass = (): string => {
    if (!logsCardRef.current) return styles.logsCard;

    logsCardRef.current.style.setProperty('--scrollbar-width', `${RESOLVE_SCROLLBAR_WIDTH[size]}`);
    return styles.logsCard;
  };

  const formLogsCard = (logs: Log[]) => (
    <div ref={logsCardRef} className={resolveLogsCardClass()}>
      <List>
        {logs.map((log, index) => (
          <ListItem
            key={`section-log-${index}`}
            className={cn(styles.logList, {
              [styles.isMobile]: isMobile,
            })}
          >
            {isMobile ? undefined : logSign(log.type)}
            <p
              className={cn(styles.logElement, styles[log.type], {
                [styles.isMobile]: isMobile,
              })}
            >
              {log.text}
            </p>
          </ListItem>
        ))}
      </List>
    </div>
  );

  const handleDragEnd: RndDragCallback = (_, data: DraggableData) => {
    setJournalPosition(data);
    storePosition({ x: data.x, y: data.y });
  };

  const handleResizeStop: RndResizeCallback = (_, __, ___, delta: ResizableDelta) => {
    setJournalSize((previousSize) => {
      const updatedSize = {
        width: previousSize.width + delta.width,
        height: previousSize.height + delta.height,
      };

      storeSize(updatedSize);

      return updatedSize;
    });
  };

  return isMobileDevice || !isLogJournalOpen ? (
    <></>
  ) : (
    <div className={styles.outerContainer}>
      <Rnd
        default={{
          x: journalPosition.x,
          y: journalPosition.y,
          width: journalSize.width,
          height: journalSize.height,
        }}
        maxWidth={maxWidth(windowSize)}
        maxHeight={maxHeight(windowSize)}
        minWidth={minWidth(windowSize)}
        minHeight={minHeight(windowSize)}
        bounds={'window'}
        onResizeStop={handleResizeStop}
        onDragStop={handleDragEnd}
      >
        <SwipeableDrawer
          anchor="bottom"
          variant="persistent"
          open={isLogJournalOpen}
          onClose={() => setIsLogJournalOpen(false)}
          onOpen={() => setIsLogJournalOpen(true)}
          PaperProps={{ sx: sx.drawer }}
        >
          <div className={styles.closeButtonContainer}>
            <Button onClick={() => setIsLogJournalOpen(false)} className={styles.closeButton}>
              <CloseIcon fontSize={'small'} />
            </Button>
          </div>
          {logsCard}
        </SwipeableDrawer>
      </Rnd>
    </div>
  );
};

const logSign = (type: LogType): JSX.Element => {
  switch (type) {
    case 'success':
      return <CheckCircleOutline style={{ color: ProjectColors.SECONDARY }} />;
    case 'info':
      return <Info style={{ color: ProjectColors.MAIN }} />;
    case 'error':
      return <DangerousOutlined style={{ color: ProjectColors.SCARLET }} />;
    case 'warning':
      return <ErrorOutline style={{ color: ProjectColors.YELLOW }} />;
  }
};

const storePosition = (position: JournalPosition): void =>
  localStorage.setItem(JOURNAL_POSITION_STORAGE_KEY, JSON.stringify(position));

const storeSize = (size: JournalSize): void => localStorage.setItem(JOURNAL_SIZE_STORAGE_KEY, JSON.stringify(size));

const getDefaultPosition = (windowSize: WindowSize): JournalPosition => {
  const positionStorageKey = localStorage.getItem(JOURNAL_POSITION_STORAGE_KEY);
  const defaultXPosition = windowSize.width - (defaultWidth(windowSize) + DEFAULT_RESERVED_WIDTH);
  const defaultYPosition = windowSize.height - (defaultHeight(windowSize) + DEFAULT_RESERVED_HEIGHT);

  const defaultPosition: JournalPosition = {
    x: defaultXPosition,
    y: defaultYPosition,
  };

  if (!positionStorageKey) return defaultPosition;

  const savedPosition = JSON.parse(positionStorageKey) as JournalPosition;

  if (
    savedPosition.x > defaultXPosition ||
    savedPosition.x < 0 ||
    savedPosition.x > windowSize.width ||
    savedPosition.y > defaultYPosition ||
    savedPosition.y < 0 ||
    savedPosition.y > windowSize.height
  ) {
    storePosition(defaultPosition);
    return defaultPosition;
  }

  return savedPosition;
};

const getDefaultSize = (windowSize: WindowSize): JournalSize => {
  const sizeStorageKey = localStorage.getItem(JOURNAL_SIZE_STORAGE_KEY);

  const defaultSize: JournalSize = {
    width: windowSize.width * DEFAULT_WIDTH_MODIFIER,
    height: windowSize.height * DEFAULT_HEIGHT_MODIFIER,
  };

  if (!sizeStorageKey) return defaultSize;
  return JSON.parse(sizeStorageKey) as JournalSize;
};
