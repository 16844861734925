import { PlayingCard } from '../../features/card/cardTypes';
import { Location } from '../../features/game/locationTrackerContextProvider';

export type Props = {
  stops: Location[];
  cards: PlayingCard[];
  runAfter?: () => void;
  runWith?: () => void;
};

export type OneMove = {
  initial: Location;
  final: Location;
};

export const durationConfig = {
  minInSec: 1,
  maxInSec: 2.5,
  stopsForMin: 2,
  stopsForMax: 7,
} as const;
