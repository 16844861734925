import React from 'react';
import { ApplyRule, CardNames, PlayingCard } from '../../../../card/cardTypes';
import { UseApplyCardAction } from './useApplyCardAction.types';
import { GameContext } from '../../../gameContext';
import { isPlayerRadianceSlotEmpty, isRadiance } from '../../../rules/rules';
import { getCurrentPlayer, getPlayer } from '../../../gameContextHandler/utils/player';
import { toAppliedAction, toAppliedEnergy } from '../../../utils/turnActions';
import { addToEngagementZone, removeCardFromPlayer, removePlayerCard } from '../../gameStateOperations';
import { DroppableGroup } from '../../../gameContextHandler/hooks/useDragAndDrop';
import { DragSource } from '../../../../../components/draggable';
import { filterUndefined, sortByEndingElement } from '../../../../../helpers/arrays';
import { AnimationContext } from '../../../animationContextProvider';
import { LocationTrackerContext } from '../../../locationTrackerContextProvider';
import { roomItems } from '../../../../room/hooks/useTrackLocation';
import { useReplaceEngagementZoneCards } from './useReplaceEngagementZoneCards';
import { Log, LogContext } from '../../../logContextProvider';
import { cardNameToLog } from '../../../../card/cardTransformers';
import { useSoundEffects } from '../useSoundEffects';

export const useApplyCardAction: UseApplyCardAction = () => {
  const { gameState, addTurnAction, enableDragAndDrop, rollProbCapsule, withUpdatingState } =
    React.useContext(GameContext);
  const { createLog, setNotification } = React.useContext(LogContext);

  const { queueMovingCardsProps } = React.useContext(AnimationContext);
  const { getItemLocation } = React.useContext(LocationTrackerContext);

  const { replaceEngagementZoneCards } = useReplaceEngagementZoneCards();
  const { applyCardSoundByCurrentPlayerAction } = useSoundEffects({ gameState: gameState });

  const applyCardToOtherPlayerWithAnimation = (
    byId: string,
    toId: string,
    card: PlayingCard,
    withCard?: PlayingCard
  ) => {
    const stops = getStopsForToOtherPlayer(byId, toId, card);
    const cards = filterOutGauntlets(filterUndefined([card, withCard]));
    const runAfter = withUpdatingState(() => applyCardToOtherPlayer(byId, toId, card, withCard));

    replaceEngagementZoneCards(card).then(() =>
      queueMovingCardsProps([{ stops: stops, cards: cards, runAfter: runAfter }])
    );
  };

  const applyCardToOtherPlayer = (byId: string, toId: string, card: PlayingCard, withCard?: PlayingCard) => {
    card.allowedApplyTypes = [];
    if (withCard && withCard.applyRule === ApplyRule.ENERGY) {
      withCard.allowedApplyTypes = [];
      applyEnergyCard(byId, withCard);
    }

    if (isRadiance(card)) {
      return playRadianceAgainst(byId, toId, card, withCard);
    }

    applyCardToPlayer(byId, toId, card, withCard);
  };

  const playRadianceAgainst = (byId: string, toId: string, card: PlayingCard, withCard?: PlayingCard) => {
    const playerAppliedTo = getPlayer(gameState.allPlayers, toId);
    if (!isPlayerRadianceSlotEmpty(card, playerAppliedTo)) {
      setNotification({ type: 'error', text: `${card.name} is already applied to Player` });
      return;
    }

    const playerAppliedBy = getPlayer(gameState.allPlayers, byId);
    removePlayerCard(playerAppliedBy, card.id);

    playerAppliedTo.activeRadiances.push(card);
    const cardIds = filterUndefined([card.id, withCard?.id]);
    const log: Log = {
      type: 'warning',
      text: `${playerAppliedBy.playerName}: '${card.name}' against ${playerAppliedTo.playerName}`,
    };
    createLog(log);
    addTurnAction({ player: byId, action: toAppliedAction(card.name), cardIds: cardIds, appliedTo: toId, logs: [log] });
    applyCardSoundByCurrentPlayerAction(card);
  };

  const applyCardToPlayer = (byId: string, toId: string, card: PlayingCard, withCard?: PlayingCard) => {
    const currentPlayer = getCurrentPlayer(gameState);

    removeCardFromPlayer(card, byId, gameState);
    addToEngagementZone(card, gameState);

    const cardIds = filterUndefined([card.id, withCard?.id]);
    const log: Log = {
      type: 'warning',
      text: `${currentPlayer.playerName}: '${card.name}' ${cardNameToLog(card.name)} ${
        getPlayer(gameState.allPlayers, toId).playerName
      }`,
    };

    createLog(log);
    addTurnAction({
      player: currentPlayer.id,
      action: toAppliedAction(card.name),
      cardIds: cardIds,
      appliedTo: toId,
      logs: [log],
    });
    applyCardSoundByCurrentPlayerAction(card);

    if (card.name === CardNames.UNCHAINED_ESSENCE) return;

    if (card.applyRule !== ApplyRule.ME) {
      gameState.defendingPlayerIds = [toId];
    }

    if (card.name === CardNames.CHALLENGE) {
      enableDragAndDrop(currentPlayer.id, DroppableGroup.DRAGGABLE, currentPlayer.id);
      return rollProbCapsule();
    }

    if (card.applyRule === ApplyRule.ME) return;

    enableDragAndDrop(toId, DroppableGroup.ALL, toId);
    gameState.activeDefendingPlayer = toId;
  };

  const getStopsForToOtherPlayer = (fromId: string, toId: string, card: PlayingCard) => {
    if (isRadiance(card)) {
      return filterUndefined([
        getItemLocation(roomItems.playerHand(fromId)),
        getItemLocation(roomItems.playerRadiances(toId)),
      ]);
    }

    return filterUndefined([
      getItemLocation(roomItems.playerHand(fromId)),
      getItemLocation(roomItems.playerArsenal(toId)),
      getItemLocation(roomItems.engagementZone),
    ]);
  };

  const applyCardToEngagementZone = (
    fromPlayerId: string,
    card: PlayingCard,
    dragSource: DragSource,
    withCard?: PlayingCard
  ) => {
    if (dragSource !== DragSource.PLAYER) {
      return;
    }

    if (withCard && withCard.applyRule === ApplyRule.ENERGY) {
      applyEnergyCard(fromPlayerId, withCard);
    }

    const currentPlayer = getCurrentPlayer(gameState);
    const sortedPlayers = sortByEndingElement(gameState.playersOrdered, (player) => player === currentPlayer.id);

    if (sortedPlayers === undefined) {
      throw Error('From player cannot be undefined');
    }

    const log: Log = { type: 'warning', text: `${currentPlayer.playerName}: '${card.name}' played` };
    const cardIds = filterUndefined([card.id, withCard?.id]);

    switch (card.applyRule) {
      case ApplyRule.ALL_BUT_ME:
        gameState.defendingPlayerIds = sortedPlayers.filter((player) => player !== currentPlayer.id);
        gameState.activeDefendingPlayer = sortedPlayers[0];
        enableDragAndDrop(sortedPlayers[0], DroppableGroup.DRAGGABLE, sortedPlayers[0]);
        break;
      case ApplyRule.ALL:
        if (card.name === CardNames.MASS_ESSENCE_ESCAPE) {
          break;
        }
        gameState.defendingPlayerIds = sortedPlayers;
        gameState.activeDefendingPlayer = sortedPlayers[0];
        enableDragAndDrop(sortedPlayers[0], DroppableGroup.DRAGGABLE, sortedPlayers[0]);
        break;
      case ApplyRule.ANY_PLAYER:
        if (card.name === CardNames.UNCHAINED_ESSENCE) {
          break;
        }
        gameState.defendingPlayerIds = [currentPlayer.id];
        gameState.activeDefendingPlayer = currentPlayer.id;
        enableDragAndDrop(currentPlayer.id, DroppableGroup.DRAGGABLE, currentPlayer.id);
        break;
    }

    removeCardFromPlayer(card, fromPlayerId, gameState);
    card.allowedApplyTypes = [];
    addToEngagementZone(card, gameState);

    addTurnAction({
      player: currentPlayer.id,
      action: toAppliedAction(card.name),
      cardIds: cardIds,
      appliedTo: currentPlayer.id,
      logs: [log],
    });
    createLog(log);
    applyCardSoundByCurrentPlayerAction(card);
  };

  const skipUpdateStateToSelf = (card: PlayingCard): boolean => {
    return [
      CardNames.TELEPORT,
      CardNames.REVEALER,
      CardNames.ESSENCE_ENCAPSULATION,
      CardNames.BLACK_HOLE,
      CardNames.SPACE_DISTORTION,
      CardNames.UNCHAINED_ESSENCE,
      CardNames.MASS_ESSENCE_ESCAPE,
      CardNames.AMNESIA,
      CardNames.FOGGER,
      CardNames.CONVERSIONER,
    ].includes(card.name);
  };

  const applyCardToEngagementZoneResolvingUpdateState = (
    fromPlayerId: string,
    card: PlayingCard,
    dragSource: DragSource,
    withCard?: PlayingCard
  ) => {
    const stops = filterUndefined([
      getItemLocation(roomItems.playerHand(fromPlayerId)),
      getItemLocation(roomItems.engagementZone),
    ]);
    const cards = filterOutGauntlets(filterUndefined([card, withCard]));

    const applyFunc = () => applyCardToEngagementZone(fromPlayerId, card, dragSource, withCard);
    const applyFuncWithUpdateStateResolved = skipUpdateStateToSelf(card) ? applyFunc : withUpdatingState(applyFunc);

    replaceEngagementZoneCards(card).then(() =>
      queueMovingCardsProps([{ stops: stops, cards: cards, runAfter: applyFuncWithUpdateStateResolved }])
    );
  };

  const filterOutGauntlets = (cards: PlayingCard[]) => cards.filter((card) => card.name !== CardNames.GAUNTLET);

  const applyEnergyCard = (byId: string, card: PlayingCard) => {
    if (card.applyRule !== ApplyRule.ENERGY) return;

    const log: Log = {
      type: 'warning',
      text: `${getCurrentPlayer(gameState).playerName}: '${card.energyColors[0]} ${card.name}' chosen as Energy source`,
    };

    removeCardFromPlayer(card, byId, gameState);
    addToEngagementZone(card, gameState);
    addTurnAction({ player: byId, action: toAppliedEnergy(card.energyColors[0]), logs: [log] });
    createLog(log);
  };

  return {
    applyCardToOtherPlayer: applyCardToOtherPlayerWithAnimation,
    applyCardToEngagementZone: applyCardToEngagementZoneResolvingUpdateState,
  };
};
