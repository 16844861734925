import React from 'react';
import { IconButton, Tooltip, Typography } from '@mui/material';
import { sx } from './notificationWidget.styles';
import { Notifications, NotificationsOff } from '@mui/icons-material';
import { tooltipStyles } from '../../../helpers/styles';
import { setFlag } from '../../../helpers/featureFlags';
import { DISABLE_NOTIFICATIONS_FLAG } from '../../../constants/featureFlags';

export const NotificationWidget: React.FC = () => {
  const [isEnabled, setIsEnabled] = React.useState(localStorage.getItem(DISABLE_NOTIFICATIONS_FLAG) !== 'true');

  const setNotificationState = (enabled: boolean) => {
    setFlag(DISABLE_NOTIFICATIONS_FLAG, !enabled);
    setIsEnabled(enabled);
  };

  const toggle = () => setNotificationState(!isEnabled);

  return (
    <div>
      <React.Fragment>
        <Tooltip
          title={<Typography sx={tooltipStyles}>Game notifications {isEnabled ? 'enabled' : 'disabled'}</Typography>}
          disableInteractive
        >
          <IconButton onClick={toggle} sx={sx.appearance}>
            {isEnabled ? <Notifications sx={sx.icon} /> : <NotificationsOff sx={sx.icon} />}
          </IconButton>
        </Tooltip>
      </React.Fragment>
    </div>
  );
};
