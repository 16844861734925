import { SxProps } from '@mui/material';
import { Theme } from '@mui/material/styles';

export type Props = Record<string, string>;

export type MobileViewGameTableRow = {
  players: string;
  playersWon: string;
  roleWon: string;
};

export type FullViewGameTableRow = MobileViewGameTableRow & {
  gameId: string;
  numberOfPlayers: number;
  duration: string;
};

type GameStatsColumn = {
  header: GamesStatsHeader;
  isMobile: boolean;
  styles: SxProps<Theme> | undefined;
};

enum GamesStatsHeader {
  GAME_ID = 'Game ID',
  NUMBER_OF_PLAYERS = '# of players',
  PLAYERS = 'Players',
  WON = 'Won',
  ROLE_WON = 'Role Won',
  DURATION = 'Duration',
}

export const FINISHED_GAME_TABLE: GameStatsColumn[] = [
  { header: GamesStatsHeader.GAME_ID, isMobile: false, styles: { minWidth: '65px' } },
  { header: GamesStatsHeader.NUMBER_OF_PLAYERS, isMobile: false, styles: undefined },
  { header: GamesStatsHeader.PLAYERS, isMobile: true, styles: undefined },
  { header: GamesStatsHeader.WON, isMobile: true, styles: undefined },
  { header: GamesStatsHeader.ROLE_WON, isMobile: true, styles: { minWidth: { xs: '80px', lg: '100px' } } },
  { header: GamesStatsHeader.DURATION, isMobile: false, styles: { minWidth: '90px' } },
];
