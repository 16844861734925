import React from 'react';
import { AnimatePresence, motion, MotionStyle } from 'framer-motion';
import { Tooltip } from '@mui/material';
import { sxInit } from './predictionTarget.styles';
import { fixIconLocationByHeaderHeight, Props } from './predictionTarget.types';
import { Location, LocationTrackerContext } from '../../features/game/locationTrackerContextProvider';
import { roomItems } from '../../features/room/hooks/useTrackLocation';
import { NORMAL_TO_EXTENDED_SIZE } from '../../helpers/sizes';
import { useCurrentSize } from '../../features/card/hooks/useCurrentSize';
import { useCardDimension } from '../card/hooks/useCardDimension';
import { CrisisAlertOutlined } from '@mui/icons-material';
import Typography from '@mui/material/Typography';
import { tooltipStyles } from '../../helpers/styles';
import styles from './predictionTarget.module.css';
import { isMobile } from 'react-device-detect';

export const PredictionTarget: React.FC<Props> = ({ playerId }) => {
  const { getItemLocation } = React.useContext(LocationTrackerContext);

  const cardSize = NORMAL_TO_EXTENDED_SIZE[useCurrentSize()];
  const { dimension } = useCardDimension({ size: cardSize });

  const capsuleLocation = getItemLocation(roomItems.playerEssenceCapsule(playerId));

  if (capsuleLocation === undefined) {
    return <></>;
  }

  const targetCoordinates = (): Location => {
    if (isMobile) {
      return {
        x: capsuleLocation.x - dimension / 1.1,
        y: capsuleLocation.y - dimension / 7,
      };
    }

    return {
      x: capsuleLocation.x - dimension / 7,
      y: capsuleLocation.y - dimension / 12,
    };
  };

  const targetVariants = {
    hidden: {
      scale: 0,
      transition: { duration: 0.5 },
    },
    visible: {
      scale: 1,
      transition: { duration: 0.5 },
    },
  };

  const pulseVariants = {
    pulse: {
      scale: [1, 1.1, 1, 1.2, 1, 1],
      transition: { delay: 0.25, repeat: Infinity, duration: 3 },
    },
  };

  const sx = sxInit(fixIconLocationByHeaderHeight(targetCoordinates(), isMobile), isMobile);

  const predictionTarget = () => {
    const key = `predicted-target-${playerId}`;
    return (
      <div key={key} data-testid={key}>
        <AnimatePresence>
          <motion.div
            exit="hidden"
            initial="hidden"
            animate="visible"
            variants={targetVariants}
            style={sx.targetLocationBox as MotionStyle}
          >
            <Tooltip
              title={<Typography sx={tooltipStyles}>Predicted target for action</Typography>}
              disableInteractive
              placement="bottom-start"
            >
              <motion.div variants={pulseVariants} animate={'pulse'}>
                <div className={styles.targetIconContainer}>
                  <CrisisAlertOutlined sx={sx.targetIcon} />
                </div>
              </motion.div>
            </Tooltip>
          </motion.div>
        </AnimatePresence>
      </div>
    );
  };

  return predictionTarget();
};
