import { WindowSize } from '../../helpers/utils';
import { getBackgroundBoxSx, SxStyles, verticalScrollbarStyle } from '../../helpers/styles';
import { SizeNormal } from '../../helpers/sizes';
import { ProjectColors } from '../../themes/mainTheme';
import { zIndexes } from '../../constants/components';

export const initSx = (windowSize: WindowSize, isMobile: boolean): SxStyles => {
  const marginSx = isMobile ? { m: '10px', mt: '20px' } : { mx: '35px', mt: '25px' };
  const mainBoxPadding = isMobile ? { p: 2 } : { p: 4 };

  return {
    mainBox: {
      ...getBackgroundBoxSx(windowSize, isMobile),
      ...mainBoxPadding,
      ...verticalScrollbarStyle(SizeNormal.M),
    },
    outerDataBoxSx: {
      ...marginSx,
      paddingBottom: 4,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    },
    innerDataBoxSx: {
      width: { xs: '100%', lg: '70%' },
      maxWidth: '1000px',
      zIndex: zIndexes.one,
    },
    socialNetworksBox: {
      p: 0.3,
      mb: 0.1,
      position: 'fixed',
      width: '100%',
      left: 0,
      bottom: 0.2,
      zIndex: zIndexes.contentBox,
      border: `3px solid ${ProjectColors.PURPLE_DARK}`,
      borderOpacity: '5%',
      backgroundColor: ProjectColors.PURPLE_VERY_DARK_TRANSPARENT,
      boxShadow: `0 6px 10px 18px ${ProjectColors.PURPLE_DARK}`,
    },
  };
};
