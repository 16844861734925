import { ProjectColors } from '../../../../themes/mainTheme';
import { SizeNormal } from '../../../../helpers/sizes';
import { zIndexes } from '../../../../constants/components';

export const sxInit = (playerSize: SizeNormal, isTrayOpen: boolean) => {
  const expandIconSize = playerSize === SizeNormal.M ? '1.5rem' : '0.9rem';

  const drawerMainSx = {
    width: 'fit-content',
    ml: 'auto',
    mr: 'auto',
    overflowY: 'hidden',
    backgroundColor: ProjectColors.PLAYER_BACKGROUND_TRANSPARENT,
    border: 0,
    zIndex: zIndexes.playerDrawer,
  };

  const drawerSx = isTrayOpen ? { ...drawerMainSx, width: '100%' } : drawerMainSx;

  return {
    drawer: drawerSx,
    expandButtonBox: { p: 0, display: 'flex', justifyContent: 'center' },
    button: { p: 0, width: '100%' },
    expandIcon: { fontSize: `${expandIconSize}` },
  };
};
