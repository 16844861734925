import React from 'react';
import { Props } from './signOutButton.types';
import { Logout } from '@mui/icons-material';
import { sx } from './signOutButton.styles';
import { AppContext } from '../../../global/context/appContext';
import { useSnackbar } from 'notistack';
import { ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { ROOT_ROUTE } from '../../../constants/routes';

export const SignOutButton: React.FC<Props> = () => {
  const { logout } = React.useContext(AppContext);
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const logoutWithNotification = () => {
    logout();
    navigate(ROOT_ROUTE);
    enqueueSnackbar('Successfully signed out', { variant: 'info' });
  };

  return (
    <ListItemButton onClick={logoutWithNotification} sx={sx.button}>
      <ListItemIcon sx={sx.icon}>
        <Logout />
      </ListItemIcon>
      <ListItemText>Sign out</ListItemText>
    </ListItemButton>
  );
};
