import React from 'react';
import { Props } from './closeModalButton.types';
import Button from '@mui/material/Button';
import CloseIcon from '@mui/icons-material/Close';
import styles from './closeModalButton.module.css';

export const CloseModalButton: React.FC<Props> = ({ onClose }) => {
  return (
    <div className={styles.container}>
      <Button onClick={onClose} className={styles.button}>
        <CloseIcon fontSize={'small'} />
      </Button>
    </div>
  );
};
