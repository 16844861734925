import React from 'react';
import { Props } from './handCompact.types';
import { ImagePath } from '../../../../../../assets/assets';
import { Label } from '../../../../../../components/label';
import { handLabel } from '../../../../../../helpers/labels';
import { GameContext } from '../../../../../game/gameContext';
import { getAllowedCardsInHandCount } from '../../../../../game/rules/rules';
import { HandType, useResolveHand } from '../../hooks/useResolveHand';
import { AnimatePresence, motion } from 'framer-motion';
import { heightChangingVariants } from '../../../../../../constants/animation';
import styles from './handCompact.module.css';
import cn from 'classnames';
import { isMobile } from 'react-device-detect';
import { isEmpty } from '../../../../../../helpers/arrays';

export const HandCompact: React.FC<Props> = ({ player, applyCardMenuOptions }) => {
  const { gameState, isDragging } = React.useContext(GameContext);
  const { handType } = useResolveHand({ playerId: player.id });

  const allowedCardsInHand = getAllowedCardsInHandCount(player, gameState.playersOrdered);
  const cardsCount = player.cardsInHand.length;
  const label = handLabel(cardsCount, allowedCardsInHand, true);

  const isDisplayed =
    isMobile ||
    handType === HandType.STATIC ||
    isDragging ||
    (handType === HandType.INTERACTIVE && !isEmpty(applyCardMenuOptions));

  return (
    <>
      <AnimatePresence>
        {isDisplayed && (
          <motion.div exit="hidden" initial="hidden" animate="visible" variants={heightChangingVariants}>
            <div className={cn(styles.container, { [styles.isMobile]: isMobile })}>
              <img
                alt={`other-player-han-${player.id}`}
                src={ImagePath.otherPlayerHand}
                className={cn(styles.image, { [styles.noCards]: cardsCount === 0 })}
              />
              <Label label={label} variant={allowedCardsInHand < cardsCount ? 'red' : 'default'} displayOver />
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </>
  );
};
