import React from 'react';
import { UseDropdownMenu } from './useDropdownMenu.types';
import { DropdownMenu } from '../../../components/dropdownMenu';

export const useDropdownMenu: UseDropdownMenu = ({ openingButtonId, items, useDivider = false }) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const isOpen = Boolean(anchorEl);

  const onClose = () => setAnchorEl(null);

  const menu = anchorEl ? (
    <DropdownMenu
      openingButtonId={openingButtonId}
      isOpen={isOpen}
      element={anchorEl}
      items={items}
      onClose={onClose}
      useDivider={useDivider}
    />
  ) : undefined;

  const openMenu = (event: React.MouseEvent<HTMLButtonElement>) => setAnchorEl(event.currentTarget);

  return {
    openMenu: openMenu,
    menu: menu,
  };
};
