import { MODAL_CTA_BUTTON_LENGTH } from '../../../constants/styles';

export const sx = {
  actionTypeBox: {
    textAlign: 'center',
    minWidth: '250px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  notificationBox: { textAlign: 'initial', height: '12px' },
  buttonsBox: { display: 'flex', justifyContent: 'center', pt: 1 },
  createButton: { px: 3, width: MODAL_CTA_BUTTON_LENGTH },
};
