import { UseProbability } from './useProbability.types';
import { ProbabilityValue, SQUANDER_PROB_MODIFIER } from '../../gameContextHandler.types';
import { getCurrentPlayer } from '../../utils/player';
import { isEmpty, removeElement } from '../../../../../helpers/arrays';
import { CardNames, PlayingCard } from '../../../../card/cardTypes';
import { getRandomValue } from '../../../../../helpers/probability';
import React from 'react';
import { toImpactedAction } from '../../../utils/turnActions';
import { Log, LogContext } from '../../../logContextProvider';

export const useProbability: UseProbability = ({ gameState, addTurnAction }) => {
  const { createLog, fetchLogs } = React.useContext(LogContext);

  const [probValue, setProbValue] = React.useState<ProbabilityValue>();
  const [isProbReRollEnabled, setIsProbReRollEnabled] = React.useState(false);
  const [isProbLogRequired, setIsProbLogRequired] = React.useState(false);
  const [isProbRolling, setIsProbRolling] = React.useState(false);

  const currentPlayer = getCurrentPlayer(gameState);

  const updateProbValue = (value: ProbabilityValue) => {
    let finalProbValue = value;

    const squanderCard = findSquanderCard();
    if (squanderCard) {
      finalProbValue = applySquanderEffect(value, squanderCard);
    }

    gameState.probRollAllowedFor = '';
    addTurnAction({ player: currentPlayer.id, action: 'probability capsule randomized', probValue: finalProbValue });
    setIsProbLogRequired(true);
    setProbValue(finalProbValue);
  };

  const findSquanderCard = (): PlayingCard | undefined => {
    if (isEmpty(currentPlayer.activeRadiances)) return undefined;

    const squanderCards = currentPlayer.activeRadiances.filter((card) => card.name === CardNames.SQUANDER);
    return isEmpty(squanderCards) ? undefined : squanderCards[0];
  };

  const applySquanderEffect = (initialVal: ProbabilityValue, squanderCard: PlayingCard): ProbabilityValue => {
    removeElement(currentPlayer.activeRadiances, (card) => card.id === squanderCard.id);
    gameState.cardsInDiscardPile.push(squanderCard);

    const log: Log = {
      type: 'warning',
      text: `${[currentPlayer.playerName]}: probability capsule result will be modified by Squander`,
    };
    addTurnAction({
      player: currentPlayer.id,
      action: toImpactedAction(squanderCard.name),
      appliedTo: currentPlayer.id,
      groupedCardIds: [['activeRadiances', [squanderCard.id]]],
      logs: [log],
    });
    createLog(log);

    return initialVal - SQUANDER_PROB_MODIFIER >= 0 ? ((initialVal - SQUANDER_PROB_MODIFIER) as ProbabilityValue) : 0;
  };

  const enableProbRollForPlayerId = (playerId: string) => {
    gameState.probRollAllowedFor = playerId;
  };

  const rollProbCapsule = () => {
    updateProbValue(getRandomValue());
    enableProbRollForPlayerId('');
    setIsProbReRollEnabled(false);
  };

  const isProbReRollAllowed = currentPlayer && isProbReRollEnabled && gameState.probRollAllowedFor === currentPlayer.id;
  const allowProbReRoll = (playerId: string) => {
    enableProbRollForPlayerId(playerId);
    setIsProbReRollEnabled(true);
  };

  const leaveProbLog = () => {
    if (probValue === undefined || !isProbLogRequired) {
      void fetchLogs();
      return;
    }

    setIsProbLogRequired(false);
    setProbValue(undefined);

    createLog({ type: 'info', text: `${[currentPlayer.playerName]}: probability capsule result ${probValue}` });
  };

  return {
    isProbReRollAllowed: isProbReRollAllowed,
    allowProbReRoll: allowProbReRoll,
    rollProbCapsule: rollProbCapsule,
    leaveProbLog: leaveProbLog,
    isProbRolling: isProbRolling,
    setIsProbRolling: setIsProbRolling,
  };
};
