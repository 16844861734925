import cardBackCoverImage from './cardBackCover.png';
import roleBackCoverImage from './roleBackCover.png';
import drawDeckFullImage from './drawDeckFull.png';
import drawDeckFullBlockedImage from './drawDeckFullBlocked.png';
import drawDeckHalfImage from './drawDeckHalf.png';
import drawDeckHalfBlockedImage from './drawDeckHalfBlocked.png';
import drawDeckShortImage from './drawDeckShort.png';
import drawDeckShortBlockedImage from './drawDeckShortBlocked.png';
import discardPileImage from './discardPile.png';
import discardPileMobileImage from './discardPileMobile.png';
import noCardPlaceholderImage from './noCardPlaceholder.png';
import gauntletPlaceholderImage from './gauntletPlaceholder.png';
import shellPlaceholderImage from './shellPlaceholder.png';
import domePlaceholderImage from './domePlaceholder.png';
import backpackPlaceholderImage from './backpackPlaceholder.png';
import roomBackground from './quadraen_background.jpg';
import logoOutline from './logo_outline.svg';
import loaderLogo from './loaderLogo.svg';
import essenceEncapsulation from './essence_encapsulation.png';
import equalizer from './equalizer.png';
import amnesia from './amnesia.png';
import unchainedEssence from './unchained_essence.png';
import massEqualizer from './mass_equalizer.png';
import fogger from './fogger.png';
import massEssenceEscape from './massEssenceEscape.png';
import backpack from './backpack.png';
import blackHole from './black_hole.png';
import challenge from './challenge.png';
import deEssencer from './de-essencer.png';
import disintigrator from './disintigrator.png';
import disorientation from './disorientation.png';
import dome from './dome.png';
import energyBlack from './energy_black.png';
import energyBlue from './energy_blue.png';
import energyRed from './energy_red.png';
import energyYellow from './energy_yellow.png';
import essenceThief from './essence_thief.png';
import gauntletBlack from './gauntlet_black.png';
import gauntletBlue from './gauntlet_blue.png';
import gauntletRed from './gauntlet_red.png';
import gauntletYellow from './gauntlet_yellow.png';
import massDeEssencer from './mass_de-essencer.png';
import revealer from './revealer.png';
import conversioner from './conversioner.png';
import shellRedBlack from './shell_red_black.png';
import shellYellowBlue from './shell_yellow_blue.png';
import spaceDistortion from './space_distortion.png';
import spy from './spy.png';
import squander from './squander.png';
import teleport from './teleport.png';
import immunarV1 from './immunar_v1.png';
import immunarV2 from './immunar_v2.png';
import ortegonist from './ortegonist.png';
import deltaredV1 from './deltared_v1.png';
import deltaredV2 from './deltared_v2.png';
import gauntletBlackPictogram from './gauntlet_black_pictogram.png';
import gauntletRedPictogram from './gauntlet_red_pictogram.png';
import gauntletBluePictogram from './gauntlet_blue_pictogram.png';
import gauntletYellowPictogram from './gauntlet_yellow_pictogram.png';
import shellRedBlackPictogram from './shell_red_black_pictogram.png';
import shellYellowBluePictogram from './shell_yellow_blue_pictogram.png';
import backpackPictogram from './backpack_pictogram.png';
import domePictogram from './dome_pictogram.png';
import betaPictogram from './beta_pictogram.png';
import backgroundAudio1 from './background-audio-1.mp3';
import backgroundAudio2 from './background-audio-2.mp3';
import amnesiaAudio from './amnesia.mp3';
import backpackAudio from './backpack.mp3';
import blackHoleAudio from './black-hole.mp3';
import challengeAudio from './challenge.mp3';
import deEssencerAudio from './de-essencer.mp3';
import discardCardAudio from './discard-card.mp3';
import disintegratorAudio from './disintegrator.mp3';
import disorientationAudio from './disorientation.mp3';
import drawCardAudio from './draw-card.mp3';
import domeAudio from './dome.mp3';
import equalizerAudio from './equalizer.mp3';
import essenceEncapsulationAudio from './essence-encapsulation.mp3';
import essenceThiefAudio from './essence-thief.mp3';
import foggerAudio from './fogger.mp3';
import gauntletAudio from './gauntlet.mp3';
import massDeEssencerAudio from './mass-de-essencer.mp3';
import massEqualizerAudio from './mass-equalizer.mp3';
import massEssenceEscapeAudio from './mass-essence-escape.mp3';
import pingPlayerAudio from './ping-player.mp3';
import revealerAudio from './revealer.mp3';
import conversionerAudio from './conversioner.mp3';
import shellAudio from './shell.mp3';
import spaceDistortionAudio from './space-distortion.mp3';
import spyAudio from './spy.mp3';
import squanderAudio from './squander.mp3';
import teleportAudio from './teleport.mp3';
import startTurnAudio from './start-turn.mp3';
import startDefendingAudio from './start-defending.mp3';
import unchainedEssenceAudio from './unchained-essence.mp3';
import volumeLevelAudio from './volume-level.mp3';
import helpUkraine from './help_ukraine.png';
import helpProject from './help_project.png';
import payPalLogo from './paypal-logo.svg';
import buyMeCoffeeLogo from './bmc-logo.svg';
import yakhnoBig from './yakhno_big.png';
import yakhnoSmall from './yakhno_small.png';
import kuretsBig from './kurets_big.png';
import kuretsSmall from './kurets_small.png';
import soundsOnboarding from './sounds_onboarding.mp4';
import draggingDecksOnboarding from './dragging_decks_onboarding.mp4';
import gameLogOnboarding from './game_log_onboarding.mp4';
import ruleBookOnboarding from './rulebook_onboarding.mp4';
import moveCardsOnboarding from './move_cards_onboarding.mp4';
import otherPlayerHand from './other_player_hand.svg';

export class ImagePath {
  static playingCardCover = cardBackCoverImage;
  static roleCardCover = roleBackCoverImage;
  static testGauntlet = gauntletBlue;
  static drawDeckFull = drawDeckFullImage;
  static drawDeckFullBlocked = drawDeckFullBlockedImage;
  static drawDeckHalf = drawDeckHalfImage;
  static drawDeckHalfBlocked = drawDeckHalfBlockedImage;
  static drawDeckShort = drawDeckShortImage;
  static drawDeckShortBlocked = drawDeckShortBlockedImage;
  static discardPile = discardPileImage;
  static discardPileMobile = discardPileMobileImage;
  static noCardPlaceholder = noCardPlaceholderImage;
  static gauntletPlaceholder = gauntletPlaceholderImage;
  static shellPlaceholder = shellPlaceholderImage;
  static domePlaceholder = domePlaceholderImage;
  static backpackPlaceholder = backpackPlaceholderImage;
  static roomBackground = roomBackground;
  static logoOutline = logoOutline;
  static loaderLogo = loaderLogo;
  static essenceEncapsulation = essenceEncapsulation;
  static equalizer = equalizer;
  static amnesia = amnesia;
  static unchainedEssence = unchainedEssence;
  static massEqualizer = massEqualizer;
  static fogger = fogger;
  static massEssenceEscape = massEssenceEscape;
  static backpack = backpack;
  static blackHole = blackHole;
  static challenge = challenge;
  static deEssencer = deEssencer;
  static disintigrator = disintigrator;
  static disorientation = disorientation;
  static dome = dome;
  static energyBlack = energyBlack;
  static energyBlue = energyBlue;
  static energyRed = energyRed;
  static energyYellow = energyYellow;
  static essenceThief = essenceThief;
  static gauntletBlack = gauntletBlack;
  static gauntletBlue = gauntletBlue;
  static gauntletRed = gauntletRed;
  static gauntletYellow = gauntletYellow;
  static massDeEssencer = massDeEssencer;
  static revealer = revealer;
  static conversioner = conversioner;
  static shellRedBlack = shellRedBlack;
  static shellYellowBlue = shellYellowBlue;
  static spaceDistortion = spaceDistortion;
  static spy = spy;
  static squander = squander;
  static teleport = teleport;
  static immunarV1 = immunarV1;
  static immunarV2 = immunarV2;
  static ortegonist = ortegonist;
  static deltaredV1 = deltaredV1;
  static deltaredV2 = deltaredV2;
  static gauntletBlackPictogram = gauntletBlackPictogram;
  static gauntletRedPictogram = gauntletRedPictogram;
  static gauntletBluePictogram = gauntletBluePictogram;
  static gauntletYellowPictogram = gauntletYellowPictogram;
  static shellRedBlackPictogram = shellRedBlackPictogram;
  static shellYellowBluePictogram = shellYellowBluePictogram;
  static backpackPictogram = backpackPictogram;
  static domePictogram = domePictogram;
  static betaPictogram = betaPictogram;
  static helpUkraine = helpUkraine;
  static helpProject = helpProject;
  static payPalLogo = payPalLogo;
  static buyMeCoffeeLogo = buyMeCoffeeLogo;
  static yakhnoBig = yakhnoBig;
  static yakhnoSmall = yakhnoSmall;
  static kuretsBig = kuretsBig;
  static kuretsSmall = kuretsSmall;
  static otherPlayerHand = otherPlayerHand;
}

export class SoundPath {
  static volumeLevel = volumeLevelAudio;
  static background1 = backgroundAudio1;
  static background2 = backgroundAudio2;
  static startTurn = startTurnAudio;
  static startDefending = startDefendingAudio;
  static amnesia = amnesiaAudio;
  static backpack = backpackAudio;
  static blackHole = blackHoleAudio;
  static challenge = challengeAudio;
  static deEssencer = deEssencerAudio;
  static discardCard = discardCardAudio;
  static disintegrator = disintegratorAudio;
  static disorientation = disorientationAudio;
  static dome = domeAudio;
  static drawCard = drawCardAudio;
  static equalizer = equalizerAudio;
  static essenceEncapsulation = essenceEncapsulationAudio;
  static essenceThief = essenceThiefAudio;
  static fogger = foggerAudio;
  static gauntlet = gauntletAudio;
  static massDeEssencer = massDeEssencerAudio;
  static massEqualizer = massEqualizerAudio;
  static massEssenceEscape = massEssenceEscapeAudio;
  static revealer = revealerAudio;
  static conversioner = conversionerAudio;
  static shell = shellAudio;
  static spaceDistortion = spaceDistortionAudio;
  static spy = spyAudio;
  static squander = squanderAudio;
  static teleport = teleportAudio;
  static unchainedEssence = unchainedEssenceAudio;
  static pingPlayer = pingPlayerAudio;
}

export class VideoPath {
  static soundsOnboarding = soundsOnboarding;
  static draggingDecksOnboarding = draggingDecksOnboarding;
  static gameLogOnboarding = gameLogOnboarding;
  static ruleBookOnboarding = ruleBookOnboarding;
  static moveCardsOnboarding = moveCardsOnboarding;
}
