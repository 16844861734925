import { Box, Tooltip } from '@mui/material';
import React from 'react';
import {
  DANGEROUS_ESSENCE_LIMIT_START,
  DisperserLocation,
  modifyExtremeEssencePercentagePosition,
  ONE_ESSENCE,
  Props,
} from './essenceBar.types';
import { initSx } from './essenceBar.styles';
import { tooltipStyles } from '../../helpers/styles';
import Typography from '@mui/material/Typography';
import { getRandomInt } from '../../helpers/utils';
import { roomItems, useTrackLocation } from '../../features/room/hooks/useTrackLocation';
import { AnimationContext } from '../../features/game/animationContextProvider';
import { AnimationType } from '../movingEssence/movingEssence.types';

export const EssenceBar: React.FC<Props> = ({
  playerId,
  count,
  disperserLocation = DisperserLocation.TOP,
  customSx,
}) => {
  const sx = initSx(count, customSx);
  const { ref: essenceCapsuleRef } = useTrackLocation({ items: [roomItems.playerEssenceCapsule(playerId)] });

  const [updateEssenceBar, setUpdateEssenceBar] = React.useState(false);
  const [initializeEssenceBar, setInitializeEssenceBar] = React.useState(false);
  const [previousEssenceCount, setPreviousEssenceCount] = React.useState(count);
  const [essenceCapsuleElement, setEssenceCapsuleElement] = React.useState(
    <Box ref={essenceCapsuleRef} sx={sx.mainBox} />
  );

  const initiateUpdateEssenceBar = () => {
    setUpdateEssenceBar(true);
  };

  const { queueMovingEssencesProps, animatingEssences } = React.useContext(AnimationContext);

  React.useEffect(() => {
    if (animatingEssences !== undefined || count < DANGEROUS_ESSENCE_LIMIT_START) {
      setInitializeEssenceBar(true);
      setUpdateEssenceBar(true);
      return;
    }

    queueMovingEssencesProps({
      playerId: playerId,
      count: count,
      animationType: AnimationType.ADDITION,
      disperserLocation: disperserLocation,
      runEssenceUpdateAction: initiateUpdateEssenceBar,
    });

    setInitializeEssenceBar(true);
  }, []);

  React.useEffect(() => {
    if (previousEssenceCount !== count) {
      queueMovingEssencesProps({
        playerId: playerId,
        count: ONE_ESSENCE,
        animationType: previousEssenceCount > count ? AnimationType.REDUCTION : AnimationType.ADDITION,
        disperserLocation: disperserLocation,
        runEssenceUpdateAction: initiateUpdateEssenceBar,
      });
    }
  }, [count]);

  React.useEffect(() => {
    if (!updateEssenceBar) {
      return;
    }

    if (previousEssenceCount === count && !initializeEssenceBar) {
      return setUpdateEssenceBar(false);
    }

    setPreviousEssenceCount(count);
    setEssenceCapsuleElement(filledEssenceElement);
    setUpdateEssenceBar(false);
    setInitializeEssenceBar(false);
  }, [updateEssenceBar]);

  if (!updateEssenceBar) {
    return essenceCapsuleElement;
  }

  const renderCheckPoints = () => {
    const checkPoints = [];

    for (let i = 0; i < count; i++) {
      const startModifier = Math.random() * (100 / count);
      const cumulativePosition = (100 / count) * i + startModifier;

      checkPoints.push(
        <Box
          key={i}
          sx={{
            ...sx.essence,
            transform: `rotate(${getRandomInt(90) - 40}deg)`,
            left: `${modifyExtremeEssencePercentagePosition(cumulativePosition)}%`,
          }}
        ></Box>
      );
    }

    return checkPoints;
  };

  const filledEssenceElement = (
    <Tooltip title={<Typography sx={tooltipStyles}>{count} essences</Typography>} disableInteractive placement="left">
      <Box ref={essenceCapsuleRef} sx={sx.mainBox}>
        <Box sx={sx.counterBox}>
          <Typography sx={sx.counter}>{count}</Typography>
        </Box>
        {renderCheckPoints()}
      </Box>
    </Tooltip>
  );

  return essenceCapsuleElement;
};
