import { ProjectColors } from '../../themes/mainTheme';
import { logoStyles, SxStyles } from '../../helpers/styles';
import { CONTENT_BOX_SX, MODAL_CTA_BUTTON_LENGTH } from '../../constants/styles';

export const initSx = (isMobile: boolean): SxStyles => {
  const tabElementSx = { textAlign: 'center' };

  const mainTabBox = {
    p: 2,
    ...tabElementSx,
    display: 'flex',
    alignItems: 'center',
  };
  const innerTabBoxSx = { width: isMobile ? '100%' : '60%' };

  return {
    logoBox: {
      ...logoStyles(),
    },
    settingsBox: {
      ...mainTabBox,
    },
    editNameBox: {
      ...innerTabBoxSx,
    },
    statsOuterBox: {
      ...mainTabBox,
    },
    statsInnerBox: {
      ...innerTabBoxSx,
    },
    innerBox: {
      ...CONTENT_BOX_SX,
      flex: 1,
      position: 'relative',
      p: 0,
      mb: 3,
      my: 1.5,
      minHeight: isMobile ? 'initial' : '340px',
    },
    cardImage: {
      width: '20%',
      height: '50%',
      m: 1,
      maskImage: 'radial-gradient(closest-side, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0))',
      display: { xs: 'none', md: 'block' },
    },
    notificationBox: { pt: 1, textAlign: 'initial', height: '20px' },
    buttonsBox: { display: 'inline-flex' },
    actionsBox: { pt: 2 },
    createButton: { ml: 2, px: 3, backgroundColor: ProjectColors.MAIN, width: MODAL_CTA_BUTTON_LENGTH },
  };
};
