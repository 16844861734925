import React from 'react';
import { Props } from './label.types';
import { isMobile } from 'react-device-detect';
import styles from './label.module.css';
import cn from 'classnames';

export const Label: React.FC<Props> = ({ label, variant = 'default', displayOver = false }) => {
  return (
    <p
      className={cn(styles.text, styles[variant], {
        [styles.isMobile]: isMobile,
        [styles.isDisplayedOver]: displayOver,
      })}
    >
      {label}
    </p>
  );
};
