import { zIndexes } from '../../../constants/components';

export const sx = {
  panel: {
    top: '0.1rem',
    position: 'fixed',
    right: '10px',
    zIndex: zIndexes.mobileHeader,
  },
};
