import React from 'react';
import { Props } from './skipActionButton.types';
import { Tooltip } from '@mui/material';
import Button from '@mui/material/Button';
import styles from './skipActionButton.module.css';
import buttonStyles from '../../css/commonModules/buttons.module.css';
import cn from 'classnames';
import { DisplayMode } from '../../features/player/player.types';
import { SkipNextRounded } from '@mui/icons-material';
import { SECOND_IN_MILLISECONDS } from '../../constants/time';
import { SkipAction, useSkipAction } from '../../features/game/actions/hooks/useSkipAction';

export const SkipActionButton: React.FC<Props> = ({ displayMode, idlePlayer }) => {
  const { defineSkipAction } = useSkipAction({ idlePlayer: idlePlayer });

  const [showTooltip, setShowTooltip] = React.useState(false);

  const handleMouseEnter = () => {
    setShowTooltip(true);
    setTimeout(() => {
      setShowTooltip(false);
    }, 3 * SECOND_IN_MILLISECONDS);
  };

  const handleMouseLeave = () => {
    setShowTooltip(false);
  };

  const skipActionBlock = (finishAction: SkipAction) => (
    <Tooltip
      title={<p className={buttonStyles.tooltip}>{finishAction.buttonTooltip()}</p>}
      disableInteractive
      open={showTooltip}
      placement="left"
    >
      <div className={styles.buttonContainer} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
        <Button
          disabled={!finishAction.isButtonEnabled()}
          onClick={finishAction.finishAction}
          variant="contained"
          className={cn(buttonStyles.button, styles.button, {
            [buttonStyles.activeButton]: finishAction.isButtonEnabled(),
            [buttonStyles.mobileButton]: displayMode === DisplayMode.MOBILE,
          })}
        >
          <SkipNextRounded className={styles.skipIcon} />
        </Button>
      </div>
    </Tooltip>
  );

  return (
    <div
      className={cn(styles.container, {
        [styles.mobileContainer]: displayMode === DisplayMode.MOBILE,
      })}
    >
      {skipActionBlock(defineSkipAction())}
    </div>
  );
};
