import { PlayingCard } from '../../card/cardTypes';
import { GameState } from '../gameContextHandler/hooks/useGameState';
import { isEmpty, removeByIndex, removeElement } from '../../../helpers/arrays';
import { getPlayer } from '../gameContextHandler/utils/player';
import { Player } from '../../player/player.types';
import { PlayerCardGroup, playerCardGroups } from '../gameContextHandler';
import { openCard } from '../../card/cardTransformers';
import { DroppableGroup, EnableDragAndDropFunc } from '../gameContextHandler/hooks/useDragAndDrop';

export const addToDiscardPile = (addedCard: PlayingCard, gameState: GameState) => {
  if (isEmpty(gameState.cardsInDeck)) {
    gameState.cardsInDeck = [addedCard];
    return;
  }

  gameState.cardsInDiscardPile.push(addedCard);
};

export const removeCardFromPlayer = (
  card: PlayingCard,
  playerId: string,
  gameState: GameState
): PlayerCardGroup | undefined => {
  const foundPlayer = getPlayer(gameState.allPlayers, playerId);
  return removePlayerCard(foundPlayer, card.id);
};

export const removePlayerCard = (player: Player, cardId: string): PlayerCardGroup | undefined => {
  let removedFromGroup: PlayerCardGroup | undefined = undefined;
  playerCardGroups.forEach((cardGroup) => {
    const wasRemoved = removePlayerGroupCard(player, cardId, cardGroup);
    if (wasRemoved) removedFromGroup = cardGroup;
  });

  return removedFromGroup;
};

const removePlayerGroupCard = (player: Player, cardId: string, cardGroup: PlayerCardGroup) => {
  const cardInHandIndex = player[cardGroup].findIndex((searchedCard) => searchedCard.id === cardId);
  if (cardInHandIndex === -1) {
    return false;
  }

  removeByIndex(player[cardGroup], cardInHandIndex);
  return true;
};

export const addToEngagementZone = (addedCard: PlayingCard, gameState: GameState) => {
  gameState.cardsInPlayingArea.unshift(openCard(addedCard));
};

export const removeFromPlayingArea = (
  removedCard: PlayingCard,
  gameState: GameState,
  enableDragAndDrop: EnableDragAndDropFunc
) => {
  removeElement(gameState.cardsInPlayingArea, (searchedCard) => searchedCard.id === removedCard.id);

  if (gameState.cardsInPlayingArea.length === 0) {
    gameState.defendingPlayerIds = [];
    gameState.activeDefendingPlayer = '';
    enableDragAndDrop(gameState.activePlayer, DroppableGroup.ALL, gameState.activePlayer);
  }
};
