import React from 'react';
import { DraggableType, Props } from './draggable.types';
import { useDrag } from 'react-dnd';
import { GameContext } from '../../features/game/gameContext';
import { getCurrentPlayer } from '../../features/game/gameContextHandler/utils/player';
import { useApplyCardRules } from '../../features/game/gameContextHandler/hooks/useApplyCardRules';
import { getEmptyImage } from 'react-dnd-html5-backend';

export const Draggable: React.FC<Props> = ({ draggableData, children }) => {
  const { gameState, isAllowedToDrag, isDraggable, setIsDragging } = React.useContext(GameContext);
  const { addApplyActiveEnergyNotificationOnDragging } = useApplyCardRules();
  const currentPlayer = getCurrentPlayer(gameState);

  draggableData.dragger = currentPlayer.id;

  const isDragEnabled =
    (currentPlayer ? isAllowedToDrag(currentPlayer.id) : false) &&
    (draggableData.fromPlayerId ? isDraggable(draggableData.fromPlayerId) : true);

  const [{ isDragging, draggedItem }, drag, dragPreview] = useDrag({
    type: DraggableType.CARD,
    item: draggableData,
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
      draggedItem: monitor.getItem(),
    }),
    canDrag: isDragEnabled,
  });

  React.useEffect(() => {
    addApplyActiveEnergyNotificationOnDragging(isDragging, draggableData);
  }, [isDragging, draggableData]);

  React.useEffect(() => {
    dragPreview(getEmptyImage(), { captureDraggingState: true });
  }, []);

  React.useEffect(() => setIsDragging(!!draggedItem), [draggedItem]);

  return (
    <div ref={drag} style={{ opacity: isDragging ? 0.3 : 1 }}>
      {children}
    </div>
  );
};
