import React from 'react';
import { Props } from './onboardingVideosAccordion.types';
import { Accordion, AccordionDetails, AccordionSummary } from '../styledAccordion/styledAccordion';
import { HtmlTypography } from '../../../components/htmlTypography';
import { Box } from '@mui/material';
import { OnboardingVideos } from '../../../components/onboardingVideos';

export const OnboardingVideosAccordion: React.FC<Props> = () => {
  const [isOpen, setIsOpen] = React.useState(false);

  return (
    <Accordion expanded={isOpen} onChange={() => setIsOpen(!isOpen)}>
      <AccordionSummary aria-controls="cards-content" id="cards-header">
        <HtmlTypography>Onboarding videos</HtmlTypography>
      </AccordionSummary>
      <AccordionDetails>
        <Box>
          <OnboardingVideos />
        </Box>
      </AccordionDetails>
    </Accordion>
  );
};
