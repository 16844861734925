import { Box, Button, Typography } from '@mui/material';
import React from 'react';
import { Props } from './manageGameModal.types';
import { ProjectColors } from '../../../themes/mainTheme';
import { CreateGameData, JoinGameData } from '../../game/gameTypes';
import { MINIMUM_NO_WARNING_LENGTH } from '../../../constants/components';
import { Modal } from '../../../components/modal';
import { useGameMode } from './hooks/useGameMode';
import { useNumberOfPlayers } from './hooks/useNumberOfPlayers';
import { useJoinGameId } from './hooks/useJoinGameId';
import { PlayerName } from './playerName';
import { sx } from './manageGameModal.styles';

export const ManageGameModal: React.FC<Props> = ({
  actionType,
  isOpen,
  onClose,
  onCreateGameApprove,
  onJoinGameApprove,
  gameId,
}) => {
  const [isButtonDisabled, setIsButtonDisabled] = React.useState(true);
  const [invalidDataBlock, setInvalidDataBlock] = React.useState<React.ReactElement | undefined>();

  const { gameMode, accessMode, gameModeBlock, gameDescriptionBlock } = useGameMode();
  const { numberOfPlayers, numberOfPlayersBlock } = useNumberOfPlayers();
  const { gameId: newGameId, gameIdBlock, isGameIdValid } = useJoinGameId({ defaultGameId: gameId });

  React.useEffect(() => {
    if (actionType === 'Create') {
      setIsButtonDisabled(false);
      setInvalidDataBlock(undefined);
      return;
    }

    if (newGameId.length <= MINIMUM_NO_WARNING_LENGTH) {
      return;
    }

    if (isGameIdValid()) {
      setIsButtonDisabled(false);
      setInvalidDataBlock(undefined);
      return;
    }

    setIsButtonDisabled(true);
    setInvalidDataBlock(invalidDataNotificationBlock('Invalid game Id'));
  }, [newGameId, actionType]);

  const invalidDataNotificationBlock = (message: string) => {
    return (
      <Typography color={ProjectColors.RED} fontSize="0.6rem">
        {message}
      </Typography>
    );
  };

  const performSaving = () => {
    switch (actionType) {
      case 'Create': {
        const createGameData: CreateGameData = {
          numberOfPlayers: numberOfPlayers,
          gameMode: gameMode.name,
          accessMode: accessMode.name,
        };

        onCreateGameApprove(createGameData);
        return;
      }
      case 'Join': {
        const joinGameData: JoinGameData = {
          gameId: newGameId,
        };

        onJoinGameApprove(joinGameData);
      }
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <Box sx={sx.actionTypeBox}>
        <Typography id="create-game-modal-title-1" variant="h6" component="h2">
          {actionType} game
        </Typography>
      </Box>
      <PlayerName onModalClose={onClose} />
      <Box>
        {actionType === 'Join' ? undefined : numberOfPlayersBlock()}
        {actionType === 'Join' ? undefined : gameModeBlock()}
        {actionType === 'Create' ? undefined : gameIdBlock()}
        {invalidDataBlock && <Box sx={sx.notificationBox}>{invalidDataBlock}</Box>}
        {actionType === 'Join' ? undefined : gameDescriptionBlock()}
        <Box sx={sx.buttonsBox}>
          <Button variant="contained" disabled={isButtonDisabled} onClick={performSaving} sx={sx.createButton}>
            {actionType}
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};
