import React from 'react';
import { Props } from './verticalLabel.types';
import styles from './verticalLabel.module.css';
import cn from 'classnames';

export const VerticalLabel: React.FC<Props> = ({ name, labelBlocks, variant = 'default' }) => {
  const rows: React.ReactElement[] = [];

  labelBlocks.forEach((block, index) => {
    rows.push(
      <p key={`label-${name}-${index}`} className={cn(styles.text, styles[variant])}>
        {block}
      </p>
    );
  });

  return <>{rows}</>;
};
