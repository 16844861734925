import { UseGameId } from './useGameId.types';
import { useParams } from 'react-router-dom';

export const useGameId: UseGameId = () => {
  const { id } = useParams();
  if (id === undefined) {
    throw Error('Game ID should be present in URL');
  }

  return id;
};
