import React from 'react';
import { Props } from './arsenalDropPoint.types';
import { Box } from '@mui/material';
import { Droppable } from '../../../droppable';
import { ApplyRuleGroup } from '../../../../card/cardTypes';
import { roomItems, useTrackLocation } from '../../../../room/hooks/useTrackLocation';
import { useCardDrop } from '../../hooks/useCardDrop';

export const ArsenalDropPoint: React.FC<Props> = ({ player, children }) => {
  const { canDropToArsenal, onDropToArsenal } = useCardDrop({
    playerId: player.id,
    cardsOnTable: player.cardsOnTable,
  });

  const { ref } = useTrackLocation({
    items: [roomItems.playerArsenal(player.id), roomItems.playerRadiances(player.id)],
  });
  return (
    <Box ref={ref}>
      <Droppable
        playerId={player.id}
        applyRuleGroup={ApplyRuleGroup.ARSENAL}
        onItemDropped={onDropToArsenal}
        canDrop={canDropToArsenal}
      >
        {children}
      </Droppable>
    </Box>
  );
};
