import { UseCardDimensionType } from './useSetCSSStyleCardDimension.types';
import React from 'react';
import { AppContext } from '../../../../global/context/appContext';
import { useCurrentSize } from '../../../card/hooks/useCurrentSize';
import { NORMAL_TO_EXTENDED_SIZE } from '../../../../helpers/sizes';
import { useCardDimension } from '../../../../components/card/hooks/useCardDimension';

export const useSetCSSStyleCardDimension: UseCardDimensionType = () => {
  const { windowSize, isMobile } = React.useContext(AppContext);

  const size = useCurrentSize();
  const extendedSize = NORMAL_TO_EXTENDED_SIZE[size];
  const { dimension: cardDimension } = useCardDimension({ size: extendedSize });

  React.useEffect(() => {
    const root = document.documentElement;
    root.style.setProperty('--card-dimension', `${cardDimension}px`);
  }, [windowSize, isMobile, extendedSize]);
};
