import React, { forwardRef, useCallback } from 'react';
import { CustomContentProps, SnackbarContent, useSnackbar } from 'notistack';
import { Box, Card, CardActions, IconButton, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { initSx } from './snackbarNotification.styles';
import { useWindowSize } from '../../helpers/utils';
import { isMobile } from 'react-device-detect';

interface ReportCompleteProps extends CustomContentProps {}

const SnackbarNotification = forwardRef<HTMLDivElement, ReportCompleteProps>(({ id, ...props }, ref) => {
  const { closeSnackbar } = useSnackbar();
  const window = useWindowSize();

  const handleDismiss = useCallback(() => {
    closeSnackbar(id);
  }, [id, closeSnackbar]);

  const sx = initSx(props.variant, window.width, isMobile);

  return (
    <SnackbarContent ref={ref} style={sx.snackbarContent}>
      <Card sx={sx.card}>
        <CardActions sx={sx.actionRoot}>
          <Typography variant="body2" sx={sx.messageBlock}>
            {props.message}
          </Typography>
          <Box sx={sx.icons}>
            <IconButton size="small" sx={sx.closeButton} onClick={handleDismiss}>
              <CloseIcon fontSize="small" />
            </IconButton>
          </Box>
        </CardActions>
      </Card>
    </SnackbarContent>
  );
});

SnackbarNotification.displayName = 'SnackbarNotification';

export default SnackbarNotification;
