import { ProjectColors } from '../../themes/mainTheme';

type Type = 'success' | 'info' | 'warning' | 'error' | 'default';

const getCardSxByType = (type: Type) => {
  switch (type) {
    case 'success':
      return {
        card: { backgroundColor: `${ProjectColors.SECONDARY}`, color: `${ProjectColors.PURPLE_DARK}` },
        buttonColor: `${ProjectColors.PURPLE_DARK}`,
      };
    case 'error':
      return {
        card: { backgroundColor: `${ProjectColors.SCARLET}`, color: `${ProjectColors.WHITE}` },
        buttonColor: `${ProjectColors.WHITE}`,
      };
    case 'warning':
      return {
        card: { backgroundColor: `${ProjectColors.YELLOW}`, color: `${ProjectColors.PURPLE_DARK}` },
        buttonColor: `${ProjectColors.PURPLE_DARK}`,
      };
    case 'info':
    case 'default':
      return {
        card: { backgroundColor: `${ProjectColors.MAIN}`, color: `${ProjectColors.PURPLE_DARK}` },
        buttonColor: `${ProjectColors.PURPLE_DARK}`,
      };
  }
};

export const initSx = (type: Type, windowWidth: number, isMobile: boolean) => {
  const typeSx = getCardSxByType(type);
  const elementWidth = windowWidth * (isMobile ? 0.4 : 0.17);

  return {
    snackbarContent: {
      maxWidth: `${elementWidth}px`,
      minWidth: `${elementWidth}px`,
    },
    card: {
      width: '100%',
      opacity: '80%',
      borderRadius: 0,
      border: `0.5px ridge ${ProjectColors.PURPLE_DARK}`,
      ...typeSx.card,
    },
    actionRoot: {
      padding: '7px',
      justifyContent: 'space-between',
    },
    icons: {
      marginLeft: 'auto',
    },
    messageBlock: {
      overflowX: 'hidden',
      textOverflow: 'ellipsis',
      pl: '8px',
      fontSize: isMobile ? 'clamp(0.7rem, 1vw, 1rem)' : 'clamp(0.4rem, 1vw, 0.8rem)',
    },
    closeButton: {
      padding: '5px 5px',
      transform: 'rotate(0deg)',
      transition: 'all .2s',
      color: typeSx.buttonColor,
    },
    button: {
      padding: 0,
      textTransform: 'none',
    },
  };
};
