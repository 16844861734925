import React from 'react';
import { BlocksDirection } from '../../../../../helpers/player';
import { EngagementZone } from '../engagementZone';
import { useCurrentSize } from '../../../../card/hooks/useCurrentSize';
import { NORMAL_TO_EXTENDED_SIZE } from '../../../../../helpers/sizes';
import { useCardDimension } from '../../../../../components/card/hooks/useCardDimension';
import { AppContext } from '../../../../../global/context/appContext';
import { getOtherPlayers } from '../../../../game/gameContextHandler/utils/player';
import { GameContext } from '../../../../game/gameContext';
import { Player } from '../../../../player/player.types';
import { OtherPlayersMobileRows } from '../../roomMobile.types';
import { playerNumberToEnum } from '../../../../game/gameTransformers';
import { NumberOfPlayers } from '../../../../game/gameTypes';
import { filterUndefined } from '../../../../../helpers/arrays';
import { OtherPlayerMobile } from '../../../../player/otherPlayer/mobile';
import { getAllowedCardsInHandCount } from '../../../../game/rules/rules';
import { DraggedCardData } from '../../../../../components/draggable';
import { ApplyCardMenu } from '../../../roomLayout/applyCardMenu';
import { useDroppedToPlayer } from '../../../roomLayout/useDroppedToPlayer';
import { useApplyCard } from '../../../../player/otherPlayer/useApplyCard';
import { ChooseEnergySourceModalMobile } from '../../../chooseEnergySourceModal';
import { DrawDeck } from '../../../../../components/drawDeck';
import { DiscardPile } from '../../../../../components/discardPile';
import styles from './roomLayout.module.css';
import cn from 'classnames';
import { CURRENT_PLAYER_MOBILE_HEIGHT_MODIFIER } from '../../../../player/currentPlayer/mobile';

export const RoomLayout: React.FC = () => {
  const { windowSize } = React.useContext(AppContext);
  const { gameState } = React.useContext(GameContext);

  const [anchor, setAnchor] = React.useState<null | HTMLElement>(null);
  const { droppedItem, onDroppedToPlayer, addDroppedItemToEngagementZone, clearDroppedItem } = useDroppedToPlayer();
  const { availableMenuOptions, clearAvailableMenuOptions, applyCard } = useApplyCard({ droppedItem: droppedItem });

  const outerContainerRef = React.useRef<HTMLDivElement>(null);

  const closeUseCardMenu = () => {
    clearDroppedItem();
    clearAvailableMenuOptions();
  };

  const size = useCurrentSize();
  const extendedSize = NORMAL_TO_EXTENDED_SIZE[size];

  const { dimension: cardDimension } = useCardDimension({ size: extendedSize });
  const otherPlayersNumber = getOtherPlayers(gameState).length;

  const playerRows = locateOtherPlayers(getOtherPlayers(gameState));

  const onDropToPlayerWithRef = (
    playerRef: React.RefObject<HTMLDivElement>,
    item: DraggedCardData,
    droppedToId: string
  ) => {
    setAnchor(playerRef.current);
    onDroppedToPlayer(item, droppedToId);
  };

  const otherPlayerMobile = (player: Player, position: BlocksDirection) => (
    <OtherPlayerMobile
      player={player}
      allowedCardInHandCount={getAllowedCardsInHandCount(player, gameState.playersOrdered)}
      onDroppedToPlayer={onDropToPlayerWithRef}
      blocksDirection={position}
      size={size}
      key={`other-player-${player.id}`}
      applyCardMenuOptions={availableMenuOptions}
    />
  );

  const renderOtherPlayerComponents = (players: Player[]) => {
    const middleIndex = Math.ceil(players.length / 2);
    const leftPlayers = players
      .slice(0, middleIndex)
      .map((player) => otherPlayerMobile(player, BlocksDirection.LEFT_TO_RIGHT));
    const rightPlayers = players
      .slice(middleIndex)
      .map((player) => otherPlayerMobile(player, BlocksDirection.RIGHT_TO_LEFT));
    return [...leftPlayers, ...rightPlayers];
  };

  const height = windowSize.height - cardDimension * CURRENT_PLAYER_MOBILE_HEIGHT_MODIFIER;
  const minHeight = cardDimension * 3;
  const innerContainerHeightStyle = minHeight > height ? 'innerFitContent' : 'innerAutoFill';
  const middleRowStyle = otherPlayersNumber > NumberOfPlayers.FIVE ? 'middleMoreThanFive' : 'middleFiveAndLess';

  const resolveOuterContainerClass = (): string => {
    if (!outerContainerRef.current) return styles.outerContainer;

    const allPlayersHeight = Math.max(height, minHeight);
    outerContainerRef.current.style.setProperty('--outer-container-height', `${allPlayersHeight}px`);
    return styles.outerContainer;
  };

  return (
    <>
      <div ref={outerContainerRef} className={resolveOuterContainerClass()}>
        <div className={cn(styles.innerContainer, styles[innerContainerHeightStyle])}>
          <div className={styles.playersTopRow}>{renderOtherPlayerComponents(playerRows.top)}</div>
          <div className={cn(styles.playersMiddleRow, styles[middleRowStyle])}>
            {playerRows.middle[0] && otherPlayerMobile(playerRows.middle[0], BlocksDirection.LEFT_TO_RIGHT)}
            <DrawDeck size={size} />
            <EngagementZone size={extendedSize} setDroppedItem={addDroppedItemToEngagementZone} />
            <DiscardPile size={size} playingCards={gameState.cardsInDiscardPile} />
            {playerRows.middle[1] && otherPlayerMobile(playerRows.middle[1], BlocksDirection.RIGHT_TO_LEFT)}
          </div>
        </div>
      </div>
      <ChooseEnergySourceModalMobile
        applyCard={applyCard}
        droppedItem={droppedItem}
        clearDroppedItem={clearDroppedItem}
      />
      <ApplyCardMenu anchor={anchor} menuOptions={availableMenuOptions} onClose={closeUseCardMenu} />
    </>
  );
};

const locateOtherPlayers = (players: Player[]): OtherPlayersMobileRows => {
  const allPlayersNumber = playerNumberToEnum(players.length + 1);
  switch (allPlayersNumber) {
    case NumberOfPlayers.SEVEN:
    case NumberOfPlayers.EIGHT: {
      const rowOne = [...players];
      const rowTwo = filterUndefined([rowOne.shift(), rowOne.pop()]);
      return { top: rowOne, middle: rowTwo };
    }
    default:
      return { top: [...players], middle: [] };
  }
};
