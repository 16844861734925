import React from 'react';
import { UseAnimateCards } from './useAnimateCards.types';
import { GameContext } from '../../game/gameContext';
import { useAnimateDiscardCards } from './currentPlayer/useAnimateDiscardCards';
import { TurnAction } from '../../game/gameTypes';
import { isEmpty, last } from '../../../helpers/arrays';
import { areSame } from '../../game/utils/turnActions';
import { getCurrentPlayer } from '../../game/gameContextHandler/utils/player';
import { useAnimateDome } from './allPlayers/useAnimateDome';
import { useAnimateShell } from './allPlayers/useAnimateShell';
import { useAnimateLostCardsUponRemoval } from './currentPlayer/useAnimateLostCardsUponRemoval';

export const useAnimateCards: UseAnimateCards = () => {
  const { gameState } = React.useContext(GameContext);
  const [currentLastAction, setCurrentLastAction] = React.useState<TurnAction>();
  const [previousLastAction, setPreviousLastAction] = React.useState<TurnAction>();

  const { animate: animateDiscardCards } = useAnimateDiscardCards({ gameState });
  const { animate: animateLostCardsUponRemoval } = useAnimateLostCardsUponRemoval({ gameState });
  const { animate: animateDome } = useAnimateDome();
  const { animate: animateShell } = useAnimateShell();

  const shouldRunCurrentTurnAnimations = (newLastAction?: TurnAction) =>
    newLastAction && (!currentLastAction || !areSame(currentLastAction, newLastAction));

  React.useEffect(() => {
    const newLastAction = last(gameState.turnActions);
    if (!shouldRunCurrentTurnAnimations(newLastAction)) return;

    const currentPlayer = getCurrentPlayer(gameState);
    animateDiscardCards(currentPlayer.id, gameState.turnActions);
    animateDome(gameState.allPlayers, gameState.turnActions);
    animateShell(gameState.allPlayers, gameState.turnActions);

    setCurrentLastAction(newLastAction);
  }, [gameState.turnActions.length]);

  const shouldRunPreviousTurnAnimations = (currentActions: TurnAction[], newLastAction?: TurnAction) =>
    isEmpty(currentActions) && newLastAction && (!previousLastAction || !areSame(previousLastAction, newLastAction));

  React.useEffect(() => {
    const newLastAction = last(gameState.previousTurnActions);
    if (!shouldRunPreviousTurnAnimations(gameState.turnActions, newLastAction)) return;

    const currentPlayer = getCurrentPlayer(gameState);
    animateDiscardCards(currentPlayer.id, gameState.previousTurnActions);
    animateLostCardsUponRemoval(currentPlayer.id, gameState.previousTurnActions);

    setPreviousLastAction(newLastAction);
  }, [gameState.previousTurnActions.length]);
};
