import { zIndexes } from '../../constants/components';

export const sx = {
  mainBox: { position: 'absolute', zIndex: zIndexes.draggableTableElement.mainBox },
  draggingIndicator: {
    cursor: 'move',
    top: -33,
    zIndex: zIndexes.draggableTableElement.draggingIndicator,
    position: 'absolute',
  },
};
