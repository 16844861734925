import React from 'react';
import { Props } from './ruleBook.types';
import { Box, TextField } from '@mui/material';
import { StoryAccordion } from './storyAccordion';
import { RulesAccordion } from './rulesAccordion';
import { CardsAccordion } from './cardsAccordion';
import { sxInit } from './ruleBooks.styles';
import { OnboardingVideosAccordion } from './onboardingVideosAccordion';

export const RuleBook: React.FC<Props> = ({
  page,
  story,
  rules,
  cardDetails,
  defaultSearch = '',
  useDarkStyle = false,
}) => {
  const [searched, setSearched] = React.useState('');

  React.useEffect(() => {
    setSearched(defaultSearch);
  }, [defaultSearch]);

  const sx = sxInit(useDarkStyle);

  return (
    <Box sx={sx.mainBox}>
      <Box sx={sx.search.box}>
        <TextField
          autoComplete="off"
          label="Search"
          variant="filled"
          type="search"
          value={searched}
          onChange={(event) => setSearched(event.target.value)}
          sx={sx.search.textField}
        />
      </Box>
      <StoryAccordion story={story} searched={searched} />
      <RulesAccordion page={page} rules={rules} searched={searched} />
      <CardsAccordion page={page} cardDetails={cardDetails} searched={searched} />
      <OnboardingVideosAccordion />
    </Box>
  );
};
