import React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { ImagePath } from '../../../assets/assets';
import { sx } from './header.styles';
import { Tooltip } from '@mui/material';
import { ABOUT_US_ROUTE, PROFILE_ROUTE, ROOT_ROUTE, RULES_ROUTE } from '../../../constants/routes';
import { useNavigate } from 'react-router-dom';
import { AppContext } from '../../context/appContext';
import { useDropdownMenu } from '../../../helpers/hooks/useDropdownMenu';
import { GoogleButton } from '../../../features/menu/googleButton';
import { SignOutButton } from '../../../features/menu/signOutButton';
import { tooltipStyles } from '../../../helpers/styles';
import { useGameModal } from '../../../features/menu/hooks/useGameModal';
import { BackToGameButton } from '../../../features/game/backToGameButton';
import { InstallButton } from '../../../features/menu/installButton';
import { DiscordLoginButton } from '../../../features/menu/discordButtonButton';

export const Header: React.FC = () => {
  const navigate = useNavigate();
  const { profile } = React.useContext(AppContext);
  const { openModal, modal } = useGameModal();

  const gameButtonId = 'open-game-button';
  const { menu: gameMenu, openMenu: openGameMenu } = useDropdownMenu({
    openingButtonId: gameButtonId,
    items: [
      { type: 'data', name: 'Create new game', action: () => openModal('Create') },
      { type: 'data', name: 'Join game', action: () => openModal('Join') },
      { type: 'element', element: <BackToGameButton /> },
    ],
  });

  const gameButtonAndMenu = (
    <div>
      <Tooltip title={'Login to play'} placement={'bottom'} disableHoverListener={Boolean(profile)}>
        <span>
          <Button id={gameButtonId} onClick={openGameMenu} sx={sx.button} disabled={!profile}>
            Game
          </Button>
        </span>
      </Tooltip>
      {gameMenu}
    </div>
  );

  const loginButtonId = 'login-button';
  const { menu: loginMenu, openMenu: openLoginMenu } = useDropdownMenu({
    openingButtonId: loginButtonId,
    items: [
      { type: 'element', element: <GoogleButton /> },
      { type: 'element', element: <DiscordLoginButton /> },
    ],
  });

  const loginButtonAndMenu = (
    <div>
      <Button id={loginButtonId} onClick={openLoginMenu} variant="outlined" color="inherit" sx={sx.button}>
        Login
      </Button>
      {loginMenu}
    </div>
  );

  const profileButtonId = 'profile-button';
  const { menu: profileMenu, openMenu: openProfileMenu } = useDropdownMenu({
    openingButtonId: profileButtonId,
    items: [
      { type: 'data', name: 'Open', action: () => navigate(PROFILE_ROUTE) },
      { type: 'element', element: <SignOutButton /> },
    ],
    useDivider: true,
  });

  const profileButtonAndMenu = (
    <div>
      <Button id={profileButtonId} onClick={openProfileMenu} variant="outlined" color="inherit" sx={sx.button}>
        Profile
      </Button>
      {profileMenu}
    </div>
  );

  const betaTooltip = (
    <React.Fragment>
      Deltaration is a game in progress, so we appreciate your feedback on any bugs you encounter. Your input is
      precious to us and we will do our best to fix the issues quickly.
      <br />
      <br />
      Have fun playing and remember: Deltas are here. This is not a simulation, this is your destiny. Embrace your
      truth, harness your energies, and annihilate your foes.
    </React.Fragment>
  );

  return (
    <Box>
      <Box sx={sx.outerBox}>
        <AppBar position="static">
          <Toolbar variant="dense" sx={sx.toolbar}>
            <Box sx={sx.innerBox} onClick={() => navigate(ROOT_ROUTE)}>
              <Box sx={sx.logoBox}>
                <Box sx={sx.logo}>
                  <img alt="deltaration-logo" src={ImagePath.logoOutline} width={'100%'} height={'100%'} />
                </Box>
              </Box>
              <Typography variant="h6" sx={sx.logoText}>
                DELTARATION
              </Typography>
              <Tooltip
                title={<Typography sx={tooltipStyles}>{betaTooltip}</Typography>}
                disableInteractive
                placement="bottom-start"
              >
                <Box sx={sx.beta}>
                  <img alt="beta-pictogram" src={ImagePath.betaPictogram} width="40%" height="40%" />
                </Box>
              </Tooltip>
            </Box>
            <Box sx={sx.innerBox}>
              <InstallButton />
              {gameButtonAndMenu}
              <Button onClick={() => navigate(RULES_ROUTE)} sx={sx.button}>
                Rules
              </Button>
              <Button onClick={() => navigate(ABOUT_US_ROUTE)} sx={sx.button}>
                About us
              </Button>
              {profile ? profileButtonAndMenu : loginButtonAndMenu}
            </Box>
          </Toolbar>
        </AppBar>
        <Box sx={sx.mainBox}>{modal()}</Box>
      </Box>
    </Box>
  );
};
