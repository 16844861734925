export enum HandType {
  STATIC,
  INTERACTIVE,
}

export type UseResolveHandProps = {
  playerId: string;
};

export type UseResolveHandResult = {
  handType: HandType;
};

export type UseResolveHand = (props: UseResolveHandProps) => UseResolveHandResult;
