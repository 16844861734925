import React from 'react';
import { Position, Props } from './arsenal.types';
import { CardNames, PlayingCard } from '../../../../card/cardTypes';
import { Box } from '@mui/material';
import { CardPictogram } from '../../../../../components/card/pictogram';
import { sx } from './arsenal.styles';
import { resolveCardImage } from '../../../../card/cardTransformers';
import { sortByDirection } from '../../../../../helpers/player';
import { ActionDrawerContext } from '../../../../room/mobile/contexts/actionDrawerContext/actionDrawerContext';
import { CardLContent } from '../../../../room/mobile/components/actionDrawer/cardLContent';
import { Draggable, DraggedCardData, DragSource } from '../../../../../components/draggable';
import { wrap, WrapperWithProps } from '../../../../../helpers/wrappers';

export const Arsenal: React.FC<Props> = ({ playerId, playerName, cardsOnTable, blocksDirection, isDraggable }) => {
  const { setContent, setName } = React.useContext(ActionDrawerContext);

  const onCardShow = (cardToShow: PlayingCard) => {
    setContent(<CardLContent card={cardToShow} />);
    setName(`Card from ${playerName}'s arsenal`);
  };

  const cardComponent = (cardName: CardNames, position: Position) => {
    const card = cardsOnTable.find((cardOnTable) => cardOnTable.name === cardName);
    if (!card) return;

    const data: DraggedCardData = { dragSource: DragSource.APPLIED_RADIANCE, fromPlayerId: playerId, cards: [card] };
    const wrappers: WrapperWithProps[] = isDraggable ? [[Draggable, { draggableData: data }]] : [];

    return (
      <Box key={`applied-${card.name}-${playerId}`} sx={sx.cardWrapper(position)} onClick={() => onCardShow(card)}>
        {wrap(
          <CardPictogram
            id={card.id}
            isOpen
            imagePath={resolveCardImage(card.name, card.energyColors)}
            content={{ type: card.playingCardType, name: card.name, details: card.details }}
            onClick={() => console.log('card has been clicked')}
          />,
          wrappers
        )}
      </Box>
    );
  };

  const leftAndRight: ('left' | 'right')[] = sortByDirection(['left', 'right'], blocksDirection);

  const cardPositions: [CardNames, Position][] = [
    [CardNames.GAUNTLET, { x: leftAndRight[0], y: 'top' }],
    [CardNames.SHELL, { x: leftAndRight[1], y: 'top' }],
    [CardNames.BACKPACK, { x: leftAndRight[0], y: 'bottom' }],
    [CardNames.DOME, { x: leftAndRight[1], y: 'bottom' }],
  ];

  return <>{cardPositions.map(([name, position]) => cardComponent(name, position))}</>;
};
