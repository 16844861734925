import { ProjectColors } from '../../../../themes/mainTheme';
import { settingsWidgetStyles } from '../../../../helpers/styles';

export const sxInit = (isChecked: boolean) => {
  return {
    mainBlock: {
      position: 'fixed',
      right: '10px',
      display: 'flex',
    },
    button: {
      main: { backgroundColor: ProjectColors.PLAYER_BACKGROUND_TRANSPARENT, p: 1, mr: 0.5 },
      icon: settingsWidgetStyles,
    },
    menuItem: {
      item: { display: 'flex', justifyContent: 'space-between' },
      controlLabel: { ml: 1 },
      checkbox: { color: ProjectColors.WHITE },
    },
    themeItemTypography: { pl: 2, pr: 1 },
    themeSwitcher: {
      '& .MuiSwitch-thumb': {
        '&::before': {
          content: isChecked ? "'2'" : "'1'",
          fontSize: 12,
          fontWeight: 'bold',
          color: `${ProjectColors.PURPLE_DARK}`,
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
        },
      },
    },
  };
};
