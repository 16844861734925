import React from 'react';
import { UseUnchainedEssenceEffect } from './useUnchainedEssenceEffect.types';
import { EssenceUpdateType, GameContext } from '../../../../game/gameContext';
import { last } from '../../../../../helpers/arrays';
import { CardNames } from '../../../cardTypes';
import { toAppliedAction, toImpactedAction } from '../../../../game/utils/turnActions';
import { getCurrentPlayer } from '../../../../game/gameContextHandler/utils/player';
import { useDefendByEncloseEssenceCard } from '../../../../game/gameContextHandler/hooks/useDefendByEncloseEssenceCard';

export const useUnchainedEssenceEffect: UseUnchainedEssenceEffect = () => {
  const { gameState, changePlayerEssenceCount, addTurnAction, performFinishDefending } = React.useContext(GameContext);
  const { isDefendingRequired, performFinishDefendingByCard } = useDefendByEncloseEssenceCard({
    gameState,
    addTurnAction,
    performFinishDefending,
  });

  return () => {
    const currentPlayer = getCurrentPlayer(gameState);
    const lastTurnAction = last(gameState.turnActions);

    if (
      lastTurnAction?.action !== toAppliedAction(CardNames.UNCHAINED_ESSENCE) ||
      lastTurnAction.appliedTo !== currentPlayer.id
    ) {
      return;
    }

    addTurnAction({ player: currentPlayer.id, action: toImpactedAction(CardNames.UNCHAINED_ESSENCE) });

    if (isDefendingRequired()) {
      performFinishDefendingByCard(currentPlayer, CardNames.UNCHAINED_ESSENCE);
      return;
    }

    changePlayerEssenceCount(currentPlayer.id, EssenceUpdateType.ADD_ONE);
  };
};
