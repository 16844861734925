import React from 'react';
import { UseRemoveEssenceOnTurnStart } from './useRemoveEssenceOnTurnStart.types';
import { getPlayer } from '../../utils/player';
import { Log, LogContext } from '../../../logContextProvider';
import { useChangeEssenceCount } from '../useChangeEssenceCount';
import { EssenceUpdateType } from '../../../gameContext';
import { useGameFinish } from '../useGameFinish';
import { useDefendingActions } from '../useDefendingActions';
import { useRemovePlayerFromGame } from '../useRemovePlayerFromGame';

export const useRemoveEssenceOnTurnStart: UseRemoveEssenceOnTurnStart = ({
  gameState,
  enableDragAndDrop,
  addTurnAction,
  finishTurn,
}) => {
  const { createLog } = React.useContext(LogContext);
  const { changeEssenceCount } = useChangeEssenceCount({ gameState: gameState, addTurnAction: addTurnAction });
  const { finishGame } = useGameFinish({ gameState, addTurnAction });
  const { performFinishDefending, forceDefendingFinish } = useDefendingActions({
    gameState: gameState,
    addTurnAction: addTurnAction,
    enableDragAndDrop: enableDragAndDrop,
  });
  const { removePlayerFromGame } = useRemovePlayerFromGame({
    gameState,
    performFinishDefending,
    forceDefendingFinish,
    finishTurn,
    addTurnAction,
  });

  const removeEssenceOnTurnStart = (playerId: string, requiredPlayersNumber: number) => {
    if (gameState.playersOrdered.length !== requiredPlayersNumber) return;

    const playerToUpdate = getPlayer(gameState.allPlayers, playerId);
    const essenceReductionLog: Log = {
      type: 'error',
      text: `${[playerToUpdate.playerName]}: Essence reduction is performed`,
    };

    changeEssenceCount(playerToUpdate.id, EssenceUpdateType.REMOVE_ONE);
    addTurnAction({ player: playerToUpdate.id, action: 'create log', logs: [essenceReductionLog] });
    createLog(essenceReductionLog);

    if (playerToUpdate.essences === 0) {
      removePlayerFromGame(playerToUpdate);
      finishGame();
    }
  };

  return {
    removeEssenceOnTurnStart: removeEssenceOnTurnStart,
  };
};
