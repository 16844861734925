import React from 'react';
import { UseEqualizerEffect } from './useEqualizerEffect.types';
import { GameContext } from '../../../../game/gameContext';
import { last } from '../../../../../helpers/arrays';
import { filterIgnoredDefendingActions } from '../../../../game/rules/rules';
import { getCurrentPlayer } from '../../../../game/gameContextHandler/utils/player';
import { cardsToAppliedInProgressActionsMap } from '../../../../game/utils/turnActions';
import { EQUALIZER_CARDS } from '../../../cardTypes';

export const useEqualizerEffect: UseEqualizerEffect = () => {
  const { gameState, addTurnAction, withUpdatingState } = React.useContext(GameContext);

  return () => {
    const currentPlayer = getCurrentPlayer(gameState);
    const lastTurnAction = last(filterIgnoredDefendingActions(gameState.turnActions))?.action;
    if (lastTurnAction === undefined || currentPlayer.id !== gameState.activeDefendingPlayer) return;

    const cardInProgressAction = cardsToAppliedInProgressActionsMap(EQUALIZER_CARDS).get(lastTurnAction);
    if (cardInProgressAction === undefined) return;

    withUpdatingState(addTurnAction)({
      player: currentPlayer.id,
      action: cardInProgressAction,
    });
  };
};
