import React from 'react';
import { Positioned, Props } from './widgetsPanel.types';
import { Box } from '@mui/material';
import { initSx } from './widgetsPanel.styles';
import { OverwritingSx } from '../../../helpers/types';

export const WidgetsPanel: React.FC<Props & Positioned & OverwritingSx> = ({ widgets, position, newSx }) => {
  const sx = initSx(position);
  const widgetsSx = newSx ?? sx.widgets;

  return (
    <React.Fragment>
      <Box sx={widgetsSx}>{widgets ?? []}</Box>
    </React.Fragment>
  );
};
