import React from 'react';

export type MainThemeAudioContextType = {
  isPlaying: boolean;
  play: () => Promise<void>;
  pause: () => void;
  setVolume: (value: number) => void;
  setCurrentTime: (value: number) => void;
  themeType: number;
  setThemeType: (value: number) => void;
};

export const MainThemeAudioContext = React.createContext({} as MainThemeAudioContextType);
