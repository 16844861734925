import React from 'react';
import { UseTrackLocation } from './useTrackLocation.types';
import { LocationTrackerContext } from '../../../game/locationTrackerContextProvider';

export const useTrackLocation: UseTrackLocation = ({ items }) => {
  if (items.length === 0) throw new Error('items must not be empty');

  const itemRef = React.useRef<HTMLDivElement>(null);
  const { setItem } = React.useContext(LocationTrackerContext);

  const setRefs = () => items.forEach((item) => setItem(item, itemRef));
  React.useEffect(() => setRefs(), []);

  return { ref: itemRef };
};
