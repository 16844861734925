import React from 'react';
import { IconBaseProps } from 'react-icons';

export type UseUseSocialNetworksResult = {
  formatUrl: (socialNetwork: SocialNetwork, id: string) => string;
  getIcon: (socialNetwork: SocialNetwork, props: IconBaseProps) => React.ReactElement;
};

export type UseSocialNetworks = () => UseUseSocialNetworksResult;

export enum SocialNetwork {
  TWITTER,
  DISCORD_USER,
  DISCORD_SERVER,
  LINKEDIN,
  FACEBOOK,
  REDDIT,
  INSTAGRAM,
}
