import React from 'react';
import { Box, CardMedia, CircularProgress } from '@mui/material';
import { ImagePath } from '../../assets/assets';
import { sx } from './logo.styles';
import { glitchProperties, Props } from './logo.type';
import { useGlitch } from 'react-powerglitch';

export const Logo: React.FC<Props> = ({ withLoader }) => {
  const glitch = useGlitch(glitchProperties);

  return (
    <Box sx={sx.box}>
      {withLoader && <CircularProgress size={110} sx={sx.circle} />}
      <CardMedia component="img" alt="logo" image={ImagePath.logoOutline} sx={sx.logo} ref={glitch.ref} />
    </Box>
  );
};
