export const encryptData = (data: string, secretKey: string): string => {
  const chunkSize = findFirstNumber(secretKey);
  const dataBase64 = window.btoa(data);
  return reorderChunks(dataBase64, chunkSize);
};

const findFirstNumber = (text: string): number => {
  const match = text.search(/[1-9]/);
  return match === -1 ? 3 : parseInt(text.charAt(match));
};

export const decryptData = (encryptedData: string, secretKey: string): string => {
  const chunkSize = findFirstNumber(secretKey);
  const dataBase64 = reorderChunks(encryptedData, chunkSize);
  return window.atob(dataBase64);
};

const reorderChunks = (data: string, chunkSize: number): string => {
  if (data.length <= chunkSize) return data;
  const chunks = [];
  for (let i = 0; i < data.length; i += chunkSize) {
    chunks.push(data.slice(i, i + chunkSize));
  }

  const lastChunk = chunks.pop();
  chunks.reverse();

  return chunks.join('') + lastChunk;
};
