import { ProjectColors } from '../../themes/mainTheme';
import { capsuleFontStyle } from '../../helpers/styles';
import { BOX_SHADOW_ELEMENT_LOWER_LAYER } from '../../constants/styles';
import { zIndexes } from '../../constants/components';

export const sx = {
  nameBox: {
    display: 'flex',
    width: '100%',
    height: '100%',
    backgroundColor: `${ProjectColors.PURPLE_VERY_DARK_TRANSPARENT}`,
    border: `2px groove ${ProjectColors.BLACK}`,
    borderRadius: '30px',
    textAlign: 'center',
    placeItems: 'center',
    boxShadow: BOX_SHADOW_ELEMENT_LOWER_LAYER,
  },
  name: {
    width: '100%',
    fontSize: capsuleFontStyle,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    px: '0.3rem',
    zIndex: zIndexes.one,
  },
};
