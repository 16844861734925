import React from 'react';
import { Box, IconButton, Tooltip, Typography } from '@mui/material';
import { Props } from './probabilityCapsule.types';
import { ACTIVE_PROB_VALUES, ProbabilityValue } from '../../features/game/gameContextHandler';
import { GameContext } from '../../features/game/gameContext';
import { last } from '../../helpers/arrays';
import { sx } from './probabilityCapsule.styles';
import { tooltipStyles } from '../../helpers/styles';
import { TurnActionTimeStamped } from '../../features/game/gameTypes';
import { getRandomValue } from '../../helpers/probability';
import ReplayIcon from '@mui/icons-material/Replay';
import { SECOND_IN_MILLISECONDS } from '../../constants/time';
import {
  isAnyDomeCheckInProgress,
  isChallengeActive,
  isDisorientationInProgress,
  isOneOfActionsActive,
} from '../../features/game/rules/rules';
import { AnimatePresence, motion, MotionStyle } from 'framer-motion';
import { toImpactedAction } from '../../features/game/utils/turnActions';
import { CardNames } from '../../features/card/cardTypes';
import { useProbabilityRollAction } from '../../features/game/actions/hooks/useProbabilityRollAction';

export const ProbabilityCapsule: React.FC<Props> = () => {
  const { gameState, isProbReRollAllowed, leaveProbLog, setIsProbRolling, isProbRolling } =
    React.useContext(GameContext);
  const { rollProbCapsule } = useProbabilityRollAction();

  const [number, setNumber] = React.useState<ProbabilityValue | null>(null);
  const [rolling, setRolling] = React.useState(false);
  const [showProbabilityCapsule, setShowProbabilityCapsule] = React.useState(false);

  const finalNumberSet = React.useRef(false);

  React.useEffect(() => {
    const isProbabilityActionInProgress = isOneOfActionsActive(gameState, [
      isChallengeActive,
      isAnyDomeCheckInProgress,
      isDisorientationInProgress,
    ]);

    if (!isProbabilityActionInProgress && showProbabilityCapsule) {
      setShowProbabilityCapsule(false);
      return;
    }

    const lastAction = last(
      gameState.turnActions.filter((action) => action.action !== toImpactedAction(CardNames.FOGGER))
    );
    if (lastAction === undefined) return;

    if (isProbabilityActionInProgress && !showProbabilityCapsule) {
      setTimeout(() => {
        setShowProbabilityCapsule(true);
        animateAppliedProbabilityValue(lastAction);
      }, SECOND_IN_MILLISECONDS);
    } else {
      animateAppliedProbabilityValue(lastAction);
    }
  }, [gameState.turnActions.length]);

  const animateAppliedProbabilityValue = (turnAction: TurnActionTimeStamped) => {
    if (turnAction.action !== 'probability capsule randomized' || turnAction.probValue === undefined) {
      setNumber(null);
      return;
    }
    setIsProbRolling(true);
    setRolling(true);
    setTimeout(() => {
      finalNumberSet.current = true;
      setRolling(false);
      const probabilityValue = turnAction.probValue as ProbabilityValue;
      setNumber(probabilityValue);
      leaveProbLog();
      setIsProbRolling(false);
    }, 2.5 * SECOND_IN_MILLISECONDS);
  };

  React.useEffect(() => {
    if (!rolling) return;
    const intervalId = setInterval(() => {
      if (!finalNumberSet.current) {
        setNumber(getRandomValue());
      }
    }, 0.05 * SECOND_IN_MILLISECONDS);
    return () => {
      clearInterval(intervalId);
      finalNumberSet.current = false;
    };
  }, [rolling, number]);

  const capsuleSections = ACTIVE_PROB_VALUES.map((v, k) => (
    <Box key={k} sx={v === number ? sx.capsuleSectionActive : sx.capsuleSectionInactive}>
      {v}
    </Box>
  ));

  const nullCapsuleSections = ['', 'N', 'U', 'L', 'L', ''].map((v, k) => (
    <Box key={k} sx={v === '' ? sx.capsuleSectionInactive : sx.capsuleSectionActive}>
      {v}
    </Box>
  ));

  const capsuleVariants = {
    hidden: {
      transform: 'scale(0)',
      transition: { duration: 1 },
    },
    visible: {
      transform: 'scale(1)',
      transition: { duration: 1 },
    },
    exit: {
      transform: 'scale(0)',
      transition: { delay: 5, duration: 1 },
    },
  };

  return (
    <>
      <AnimatePresence>
        {showProbabilityCapsule && (
          <motion.div
            exit="exit"
            initial="hidden"
            animate="visible"
            variants={capsuleVariants}
            style={sx.capsuleMotionBlockSx as MotionStyle}
          >
            <Tooltip
              title={<Typography sx={tooltipStyles}>Probability Capsule</Typography>}
              disableInteractive
              placement="left"
            >
              <Box sx={sx.outerBox}>
                <Box sx={sx.capsule}>{number === 0 ? nullCapsuleSections : capsuleSections}</Box>
                {isProbReRollAllowed && (
                  <IconButton disabled={isProbRolling} onClick={rollProbCapsule} sx={sx.retryButton}>
                    <ReplayIcon sx={sx.retryIcon} />
                  </IconButton>
                )}
              </Box>
            </Tooltip>
          </motion.div>
        )}
      </AnimatePresence>
    </>
  );
};
