import React from 'react';
import { ActiveGameAction, Props } from './activeGamesBlock.types';
import { AppContext } from '../../../global/context/appContext';
import { useGameModal } from '../../../features/menu/hooks/useGameModal';
import { JoinGameButton } from '../../../features/game/playNowButtons/joinGameButton';
import { StatsBlock, StatsGlitchedContent } from '../../statsGlitchedContent';
import { GameApiData } from '../../../features/game/gameContextHandler/hooks/useGameState';
import { isEmpty } from '../../../helpers/arrays';
import styles from './activeGamesBlock.module.css';
import { PRIVATE_ACCESS_MODE, PUBLIC_ACCESS_MODE } from '../../../features/game/gameTypes';

export const ActiveGamesBlock: React.FC<Props> = ({ activeGames, pendingGames }) => {
  const { isMobile } = React.useContext(AppContext);
  const { openModal, modal } = useGameModal();

  const gamesToStatsBlockByAction = (games: GameApiData[], action: ActiveGameAction): StatsBlock[] => {
    const publicName = PUBLIC_ACCESS_MODE.name.toLowerCase();
    const privateName = PRIVATE_ACCESS_MODE.name.toLowerCase();
    const sortedGames = games.sort((a, b) => {
      if (a.access === publicName && b.access === privateName) return -1;
      if (a.access === privateName && b.access === publicName) return 1;
      return 0;
    });

    return sortedGames.map((game) => {
      const mainGameInfo = game.creator.name + "'s " + game.mode + ' game for ' + game.numberOfPlayers;
      const players = game.players.filter((player) => player.id !== game.creator.id).map((player) => player.name);
      const gameInfoWithPlayers = !isEmpty(players) ? ' with ' + players.join(', ') : '';
      const fullGameInfo = isMobile ? mainGameInfo : mainGameInfo + gameInfoWithPlayers;

      return {
        name: game.uniqueId,
        leftBlock: fullGameInfo,
        rightBlock: defineGameActionBlock(game, action),
      };
    });
  };

  const defineGameActionBlock = (game: GameApiData, action: ActiveGameAction): React.JSX.Element | ActiveGameAction => {
    if (action === 'Join' && game.access === PUBLIC_ACCESS_MODE.name.toLowerCase()) {
      const buttonName = isMobile ? 'Join' : 'Join (' + game.players.length + '/' + game.numberOfPlayers + ')';

      return (
        <JoinGameButton
          buttonName={buttonName}
          onClick={(event) => openModal('Join', event, game.uniqueId)}
          customStylesClassname={'standalone'}
        />
      );
    }

    if (action === 'In progress') return action;

    return 'Private';
  };

  const formattedActiveGames = gamesToStatsBlockByAction(activeGames, 'In progress');
  const formattedPendingGames = gamesToStatsBlockByAction(pendingGames, 'Join');
  const statsBlocks = [...formattedPendingGames, ...formattedActiveGames];

  return (
    <div className={styles.container}>
      <StatsGlitchedContent statsBlocks={statsBlocks} />
      {modal()}
    </div>
  );
};
