import React from 'react';
import { UseSpyEffect } from './useSpyEffect.types';
import { GameContext } from '../../../../game/gameContext';
import { last } from '../../../../../helpers/arrays';
import { CardNames } from '../../../cardTypes';
import { filterIgnoredDefendingActions } from '../../../../game/rules/rules';
import {
  addTurnActionWithStateUpdateIfNeeded,
  toAppliedAction,
  toInProgressAction,
} from '../../../../game/utils/turnActions';
import { getCurrentPlayer } from '../../../../game/gameContextHandler/utils/player';

export const useSpyEffect: UseSpyEffect = () => {
  const { gameState, addTurnAction, withUpdatingState } = React.useContext(GameContext);

  return () => {
    const currentPlayer = getCurrentPlayer(gameState);
    const lastTurnAction = last(filterIgnoredDefendingActions(gameState.turnActions))?.action;

    if (
      lastTurnAction === undefined ||
      currentPlayer.id !== gameState.activeDefendingPlayer ||
      lastTurnAction !== toAppliedAction(CardNames.SPY)
    ) {
      return;
    }

    addTurnActionWithStateUpdateIfNeeded(
      currentPlayer,
      toInProgressAction(CardNames.SPY),
      withUpdatingState,
      addTurnAction
    );
  };
};
