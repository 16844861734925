import React from 'react';
import {
  AVERAGE_GAME_LASTS_STATS_NAME,
  FAVORITE_PLAYERS_NUMBER_STATS_NAME,
  GAMES_PLAYED_STATS_NAME,
  MOST_WINS_ROLE_STATS_NAME,
  Props,
} from './mainGameStatsBlock.types';
import { Box, CardMedia } from '@mui/material';
import { DynamicCounter } from '../../dynamicCounter';
import { AppContext } from '../../../global/context/appContext';
import { sxInit } from './mainGameStatsBlock.styles';
import { PlayingCard } from '../../../features/card/cardTypes';
import { getRandom } from '../../../helpers/arrays';
import { StatsBlock, StatsGlitchedContent } from '../../statsGlitchedContent';

export const MainGameStatsBlock: React.FC<Props> = ({ mainStatsData }) => {
  const { allPlayingCards, isMobile } = React.useContext(AppContext);

  const [randomCard1, setRandomCard1] = React.useState<PlayingCard>();
  const [randomCard2, setRandomCard2] = React.useState<PlayingCard>();

  React.useEffect(() => {
    setRandomCard1(getRandom(allPlayingCards));
    setRandomCard2(getRandom(allPlayingCards));
  }, [allPlayingCards]);

  const sx = sxInit(isMobile);

  const statsBlocks: StatsBlock[] = [
    {
      name: GAMES_PLAYED_STATS_NAME,
      leftBlock: `${GAMES_PLAYED_STATS_NAME}:`,
      rightBlock: (
        <DynamicCounter
          initialValue={0}
          finalValue={mainStatsData.gamesPlayed}
          startDelay={1200}
          duration={2000}
          key={'games-player-dynamic-counter'}
        ></DynamicCounter>
      ),
    },
    {
      name: MOST_WINS_ROLE_STATS_NAME,
      leftBlock: `${MOST_WINS_ROLE_STATS_NAME}:`,
      rightBlock: mainStatsData.mostWinsRole,
    },
    {
      name: FAVORITE_PLAYERS_NUMBER_STATS_NAME,
      leftBlock: `${FAVORITE_PLAYERS_NUMBER_STATS_NAME}:`,
      rightBlock: mainStatsData.popularPlayersNumber,
    },
    {
      name: AVERAGE_GAME_LASTS_STATS_NAME,
      leftBlock: `${AVERAGE_GAME_LASTS_STATS_NAME}:`,
      rightBlock: mainStatsData.averageGameDuration,
    },
  ];

  return (
    <Box sx={sx.mainGameStatsBlock}>
      {randomCard1 && <CardMedia component="img" alt="randomCard1" image={randomCard1.image} sx={sx.cardImage} />}
      <Box sx={sx.statsBlock}>
        <StatsGlitchedContent statsBlocks={statsBlocks} useGlitchEffect={true} />
      </Box>
      {randomCard2 && <CardMedia component="img" alt="randomCard2" image={randomCard2.image} sx={sx.cardImage} />}
    </Box>
  );
};
