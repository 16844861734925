import { UseDefendByEncloseEssenceCard } from './useDefendByEncloseEssenceCard.types';
import React from 'react';
import { Log, LogContext } from '../../../logContextProvider';
import { last } from '../../../../../helpers/arrays';
import { filterIgnoredDefendingActions } from '../../../rules/rules';
import { cardsAndActionTypesToActions } from '../../../utils/turnActions';
import { CardNames, ESSENCE_LOSS_CARDS } from '../../../../card/cardTypes';
import { IN_PROGRESS } from '../../../gameTypes';
import { Player } from '../../../../player/player.types';
import { useApplyEssenceThiefEffect } from '../useApplyEssenceThiefEffect';

export const useDefendByEncloseEssenceCard: UseDefendByEncloseEssenceCard = ({
  gameState,
  addTurnAction,
  performFinishDefending,
}) => {
  const { applyEssenceThiefEffectIfRelevant } = useApplyEssenceThiefEffect({
    gameState: gameState,
    addTurnAction: addTurnAction,
  });
  const { createLog } = React.useContext(LogContext);

  const isDefendingRequired = (): boolean => {
    const lastAttackingAction = last(filterIgnoredDefendingActions(gameState.turnActions))?.action;
    const essenceLossActions = cardsAndActionTypesToActions(ESSENCE_LOSS_CARDS, [IN_PROGRESS]);

    return lastAttackingAction !== undefined && essenceLossActions.includes(lastAttackingAction);
  };

  const performFinishDefendingByCard = (player: Player, card: CardNames) => {
    const log: Log = { type: 'info', text: `${player.playerName}: saved by ${card}` };
    addTurnAction({ player: player.id, action: 'create log', logs: [log] });
    createLog(log);

    applyEssenceThiefEffectIfRelevant();
    performFinishDefending('successfulDefending');
  };

  return {
    isDefendingRequired: isDefendingRequired,
    performFinishDefendingByCard: performFinishDefendingByCard,
  };
};
