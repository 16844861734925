import React from 'react';
import { Props } from './lastPlayedGamesStatsBlock.types';
import { Box, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';
import { AppContext } from '../../../global/context/appContext';
import { sxInit } from './lastPlayedGamesStatsBlock.styles';
import { finishedGameApiDataToStatsTableRow } from '../../../features/game/gameTransformers';
import { FINISHED_GAME_TABLE } from '../gameStatsBlock.types';

export const LastPlayedGamesStatsBlock: React.FC<Props> = ({ lastFinishedGames }) => {
  const { isMobile } = React.useContext(AppContext);

  const sx = sxInit(isMobile);

  const formattedFinishedGames = lastFinishedGames.map((game) => finishedGameApiDataToStatsTableRow(game));

  const mobileColumns = FINISHED_GAME_TABLE.filter((column) => column.isMobile);
  const tableColumns = isMobile ? mobileColumns : FINISHED_GAME_TABLE;

  return (
    <Box>
      <Table sx={sx.table} aria-label="last played games table">
        <TableHead sx={sx.tableHeader}>
          <TableRow>
            {tableColumns.map((column) => (
              <TableCell sx={sx.headerTableCell} key={`game-column-name-${column.header}`}>
                <Typography key={`game-column-content-${column.header}`} sx={column.styles}>
                  {column.header}
                </Typography>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {formattedFinishedGames.map((game) => (
            <TableRow key={`game-row-${game.gameId}`}>
              {Object.values(game).map((gameData, index) => (
                <React.Fragment key={`game-row-${gameData}-column-${index}`}>
                  {isMobile ? (
                    mobileColumns.includes(FINISHED_GAME_TABLE[index]) && (
                      <TableCell sx={sx.bodyTableCell}>{gameData}</TableCell>
                    )
                  ) : (
                    <TableCell sx={sx.bodyTableCell}>{gameData}</TableCell>
                  )}
                </React.Fragment>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Box>
  );
};
