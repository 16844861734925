import axios from 'axios';
import { getHttpStatusFromErr } from '../helpers/utils';
import { ErrorEvent } from '../helpers/hooks/useErrorHandling';

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    const statusCode = getHttpStatusFromErr(error);
    if (statusCode === 403) ErrorEvent.emit('accessDenied', error);
    if (statusCode && statusCode >= 500) ErrorEvent.emit('unexpectedError', error);
    return Promise.reject(error);
  }
);
