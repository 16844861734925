import React from 'react';
import { UseConversionerEffect } from './useConversionerEffect.types';
import { GameContext } from '../../../../game/gameContext';
import { last } from '../../../../../helpers/arrays';
import { CardNames } from '../../../cardTypes';
import {
  addTurnActionWithStateUpdateIfNeeded,
  toAppliedAction,
  toInProgressAction,
} from '../../../../game/utils/turnActions';
import { getCurrentPlayer } from '../../../../game/gameContextHandler/utils/player';
import { filterIgnoredDefendingActions } from '../../../../game/rules/rules';

export const useConversionerEffect: UseConversionerEffect = () => {
  const { gameState, addTurnAction, withUpdatingState, performFinishAndConvert } = React.useContext(GameContext);

  return () => {
    const currentPlayer = getCurrentPlayer(gameState);
    const lastTurnAction = last(filterIgnoredDefendingActions(gameState.turnActions))?.action;

    if (
      lastTurnAction === undefined ||
      currentPlayer.id !== gameState.activeDefendingPlayer ||
      lastTurnAction !== toAppliedAction(CardNames.CONVERSIONER)
    ) {
      return;
    }

    if (gameState.activePlayer === gameState.activeDefendingPlayer) {
      return performFinishAndConvert();
    }

    addTurnActionWithStateUpdateIfNeeded(
      currentPlayer,
      toInProgressAction(CardNames.CONVERSIONER),
      withUpdatingState,
      addTurnAction
    );
  };
};
