export const ROOT_ROUTE = '/';
export const GAME_ROUTE = '/game';
export const ACTIVE_GAME_ROUTE = (id: string = ':id') => GAME_ROUTE + `/${id}`;
export const RULES_ROUTE = '/rules';
export const ABOUT_US_ROUTE = '/aboutUs';
export const PROFILE_ROUTE = '/profile';

export const ERROR_PAGE_ROUTE = '/error';
export const SPECIFIC_ERROR_ROUTE = (type: string = ':errorType') => ERROR_PAGE_ROUTE + `/${type}`;

export const NOT_FOUND_ERROR_ROUTE = SPECIFIC_ERROR_ROUTE('notFound');
export const ACCESS_DENIED_ERROR_ROUTE = SPECIFIC_ERROR_ROUTE('accessDenied');
export const UNEXPECTED_ERROR_ROUTE = SPECIFIC_ERROR_ROUTE('unexpected');
