import { Box, Typography } from '@mui/material';
import React from 'react';
import { Props, PropsMobile } from './essenceHuntModal.types';
import { sx } from './essenceHuntModal.styles';
import { GameContext } from '../../game/gameContext';
import { getCurrentPlayer, getPlayerCards } from '../../game/gameContextHandler/utils/player';
import Button from '@mui/material/Button';
import { CardGroup } from '../../../components/cardGroup';
import { SizeExtended } from '../../../helpers/sizes';
import { PlayingCard } from '../../card/cardTypes';
import { SelectorType } from '../../../components/card';
import { ESSENCE_HUNT_CARDS_COUNT } from '../../../constants/rules';
import { EssenceHuntContext } from '../essenceHuntContextProvider/essenceHuntContextProvider.types';
import { useHuntEssenceAction } from '../../game/actions/hooks/useHuntEssenceAction';
import { Modal } from '../../../components/modal';

export const EssenceHuntModal: React.FC<Props> = ({ isOpen, onCardClick }) => {
  const [selectedCards, setSelectedCards] = React.useState<PlayingCard[]>([]);

  const { gameState } = React.useContext(GameContext);
  const { setIsEssenceHuntModalOpen } = React.useContext(EssenceHuntContext);
  const { completeHuntEssence, cancelHuntEssence } = useHuntEssenceAction();

  const selectAndShowCard = (card: PlayingCard) => {
    setSelectedCards((alreadySelectedCards) => {
      if (alreadySelectedCards.includes(card)) {
        return alreadySelectedCards.filter((selectedCard) => selectedCard !== card);
      }
      return [...alreadySelectedCards, card];
    });
    onCardClick(card);
  };

  const closeModal = () => {
    setSelectedCards([]);
    setIsEssenceHuntModalOpen(false);
    cancelHuntEssence();
  };

  const currentPlayer = getCurrentPlayer(gameState);
  const allPlayerCards = getPlayerCards(currentPlayer);

  const huntEssenceAction = () => {
    completeHuntEssence(selectedCards);
    setIsEssenceHuntModalOpen(false);
  };

  return (
    <Modal isOpen={isOpen} onClose={closeModal}>
      <Typography id="finished-game-modal-title" variant="h5" component="h2" textAlign={'center'}>
        Choose cards to discard
      </Typography>
      <Box sx={sx.cardsBox}>
        <CardGroup
          isActive={true}
          cards={allPlayerCards}
          size={SizeExtended.M}
          placeholdersCount={0}
          onCardClick={selectAndShowCard}
          selectorType={SelectorType.MULTIPLE_ON_CLICK}
        />
        <Box sx={sx.actionBox}>
          <Button
            variant="contained"
            disabled={selectedCards.length !== ESSENCE_HUNT_CARDS_COUNT}
            onClick={huntEssenceAction}
            sx={sx.approveButton}
          >
            Hunt essence
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export const EssenceHuntModalMobile: React.FC<PropsMobile> = (props) => {
  return <EssenceHuntModal {...props} onCardClick={() => undefined} />;
};
