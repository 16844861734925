import React from 'react';
import { getMappedRoleCards, Props } from './rulesAccordion.types';
import {
  Box,
  CardMedia,
  Divider,
  List,
  ListItem,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';
import { DistributionTable, EssenceCountTable, showFullRulesContent } from '../ruleBook.types';
import { Role } from '../../card/cardTypes';
import {
  Accordion,
  AccordionDetails,
  AccordionRuleSummary,
  AccordionSummary,
} from '../styledAccordion/styledAccordion';
import { containsSearched } from '../../../helpers/strings';
import { HtmlTypography } from '../../../components/htmlTypography';
import { sxInit } from './rulesAccordion.styles';
import { AppContext } from '../../../global/context/appContext';
import { ROLES_SECTION_TITLE } from '../../../constants/ruleBook';

export const RulesAccordion: React.FC<Props> = ({ page, rules, searched }) => {
  const { windowSize } = React.useContext(AppContext);

  const showFullContent = showFullRulesContent(page, windowSize);

  const [isOpen, setIsOpen] = React.useState(false);
  const [openSections, setOpenSections] = React.useState<string[]>([]);

  const setOpenSectionBySearch = React.useCallback(() => {
    const markedAsOpenSections: string[] = [];
    rules.forEach((ruleSection) => {
      if (
        containsSearched(ruleSection.rules.join('\n'), searched) ||
        containsSearched(ruleSection.sectionTitle, searched)
      ) {
        markedAsOpenSections.push(ruleSection.sectionTitle);
      }
    });

    setOpenSections(markedAsOpenSections);
    setIsOpen(markedAsOpenSections.length > 0);
  }, [rules, searched]);

  const toggleSection = (toggled: string) => {
    setOpenSections((sections) => {
      const updatedSection: string[] = [];
      if (sections.includes(toggled)) {
        updatedSection.push(...sections.filter((section) => section !== toggled));
      } else {
        updatedSection.push(...sections, toggled);
      }

      return updatedSection;
    });
  };

  React.useEffect(() => {
    setOpenSectionBySearch();
  }, [setOpenSectionBySearch]);

  const sx = sxInit(showFullContent);

  const distributionTableValues = Object.values(DistributionTable);
  const essenceCountTable = Object.values(EssenceCountTable);
  const roleDistributionTable = showFullContent ? (
    <Table sx={sx.table.general} aria-label="role distribution table">
      <TableHead sx={sx.table.head}>
        <TableRow>
          <TableCell>Role \ Total players</TableCell>
          {Object.keys(DistributionTable).map((key) => (
            <TableCell key={`distribution-column-name-${key}`}>
              <HtmlTypography searched={searched}>{key}</HtmlTypography>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {Object.values(Role).map((role) => (
          <TableRow key={`distribution-row-${role}`}>
            <TableCell>
              <HtmlTypography searched={searched}>{role}</HtmlTypography>
            </TableCell>
            {distributionTableValues.map((roleDistribution, index) => (
              <TableCell key={`distribution-row-${role}-column-${index}`}>{roleDistribution[role]}</TableCell>
            ))}
          </TableRow>
        ))}
        <TableRow sx={sx.essences.mainRow}>
          <TableCell>
            <Box sx={sx.essences.descriptionCell}>
              <HtmlTypography sx={sx.essences.description} searched={searched}>
                Number of essences
              </HtmlTypography>
            </Box>
          </TableCell>
          {essenceCountTable.map((essenceCount, index) => (
            <TableCell key={`essence-distribution-column-${index}`}>
              {essenceCount.min === essenceCount.max ? essenceCount.max : essenceCount.min + '-' + essenceCount.max}
            </TableCell>
          ))}
        </TableRow>
      </TableBody>
    </Table>
  ) : undefined;

  const rolesDescriptionSection = (
    <List sx={sx.rules.list.roles}>
      {Array.from(getMappedRoleCards).map(([role, roleRuleCard]) => (
        <ListItem key={`section-${ROLES_SECTION_TITLE}-rule${role}`}>
          <Box sx={sx.roles.mainBox}>
            <Box sx={sx.roles.contentBox}>
              <Box sx={sx.roles.imageBox}>
                {roleRuleCard.images.map((image, imageIndex) => (
                  <CardMedia
                    key={`section-${ROLES_SECTION_TITLE}-rule-${role}-${imageIndex}`}
                    component="img"
                    src={image}
                    alt={`role-image-${role}`}
                    sx={sx.roles.roleImage}
                  />
                ))}
              </Box>
              <Box sx={sx.roles.descriptionBox}>
                <HtmlTypography searched={searched}>
                  {'<u><b>' + role + '</b></u> - ' + roleRuleCard.description}
                </HtmlTypography>
              </Box>
            </Box>
            <Divider variant="middle" sx={sx.roles.divider} />
          </Box>
        </ListItem>
      ))}
    </List>
  );

  const rulesComponent = rules.map((section) => (
    <Accordion
      key={`section-${section.sectionTitle}`}
      expanded={openSections.includes(section.sectionTitle)}
      onChange={() => toggleSection(section.sectionTitle)}
    >
      <AccordionRuleSummary>
        <HtmlTypography searched={searched}>{section.sectionTitle}</HtmlTypography>
      </AccordionRuleSummary>
      <AccordionDetails>
        <List sx={sx.rules.list.general}>
          {section.rules.map((rule, index) => (
            <ListItem key={`section-${section.sectionTitle}-rule${index}`} sx={sx.rules.list.item}>
              <HtmlTypography searched={searched}>{rule}</HtmlTypography>
            </ListItem>
          ))}
        </List>
        {section.sectionTitle === ROLES_SECTION_TITLE && rolesDescriptionSection}
        {section.sectionTitle === ROLES_SECTION_TITLE && roleDistributionTable}
      </AccordionDetails>
    </Accordion>
  ));

  return (
    <Accordion expanded={isOpen} onChange={() => setIsOpen(!isOpen)}>
      <AccordionSummary aria-controls="rules-content" id="rules-header">
        <HtmlTypography>Rules</HtmlTypography>
      </AccordionSummary>
      <AccordionDetails>{rulesComponent}</AccordionDetails>
    </Accordion>
  );
};
