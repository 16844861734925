import React from 'react';
import { Props } from './motionBlock.types';
import { motion, AnimatePresence } from 'framer-motion';

export const MotionBlock: React.FC<Props> = ({ transitionDuration, children, customStyles = undefined }) => {
  return (
    <AnimatePresence>
      <motion.div
        exit={{ opacity: 0, y: -2000 }}
        initial={{ opacity: 0, y: -2000 }}
        transition={{ ease: 'easeOut', duration: transitionDuration }}
        animate={{ opacity: 1, y: 0 }}
        style={customStyles}
      >
        {children}
      </motion.div>
    </AnimatePresence>
  );
};
