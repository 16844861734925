import React from 'react';
import { PlayingCard } from '../../features/card/cardTypes';

export type Props = {
  draggableData: DraggedCardData;
  children?: React.ReactNode;
};

export type DraggedCardData = {
  cards: PlayingCard[];
  fromPlayerId?: string;
  dragSource: DragSource;
  dragger?: string;
  isActive?: boolean;
};

export enum DraggableType {
  CARD = 'card',
}

export enum DragSource {
  PLAYER,
  PLAYING_AREA,
  APPLIED_RADIANCE,
}
