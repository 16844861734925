import React from 'react';
import { Button } from '@mui/material';
import styles from './../playNowButtons.module.css';
import { Props } from './createGameButton.types';
import { MouseEvent } from '../playNowButtons';
import GroupsIcon from '@mui/icons-material/Groups';

export const CreateGameButton: React.FC<Props> = ({ buttonName, onClick }) => {
  const onButtonClick = (event: MouseEvent) => onClick(event, 'Create');

  return (
    <Button onClick={onButtonClick} variant="outlined" className={`${styles.button} ${styles.create}`}>
      <GroupsIcon className={styles.icon} />
      <p className={styles.text}>{buttonName}</p>
    </Button>
  );
};
