import { ProjectColors } from '../../themes/mainTheme';

export const sx = {
  box: { display: 'flex', justifyContent: 'center', position: 'relative', mb: '1%', height: '100px' },
  logo: {
    width: '100px',
    left: 'calc(50% - 50px)',
    position: 'absolute',
    top: `16px`,
    py: 1,
    px: 3,
    border: `1px solid ${ProjectColors.PINK_LOGO}`,
    borderRadius: 100,
    background: `${ProjectColors.PURPLE_VERY_DARK_TRANSPARENT}}`,
  },
  circle: {
    position: 'absolute',
    circle: {
      strokeWidth: '2.5px',
    },
    top: `11px`,
    color: `${ProjectColors.PINK_LOGO}`,
  },
};
