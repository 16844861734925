import React from 'react';
import { Props } from './youtubePlayer.types';
import YouTube, { YouTubeProps } from 'react-youtube';
import { sx } from './youtubePlayer.styles';

export const YoutubePlayer: React.FC<Props> = ({ videoId, height, width }) => {
  const onPlayerReady: YouTubeProps['onReady'] = (event) => {
    event.target.pauseVideo();
  };

  const opts: YouTubeProps['opts'] = {
    height: height,
    width: width,
  };

  return <YouTube videoId={videoId} opts={opts} onReady={onPlayerReady} style={sx.iFrame} />;
};
