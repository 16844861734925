import { Profile } from '../features/auth/authTypes';
import { PROFILE_STORAGE_KEY } from '../constants/login';
import { GameState } from '../features/game/gameContextHandler/hooks/useGameState';
import { PlayerState } from '../features/player/player.types';
import { getAttackingPlayerId, getPlayer } from '../features/game/gameContextHandler/utils/player';

export const parseProfile = (): Profile | null => {
  try {
    const profileString = localStorage.getItem(PROFILE_STORAGE_KEY);
    return profileString ? (JSON.parse(profileString) as Profile) : null;
  } catch {
    return null;
  }
};

export enum BlocksDirection {
  RIGHT_TO_LEFT,
  LEFT_TO_RIGHT,
}

export const sortByDirection = <T>(elements: T[], blocksDirection: BlocksDirection): T[] => {
  switch (blocksDirection) {
    case BlocksDirection.LEFT_TO_RIGHT:
      return [...elements];
    case BlocksDirection.RIGHT_TO_LEFT:
      return [...elements.reverse()];
  }
};

export const definePlayerStateByGameState = (playerId: string, gameState: GameState): PlayerState => {
  const player = getPlayer(gameState.allPlayers, playerId);
  const isDefending = gameState.defendingPlayerIds.includes(player.id);
  const isDefendingNow = gameState.activeDefendingPlayer === player.id;
  const isActive = gameState.activePlayer === player.id;

  if (gameState.isFinished && player.isWinner) {
    return PlayerState.WINNER;
  }
  if (!gameState.playersOrdered.includes(playerId) || (gameState.isFinished && !player.isWinner)) {
    return PlayerState.OUT_OF_GAME;
  }
  if (isDefendingNow && isActive) {
    return PlayerState.ACTIVE_DEFENDING;
  }
  if (isDefendingNow) {
    return PlayerState.DEFENDING_NOW;
  }
  if (isDefending && isActive) {
    return PlayerState.ACTIVE_DEFENDING_PENDING;
  }
  if (isDefending) {
    return PlayerState.DEFENDING_PENDING;
  }
  if (isActive) {
    return PlayerState.ACTIVE_ATTACKING;
  }
  if (!isActive && getAttackingPlayerId(gameState) === player.id) {
    return PlayerState.INACTIVE_ATTACKING;
  }

  return PlayerState.IN_GAME;
};
