import React from 'react';
import { Props } from './droppable.types';
import { useDrop } from 'react-dnd';
import { DraggableType } from '../draggable';
import { Box } from '@mui/material';
import { sx } from './droppable.styles';

export const Droppable: React.FC<Props> = ({ canDrop, onItemDropped, children, extraSx }) => {
  const [{ isOver, isDropAllowed }, drop] = useDrop({
    accept: DraggableType.CARD,
    drop: onItemDropped,
    canDrop: canDrop,
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      didDrop: monitor.didDrop(),
      isDropAllowed: monitor.canDrop(),
    }),
  });

  let style = {};
  if (isDropAllowed) style = sx.highlighted;
  if (isOver) style = sx.hovered;
  style = { ...style, ...extraSx };

  return isDropAllowed ? (
    <Box ref={drop} sx={style}>
      {children}
    </Box>
  ) : (
    <Box>{children}</Box>
  );
};
