import React from 'react';
import { Props } from './aboutUs.types';
import { Box, Divider, IconButton, Typography } from '@mui/material';
import { Card, CardContent } from '../../components/card';
import { SizeExtended } from '../../helpers/sizes';
import { ImagePath } from '../../assets/assets';
import { ProjectColors } from '../../themes/mainTheme';
import { initSx } from './aboutUs.styles';
import { AppContext } from '../../global/context/appContext';
import { MotionBlock } from '../../components/motionBlock';
import { SocialNetwork, useSocialNetworks } from '../../helpers/hooks/useSocialNetworks';
import { Flipper } from '../../components/flipper';
import {
  KURETS_DISCORD,
  KURETS_LINKEDIN,
  KURETS_TWITTER,
  YAKHNO_DISCORD,
  YAKHNO_LINKEDIN,
  YAKHNO_TWITTER,
} from '../../constants/socialNetworks';
import { Logo } from '../../components/logo/logo';
import { ContentPageWrapper } from '../../components/contentPageWrapper';

export const AboutUs: React.FC<Props> = () => {
  const { isMobile } = React.useContext(AppContext);
  const sx = initSx();

  const { formatUrl, getIcon } = useSocialNetworks();

  const [isYakhnoOpen, setIsYakhnoOpen] = React.useState(false);
  const [yakhnoImage, setYakhnoImage] = React.useState(ImagePath.yakhnoBig);
  const [isKuretsOpen, setIsKuretsOpen] = React.useState(false);
  const [kuretsImage, setKuretsImage] = React.useState(ImagePath.kuretsBig);

  const ONE_SECOND = 1000;

  React.useEffect(() => {
    const timer = setTimeout(() => {
      setIsYakhnoOpen(true);
      setIsKuretsOpen(true);
    }, ONE_SECOND * 2);

    return () => {
      clearTimeout(timer);
    };
  }, []);

  const iconProps = { size: 30 };
  const iconButton = (sn: SocialNetwork, snUserId: string) => (
    <IconButton href={formatUrl(sn, snUserId)} target={'_blank'} aria-label={`${sn} link`} sx={sx.iconButton}>
      {getIcon(sn, iconProps)}
    </IconButton>
  );

  const yakhnoIcons = (
    <Box sx={sx.iconsBox}>
      {iconButton(SocialNetwork.TWITTER, YAKHNO_TWITTER)}
      {iconButton(SocialNetwork.LINKEDIN, YAKHNO_LINKEDIN)}
      {iconButton(SocialNetwork.DISCORD_USER, YAKHNO_DISCORD)}
    </Box>
  );

  const yakhnoContent: CardContent = {
    type: 'Yurii',
    name: 'Sam de facto',
    details: 'The Galactic GameSmith, strumming cosmic chords and sculpting digital dreams.',
  };

  const handleYakhnoClick = (): void => {
    if (!isYakhnoOpen) {
      setYakhnoImage(yakhnoImage === ImagePath.yakhnoBig ? ImagePath.yakhnoSmall : ImagePath.yakhnoBig);
    }
    setIsYakhnoOpen(!isYakhnoOpen);
  };

  const kuretsIcons = (
    <Box sx={sx.iconsBox}>
      {iconButton(SocialNetwork.TWITTER, KURETS_TWITTER)}
      {iconButton(SocialNetwork.LINKEDIN, KURETS_LINKEDIN)}
      {iconButton(SocialNetwork.DISCORD_USER, KURETS_DISCORD)}
    </Box>
  );

  const kuretsContent: CardContent = {
    type: 'Dmytro',
    name: 'DareDev',
    details: 'The Lyrically Wired Wizard, crafting digital dimensions with a potent brew of beats and bytes.',
  };

  const handleKuretsClick = (): void => {
    if (!isKuretsOpen) {
      setKuretsImage(kuretsImage === ImagePath.kuretsBig ? ImagePath.kuretsSmall : ImagePath.kuretsBig);
    }
    setIsKuretsOpen(!isKuretsOpen);
  };

  const card = (
    content: CardContent,
    image: string,
    icons: React.ReactElement,
    isOpen: boolean,
    handleClick: () => void
  ) => {
    return (
      <Box key={`${image}${isOpen}`} onClick={handleClick} sx={sx.creatorBox}>
        <Flipper transitionDuration={0.5}>
          <Card
            isOpen={isOpen}
            size={SizeExtended.L}
            imagePath={image}
            backCoverImagePath={ImagePath.roleCardCover}
            content={{ ...content, details: '' }}
            backgroundColor={ProjectColors.ROLE_CARD}
            rotation={0}
            ignoreWindowSize
            hideRuleBookWidget
          />
        </Flipper>
        <Typography sx={sx.creatorDescription}>{content.details}</Typography>
        {icons}
      </Box>
    );
  };

  const divider = <Divider variant="middle" sx={sx.divider} />;

  const resolveRoleBox = (elements: React.ReactElement[]) => {
    const elementsWithDividers: React.ReactElement[] = [];

    for (let i = 0; i < elements.length; i++) {
      elementsWithDividers.push(elements[i]);
      if (i === elements.length - 1) break;

      elementsWithDividers.push(divider);
    }
    return isMobile ? elementsWithDividers : <Box sx={sx.rolesBox}>{elements}</Box>;
  };

  const outerElements = (
    <Box sx={sx.logoBox}>
      <MotionBlock transitionDuration={1.5}>
        <Logo />
      </MotionBlock>
    </Box>
  );

  const innerElements = (
    <MotionBlock transitionDuration={2}>
      <Box sx={sx.storyBox}>
        <Typography variant="h4">About us</Typography>
        {divider}
        <Typography variant="h5">Our mission is:</Typography>
        <Typography sx={sx.missionText}>
          To beam the heart of traditional board gaming into the digital ether, crafting immersive realm of play
        </Typography>
        {divider}
        {resolveRoleBox([
          card(kuretsContent, kuretsImage, kuretsIcons, isKuretsOpen, handleKuretsClick),
          card(yakhnoContent, yakhnoImage, yakhnoIcons, isYakhnoOpen, handleYakhnoClick),
        ])}
        {divider}
        <Typography variant="h5">Contact us</Typography>
        <Typography>contact.deltaration@gmail.com</Typography>
      </Box>
    </MotionBlock>
  );

  return <ContentPageWrapper outerBoxElements={outerElements} innerBoxElements={innerElements} />;
};
