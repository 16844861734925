import { getAttackingPlayerId } from '../../utils/player';
import { CardNames } from '../../../../card/cardTypes';
import { last } from '../../../../../helpers/arrays';
import { isActionForAppliedOrInProgressCard } from '../../../utils/turnActions';
import { filterIgnoredDefendingActions } from '../../../rules/rules';
import { EssenceUpdateType } from '../../../gameContext';
import { useChangeEssenceCount } from '../useChangeEssenceCount';
import { UseApplyEssenceThiefEffect } from './useApplyEssenceThiefEffect.types';

export const useApplyEssenceThiefEffect: UseApplyEssenceThiefEffect = ({ gameState, addTurnAction }) => {
  const { changeEssenceCount } = useChangeEssenceCount({
    gameState: gameState,
    addTurnAction: addTurnAction,
  });

  const applyEssenceThiefEffectIfRelevant = () => {
    if (!isEssenceThiefEffectRelevant()) return;

    changeEssenceCount(getAttackingPlayerId(gameState), EssenceUpdateType.ADD_ONE);
  };

  const isEssenceThiefEffectRelevant = () =>
    isActionForAppliedOrInProgressCard(last(filterIgnoredDefendingActions(gameState.turnActions))?.action, [
      CardNames.ESSENCE_THIEF,
    ]);

  return {
    applyEssenceThiefEffectIfRelevant: applyEssenceThiefEffectIfRelevant,
  };
};
