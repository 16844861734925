import React from 'react';
import { WindowSize } from '../../helpers/utils';
import { SocketIoClient } from '../../socketIo/SocketClient';
import { PlayingCard } from '../../features/card/cardTypes';
import { CardTypeDetails, Rule } from '../../features/ruleBook';
import { Profile } from '../../features/auth/authTypes';

export type AppContextType = {
  isMobile: boolean;
  windowSize: WindowSize;
  socketClient: SocketIoClient;
  allPlayingCards: PlayingCard[];
  story: string[];
  rules: Rule[];
  cardDetails: CardTypeDetails[];
  profile: Profile | null;
  refreshProfile: () => void;
  logout: () => void;
  screenSizeModifier: number;
};

export const AppContext = React.createContext({} as AppContextType);
