import React from 'react';
import { Props } from './currentPlayerTray.types';
import { Box, Tooltip, Typography } from '@mui/material';
import { Card, SelectorType } from '../../../../components/card';
import { cardToComponent } from '../../../../helpers/transformers';
import { NORMAL_TO_EXTENDED_SIZE } from '../../../../helpers/sizes';
import { initSx } from './currentPlayerTray.styles';
import { Droppable } from '../../droppable';
import { ApplyRuleGroup } from '../../../card/cardTypes';
import { canPlayerManageArsenal } from '../../../game/rules/rules';
import { GameContext } from '../../../game/gameContext';
import { DragSource } from '../../../../components/draggable';
import { getPlayerBoxSxFromState, tooltipStyles } from '../../../../helpers/styles';
import { FinishActionButton } from '../../../../components/finishActionButton';
import { EssenceBar } from '../../../../components/essenceBar';
import { DisplayMode } from '../../player.types';
import { useCardDimension } from '../../../../components/card/hooks/useCardDimension';
import { roomItems, useTrackLocation } from '../../../room/hooks/useTrackLocation';
import { Name } from '../../../../components/name';
import { BlocksDirection, definePlayerStateByGameState } from '../../../../helpers/player';
import { useCardDrop } from '../hooks/useCardDrop';
import { handLabel } from '../../../../helpers/labels';
import { VerticalLabel } from '../../../../components/verticalLabel';
import { Arsenal } from '../../allPlayers/arsenal';
import { CardGroup } from '../../../../components/cardGroup';
import { NotificationTicker } from '../../../room/mobile/components/notificationTicker';

export const CurrentPlayerTray: React.FC<Props> = ({ player, allowedCardInHandCount, size, onCardShow }) => {
  const { id, cardsInHand, cardsOnTable, activeRadiances, roleCard, playerName, essences } = player;
  const { gameState, isDraggable, isAllowedToDrag } = React.useContext(GameContext);

  const { canDropToHand, canDropToArsenal, onDropToHand, onDropToArsenal } = useCardDrop({
    playerId: id,
    cardsOnTable: cardsOnTable,
  });

  const extendedSize = NORMAL_TO_EXTENDED_SIZE[size];

  const { dimension: cardDimension } = useCardDimension({ size: extendedSize });
  const sx = initSx(cardDimension);

  const nameComponent = <Typography sx={tooltipStyles}>{`Name: ${playerName}`}</Typography>;
  const role = (
    <Tooltip title={nameComponent} disableInteractive placement="right">
      <Box sx={sx.roleBox} onClick={() => onCardShow(roleCard)}>
        <Card {...cardToComponent(roleCard, extendedSize, undefined, undefined)} />
      </Box>
    </Tooltip>
  );

  const isDefendingNow = gameState.activeDefendingPlayer === id;
  const isActing = !gameState.isFinished && (gameState.activePlayer === id || isDefendingNow);

  const { ref: arsenalRef } = useTrackLocation({ items: [roomItems.playerArsenal(id)] });
  const arsenal = (
    <Box ref={arsenalRef} sx={sx.arsenal}>
      <Droppable
        playerId={id}
        applyRuleGroup={ApplyRuleGroup.ARSENAL}
        onItemDropped={onDropToArsenal}
        canDrop={canDropToArsenal}
      >
        <Arsenal
          playerId={id}
          size={extendedSize}
          cardsOnTable={cardsOnTable}
          blocksDirection={BlocksDirection.LEFT_TO_RIGHT}
          isDraggable={canPlayerManageArsenal(id, gameState.activePlayer, gameState.isFinished)}
          onCardClick={onCardShow}
          displayType={'standard'}
        />
      </Droppable>
    </Box>
  );

  const getHandSx = () => {
    const handSx = sx.hand;

    if (allowedCardInHandCount >= cardsInHand.length) {
      return handSx.withinLimit;
    }

    return handSx.overLimit;
  };

  const { ref: handRef } = useTrackLocation({ items: [roomItems.playerHand(id)] });
  const hand = (
    <Box ref={handRef} sx={{ ...getHandSx() }}>
      <Droppable playerId={id} applyRuleGroup={ApplyRuleGroup.ALL} onItemDropped={onDropToHand} canDrop={canDropToHand}>
        <Box sx={sx.hand.innerBox}>
          <Tooltip title={handLabel(cardsInHand.length, allowedCardInHandCount)} disableInteractive placement="top">
            <Box sx={sx.hand.labelBox}>
              <VerticalLabel
                name={'tray'}
                labelBlocks={[cardsInHand.length, '/', allowedCardInHandCount]}
                variant={allowedCardInHandCount < cardsInHand.length ? 'red' : 'default'}
              />
            </Box>
          </Tooltip>
          <CardGroup
            isActive={isAllowedToDrag(id) && isDraggable(id)}
            cards={cardsInHand}
            size={extendedSize}
            placeholdersCount={allowedCardInHandCount}
            onCardClick={onCardShow}
            sourceType={DragSource.PLAYER}
            owningPlayerId={id}
            selectorType={isActing ? SelectorType.MULTIPLE_ON_CTRL : undefined}
            styles={{ mainBox: { width: '97%' } }}
          />
        </Box>
      </Droppable>
    </Box>
  );

  const { ref: radiancesRef } = useTrackLocation({ items: [roomItems.playerRadiances(id)] });
  const radiancesComponent = (
    <Box ref={radiancesRef} sx={sx.radiances}>
      <CardGroup
        isActive={isAllowedToDrag(id) && isDraggable(id)}
        cards={activeRadiances}
        size={extendedSize}
        placeholdersCount={0}
        onCardClick={onCardShow}
        sourceType={DragSource.APPLIED_RADIANCE}
        owningPlayerId={id}
      />
    </Box>
  );

  const playerState = definePlayerStateByGameState(id, gameState);

  return (
    <Box sx={sx.mainBox}>
      <NotificationTicker variant={'desktopTray'} />
      <Box sx={sx.outerBox}>
        {radiancesComponent}
        <Box
          key={'current-player-animated-box' + id + playerState}
          sx={{
            ...getPlayerBoxSxFromState(playerState, DisplayMode.TRAY),
            ...sx.stateBox,
          }}
        />
        <Box key={'current-player-tray-inner-box-' + id} sx={sx.trayBox}>
          <Box sx={sx.mainActionBox}>
            <Box sx={sx.capsuleBox}>
              <EssenceBar playerId={id} count={essences} />
            </Box>
            <FinishActionButton displayMode={DisplayMode.TRAY} />
            <Box sx={sx.capsuleBox}>{<Name playerName={playerName} withTooltip tooltipPlacement={'left'} />}</Box>
          </Box>
          {role}
          {arsenal}
          {hand}
        </Box>
      </Box>
    </Box>
  );
};
