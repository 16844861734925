import axios from 'axios';
import { DELTARATION_API_PLAYER_MAIN_STATS_URL, DELTARATION_API_PLAYER_URL } from '../../constants/delarationApi';
import { parseProfile } from '../../helpers/player';
import { PlayerMainStatsData, UpdatePlayerRequest } from './playerTypes';
import { Profile } from '../auth/authTypes';

class PlayerApi {
  private withAuthorization(headers: object) {
    return { ...headers, Authorization: `Bearer ${parseProfile()?.appToken}` };
  }

  public async get(playerId: string): Promise<Profile> {
    const response = await axios.get(DELTARATION_API_PLAYER_URL(playerId), {
      headers: this.withAuthorization({
        'Content-Type': 'application/json',
      }),
    });

    return { playerId: response.data.id, playerName: response.data.name, appToken: parseProfile()?.appToken ?? '' };
  }

  public async update(playerId: string, updatePlayerRequest: UpdatePlayerRequest): Promise<Profile> {
    const response = await axios.put(DELTARATION_API_PLAYER_URL(playerId), updatePlayerRequest, {
      headers: this.withAuthorization({
        'Content-Type': 'application/json',
      }),
    });

    return { playerId: response.data.id, playerName: response.data.name, appToken: parseProfile()?.appToken ?? '' };
  }

  public async getMainStats(playerId: string): Promise<PlayerMainStatsData> {
    const response = await axios.get(DELTARATION_API_PLAYER_MAIN_STATS_URL(playerId), {
      headers: this.withAuthorization({
        'Content-Type': 'application/json',
      }),
    });

    return response.data;
  }
}

export default PlayerApi;
