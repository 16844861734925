import { PlayingCardDto } from './cardTypes';
import axios from 'axios';
import { DELTARATION_API_CARD_URL } from '../../constants/delarationApi';

class CardApi {
  private static instance: CardApi;

  public static getInstance(): CardApi {
    if (!CardApi.instance) {
      CardApi.instance = new CardApi();
    }

    return CardApi.instance;
  }

  public async getAllActiveCards(): Promise<PlayingCardDto[]> {
    const response = await axios.get(DELTARATION_API_CARD_URL, {
      headers: {
        'Content-Type': 'application/json',
      },
    });

    return response.data;
  }
}

export default CardApi.getInstance();
