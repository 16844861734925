import { ErrorEvent, UseErrorHandling } from './useErrorHandling.types';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import React from 'react';
import { ACCESS_DENIED_ERROR_ROUTE, UNEXPECTED_ERROR_ROUTE } from '../../../constants/routes';

export const useErrorHandling: UseErrorHandling = ({ logout }) => {
  const { enqueueSnackbar } = useSnackbar();

  const navigate = useNavigate();

  const onAccessDenied = () => {
    logout();
    navigate(ACCESS_DENIED_ERROR_ROUTE);
    const notificationMessage = 'Oops! There was a problem with your session. Try to sign in again';
    enqueueSnackbar(notificationMessage, { variant: 'error' });
  };

  const onUnexpectedError = () => {
    navigate(UNEXPECTED_ERROR_ROUTE);
    const notificationMessage = 'Oops! Something went wrong';
    enqueueSnackbar(notificationMessage, { variant: 'error' });
  };

  React.useEffect(() => {
    ErrorEvent.on('accessDenied', onAccessDenied);
    ErrorEvent.on('unexpectedError', onUnexpectedError);

    return () => {
      ErrorEvent.off('accessDenied', onAccessDenied);
      ErrorEvent.off('unexpectedError', onUnexpectedError);
    };
  }, []);

  return {
    logout: logout,
  };
};
