import { GameState } from '../useGameState';

export type UseDragAndDropGameStateData = {
  draggable?: string;
  droppableList: string[];
  dragger?: string;
};

export enum DroppableGroup {
  ALL,
  ALL_BUT_DRAGGABLE,
  NONE,
  DRAGGABLE,
}

export type EnableDragAndDropFunc = (
  draggablePlayerId: string,
  droppableGroup: DroppableGroup,
  dragger: string
) => void;

export type UseDragAndDropResult = {
  isDraggable: (playerId: string) => boolean;
  isDroppable: (playerId: string) => boolean;
  isAllowedToDrag: (playerId: string) => boolean;
  enableDragAndDrop: EnableDragAndDropFunc;
  isDragging: boolean;
  setIsDragging: (isDragging: boolean) => void;
};

export type UseDragAndDropProps = {
  gameState: GameState;
};

export type UseDragAndDrop = (props: UseDragAndDropProps) => UseDragAndDropResult;
