export const isFlagSet = (flag: string) => localStorage.getItem(flag) !== null;
export const isFlagOn = (flag: string) => localStorage.getItem(flag) === true.toString();
export const setFlag = (flag: string, isOn: boolean) => localStorage.setItem(flag, String(isOn));
export const setValue = (flag: string, value: string) => localStorage.setItem(flag, value);
export const getValue = (flag: string) => localStorage.getItem(flag);

export const getNumberValue = (key: string): number | null => {
  try {
    const cachedValued = getValue(key);
    return cachedValued ? parseInt(cachedValued) : null;
  } catch (e) {
    localStorage.removeItem(key);
    return null;
  }
};
