import React from 'react';
import { Box, SwipeableDrawer, Typography } from '@mui/material';
import { sx } from './actionDrawer.styles';
import { ExpandMore } from '@mui/icons-material';
import Button from '@mui/material/Button';
import { ActionDrawerContext } from '../../contexts/actionDrawerContext/actionDrawerContext';
import { LOG_JOURNAL_NAME, LogContext } from '../../../../game/logContextProvider';

export const ActionDrawer: React.FC = () => {
  const { name, setName, content, setContent } = React.useContext(ActionDrawerContext);
  const { setIsLogJournalOpen } = React.useContext(LogContext);

  const closeDrawer = () => {
    if (name === LOG_JOURNAL_NAME) {
      setIsLogJournalOpen(false);
    }

    setName('');
    setContent(undefined);
  };

  const isOpen = name !== '' && content !== undefined;
  return (
    <div>
      <React.Fragment key="action-drawer">
        <SwipeableDrawer
          variant="temporary"
          anchor="bottom"
          open={isOpen}
          onClose={closeDrawer}
          onOpen={() => undefined}
          PaperProps={{ sx: sx.drawer }}
          sx={sx.drawerBox}
        >
          <Button onClick={closeDrawer} sx={sx.nameBox}>
            <Typography id="action-drawer-title" variant="h5" component="h2" sx={sx.name}>
              {name}
            </Typography>
            <ExpandMore />
          </Button>
          <Box sx={sx.contentBox}>{content}</Box>
        </SwipeableDrawer>
      </React.Fragment>
    </div>
  );
};
