import React from 'react';
import { Props } from './dropdownMenu.types';
import { Divider, Menu, MenuItem } from '@mui/material';
import { withFunc } from '../../helpers/utils';
import { sx } from './dropdownMenu.styles';

export const DropdownMenu: React.FC<Props> = ({
  openingButtonId,
  isOpen,
  element,
  items,
  onClose,
  useDivider = false,
}) => {
  const menuId = `${openingButtonId}-menu`;
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl) && isOpen;

  React.useEffect(() => setAnchorEl(element), [element]);

  const handleClose = () => {
    setAnchorEl(null);
    onClose();
  };

  const menuItems = items.map((item, key) => {
    const itemKey = `${menuId}-${key}`;
    const divider = useDivider && key > 0 ? <Divider variant="middle" /> : undefined;
    switch (item.type) {
      case 'data':
        return [
          divider,
          <MenuItem key={itemKey} onClick={withFunc(item.action, onClose)} sx={sx.dataMenuItem}>
            {item.name}
          </MenuItem>,
        ];
      case 'element':
        return [
          divider,
          <MenuItem key={itemKey} onClick={onClose} sx={sx.elementMenuItem}>
            {item.element}
          </MenuItem>,
        ];
      default:
        throw Error(`Undefined type provided in menu ${menuId}`);
    }
  });

  return (
    <Menu
      id={menuId}
      anchorEl={anchorEl}
      open={open}
      onClose={handleClose}
      MenuListProps={{
        'aria-labelledby': openingButtonId,
      }}
    >
      {menuItems}
    </Menu>
  );
};
