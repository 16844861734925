import { UseCardSelection } from './useCardSelection.types';
import React from 'react';
import { SelectorType } from '../../card.types';

export const useCardSelection: UseCardSelection = ({ dragProps, selectorProps }) => {
  const [isCardSelected, setIsCardSelected] = React.useState(selectorProps !== undefined && selectorProps.isSelected);

  React.useEffect(() => {
    setIsCardSelected(selectorProps !== undefined && selectorProps.isSelected);
  }, [selectorProps]);

  const handleCardClick = (event: React.MouseEvent) => {
    if (dragProps === undefined || selectorProps === undefined) return;

    if (selectorProps.type === SelectorType.SINGLE) {
      setIsCardSelected(true);
      dragProps.clearAllAndAddToDragged();
      return;
    }

    if (event.ctrlKey || selectorProps.type === SelectorType.MULTIPLE_ON_CLICK) {
      if (isCardSelected) {
        dragProps.removeFromDragged();
        setIsCardSelected(false);
        return;
      }

      setIsCardSelected(true);
      dragProps.addToDragged();
      return;
    }

    setIsCardSelected(true);
    dragProps.clearAllAndAddToDragged();
  };

  return {
    handleCardClick: handleCardClick,
    isCardSelected: isCardSelected,
  };
};
