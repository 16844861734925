import React from 'react';
import {
  LocationTrackerContext,
  LocationTrackerContextType,
  Props,
  Location,
} from './locationTrackerContextProvider.types';

export const LocationTrackerContextProvider: React.FC<Props> = ({ children }) => {
  const [itemLocationMap, setItemLocationMap] = React.useState(new Map<string, React.RefObject<HTMLDivElement>>());

  const getCenterLocation = (rect: DOMRect): Location => ({
    x: rect.left + rect.width / 2,
    y: rect.top + rect.height / 2,
  });

  const getItemLocation = (item: string): Location | undefined => {
    const itemRef = itemLocationMap.get(item);
    if (!itemRef?.current) return;
    const rect = itemRef.current.getBoundingClientRect();

    return getCenterLocation(rect);
  };

  const setItem = (item: string, ref: React.RefObject<HTMLDivElement>) => {
    setItemLocationMap((prevMap) => {
      const newMap = new Map(prevMap);
      newMap.set(item, ref);
      return newMap;
    });
  };

  const initializeContext = (): LocationTrackerContextType => ({
    getItemLocation: getItemLocation,
    setItem: setItem,
  });

  return <LocationTrackerContext.Provider value={initializeContext()}>{children}</LocationTrackerContext.Provider>;
};
