import { FinishedGameApiData, GamePlayersMainStatsData } from '../../../../features/stats/statsTypes';

export type MainGameStatsData = {
  gamesPlayed: number;
  mostWinsRole: string;
  popularPlayersNumber: number;
  averageGameDuration: string;
};

export type UseGameStatsResult = {
  fillGamesData: () => void;
  mainStatsData: MainGameStatsData;
  lastFinishedGames: FinishedGameApiData[];
  playersStats: GamePlayersMainStatsData[];
};

export type UseGameStats = () => UseGameStatsResult;

export const LAST_FINISHED_GAME_MAIN_STATS_LIMIT = 5;
export const TOP_PLAYERS_MAIN_STATS_LIMIT = 5;
export const NOT_AVAILABLE_VALUE = 'N/A';

export const DefaultMainGameStatsData: MainGameStatsData = {
  gamesPlayed: 0,
  mostWinsRole: NOT_AVAILABLE_VALUE,
  popularPlayersNumber: 0,
  averageGameDuration: NOT_AVAILABLE_VALUE,
};
