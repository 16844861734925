import { GAME_ACTION_DEFAULT_ELEMENT_WIDTH } from '../../../constants/components';

export const HEADER_HEIGHT = 44;
export const TOOLBAR_HEIGHT = 48;
export const sx = {
  toolbar: { display: 'flex', justifyContent: 'space-between', minHeight: `${TOOLBAR_HEIGHT}px` },
  logoBox: {
    display: { xs: 'flex', sm: 'block' },
    flexGrow: { xs: 1, sm: 1 },
    height: '90%',
  },
  logo: {
    height: '90%',
    p: 0.2,
  },
  beta: {
    display: { xs: 'flex', sm: 'block' },
    transform: 'rotate(45deg)',
    transformOrigin: { xs: '20% 5%', sm: '15% 5%' },
    flexGrow: { xs: 1, sm: 1 },
    ml: { xs: 1, sm: 0 },
  },
  innerBox: { display: 'flex', alignItems: 'center', height: '100%', cursor: 'pointer' },
  outerBox: { flexGrow: 1, display: 'flex', height: `${HEADER_HEIGHT}px`, position: 'relative', zIndex: 1 },
  logoText: {
    ml: 2,
    mr: 2,
    display: { xs: 'none', sm: 'flex' },
    fontFamily: 'monospace',
    fontWeight: 700,
    letterSpacing: '.3rem',
    color: 'inherit',
    textDecoration: 'none',
    flexGrow: 1,
    fontSize: { sm: '0.7rem', md: '0.8rem' },
  },
  button: {
    pl: { md: 5 },
    pr: { md: 5 },
    mb: 0.5,
    maxHeight: '45px',
    fontSize: { xs: '0.6rem', lg: '0.8rem' },
  },
  mainBox: { width: GAME_ACTION_DEFAULT_ELEMENT_WIDTH, m: 'auto' },
};
