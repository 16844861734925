import React from 'react';
import { UseStateWithDelayType } from './useStateWithDelay.types';

export const useStateWithDelay: UseStateWithDelayType = <S>(initialState: S | (() => S)) => {
  const [state, setState] = React.useState(initialState);

  const delayedSetState = (newState: S, delay: number) => {
    setTimeout(() => {
      setState(newState);
    }, delay);
  };

  return [state, delayedSetState];
};
