import React from 'react';
import { Props } from './handExpanded.types';
import { Box, SxProps } from '@mui/material';
import { Label } from '../../../../../../components/label';
import { useCurrentSize } from '../../../../../card/hooks/useCurrentSize';
import { NORMAL_TO_EXTENDED_SIZE } from '../../../../../../helpers/sizes';
import { GameContext } from '../../../../../game/gameContext';
import { getAllowedCardsInHandCount } from '../../../../../game/rules/rules';
import { handLabel } from '../../../../../../helpers/labels';
import { DragSource } from '../../../../../../components/draggable';
import { HandType, useResolveHand } from '../../hooks/useResolveHand';
import { sx } from './handExpanded.styles';
import { AnimatePresence, motion } from 'framer-motion';
import { heightChangingVariants } from '../../../../../../constants/animation';
import { useCardDimension } from '../../../../../../components/card/hooks/useCardDimension';
import { animationCollapse, animationExpand } from '../../../../../../helpers/styles';
import { CardGroup } from '../../../../../../components/cardGroup';
import { getCurrentPlayer } from '../../../../../game/gameContextHandler/utils/player';

export const HandExpanded: React.FC<Props> = ({ player, sx: extraSx = {}, isShown }) => {
  const { gameState, isDragging, isDraggable, isAllowedToDrag } = React.useContext(GameContext);
  const { handType } = useResolveHand({ playerId: player.id });
  const size = useCurrentSize();
  const extendedSize = NORMAL_TO_EXTENDED_SIZE[size];
  const { dimension } = useCardDimension({ size: extendedSize });

  const allowedCardsInHand = getAllowedCardsInHandCount(player, gameState.playersOrdered);
  const cardsCount = player.cardsInHand.length;
  const label = handLabel(cardsCount, allowedCardsInHand, false);

  const isDisplayed = isShown && handType === HandType.INTERACTIVE;

  const outerBoxProps: SxProps = { ...extraSx, ...sx.outerBox };

  const height = dimension * 1.4;

  const outerBoxSx = isDragging
    ? { ...outerBoxProps, ...animationCollapse(height) }
    : { ...outerBoxProps, ...animationExpand(height) };

  return (
    <>
      <AnimatePresence>
        {isDisplayed && (
          <Box sx={outerBoxSx}>
            <motion.div exit="hidden" initial="hidden" animate="visible" variants={heightChangingVariants}>
              <Box sx={sx.innerBox}>
                <Label label={label} />
                <CardGroup
                  isActive={isAllowedToDrag(getCurrentPlayer(gameState).id) && isDraggable(player.id)}
                  cards={player.cardsInHand}
                  size={extendedSize}
                  placeholdersCount={0}
                  onCardClick={() => undefined}
                  sourceType={DragSource.PLAYER}
                  owningPlayerId={player.id}
                  cardsHaveActiveAnimation={true}
                />
              </Box>
            </motion.div>
          </Box>
        )}
      </AnimatePresence>
    </>
  );
};
