import React, { type JSX } from 'react';

export type Wrapper = React.ComponentType<React.PropsWithChildren<any>>;
export type WrapperWithProps = [Wrapper, Record<string, any>];

export const wrap = (rootComponent: React.ReactElement, wrappers: WrapperWithProps[]): JSX.Element => {
  let output: JSX.Element | null = rootComponent;

  [...wrappers].reverse().forEach((component) => {
    const [Component, props] = component;
    const { key, ...otherProps } = props;
    output = (
      <Component key={key} {...otherProps}>
        {output}
      </Component>
    );
  });

  return output;
};
