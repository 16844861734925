import { Position } from './radiances.types';
import { BASE_MOBILE_PLAYER_CARD_SX } from '../../../../../constants/styles';

export const sx = {
  cardWrapper: (position: Position) => ({
    ...BASE_MOBILE_PLAYER_CARD_SX,
    [position]: '-30%',
    bottom: '30%',
  }),
};
