import { UsePlaySound } from './usePlaySound.types';
import { SoundPath } from '../../../assets/assets';

export const usePlaySound: UsePlaySound = () => {
  const play = (soundVolume: number, soundPath: SoundPath) => {
    const audio = new Audio(soundPath as string);
    audio.volume = soundVolume / 100;

    audio.play().catch(() => {
      audio.pause();
      audio.currentTime = 0;
    });

    return () => {
      audio.pause();
      audio.currentTime = 0;
    };
  };

  return {
    play: play,
  };
};
