import { UseTeleportEffect } from './useTeleportEffect.types';
import React from 'react';
import { GameContext } from '../../../../game/gameContext';
import { last } from '../../../../../helpers/arrays';
import { filterIgnoredDefendingActions } from '../../../../game/rules/rules';
import {
  addTurnActionWithStateUpdateIfNeeded,
  toAppliedAction,
  toImpactedTurnAction,
  toInProgressAction,
} from '../../../../game/utils/turnActions';
import { CardNames } from '../../../cardTypes';
import { getCurrentPlayer, isPlayerOutOfCards } from '../../../../game/gameContextHandler/utils/player';
import { Log, LogContext } from '../../../../game/logContextProvider';

export const useTeleportEffect: UseTeleportEffect = () => {
  const { gameState, addTurnAction, withUpdatingState, performFinishAndEnableTeleport, performFinishDefending } =
    React.useContext(GameContext);
  const { createLog } = React.useContext(LogContext);

  return () => {
    const currentPlayer = getCurrentPlayer(gameState);
    const lastTurnAction = last(filterIgnoredDefendingActions(gameState.turnActions));

    if (
      currentPlayer.id !== gameState.activeDefendingPlayer ||
      lastTurnAction?.action !== toAppliedAction(CardNames.TELEPORT)
    ) {
      return;
    }

    if (isPlayerOutOfCards(gameState.allPlayers, gameState.activeDefendingPlayer)) {
      const log: Log = { type: 'info', text: `${currentPlayer.playerName}: has nothing to teleport` };
      addTurnAction({ ...toImpactedTurnAction(gameState, CardNames.TELEPORT), logs: [log] });
      createLog(log);
      performFinishDefending('successfulDefending');
      return;
    }

    if (gameState.activePlayer === gameState.activeDefendingPlayer) {
      addTurnAction({ ...lastTurnAction, action: toInProgressAction(CardNames.TELEPORT) });
      performFinishAndEnableTeleport();
      return;
    }

    addTurnActionWithStateUpdateIfNeeded(
      currentPlayer,
      toInProgressAction(CardNames.TELEPORT),
      withUpdatingState,
      addTurnAction
    );
  };
};
