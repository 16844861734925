import React from 'react';
import { ActionType, ManageGameModal } from '../../manageGameModal';
import { UseGameModal } from './useGameModal.types';
import { CreateGameData, JoinGameData } from '../../../game/gameTypes';
import { AppContext } from '../../../../global/context/appContext';
import { ApiContext } from '../../../../global/app/api/apiContext';
import { PROFILE_STORAGE_KEY } from '../../../../constants/login';
import { logResponseErr } from '../../../../helpers/utils';
import { useSnackbar } from 'notistack';
import { useGameApi } from '../../../game/gameApi/useGameApi';
import { useDropdownMenu } from '../../../../helpers/hooks/useDropdownMenu';
import { GoogleButton } from '../../googleButton';
import { DiscordLoginButton } from '../../discordButtonButton';
import { MouseEvent } from '../../../game/playNowButtons';

export const useGameModal: UseGameModal = () => {
  const { profile, refreshProfile } = React.useContext(AppContext);
  const { playerApi } = React.useContext(ApiContext);
  const { enqueueSnackbar } = useSnackbar();
  const { createGame, joinGame } = useGameApi();
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [selectedActionType, setSelectedActionType] = React.useState<ActionType>('Create');
  const [gameId, setGameId] = React.useState<string>();
  const [openAfterLogin, setOpenAfterLogin] = React.useState<ActionType>();

  const loginButtonId = 'login-button';
  const { menu: loginMenu, openMenu: openLoginMenu } = useDropdownMenu({
    openingButtonId: loginButtonId,
    items: [
      { type: 'element', element: <GoogleButton /> },
      { type: 'element', element: <DiscordLoginButton /> },
    ],
  });

  React.useEffect(() => {
    if (!profile || !openAfterLogin) return;

    openModal(openAfterLogin, gameId);
    setOpenAfterLogin(undefined);
  }, [profile, openAfterLogin]);

  const loginAndAction = (type: ActionType, event?: MouseEvent, forGameId?: string) => {
    setOpenAfterLogin(type);
    setGameId(forGameId);

    if (event) openLoginMenu(event);
  };

  const openModalOrPromptLogin = (type: ActionType, event?: MouseEvent, forGameId?: string) =>
    profile ? openModal(type, forGameId) : loginAndAction(type, event, forGameId);

  const openModal = (action: ActionType, forGameId?: string) => {
    setSelectedActionType(action);
    setIsModalOpen(true);
    setGameId(forGameId);
  };

  const updatePlayerProfileIfNeeded = async () => {
    if (profile?.playerName !== undefined) return;

    const playerFullProfile = await playerApi.get(profile!.playerId);
    localStorage.setItem(PROFILE_STORAGE_KEY, JSON.stringify(playerFullProfile));
    refreshProfile();
  };

  const onCreateGameApprove = async (createGameData: CreateGameData) => {
    try {
      await updatePlayerProfileIfNeeded();
      createGame(createGameData);
    } catch (e: any) {
      logResponseErr(e);
      enqueueSnackbar(`Failed to create game: ${e.response?.data?.message ?? 'technical error'}`, {
        variant: 'error',
      });
    } finally {
      setIsModalOpen(false);
    }
  };

  const onJoinGameApprove = async (joinGameData: JoinGameData) => {
    try {
      await updatePlayerProfileIfNeeded();
      joinGame(joinGameData);
    } catch (e: any) {
      logResponseErr(e);
      enqueueSnackbar(`Failed to join game: ${e.response?.data?.message ?? 'technical error'}`, {
        variant: 'error',
      });
    } finally {
      setIsModalOpen(false);
    }
  };

  const manageGameModal = () => {
    return (
      <>
        <ManageGameModal
          actionType={selectedActionType}
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          onCreateGameApprove={onCreateGameApprove}
          onJoinGameApprove={onJoinGameApprove}
          gameId={gameId}
        />
        {loginMenu}
      </>
    );
  };

  return {
    openModal: openModalOrPromptLogin,
    modal: manageGameModal,
  };
};
