import React, { useState } from 'react';
import { Props, Video } from './onboardingVideos.types';
import { Box, CardMedia, IconButton, Typography } from '@mui/material';
import { sx } from './onboardingVideos.styles';
import ArrowBackIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIcon from '@mui/icons-material/ArrowForwardIos';
import { VideoPath } from '../../assets/assets';

export const OnboardingVideos: React.FC<Props> = () => {
  const [currentVideoIndex, setCurrentVideoIndex] = useState(0);
  const videos: Video[] = [
    { title: 'Movable elements in the room', path: VideoPath.draggingDecksOnboarding },
    { title: 'Playing cards', path: VideoPath.moveCardsOnboarding },
    { title: 'Rulebook', path: VideoPath.ruleBookOnboarding },
    { title: 'Game logs', path: VideoPath.gameLogOnboarding },
    { title: 'Sounds', path: VideoPath.soundsOnboarding },
  ];

  const handleNext = () => {
    if (currentVideoIndex === videos.length - 1) {
      return;
    }
    setCurrentVideoIndex(currentVideoIndex + 1);
  };

  const handlePrevious = () => {
    if (currentVideoIndex === 0) return;
    setCurrentVideoIndex(currentVideoIndex - 1);
  };

  return (
    <>
      <Typography id="onboarding-video-title" variant="h5" component="h2" textAlign={'center'}>
        {videos[currentVideoIndex].title}
      </Typography>
      <Box sx={sx.videoBox}>
        <IconButton onClick={handlePrevious} disabled={currentVideoIndex === 0}>
          <ArrowBackIcon />
        </IconButton>
        {videos.map((video, index) => (
          <CardMedia
            key={index}
            component="video"
            src={video.path}
            title={video.title}
            controls
            loop
            muted
            autoPlay
            sx={{ ...sx.video, display: index === currentVideoIndex ? 'block' : 'none' }}
          />
        ))}
        <IconButton onClick={handleNext} disabled={currentVideoIndex === videos.length - 1}>
          <ArrowForwardIcon />
        </IconButton>
      </Box>
      <Box sx={sx.dotsBox}>
        {videos.map((_, index) => (
          <Box
            key={index}
            sx={index === currentVideoIndex ? sx.activeDot : sx.dot}
            onClick={() => setCurrentVideoIndex(index)}
          >
            {' '}
          </Box>
        ))}
      </Box>
    </>
  );
};
