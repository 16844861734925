import React, { useMemo } from 'react';
import { Props } from './apiContextProvider.types';
import { ApiContext, ApiContextType } from './apiContext';
import GameApi from '../../../features/game/gameApi/gameApi';
import StatsApi from '../../../features/stats/statsApi';
import PlayerApi from '../../../features/player/playerApi';
import PredictionApi from '../../../features/prediction/predictionApi';

export const ApiContextProvider: React.FC<Props> = ({ children }) => {
  const gameApi = useMemo(() => new GameApi(), []);
  const statsApi = useMemo(() => new StatsApi(), []);
  const playerApi = useMemo(() => new PlayerApi(), []);
  const predictionApi = useMemo(() => new PredictionApi(), []);

  const initializeContext = (): ApiContextType => ({
    gameApi: gameApi,
    statsApi: statsApi,
    playerApi: playerApi,
    predictionApi: predictionApi,
  });

  return <ApiContext.Provider value={initializeContext()}>{children}</ApiContext.Provider>;
};
