import React from 'react';
import {
  ANIMATION_SECONDS,
  ANIMATION_SIZE_MODIFIER,
  DECK_HALF_CARDS_COUNT,
  DECK_SHORT_CARDS_COUNT,
  defaultTablePosition,
  DRAW_DECK_EMPTY_OPACITY,
  DRAW_DECK_HAS_CARDS_OPACITY,
  Props,
} from './drawDeck.types';
import { Box, CardMedia, CircularProgress } from '@mui/material';
import { isEmpty } from '../../helpers/arrays';
import { ImagePath } from '../../assets/assets';
import { GameContext } from '../../features/game/gameContext';
import { canPlayerExchangeEssenceForCards, isDrawDeckActive } from '../../features/game/rules/rules';
import { EssenceLossOnCardDrawModal } from '../essenceLossOnCardDrawModal';
import { initSx } from './drawDeck.styles';
import { AppContext } from '../../global/context/appContext';
import { DraggableTableElement } from '../draggableTableElement';
import { SxStyles } from '../../helpers/styles';
import { useCardDimension } from '../card/hooks/useCardDimension';
import { NORMAL_TO_EXTENDED_SIZE } from '../../helpers/sizes';
import { roomItems, useTrackLocation } from '../../features/room/hooks/useTrackLocation';
import { motion } from 'framer-motion';
import { useDrawCardFromDeck } from '../../features/game/actions/hooks/useDrawCardAction';
import { isMobile } from 'react-device-detect';

export const DrawDeck: React.FC<Props> = ({ size }) => {
  const { gameState } = React.useContext(GameContext);
  const { drawCardFromDeck, addTwoCardsToDrawAmount } = useDrawCardFromDeck();
  const { windowSize } = React.useContext(AppContext);

  const [opacity, setOpacity] = React.useState(DRAW_DECK_HAS_CARDS_OPACITY);
  const [deckImage, setDeckImage] = React.useState(ImagePath.drawDeckFull);
  const [isCardDrawAllowed, setIsCardDrawAllowed] = React.useState(false);
  const [isDeckStateRelevant, setIsDeckStateRelevant] = React.useState(false);
  const [confirmEssenceLossModalOpen, setConfirmEssenceLossModalOpen] = React.useState(false);
  const { ref: drawDeckRef } = useTrackLocation({ items: [roomItems.drawDeck] });
  const [animate, setAnimate] = React.useState(false);

  React.useEffect(() => {
    const isDeckActive = isDrawDeckActive(gameState);
    setIsCardDrawAllowed(isDeckActive);
    updateDeckImage(gameState.cardsInDeck.length, isDeckActive, canPlayerExchangeEssenceForCards(gameState));
    setIsDeckStateRelevant(true);
    setOpacity(isEmpty(gameState.cardsInDeck) ? DRAW_DECK_EMPTY_OPACITY : DRAW_DECK_HAS_CARDS_OPACITY);
  }, [gameState, gameState.cardsInDeck]);

  const updateDeckImage = (cardInDeck: number, isDeckActive: boolean, canExchangeEssence: boolean) => {
    if (!isDeckActive && canExchangeEssence) {
      if (cardInDeck <= DECK_SHORT_CARDS_COUNT) {
        return setDeckImage(ImagePath.drawDeckShortBlocked);
      }
      if (cardInDeck <= DECK_HALF_CARDS_COUNT) {
        return setDeckImage(ImagePath.drawDeckHalfBlocked);
      }
      return setDeckImage(ImagePath.drawDeckFullBlocked);
    }

    if (cardInDeck <= DECK_SHORT_CARDS_COUNT) {
      return setDeckImage(ImagePath.drawDeckShort);
    }
    if (cardInDeck <= DECK_HALF_CARDS_COUNT) {
      return setDeckImage(ImagePath.drawDeckHalf);
    }
    setDeckImage(ImagePath.drawDeckFull);
  };

  const { dimension: cardDimension } = useCardDimension({ size: NORMAL_TO_EXTENDED_SIZE[size] });
  const sx = initSx(cardDimension, isDeckStateRelevant, isMobile);

  const onDrawCardAction = () => {
    if (!isDeckStateRelevant || isEmpty(gameState.cardsInDeck)) {
      return;
    }
    if (!isCardDrawAllowed) {
      if (canPlayerExchangeEssenceForCards(gameState)) {
        setConfirmEssenceLossModalOpen(true);
        return;
      }
      return;
    }
    setIsDeckStateRelevant(false);
    setAnimate(true);
    drawCardFromDeck();
  };

  const closeEssenceLossModal = () => {
    setConfirmEssenceLossModalOpen(false);
  };

  const confirmEssenceLoss = () => {
    setIsDeckStateRelevant(false);
    setConfirmEssenceLossModalOpen(false);
    addTwoCardsToDrawAmount();
  };

  const isDrawDeckActionAvailable = isCardDrawAllowed || canPlayerExchangeEssenceForCards(gameState);

  const drawDeckBlock = (
    <Box ref={drawDeckRef} sx={isDrawDeckActionAvailable ? sx.highlightedBox : sx.defaultBox}>
      {!isDeckStateRelevant && <CircularProgress size={cardDimension * 0.66} thickness={3} sx={sx.loading} />}
      <Box sx={sx.defaultImageSx}>
        <motion.div
          animate={{ scale: animate ? ANIMATION_SIZE_MODIFIER : 1 }}
          transition={{ duration: ANIMATION_SECONDS }}
          onAnimationComplete={() => setAnimate(false)}
        >
          <EssenceLossOnCardDrawModal
            isOpen={confirmEssenceLossModalOpen}
            onClose={closeEssenceLossModal}
            onConfirm={confirmEssenceLoss}
          />
          <CardMedia
            component="img"
            alt="card-back-cover"
            image={deckImage}
            draggable={false}
            sx={{ ...sx.imageCardSx, opacity: opacity }}
          />
          <Box
            sx={isDrawDeckActionAvailable && isDeckStateRelevant ? sx.activeDeckAnimationSx : undefined}
            onClick={onDrawCardAction}
          ></Box>
        </motion.div>
      </Box>
    </Box>
  );

  return isMobile ? (
    drawDeckBlock
  ) : (
    <DraggableTableElement
      outerBoxStyles={sx.outerBox as SxStyles}
      name={'drawDeck'}
      position={defaultTablePosition(windowSize)}
      size={{ width: sx.defaultImageSx.width, height: sx.defaultImageSx.height }}
    >
      {drawDeckBlock}
    </DraggableTableElement>
  );
};
