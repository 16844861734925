import { ProjectColors } from '../../../../../themes/mainTheme';
import { CAPSULE_OUTER_COMMON_SX, CAPSULE_INNER_COMMON_SX } from '../../../../../constants/styles';
import { capsuleFontStyle } from '../../../../../helpers/styles';

const capsuleSectionCommonSx = {
  ...CAPSULE_OUTER_COMMON_SX,
  cursor: 'pointer',
  mx: '0.3rem',
  px: '0.5rem',
  fontSize: '1rem',
};

export const initSx = (extraNarrowScreen: boolean) => {
  return {
    capsuleSectionActive: {
      ...capsuleSectionCommonSx,
      background: `linear-gradient(90deg, transparent, ${ProjectColors.BLUE_PROBABILITY}, ${ProjectColors.BLUE_PROBABILITY}, transparent)`,
      color: ProjectColors.WHITE,
    },
    capsuleSectionInactive: {
      ...capsuleSectionCommonSx,
      background: `transparent`,
      color: ProjectColors.GREY,
    },
    label: {
      textAlign: 'center',
      mt: '0.75rem',
      mb: '0.5rem',
      fontSize: '0.75rem',
    },
    outerBlock: {
      display: 'flex',
      justifyContent: 'space-evenly',
      mt: '1.25rem',
    },
    capsuleOuterBlock: {
      display: 'flex',
      justifyContent: 'center',
      width: `${extraNarrowScreen ? '220px' : '300px'}`,
    },
    capsule: {
      ...CAPSULE_INNER_COMMON_SX,
      fontSize: capsuleFontStyle,
    },
    changeNumberOfPlayersButton: {
      backgroundColor: `${ProjectColors.BLUE_TRANSPARENT}`,
    },
  };
};
