import React from 'react';
import { Props } from './helpProjectWidget.types';
import { Box, CardMedia, IconButton, Tooltip } from '@mui/material';
import { ImagePath } from '../../../../assets/assets';
import { useDropdownMenu } from '../../../../helpers/hooks/useDropdownMenu';
import { PayPalButton } from './payPalButton';
import { BuyMeCoffeeButton } from './buyMeCoffeeButton';
import { sx } from './helpProjectWidget.styles';

export const HelpProjectWidget: React.FC<Props> = () => {
  const helpProjectButtonId = 'open-help-project_button';
  const { menu, openMenu } = useDropdownMenu({
    openingButtonId: helpProjectButtonId,
    items: [
      { type: 'element', element: <BuyMeCoffeeButton /> },
      { type: 'element', element: <PayPalButton /> },
    ],
  });

  const tooltipText = (
    <React.Fragment>
      Ignite our interstellar journey with ☕️.
      <br />
      <br />
      Your cosmic generosity ✨ empowers us. Together, we forge a legendary path among the stars. Gratitude 🙏 awaits
      your boundless support. Join us as we embark on this extraordinary adventure!
    </React.Fragment>
  );

  return (
    <React.Fragment>
      <Tooltip title={tooltipText} placement={'bottom-end'}>
        <Box>
          <IconButton id={helpProjectButtonId} onClick={openMenu} sx={sx.button}>
            <CardMedia component="img" alt="help-ukraine-widget" image={ImagePath.helpProject} sx={sx.logo} />
          </IconButton>
        </Box>
      </Tooltip>
      {menu}
    </React.Fragment>
  );
};
