import { ProjectColors } from '../../themes/mainTheme';

export const sxInit = (minWidth: string, color: string | undefined) => ({
  minWidth: minWidth,
  '& .MuiInputBase-input': { height: 'auto' },
  '& .MuiOutlinedInput-root': {
    '&.Mui-focused fieldset': {
      borderColor: color,
    },
  },
  '& label.Mui-focused': {
    color: color,
  },
  '& label': {
    color: ProjectColors.MAIN,
  },
});
