import { Position } from './arsenal.types';
import { BASE_MOBILE_PLAYER_CARD_SX } from '../../../../../constants/styles';

export const sx = {
  cardWrapper: (position: Position) => ({
    ...BASE_MOBILE_PLAYER_CARD_SX,
    [position.x]: '-15%',
    [position.y]: '-15%',
  }),
};
