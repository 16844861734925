import React from 'react';
import { isEmpty, penultimate } from '../../../../../helpers/arrays';
import { ignoreProvidedActionTypes } from '../../../../game/utils/turnActions';
import { AnimationContext } from '../../../../game/animationContextProvider';
import { SECOND_IN_MILLISECONDS } from '../../../../../constants/time';
import { TurnAction } from '../../../../game/gameTypes';
import { UseAnimateDiscardCards } from './useAnimateLostCardsUponRemoval.types';
import { useMovingCardsPropsTransformers } from '../../allPlayers/useMovingCardsPropsTransformers';

export const useAnimateLostCardsUponRemoval: UseAnimateDiscardCards = ({ gameState }) => {
  const { queueMovingCardsProps } = React.useContext(AnimationContext);

  const { lostCardsCurrentPlayerActionToMovingCards } = useMovingCardsPropsTransformers({ gameState });

  const canAnimate = (currentPlayerId: string, lastTurnAction: TurnAction) => {
    return 'lost cards upon removal' === lastTurnAction.action && currentPlayerId === lastTurnAction.appliedTo;
  };

  const animate = (currentPlayerId: string, turnActions: TurnAction[]) => {
    const filteredTurnActions = ignoreProvidedActionTypes(turnActions, [
      'probability capsule randomized',
      'create log',
    ]);
    if (isEmpty(filteredTurnActions)) return;

    const turnAction = penultimate(filteredTurnActions);
    if (!turnAction || !canAnimate(currentPlayerId, turnAction)) return;

    const movingCardsPropsList = lostCardsCurrentPlayerActionToMovingCards(turnAction, currentPlayerId);
    if (isEmpty(movingCardsPropsList)) return;

    //TODO remove setTimeout after animation is not effected by re-renders
    setTimeout(() => queueMovingCardsProps(movingCardsPropsList), SECOND_IN_MILLISECONDS * 0.5);
  };

  return {
    animate: animate,
  };
};
