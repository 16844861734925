import { getAttackingPlayerId, getCurrentPlayer } from '../../features/game/gameContextHandler/utils/player';
import { GameState } from '../../features/game/gameContextHandler/hooks/useGameState';
import { getAllowedCardsInHandCount } from '../../features/game/rules/rules';
import { DisplayMode } from '../../features/player/player.types';
import { isEmpty } from '../../helpers/arrays';

export type Props = {
  displayMode: DisplayMode;
};

export interface FinishAction {
  isButtonEnabled: () => boolean;
  finishAction: () => void;
  buttonName: () => string;
  buttonTooltip: () => string;
}

export class FinishTurn implements FinishAction {
  isButtonEnabled: () => boolean;
  finishAction: () => void;
  buttonName: () => string;
  buttonTooltip: () => string;

  constructor(props: FinishTurnProps) {
    const currentPlayer = getCurrentPlayer(props.gameState);

    this.buttonName = () => resolveTextByMode(props.displayMode, 'Finish turn', 'Finish');

    this.buttonTooltip = () => {
      if (!props.gameState.playersOrdered.includes(currentPlayer.id)) {
        return 'You finished this game';
      }
      if (props.gameState.isFinished) {
        return 'This deltaration game is finished';
      }
      if (currentPlayer.id !== getAttackingPlayerId(props.gameState)) {
        return 'Wait for your turn';
      }
      if (props.isTeleportInProgress) {
        return "Finish Teleport action - move Opponent's card to another player";
      }
      if (props.isDisintegratorInProgress) {
        return "Finish Disintegrator action - move Opponent's card to discard pile";
      }
      if (props.isChallengeInProgress) {
        return 'Wait for your opponent to randomize a probability capsule for Challenge';
      }
      if (!isEmpty(props.gameState.defendingPlayerIds)) {
        return 'Wait for all defending players';
      }
      if (props.isEssenceHuntInProgress) {
        return 'Remove 2 cards to Discard pile in order to finish Essence Hunt';
      }
      if (!props.isHandCardsCountWithinLimits) {
        return `Leave maximum ${getAllowedCardsInHandCount(
          currentPlayer,
          props.gameState.playersOrdered
        )} cards in hand`;
      }
      return 'Finish your turn';
    };

    this.isButtonEnabled = () => {
      if (currentPlayer.id !== props.gameState.activePlayer) {
        return false;
      }
      if (props.gameState.defendingPlayerIds.length > 0) {
        return false;
      }
      if (props.gameState.isFinished) {
        return false;
      }
      if (props.isEssenceHuntInProgress) {
        return false;
      }
      return props.isHandCardsCountWithinLimits;
    };

    this.finishAction = () => props.finishTurn(false);
  }
}

export class FinishAndLoseEssence implements FinishAction {
  isButtonEnabled: () => boolean;
  finishAction: () => void;
  buttonName: () => string;
  buttonTooltip: () => string;

  constructor(props: FinishActionProps) {
    this.buttonName = () => resolveTextByMode(props.displayMode, 'Lose Essence', '- Essence');
    this.buttonTooltip = () => 'Finish defending and lose essence';

    this.isButtonEnabled = () => props.canFinishAction;
    this.finishAction = () => props.finishAction();
  }
}

export class FinishAndRevealRole implements FinishAction {
  isButtonEnabled: () => boolean;
  finishAction: () => void;
  buttonName: () => string;
  buttonTooltip: () => string;

  constructor(props: FinishActionProps) {
    this.buttonName = () => resolveTextByMode(props.displayMode, 'Reveal Role', 'Reveal');
    this.buttonTooltip = () => 'Finish defending and reveal role';

    this.isButtonEnabled = () => props.canFinishAction;
    this.finishAction = () => props.finishAction();
  }
}

export class FinishAndConvert implements FinishAction {
  isButtonEnabled: () => boolean;
  finishAction: () => void;
  buttonName: () => string;
  buttonTooltip: () => string;

  constructor(props: FinishActionProps) {
    this.buttonName = () => resolveTextByMode(props.displayMode, 'Convert', 'Convert');
    this.buttonTooltip = () => 'Finish defending and convert to Ortegonist';

    this.isButtonEnabled = () => props.canFinishAction;
    this.finishAction = () => props.finishAction();
  }
}

export class FinishAndAllowTeleport implements FinishAction {
  isButtonEnabled: () => boolean;
  finishAction: () => void;
  buttonName: () => string;
  buttonTooltip: () => string;

  constructor(props: FinishActionProps) {
    this.buttonName = () => resolveTextByMode(props.displayMode, 'Lose Card', '- Card');
    this.buttonTooltip = () => 'Finish defending and allow teleport';

    this.isButtonEnabled = () => props.canFinishAction;
    this.finishAction = () => props.finishAction();
  }
}

export class FinishAndAllowDisintegrator implements FinishAction {
  isButtonEnabled: () => boolean;
  finishAction: () => void;
  buttonName: () => string;
  buttonTooltip: () => string;

  constructor(props: FinishActionProps) {
    this.buttonName = () => resolveTextByMode(props.displayMode, 'Lose Card', '- Card');
    this.buttonTooltip = () => 'Finish defending and allow disintegrator';

    this.isButtonEnabled = () => props.canFinishAction;
    this.finishAction = () => props.finishAction();
  }
}

export class HuntEssence implements FinishAction {
  isButtonEnabled: () => boolean;
  finishAction: () => void;
  buttonName: () => string;
  buttonTooltip: () => string;

  constructor(props: FinishActionProps) {
    this.buttonName = () => resolveTextByMode(props.displayMode, 'Hunt essence', 'Hunt');
    this.buttonTooltip = () => 'Discard 2 cards for 1 Essence, skip turn';

    this.isButtonEnabled = () => true;
    this.finishAction = () => props.finishAction();
  }
}

export class FinishChallenge implements FinishAction {
  isButtonEnabled: () => boolean;
  finishAction: () => void;
  buttonName: () => string;
  buttonTooltip: () => string;

  constructor(props: FinishActionProps) {
    this.buttonName = () => 'Challenge';
    this.buttonTooltip = () => {
      if (props.canFinishAction) {
        return 'Finish your Challenge part';
      }
      return 'Randomize a probability capsule for Challenge';
    };

    this.isButtonEnabled = () => props.canFinishAction;
    this.finishAction = () => props.finishAction();
  }
}

export class FinishDisorientation implements FinishAction {
  isButtonEnabled: () => boolean;
  finishAction: () => void;
  buttonName: () => string;
  buttonTooltip: () => string;

  constructor(props: FinishActionProps) {
    this.buttonName = () => 'Check';
    this.buttonTooltip = () => {
      if (props.canFinishAction) {
        return 'Finish Disorientation check';
      }
      return 'Randomize a probability capsule to check if Disorientation takes effect';
    };

    this.isButtonEnabled = () => props.canFinishAction;
    this.finishAction = () => props.finishAction();
  }
}

export class FinishDomeCheck implements FinishAction {
  isButtonEnabled: () => boolean;
  finishAction: () => void;
  buttonName: () => string;
  buttonTooltip: () => string;

  constructor(props: FinishActionProps) {
    this.buttonName = () => resolveTextByMode(props.displayMode, 'Dome check', 'Check');
    this.buttonTooltip = () => {
      if (props.canFinishAction) {
        return 'Finish Dome check';
      }
      return 'Randomize a probability capsule to check if Dome takes effect';
    };

    this.isButtonEnabled = () => props.canFinishAction;
    this.finishAction = () => props.finishAction();
  }
}

export type FinishTurnProps = {
  displayMode: DisplayMode;
  gameState: GameState;
  isHandCardsCountWithinLimits: boolean;
  isTeleportInProgress: boolean;
  isDisintegratorInProgress: boolean;
  isEssenceHuntInProgress: boolean;
  isChallengeInProgress: boolean;
  finishTurn: (onRemovalFromGame: boolean) => void;
};

export type FinishActionProps = {
  canFinishAction: boolean;
  finishAction: () => void;
  displayMode: DisplayMode;
};

const resolveTextByMode = (displayMode: DisplayMode, full: string, short: string) => {
  if ([DisplayMode.COMPACT, DisplayMode.TRAY, DisplayMode.MOBILE].includes(displayMode)) {
    return short;
  }

  return full;
};
