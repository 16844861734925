import { EXTENDED_TO_NORMAL_SIZE, SizeExtended } from '../../helpers/sizes';
import { CARD_SIZE_MODIFIERS, L_SIZE_BOARDER, L_SIZE_CARD_CONTENT_DETAILS_FONT, L_SIZE_RADIUS } from './card.types';
import { ProjectColors } from '../../themes/mainTheme';
import { horizontalScrollbarStyle, verticalScrollbarStyle } from '../../helpers/styles';
import { capitalize, SxProps } from '@mui/material';
import { CARD_FONT_MODIFIERS } from './hooks/useCardDimension';
import { BOX_SHADOW_ELEMENT_HIGHER_LAYER, TYPOGRAPHY_SHADOW_SX } from '../../constants/styles';

export const initSx = (
  size: SizeExtended,
  dimension: number,
  screenSizeModifier: number,
  backgroundColor: ProjectColors,
  rotation: number,
  isActive: boolean
) => {
  const scaleValueByCardSize = (value: number) => {
    return value * CARD_SIZE_MODIFIERS[size];
  };

  const scaleValueByScreenSize = (value: number) => {
    return screenSizeModifier * value;
  };

  const radius = scaleValueByScreenSize(scaleValueByCardSize(L_SIZE_RADIUS));
  const boarderSize = scaleValueByScreenSize(scaleValueByCardSize(L_SIZE_BOARDER));

  const defaultTypographySx = {
    textAlign: 'center',
    fontWeight: '600',
  };

  const pictureGradientPercentage = 77.5;
  const cardMainBackground =
    backgroundColor !== ProjectColors.TRANSPARENT
      ? {
          backgroundRepeat: 'no-repeat',
          backgroundImage: `
            linear-gradient(to bottom left, transparent 0%, transparent 45%, ${ProjectColors.BLACK} 50%, transparent 55%, transparent 100%),
            linear-gradient(to right, transparent 0%, transparent ${pictureGradientPercentage}%, ${backgroundColor} 100%),
            linear-gradient(to bottom, ${ProjectColors.BLACK} 0%, ${ProjectColors.BLACK} ${pictureGradientPercentage}%, ${backgroundColor} 100%)
          `,
        }
      : { backgroundColor: backgroundColor };

  const cardMainSx = {
    height: dimension,
    maxHeight: dimension,
    minWidth: dimension,
    width: dimension,
    borderRadius: `${radius}px`,
    border: `${boarderSize}px solid ${ProjectColors.PURPLE_VERY_DARK}`,
    rotate: `${rotation}deg`,
    backgroundColor: ProjectColors.TRANSPARENT,
    boxShadow: backgroundColor !== ProjectColors.TRANSPARENT && isActive ? BOX_SHADOW_ELEMENT_HIGHER_LAYER : 'none',
    position: 'relative',
    zIndex: 1,
  };

  type Side = 'top' | 'bottom' | 'left' | 'right';
  const twoSidesBorderSx = (sideA: Side, sideB: Side) => ({
    [`border${capitalize(sideA)}`]: `1px solid ${ProjectColors.WHITE}`,
    [`border${capitalize(sideB)}`]: `1px solid ${ProjectColors.WHITE}`,
  });

  const onCardFonts = dimension * CARD_FONT_MODIFIERS[size];
  const lSizeLetterSpacing = '0.3rem';
  const cardTypeTextBasicSx = {
    ...defaultTypographySx,
    fontSize: onCardFonts,
    letterSpacing: size === SizeExtended.L ? lSizeLetterSpacing : 'none',
    writingMode: 'vertical-rl',
    textOrientation: 'upright',
  };

  const cardTypeTextNotLSizeSx = {
    ...cardTypeTextBasicSx,
    ':first-letter': twoSidesBorderSx('top', 'bottom'),
  };

  const cardTypeTextLSizeSx = {
    ...cardTypeTextBasicSx,
    ...twoSidesBorderSx('top', 'bottom'),
    pt: lSizeLetterSpacing,
  };

  const normalSize = EXTENDED_TO_NORMAL_SIZE[size];

  const nameExtraSx = size === SizeExtended.L ? {} : { position: 'absolute', bottom: 0 };

  return {
    card: {
      ...cardMainSx,
    },
    innerBox: {
      height: '100%',
      width: '100%',
      ...cardMainBackground,
      filter: isActive ? 'none' : 'grayscale(90%)',
    },
    selectedCard: {
      ...cardMainSx,
      border: `${boarderSize}px solid ${ProjectColors.YELLOW}`,
    },
    cardName: {
      box: { display: 'flex', justifyContent: 'center', alignItems: 'center', height: '15%' },
    },
    cardContent: {
      padding: 0,
      height: '100%',
      '&:last-child': {
        pb: 0.1,
      },
    },
    openCardPictureBox: {
      position: 'relative',
      pt: 0,
      objectPosition: 'top',
      objectFit: 'scale-down',
      width: '85%',
      '&::after': {
        content: '""',
        position: 'absolute',
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        backgroundImage: `
          linear-gradient(to bottom, transparent 0%, transparent 93%, ${ProjectColors.BLACK}), 
          linear-gradient(to right, transparent 0%, transparent 93%, ${ProjectColors.BLACK})`,
      },
    },
    openCardPicture: {
      borderRadius: `0 0 ${radius}px 0`,
    },
    pictureAndTypeBox: { display: 'flex' },
    detailsDivider: { mb: 1 },
    detailsBox: {
      p: 1,
      position: 'absolute',
      bottom: '5%',
      right: '5%',
      width: '90%',
      maxHeight: '80%',
      backgroundColor: ProjectColors.PURPLE_VERY_DARK_TRANSPARENT,
      border: `1px solid ${backgroundColor}`,
      ...verticalScrollbarStyle(normalSize),
      zIndex: 1,
    },
    typeRow: {
      box: {
        position: 'relative',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '15%',
      },
      ruleBookWidget: {
        position: 'absolute',
        top: 0,
        right: -2,
        ruleBookSize: `${scaleValueByScreenSize(100) + '%'}`,
        zIndex: 1,
      },
      type: {},
    },
    typography: {
      type: size === SizeExtended.L ? cardTypeTextLSizeSx : cardTypeTextNotLSizeSx,
      name: {
        ...defaultTypographySx,
        ...nameExtraSx,
        background: `linear-gradient(90deg, transparent, ${ProjectColors.PURPLE_VERY_DARK}, transparent)`,
        fontSize: onCardFonts,
        ...(size === SizeExtended.L && {
          whiteSpace: 'nowrap',
          ...horizontalScrollbarStyle(normalSize),
          justifyContent: 'center',
          alignItems: 'center',
          background: 'none',
        }),
        ...TYPOGRAPHY_SHADOW_SX,
      } as SxProps,
      content: {
        ...defaultTypographySx,
        textAlign: 'center',
        fontSize: `${scaleValueByScreenSize(L_SIZE_CARD_CONTENT_DETAILS_FONT)}rem`,
      },
    },
  };
};
