import { isEmpty } from '../../helpers/arrays';
import { DISCARD_PILE_EMPTY_OPACITY, DISCARD_PILE_HAS_CARDS_OPACITY } from './discardPile.types';
import { PlayingCard } from '../../features/card/cardTypes';
import { ProjectColors } from '../../themes/mainTheme';

export const initSx = (playingCards: PlayingCard[], height: number, width: number, isMobile: boolean) => {
  const opacity = isEmpty(playingCards) ? DISCARD_PILE_EMPTY_OPACITY : DISCARD_PILE_HAS_CARDS_OPACITY;

  const droppableElementCommonSx = {
    position: 'absolute',
    borderRadius: '5%',
    zIndex: 1,
  };

  return {
    imageSx: { height: height, width: width, opacity: opacity, mx: isMobile ? 0 : 0.5 },
    outerBox: { display: 'flex', justifyContent: 'center' },
    mobileOuterBox: { position: 'relative', top: 0 },
    droppableMainBox: {
      height: `100%`,
      width: `100%`,
      background: `${ProjectColors.TRANSPARENT}`,
      position: isMobile ? 'relative' : 'absolute',
    },
    droppableElement: {
      m: '-15%',
      p: '10%',
      top: '15%',
      height: `105%`,
      width: `100%`,
      ...droppableElementCommonSx,
    },
    droppableElementMobile: {
      height: height,
      width: width,
      ...droppableElementCommonSx,
    },
  };
};
