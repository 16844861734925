import React from 'react';
import { logResponseErr } from '../../../helpers/utils';
import { Log, LogContext, LogContextType, Props } from './logContextProvider.types';
import { useGameId } from '../gameContextHandler/hooks/useGameId';
import { AppContext } from '../../../global/context/appContext';
import { ApiContext } from '../../../global/app/api/apiContext';
import { GameLogRequest } from '../gameTypes';
import { createApiAuthRequest } from '../gameContextHandler/utils/api';
import { setFlag } from '../../../helpers/featureFlags';
import { IS_LOG_BLOCK_CLOSED_STORAGE_KEY } from '../../../constants/components';
import { isMobile } from 'react-device-detect';

export const LogContextProvider: React.FC<Props> = ({ children }) => {
  const id = useGameId();
  const { socketClient } = React.useContext(AppContext);
  const { gameApi } = React.useContext(ApiContext);

  const [logs, setLogs] = React.useState<Log[]>([]);
  const [notification, setNotification] = React.useState<Log | undefined>(undefined);
  const [isLogJournalOpen, setIsLogJournalOpen] = React.useState<boolean>(
    localStorage.getItem(IS_LOG_BLOCK_CLOSED_STORAGE_KEY) !== 'true' && !isMobile
  );

  const createApiLog = async (gameLogRequest: GameLogRequest, withSocketEvent: boolean) => {
    try {
      await gameApi.createGameLog(id, createApiAuthRequest(), gameLogRequest);
      if (withSocketEvent) socketClient.socket.emit('createNotification', id, gameLogRequest);
    } catch (e: any) {
      logResponseErr(e);
    }
  };

  const fetchLogs = async () => {
    const allGameLogs = await gameApi.getAllGameLogs(id, createApiAuthRequest());
    setLogs(allGameLogs);
  };

  const createLog = (log: Log) => {
    if (!isLogJournalOpen || isMobile) {
      setNotification(log);
    } else {
      setLogs((prevLogs) => [log, ...prevLogs]);
    }

    void createApiLog(log, false);
  };

  const createRealtimeLog = (log: Log) => {
    setLogs((prevLogs) => [log, ...prevLogs]);
    void createApiLog(log, true);
  };

  const applyLogs = (newLogs: Log[]) => {
    newLogs.reverse();

    if (!isLogJournalOpen) return newLogs.forEach((log) => setNotification(log));

    setLogs((prevLogs) => [...newLogs, ...prevLogs]);
  };

  const updateLogJournalState = (isOpen: boolean) => {
    setFlag(IS_LOG_BLOCK_CLOSED_STORAGE_KEY, !isOpen);
    setIsLogJournalOpen(isOpen);
  };

  const initializeContext = (): LogContextType => ({
    logs: logs,
    createLog: createLog,
    createRealtimeLog: createRealtimeLog,
    applyLogs: applyLogs,
    fetchLogs: fetchLogs,
    notification: notification,
    setNotification: setNotification,
    isLogJournalOpen: isLogJournalOpen,
    setIsLogJournalOpen: updateLogJournalState,
  });

  return <LogContext.Provider value={initializeContext()}>{children}</LogContext.Provider>;
};
