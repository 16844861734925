import React from 'react';
import { useParams } from 'react-router-dom';
import { GameContextHandler } from '../../../../features/game/gameContextHandler';
import { Game } from '../../../../views/game';
import { wrap, WrapperWithProps } from '../../../../helpers/wrappers';
import { AnimationContextProvider } from '../../../../features/game/animationContextProvider';
import { LocationTrackerContextProvider } from '../../../../features/game/locationTrackerContextProvider';
import { LogContextProvider } from '../../../../features/game/logContextProvider';

export const GameWithContext: React.FC = () => {
  const { id } = useParams();

  const wrappers: WrapperWithProps[] = [
    [LocationTrackerContextProvider, {}],
    [AnimationContextProvider, []],
    [LogContextProvider, []],
    [GameContextHandler, { key: id }],
  ];

  return wrap(<Game />, wrappers);
};
