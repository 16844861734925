import React from 'react';
import { CARD_AMOUNT_FIRST_SERVE, PASSING_PROB_VALUES, Props } from './gameContextHandler.types';
import { EssenceUpdateType, GameContext, GameContextType } from '../gameContext';
import { AppContext } from '../../../global/context/appContext';
import { Player } from '../../player/player.types';
import { CardNames, PlayingCard, RoleCard } from '../../card/cardTypes';
import { isEmpty, shuffle } from '../../../helpers/arrays';
import { closeCard } from '../../card/cardTransformers';
import { toImpactedAction, toInProgressAction } from '../utils/turnActions';
import { NumberOfPlayers, PlayerData, TurnAction } from '../gameTypes';
import {
  getActionCheckProbabilityValue,
  getChallengeLostPlayerId,
  isChallengeActive,
  isDomeCheckInProgress,
} from '../rules/rules';
import { useRevealRole } from './hooks/useRevealRole';
import { getAttackingPlayerId, getCurrentPlayer, getPlayer } from './utils/player';
import { useGameFinish } from './hooks/useGameFinish';
import { useChangeEssenceCount } from './hooks/useChangeEssenceCount';
import { useTurnActions } from './hooks/useTurnActions';
import { DroppableGroup, useDragAndDrop } from './hooks/useDragAndDrop';
import { useDefendingActions } from './hooks/useDefendingActions';
import { useRemovePlayerFromGame } from './hooks/useRemovePlayerFromGame';
import { useGameState } from './hooks/useGameState';
import { useProbability } from './hooks/useProbability';
import { RELOAD_GAME_PAGE_ON_IDLE_SEC } from '../../../constants/components';
import { useRunOnIdle } from '../../../helpers/hooks/useRunOnIdle';
import { useApplyGameStateActionSounds } from './hooks/useApplyGameStateActionSounds';
import { getPlayerInitialEssenceCount, getRoleCardsByNumberOfPlayers } from '../rules/roles';
import { Log, LogContext } from '../logContextProvider';
import { useGameApi } from '../gameApi/useGameApi';
import { usePreparePlayingCards } from './hooks/usePreparePlayingCards';
import { useApplyEssenceThiefEffect } from './hooks/useApplyEssenceThiefEffect';
import { useIdlePlayer } from './hooks/useIdlePlayer';

export const GameContextHandler: React.FC<Props> = ({ children }) => {
  const { allPlayingCards: apiPlayingCards, profile } = React.useContext(AppContext);

  const { createLog, fetchLogs, setNotification } = React.useContext(LogContext);

  const [allPlayingCards, setAllPlayingCards] = React.useState<PlayingCard[]>([]);
  const [expectedPlayersCount, setExpectedPlayersCount] = React.useState<NumberOfPlayers>();
  const [playersDataList, setPlayersDataList] = React.useState<PlayerData[]>([]);
  const [playersAdded, setPlayersAdded] = React.useState(0);

  const [isReadyToPlay, setIsReadyToPlay] = React.useState(false);

  const { game } = useGameApi();
  const { gameState, apiGameState, isUiBlocked, withUpdatingState, getCurrentGameState } = useGameState({
    allPlayingCards: allPlayingCards,
    playersAdded: playersAdded,
    isReadyToPlay: isReadyToPlay,
    setExpectedPlayersCount: setExpectedPlayersCount,
    setPlayersDataList: setPlayersDataList,
    setPlayersAdded: setPlayersAdded,
  });

  useRunOnIdle({
    dependencies: [gameState],
    idleSeconds: RELOAD_GAME_PAGE_ON_IDLE_SEC,
    onIdle: getCurrentGameState,
    isDisabled: !isReadyToPlay || gameState.isFinished,
  });

  React.useEffect(() => {
    if (isEmpty(allPlayingCards) || !isEmpty(gameState.cardsInDeck)) return;

    shuffle(allPlayingCards);
    gameState.cardsInDeck = [...allPlayingCards];
  }, [allPlayingCards]);

  const { filterByGame } = usePreparePlayingCards();

  React.useEffect(() => {
    if (!game || !isEmpty(allPlayingCards)) return;

    setAllPlayingCards(filterByGame(apiPlayingCards, game));
  }, [apiPlayingCards, game]);

  React.useEffect(() => {
    if (
      !isEmpty(gameState.cardsInDeck) &&
      isEmpty(gameState.allPlayers) &&
      expectedPlayersCount !== undefined &&
      playersDataList.length === expectedPlayersCount &&
      apiGameState === undefined
    ) {
      createPlayers();
      setIsReadyToPlay(!isEmpty(allPlayingCards) && expectedPlayersCount === gameState.allPlayers.length);
    }
  }, [playersDataList.length]);

  React.useEffect(() => {
    if (!isEmpty(playersDataList)) {
      validateAddedPlayers();
    }
  }, [playersDataList]);

  React.useEffect(() => {
    if (!isReadyToPlay) {
      return;
    }

    void getCurrentGameState();
    void fetchLogs();
  }, [isReadyToPlay]);

  const { isDroppable, isDraggable, isAllowedToDrag, enableDragAndDrop, isDragging, setIsDragging } = useDragAndDrop({
    gameState: gameState,
  });

  const { finishTurn, addTurnAction, startDisorientationCheck, finishDisorientationCheck } = useTurnActions({
    gameState: gameState,
    enableDragAndDrop: enableDragAndDrop,
  });

  const { finishGame, getGameWinningRole, gameDurationLog } = useGameFinish({ gameState, addTurnAction });

  const { idlePlayer, setIdlePlayer } = useIdlePlayer();

  const { rollProbCapsule, isProbReRollAllowed, allowProbReRoll, leaveProbLog, isProbRolling, setIsProbRolling } =
    useProbability({
      gameState: gameState,
      addTurnAction: addTurnAction,
    });

  const { finishDefending, performFinishDefending, reflectAttackingCard, forceDefendingFinish } = useDefendingActions({
    gameState: gameState,
    addTurnAction: addTurnAction,
    enableDragAndDrop: enableDragAndDrop,
  });

  useApplyGameStateActionSounds({ gameState: gameState });

  const validateAddedPlayers = () => {
    if (expectedPlayersCount === undefined) {
      throw Error('Number of players for the game should be provided first');
    }

    if (playersDataList.length > expectedPlayersCount) {
      throw Error('Max number of players has already been reached');
    }
  };

  const serveCards = (amount: number): PlayingCard[] => {
    if (amount > gameState.cardsInDeck.length) {
      throw Error('not enough cards in the draw deck');
    }

    return [...gameState.cardsInDeck.splice(0, amount)];
  };

  const createPlayer = (
    isCurrent: boolean,
    playerId: string,
    name: string,
    role: RoleCard,
    cardsInHand: PlayingCard[]
  ): Player => ({
    isCurrent: isCurrent,
    id: playerId,
    isWinner: false,
    cardsInHand: cardsInHand,
    cardsOnTable: [],
    roleCard: role,
    roleVisibleTo: [],
    playerName: name,
    essences: getPlayerInitialEssenceCount(expectedPlayersCount),
    activeRadiances: [],
    actionSkips: 0,
  });

  const createPlayers = () => {
    const currentPlayerData = playersDataList.filter((player) => player.id === profile!.playerId);

    if (currentPlayerData.length === 0) {
      throw Error('Player data not found in all Players data list');
    }

    const currentPlayerIdFromData = currentPlayerData[0].id;
    const otherPlayersData = playersDataList;

    gameState.draggable = currentPlayerIdFromData;
    gameState.dragger = currentPlayerIdFromData;
    gameState.droppableList = otherPlayersData.map((player) => player.id);

    otherPlayersData.shift();

    const allRoles = getRoleCardsByNumberOfPlayers(game!.numberOfPlayers);
    shuffle(allRoles);
    gameState.allPlayers = [
      createPlayer(
        true,
        currentPlayerIdFromData,
        currentPlayerData[0].name,
        allRoles.pop()!,
        serveCards(CARD_AMOUNT_FIRST_SERVE)
      ),
      ...otherPlayersData.map((player) =>
        createPlayer(false, player.id, player.name, closeCard(allRoles.pop()!), serveCards(CARD_AMOUNT_FIRST_SERVE))
      ),
    ];
  };

  const { changeEssenceCount } = useChangeEssenceCount({
    gameState: gameState,
    addTurnAction: addTurnAction,
  });

  const { applyEssenceThiefEffectIfRelevant } = useApplyEssenceThiefEffect({
    gameState: gameState,
    addTurnAction: addTurnAction,
  });

  const { revealCurrentPlayerRoleBy, revealRoleByDefendingPlayer, convertAndRevealRoleByDefendingPlayer } =
    useRevealRole({
      gameState: gameState,
      addTurnAction: addTurnAction,
      finishDefending: finishDefending,
      performFinishDefending: performFinishDefending,
      changeEssenceCount: changeEssenceCount,
      finishGame: finishGame,
    });

  const { removePlayerFromGame } = useRemovePlayerFromGame({
    gameState,
    performFinishDefending,
    forceDefendingFinish,
    finishTurn,
    addTurnAction,
  });

  const performFinishAndLoseEssence = (player: Player) => {
    changeEssenceCount(player.id, EssenceUpdateType.REMOVE_ONE);
    applyEssenceThiefEffectIfRelevant();

    if (player.essences === 0) {
      removePlayerFromGame(player);
      finishGame();
      return;
    }
    performFinishDefending('withEssenceLoss');
  };

  const performFinishAndEnableTeleport = () => {
    const dragger = getAttackingPlayerId(gameState);
    const defendingPlayer = getPlayer(gameState.allPlayers, gameState.activeDefendingPlayer);
    enableDragAndDrop(defendingPlayer.id, DroppableGroup.ALL_BUT_DRAGGABLE, dragger);
    performFinishDefending('withCardTeleported');
    if (gameState.activePlayer === gameState.activeDefendingPlayer && gameState.activePlayer === dragger) return;

    const activePlayerName = gameState.allPlayers.find((player) => player.id === dragger)?.playerName;
    const log: Log = {
      type: 'warning',
      text: `${defendingPlayer.playerName}: defending finished, allowed ${activePlayerName} to teleport.`,
    };
    addTurnAction({ player: defendingPlayer.id, action: 'create log', logs: [log] });
    createLog(log);
  };

  const performFinishAndEnableDisintegrator = () => {
    const dragger = getAttackingPlayerId(gameState);
    const defendingPlayer = getPlayer(gameState.allPlayers, gameState.activeDefendingPlayer);
    enableDragAndDrop(defendingPlayer.id, DroppableGroup.NONE, dragger);
    performFinishDefending('withCardDisintegrated');

    const activePlayerName = gameState.allPlayers.find((player) => player.id === dragger)?.playerName;
    const log: Log = {
      type: 'warning',
      text: `${defendingPlayer.playerName}: defending finished, allowed ${activePlayerName} to disintegrate.`,
    };
    addTurnAction({ player: getCurrentPlayer(gameState).id, action: 'create log', logs: [log] });
    createLog(log);
  };

  const finishCurrentPlayerChallengeAction = () => {
    const currentPlayer = getCurrentPlayer(gameState);
    if (currentPlayer.id === gameState.activePlayer) {
      gameState.activeDefendingPlayer = gameState.defendingPlayerIds[0];
      enableDragAndDrop(gameState.defendingPlayerIds[0], DroppableGroup.DRAGGABLE, gameState.defendingPlayerIds[0]);
      addTurnAction({ player: currentPlayer.id, action: toInProgressAction(CardNames.CHALLENGE) });
      return;
    }

    if (currentPlayer.id !== gameState.activeDefendingPlayer) {
      throw Error('Challenge cannot be finished');
    }

    finishChallenge();
  };

  const finishChallenge = () => {
    const currentPlayer = getCurrentPlayer(gameState);

    try {
      const challengeLostPlayerId = getChallengeLostPlayerId(gameState);

      if (challengeLostPlayerId === undefined) {
        const log: Log = { type: 'warning', text: `Challenge result: draw` };
        addTurnAction({ player: currentPlayer.id, action: 'create log', logs: [log] });
        createLog(log);
        performFinishDefending('successfulDefending');
        return;
      }

      const lostPlayer = getPlayer(gameState.allPlayers, challengeLostPlayerId);
      performFinishAndLoseEssence(lostPlayer);
      const log: Log = { type: 'warning', text: `Challenge result: ${lostPlayer.playerName} lost` };
      addTurnAction({ player: currentPlayer.id, action: 'create log', logs: [log] });
      createLog(log);
    } catch (error) {
      const log: Log = { type: 'warning', text: `Challenge left unfulfilled` };
      addTurnAction({ player: currentPlayer.id, action: toImpactedAction(CardNames.CHALLENGE), logs: [log] });
      createLog(log);
      forceDefendingFinish();
    }
  };

  const finishDomeCheck = () => {
    const currentPlayer = getCurrentPlayer(gameState);
    const probRollValue = getActionCheckProbabilityValue(gameState, isDomeCheckInProgress);

    const domeImpactedTurnAction: TurnAction = {
      player: currentPlayer.id,
      action: toImpactedAction(CardNames.DOME),
      probValue: probRollValue,
    };

    if (PASSING_PROB_VALUES.includes(probRollValue)) {
      const savedByDomeLog: Log = { type: 'info', text: `${currentPlayer.playerName}: protected by Dome` };
      domeImpactedTurnAction.logs = [savedByDomeLog];
      addTurnAction(domeImpactedTurnAction);
      createLog(savedByDomeLog);
      return withUpdatingState(performFinishDefending)('successfulDefending');
    }

    const domeFailedLog: Log = { type: 'error', text: `${[currentPlayer.playerName]}: Dome check failed` };
    createLog(domeFailedLog);
    domeImpactedTurnAction.logs = [domeFailedLog];

    if (isChallengeActive(gameState)) {
      return addTurnAction(domeImpactedTurnAction);
    }

    withUpdatingState(addTurnAction)(domeImpactedTurnAction);
  };

  const checkDisorientationEffect = () => {
    startDisorientationCheck();
    rollProbCapsule();
  };

  const applyFoggerEffect = () => {
    const currentPlayer = getCurrentPlayer(gameState);
    rollProbCapsule();
    setNotification({ type: 'warning', text: 'Probability capsule was re-randomized' });
    setNotification({ type: 'warning', text: 'You can re-randomize a probability capsule' });

    allowProbReRoll(currentPlayer.id);
    addTurnAction({ player: currentPlayer.id, action: toImpactedAction(CardNames.FOGGER) });
  };

  const initializeContext = (): GameContextType => ({
    gameState: gameState,
    expectedPlayersCount: expectedPlayersCount,
    playerDataList: playersDataList,
    isReadyToPlay: isReadyToPlay,
    changePlayerEssenceCount: withUpdatingState(changeEssenceCount),
    finishTurn: finishTurn,
    revealCurrentPlayerRoleBy: revealCurrentPlayerRoleBy,
    addTurnAction: addTurnAction,
    performFinishDefending: withUpdatingState(performFinishDefending),
    performFinishAndLoseEssence: withUpdatingState(performFinishAndLoseEssence),
    performFinishAndRevealRole: withUpdatingState(revealRoleByDefendingPlayer),
    performFinishAndConvert: withUpdatingState(convertAndRevealRoleByDefendingPlayer),
    performFinishAndEnableTeleport: withUpdatingState(performFinishAndEnableTeleport),
    performFinishAndEnableDisintegrator: withUpdatingState(performFinishAndEnableDisintegrator),
    reflectAttackingCard: withUpdatingState(reflectAttackingCard),
    finishChallenge: withUpdatingState(finishCurrentPlayerChallengeAction),
    forceFinishChallenge: withUpdatingState(finishChallenge),
    startDisorientationCheck: withUpdatingState(checkDisorientationEffect),
    finishDisorientationCheck: withUpdatingState(finishDisorientationCheck),
    finishDomeCheck: finishDomeCheck,
    rollProbCapsule: rollProbCapsule,
    applyFoggerEffect: withUpdatingState(applyFoggerEffect),
    isProbReRollAllowed: isProbReRollAllowed,
    allowProbReRoll: allowProbReRoll,
    leaveProbLog: leaveProbLog,
    isUiBlocked: isUiBlocked,
    isDraggable: isDraggable,
    isDroppable: isDroppable,
    isAllowedToDrag: isAllowedToDrag,
    isDragging: isDragging,
    setIsDragging: setIsDragging,
    getGameWinningRole: getGameWinningRole,
    gameDurationLog: gameDurationLog,
    withUpdatingState: withUpdatingState,
    enableDragAndDrop: enableDragAndDrop,
    finishDefending: finishDefending,
    isProbRolling: isProbRolling,
    setIsProbRolling: setIsProbRolling,
    idlePlayer: idlePlayer,
    setIdlePlayer: setIdlePlayer,
  });

  return <GameContext.Provider value={initializeContext()}>{children}</GameContext.Provider>;
};
