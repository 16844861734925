import { ThemeOptions, createTheme } from '@mui/material/styles';
import { zIndexes } from '../constants/components';

export enum ProjectColors {
  MAIN = '#ffffff',
  SECONDARY = '#6DEAD6',
  BLACK = '#000000',
  PURPLE_DARK = '#09001a',
  PURPLE = '#9d53fb',
  ESSENCE = '#58219e',
  ESSENCE_TRANSPARENT = '#58219e99',
  PURPLE_VERY_DARK = '#020018',
  PURPLE_VERY_DARK_TRANSPARENT = '#02001899',
  ACTION_DRAWER_MOBILE = 'rgb(11,11,28, 0.8)',
  TRANSPARENT_BLACK = '#0000004C',
  BLUE = '#051d7a',
  BLUE_PROBABILITY = '#0031ff',
  BLUE_TRANSPARENT = '#051d7a99',
  AQUA = '#4aa7a7',
  AQUA_TRANSPARENT = 'rgba(74,167,167,0.2)',
  LIGHT_BLUE = 'rgba(66, 104, 246, 0.60)',
  WHITE = '#ffffff',
  ACTIVE_PLAYER_BACKGROUND = 'rgba(0, 188, 25, 0.3)',
  ACTIVE_PLAYER_BOARDER = 'rgba(25, 165, 32, 0.5)',
  DEFENDING_PLAYER_BACKGROUND = 'rgba(72, 185, 255, 0.3)',
  DEFENDING_PLAYER_BOARDER = 'rgba(108, 196, 255, 0.5)',
  ACTIVE_DEFENDING_PLAYER_BACKGROUND = 'rgba(220, 83, 83, 0.3)',
  ACTIVE_DEFENDING_PLAYER_BOARDER = 'rgba(198, 59, 59, 0.5)',
  OUT_OF_GAME_PLAYER_BACKGROUND = 'rgba(99, 89, 94, 0.3)',
  OUT_OF_GAME_PLAYER_BOARDER = 'rgba(67, 67, 67, 0.5)',
  WINNER_PLAYER_BACKGROUND = 'rgba(212, 175, 55, 0.3)',
  WINNER_PLAYER_BOARDER = 'rgba(212, 175, 55, 0.5)',
  PLAYER_BACKGROUND_TRANSPARENT = 'rgba(87, 62, 175, 0.3)',
  GREY = '#868282',
  GREY_TRANSPARENT = 'rgba(0, 0, 0, 0.6)',
  DARK_GREY_TRANSPARENT = 'rgba(0, 0, 0, 0.7)',
  RED = '#FF0000',
  GREEN = '#028A0F',
  YELLOW = '#F09000',
  YELLOW_TRANSPARENT = '#F09000CC',
  SCARLET = '#D62727',
  PINK = '#FF2D2D66',
  PINK_LOGO = '#ef56fc',
  PLAY_CARD_ARSENAL = '#A56307',
  PLAY_CARD_NEBULA = '#3B0ABD',
  PLAY_CARD_RADIANCE = '#6307A5',
  PLAY_CARD_LIQUID = '#07A563',
  ROLE_CARD = '#252525',
  TRANSPARENT = '#FFFFFF00',
  TRANSPARENT_WHITE = 'rgba(255,255,255,0.36)',
  RULEBOOK_SECTIONS = '#19172c',
}

//TODO check theme
export const mainTheme: ThemeOptions = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: ProjectColors.MAIN,
      contrastText: ProjectColors.BLACK,
      dark: ProjectColors.SECONDARY,
      light: ProjectColors.WHITE,
    },
    secondary: {
      main: ProjectColors.PURPLE_VERY_DARK,
      contrastText: ProjectColors.BLACK,
      dark: ProjectColors.WHITE,
      light: ProjectColors.WHITE,
    },
    background: {
      default: ProjectColors.PURPLE_DARK,
      paper: ProjectColors.PURPLE_VERY_DARK,
    },
    text: {
      primary: ProjectColors.MAIN,
      secondary: ProjectColors.GREY,
      disabled: ProjectColors.GREY,
    },
    divider: ProjectColors.MAIN,
  },
  typography: {
    fontFamily: 'cousine',
    button: {
      fontFamily: 'cousine',
      fontSize: '0.7rem',
    },
    body1: {
      fontFamily: 'cousine',
      fontSize: '0.8rem',
    },
    body2: {
      fontFamily: 'cousine',
      fontSize: '0.8rem',
    },
    h1: {
      fontFamily: 'cousine-bold',
      fontSize: '1rem',
      fontWeight: 'bold',
    },
    h5: {
      fontFamily: 'cousine',
      fontSize: '1.1rem',
    },
    h6: {
      fontFamily: 'cousine',
      fontSize: '1rem',
    },
  },
  shape: {
    borderRadius: 2.5,
  },
  components: {
    MuiButton: {
      styleOverrides: {
        outlined: {
          '&:hover': {
            borderColor: ProjectColors.WHITE,
            color: ProjectColors.WHITE,
          },
        },
      },
    },
    MuiPopover: {
      styleOverrides: {
        root: {
          zIndex: zIndexes.inputFieldPopover,
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        popper: {
          zIndex: zIndexes.tooltipPopper,
        },
      },
    },
  },
});
