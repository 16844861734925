import React from 'react';
import { Props } from './buyMeCoffeeButton.types';
import { CardMedia, Typography } from '@mui/material';
import { ImagePath } from '../../../../../assets/assets';
import Button from '@mui/material/Button';
import { sx } from './buyMeCoffeeButton.styles';

export const BuyMeCoffeeButton: React.FC<Props> = () => {
  const handleButtonClick = () => {
    const popUpWindow = window.open('https://www.buymeacoffee.com/deltaration', '_blank', 'width=430,height=730');
    if (popUpWindow) popUpWindow.focus();
  };

  return (
    <React.Fragment>
      <Button onClick={handleButtonClick} sx={sx.button}>
        <CardMedia component="img" alt="donate-with-buy-me-coffee" image={ImagePath.buyMeCoffeeLogo} sx={sx.logo} />
        <Typography>Buy me a coffee</Typography>
      </Button>
    </React.Fragment>
  );
};
