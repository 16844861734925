import React from 'react';
import { UseAmnesiaEffect } from './useAmnesiaEffect.types';
import { GameContext } from '../../../../game/gameContext';
import { last } from '../../../../../helpers/arrays';
import { CardNames } from '../../../cardTypes';
import { toAppliedAction, toImpactedAction } from '../../../../game/utils/turnActions';
import { getCurrentPlayer } from '../../../../game/gameContextHandler/utils/player';
import { useProbabilityRollAction } from '../../../../game/actions/hooks/useProbabilityRollAction';
import { LogContext } from '../../../../game/logContextProvider';

export const useAmnesiaEffect: UseAmnesiaEffect = () => {
  const { gameState, addTurnAction } = React.useContext(GameContext);
  const { setNotification } = React.useContext(LogContext);
  const { rollProbCapsule } = useProbabilityRollAction();

  return () => {
    const currentPlayer = getCurrentPlayer(gameState);
    const lastTurnAction = last(gameState.turnActions.filter((action) => action.player === currentPlayer.id))?.action;

    if (lastTurnAction === undefined || lastTurnAction !== toAppliedAction(CardNames.AMNESIA)) return;

    addTurnAction({ player: currentPlayer.id, action: toImpactedAction(CardNames.AMNESIA) });
    rollProbCapsule();
    setNotification({ type: 'warning', text: 'Probability capsule was re-randomized' });
  };
};
