import { ProjectColors } from '../../themes/mainTheme';
import { WindowSize } from '../../helpers/utils';
import { IS_PLAYER_TRAY_OPEN_STORAGE_KEY, MEDIUM_SIZE_HEIGHT, zIndexes } from '../../constants/components';
import { isFlagOn } from '../../helpers/featureFlags';

export const sxInit = (windowSize: WindowSize, dimension: number) => {
  const closeButtonPaddingSx = windowSize.height > MEDIUM_SIZE_HEIGHT ? { p: 1 } : { p: 0.2 };

  const position = isFlagOn(IS_PLAYER_TRAY_OPEN_STORAGE_KEY) ? { bottom: `calc(${dimension}px + 2%)` } : { bottom: 0 };

  return {
    drawer: {
      width: 'fit-content',
      alignItems: 'flex-end',
      backgroundColor: 'transparent',
      border: 0,
      zIndex: zIndexes.cardDrawer,
      ...position,
    },
    button: {
      backgroundColor: ProjectColors.PLAYER_BACKGROUND_TRANSPARENT,
      borderRadius: 10,
      minWidth: 0,
      ...closeButtonPaddingSx,
    },
  };
};
