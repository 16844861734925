import React, { useContext } from 'react';
import { Props } from './rules.types';
import { RuleBook } from '../../features/ruleBook';
import { Box } from '@mui/material';
import { AppContext } from '../../global/context/appContext';
import { sx } from './rules.styles';
import { MotionBlock } from '../../components/motionBlock';
import { Logo } from '../../components/logo/logo';
import { ContentPageWrapper } from '../../components/contentPageWrapper';

export const Rules: React.FC<Props> = () => {
  const { story, rules, cardDetails } = useContext(AppContext);

  const outerElements = (
    <Box sx={sx.logoBox}>
      <MotionBlock transitionDuration={1.5}>
        <Logo />
      </MotionBlock>
    </Box>
  );

  const innerElements = (
    <MotionBlock transitionDuration={1}>
      <RuleBook page={'Rules'} story={story} rules={rules} cardDetails={cardDetails} useDarkStyle={true} />
    </MotionBlock>
  );

  return <ContentPageWrapper outerBoxElements={outerElements} innerBoxElements={innerElements} />;
};
