import React from 'react';
import { Props } from './card.types';
import { Box, Card as MuiCard, CardContent, CardMedia, Tooltip, Typography } from '@mui/material';
import { SizeExtended } from '../../helpers/sizes';
import { initSx } from './card.styles';
import { RuleBookWidget } from '../../features/room/ruleBookWidget';
import { useCardSelection } from './hooks/useCardSelection';
import { AppContext } from '../../global/context/appContext';
import { getActiveActionButtonSx, tooltipStyles } from '../../helpers/styles';
import { useCardDimension } from './hooks/useCardDimension';
import { DisplayMode } from '../../features/player/player.types';
import { ProjectColors } from '../../themes/mainTheme';
import { GameContext } from '../../features/game/gameContext';

export const Card: React.FC<Props> = ({
  isOpen,
  size,
  content,
  imagePath,
  backCoverImagePath,
  backgroundColor,
  rotation,
  dragProps,
  selectorProps,
  ignoreWindowSize = false,
  hideRuleBookWidget = false,
  isActive = true,
  hasActiveAnimation = false,
  showImageOnly = false,
  customStyles,
}) => {
  const { screenSizeModifier } = React.useContext(AppContext);
  const { isDragging } = React.useContext(GameContext);
  const [isHovered, setIsHovered] = React.useState(false);
  const { dimension } = useCardDimension({ size: size });

  const sx = initSx(size, dimension, ignoreWindowSize ? 1 : screenSizeModifier, backgroundColor, rotation, isActive);
  const { isCardSelected, handleCardClick } = useCardSelection({ dragProps, selectorProps });

  const backCover = () => <CardMedia component="img" alt="card-back-cover" image={backCoverImagePath} />;

  const contentDetails = size === SizeExtended.L && content.details.length > 0 && (
    <Box>
      <Box sx={sx.detailsBox}>
        <Typography sx={sx.typography.content}>{content.details}</Typography>
      </Box>
    </Box>
  );

  const cardMedia = (
    <CardMedia component="img" alt={`card-${content.name}-image`} image={imagePath} sx={sx.openCardPicture} />
  );

  const openCardPicture = showImageOnly ? (
    cardMedia
  ) : (
    <Box sx={sx.openCardPictureBox}>
      {cardMedia}
      {!hideRuleBookWidget && size === SizeExtended.L && (
        <Box sx={sx.typeRow.ruleBookWidget}>
          <RuleBookWidget defaultSearch={content.name} iconSize={sx.typeRow.ruleBookWidget.ruleBookSize} />
        </Box>
      )}
      {contentDetails}
    </Box>
  );

  const resolveShowType = (
    <>
      <Box sx={sx.typeRow.box}>
        <Typography sx={sx.typography.type}>{size === SizeExtended.L ? content.type : content.type[0]}</Typography>
      </Box>
    </>
  );

  const handleCardClickConsideringSize = (event: React.MouseEvent) => {
    if (size === SizeExtended.L) return;
    handleCardClick(event);
  };

  const cardContent = () => (
    <Tooltip title={<Typography sx={tooltipStyles}>{content.name}</Typography>} disableInteractive placement="top">
      <CardContent sx={sx.cardContent} onClick={(event) => handleCardClickConsideringSize(event)}>
        {showImageOnly ? (
          openCardPicture
        ) : (
          <>
            <Box sx={sx.pictureAndTypeBox}>
              {openCardPicture}
              {resolveShowType}
            </Box>
            <Box sx={sx.cardName.box}>
              <Typography sx={sx.typography.name}>{content.name}</Typography>
            </Box>
          </>
        )}
      </CardContent>
    </Tooltip>
  );

  const resolveCardSide = () => {
    return isOpen ? cardContent() : backCover();
  };

  return (
    <MuiCard
      sx={{
        ...(hasActiveAnimation && !isDragging && !isHovered
          ? getActiveActionButtonSx(5, DisplayMode.MOBILE, ProjectColors.PURPLE)
          : {}),
        ...(isCardSelected ? sx.selectedCard : sx.card),
        ...customStyles,
      }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <Box sx={sx.innerBox}>{resolveCardSide()}</Box>
    </MuiCard>
  );
};
