import { UseRunOnIdle } from './useRunOnIdle.types';
import React from 'react';
import { SECOND_IN_MILLISECONDS } from '../../../constants/time';

export const useRunOnIdle: UseRunOnIdle = ({ dependencies, idleSeconds, onIdle, isDisabled }) => {
  const [lastChange, setLastChange] = React.useState(Date.now());

  React.useEffect(() => {
    setLastChange(Date.now());
  }, dependencies);

  const idleMilliseconds = idleSeconds * SECOND_IN_MILLISECONDS;

  React.useEffect(() => {
    if (isDisabled) return;

    const interval = setInterval(() => {
      if (Date.now() - lastChange > idleMilliseconds) {
        onIdle();
        setLastChange(Date.now());
      }
    }, SECOND_IN_MILLISECONDS);

    return () => {
      clearInterval(interval);
    };
  }, [lastChange]);
};
