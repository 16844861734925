import React from 'react';
import { WindowSize } from '../../helpers/utils';
import { SxStyles } from '../../helpers/styles';

export type Props = {
  name: ElementName;
  position: ElementPosition;
  size: ElementSize;
  children: React.ReactNode;
  outerBoxStyles?: SxStyles;
};

const RESERVED_HEIGHT = 30;
const RESERVED_WIDTH = 30;

const elementPositionStorageKey = (elementName: ElementName) => elementName + '-position';

export type ElementName = 'drawDeck' | 'discardPile';

export type ElementSize = {
  width: number;
  height: number;
};

export type ElementPosition = {
  x: number;
  y: number;
};

export const getDefaultElementPosition = (
  elementName: ElementName,
  initialPosition: ElementPosition,
  windowSize: WindowSize
): ElementPosition => {
  const positionStorageKey = localStorage.getItem(elementPositionStorageKey(elementName));

  if (!positionStorageKey) return initialPosition;

  const savedPosition = JSON.parse(positionStorageKey) as ElementPosition;

  if (
    savedPosition.x > windowSize.width - RESERVED_WIDTH ||
    savedPosition.x < 0 ||
    savedPosition.y > windowSize.height - RESERVED_HEIGHT ||
    savedPosition.y < 0
  ) {
    storeElementPosition(elementName, initialPosition);
    return initialPosition;
  }

  return savedPosition;
};

export const storeElementPosition = (elementName: ElementName, position: ElementPosition): void =>
  localStorage.setItem(elementPositionStorageKey(elementName), JSON.stringify(position));

export const DRAGGABLE_ICON_STALL_TIME_MILLISECONDS = 1000;
