import { UseDisintegratorEffect } from './useDisintegratorEffect.types';
import React from 'react';
import { GameContext } from '../../../../game/gameContext';
import { last } from '../../../../../helpers/arrays';
import { filterIgnoredDefendingActions } from '../../../../game/rules/rules';
import { CardNames } from '../../../cardTypes';
import { getCurrentPlayer, isPlayerOutOfCards } from '../../../../game/gameContextHandler/utils/player';
import {
  addTurnActionWithStateUpdateIfNeeded,
  toAppliedAction,
  toImpactedTurnAction,
  toInProgressAction,
} from '../../../../game/utils/turnActions';
import { Log, LogContext } from '../../../../game/logContextProvider';

export const useDisintegratorEffect: UseDisintegratorEffect = () => {
  const { gameState, addTurnAction, withUpdatingState, performFinishDefending } = React.useContext(GameContext);
  const { createLog } = React.useContext(LogContext);

  return () => {
    const currentPlayer = getCurrentPlayer(gameState);
    const lastTurnAction = last(filterIgnoredDefendingActions(gameState.turnActions))?.action;

    if (
      !gameState.activePlayer ||
      !lastTurnAction ||
      currentPlayer.id !== gameState.activeDefendingPlayer ||
      lastTurnAction !== toAppliedAction(CardNames.DISINTEGRATOR)
    ) {
      return;
    }

    if (isPlayerOutOfCards(gameState.allPlayers, gameState.activeDefendingPlayer)) {
      const log: Log = { type: 'info', text: `${currentPlayer.playerName}: has nothing to disintegrate` };
      addTurnAction({ ...toImpactedTurnAction(gameState, CardNames.DISINTEGRATOR), logs: [log] });
      createLog(log);
      performFinishDefending('successfulDefending');
      return;
    }

    addTurnActionWithStateUpdateIfNeeded(
      currentPlayer,
      toInProgressAction(CardNames.DISINTEGRATOR),
      withUpdatingState,
      addTurnAction
    );
  };
};
