import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ACTIVE_GAME_ROUTE } from '../../../constants/routes';
import DirectionsRunIcon from '@mui/icons-material/DirectionsRun';
import styles from './backToGameButton.module.css';
import cn from 'classnames';
import { Props } from './backToGameButton.types';
import { useGameApi } from '../gameApi/useGameApi';
import { AppContext } from '../../../global/context/appContext';
import { ListItemButton } from '@mui/material';
import { wrap, WrapperWithProps } from '../../../helpers/wrappers';

export const BackToGameButton: React.FC<Props> = ({ onClick }) => {
  const { isMobile } = React.useContext(AppContext);
  const navigate = useNavigate();
  const { game } = useGameApi();
  if (!game || game.finishedAt) return null;

  const navigateToGame = () => {
    navigate(ACTIVE_GAME_ROUTE(game.uniqueId));
    onClick?.();
  };

  const wrappers: WrapperWithProps[] = isMobile ? [[ListItemButton, {}]] : [];
  return wrap(
    <div className={cn(styles.container, { [styles.isMobile]: isMobile })}>
      <DirectionsRunIcon />
      <a className={styles.button} onClick={navigateToGame}>
        Back to game
      </a>
    </div>,
    wrappers
  );
};
