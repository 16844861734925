import React from 'react';
import { GAME_ID_LENGTH } from '../../../../../constants/components';
import { InputField } from '../../../../../components/textField';
import styles from './useJoinGameId.module.css';
import { UseJoinGameId } from './useJoinGameId.types';

export const useJoinGameId: UseJoinGameId = ({ defaultGameId }) => {
  const [gameId, setGameId] = React.useState(defaultGameId ? defaultGameId : '');

  React.useEffect(() => {
    if (!defaultGameId) return;

    setGameId(defaultGameId);
  }, [defaultGameId]);

  const isGameIdValid = () => gameId.length === GAME_ID_LENGTH;

  const gameIdBlock = () => {
    return (
      <div className={styles.container}>
        <InputField
          label={'Game Id'}
          disabled={defaultGameId !== undefined}
          onChange={(event: string) => setGameId(event)}
          defaultValue={defaultGameId}
          inputProps={{ maxLength: GAME_ID_LENGTH }}
        />
      </div>
    );
  };

  return {
    gameIdBlock: gameIdBlock,
    gameId: gameId,
    isGameIdValid: isGameIdValid,
  };
};
