import { ProjectColors } from '../../../themes/mainTheme';

const boarder = `1px solid ${ProjectColors.GREY}`;

export const sx = {
  tableCell: { border: boarder },
  table: { boarder: boarder },
  tableHeader: { boarder: boarder, backgroundColor: ProjectColors.RULEBOOK_SECTIONS },
  imageTableCell: { p: 0 },
  cardImage: { height: '65px', objectFit: 'scale-down' },
  inactiveCard: { opacity: '50%' },
};
