import { getRandomInt } from '../../helpers/utils';
import { ProjectColors } from '../../themes/mainTheme';

export const sx = {
  mainBox: {
    width: '100vw',
    height: '100vh',
    overflow: 'hidden',
    position: 'fixed',
    left: 0,
    top: 0,
    zIndex: 0,
  },
  fillingStars: {
    opacity: 0,
    position: 'absolute',
    width: '2px',
    height: '2px',
    top: '2px',
    background: `${ProjectColors.WHITE}`,
    borderRadius: '50%',
    boxShadow: `0 0 10px ${getRandomInt(5)}px ${ProjectColors.WHITE}`,
    animationTimingFunction: 'linear',
  },
};
