import React from 'react';
import { Props } from './interactiveCards.types';
import { GameContext } from '../../../../game/gameContext';
import { Draggable, DragSource } from '../../../../../components/draggable';
import { Card } from '../../../../../components/card';
import { cardToComponent } from '../../../../../helpers/transformers';
import styles from './interactiveCards.module.css';
import { HandType, useResolveHand } from '../../hand/hooks/useResolveHand';
import cn from 'classnames';
import { canDisintegrate, canTeleport } from '../../../../game/rules/rules';

export const InteractiveCards: React.FC<Props> = ({ player, size, forceHidden }) => {
  const { gameState, isDragging } = React.useContext(GameContext);
  const allPlayerCards = [...player.cardsOnTable, ...player.activeRadiances, ...player.cardsInHand];

  const { handType } = useResolveHand({ playerId: player.id });
  if (handType !== HandType.INTERACTIVE || forceHidden) return null;

  const action: 'teleport' | 'disintegrate' | null = canTeleport(gameState.turnActions)
    ? 'teleport'
    : canDisintegrate(gameState.turnActions)
    ? 'disintegrate'
    : null;

  const text = `Start dragging ${player.playerName}'s card${action ? ` to ${action} it` : ''}`;

  return (
    <>
      <div className={cn(styles.dimmer, { [styles.isHidden]: isDragging })} />
      <div
        className={cn(styles.container, {
          [styles.collapsible]: isDragging,
          [styles.expandable]: !isDragging,
        })}
      >
        <div className={styles.textContainer}>
          <h2 className={styles.text}>{text}</h2>
        </div>
        <div className={styles.content}>
          <div className={styles.cards}>
            {allPlayerCards.map((card) => (
              <div key={`interactive-card-${card.id}`} className={styles.item}>
                <Draggable draggableData={{ dragSource: DragSource.PLAYER, fromPlayerId: player.id, cards: [card] }}>
                  <Card {...cardToComponent(card, size)} />
                </Draggable>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};
