import { getRandomInt } from './utils';

export type PopRandomResult<Type> = {
  popped: Type;
  remaining: Type[];
};

export function popRandom<Type>(array: Type[]): PopRandomResult<Type> {
  const randomIndex = getRandomInt(array.length);
  const popped = array.splice(randomIndex, 1)[0];

  return { popped: popped, remaining: array };
}

export function removeRandomElements<Type>(array: Type[], number: number): Type[] {
  let removedElements: Type[] = [];

  if (number > array.length) {
    throw Error('Number of elements to remove is greater than array length');
  }

  for (let i = 0; i < number; i++) {
    const randomIndex = Math.floor(Math.random() * array.length);
    removedElements.push(...array.splice(randomIndex, 1));
  }

  return removedElements;
}

export function getRandom<Type>(array: Type[]): Type {
  return array[getRandomInt(array.length)];
}

export function isEmpty<Type>(array: Type[]): boolean {
  return array.length === 0;
}

export function filterEmpty<Type>(matrix: Type[][]): Type[][] {
  return matrix.filter((list) => !isEmpty(list));
}

export function hasOneElement<Type>(array: Type[]): boolean {
  return array.length === 1;
}

export function shuffle<Type>(array: Type[]): void {
  let currentIndex = array.length,
    randomIndex;

  while (currentIndex !== 0) {
    randomIndex = getRandomInt(currentIndex);
    currentIndex--;

    [array[currentIndex], array[randomIndex]] = [array[randomIndex], array[currentIndex]];
  }
}

export function last<Type>(array: Type[]): Type | undefined {
  return array.at(-1);
}

export function first<Type>(array: Type[]): Type | undefined {
  return array.at(0);
}

export function penultimate<Type>(array: Type[]): Type | undefined {
  return array.at(-2);
}

export function sortByStartingElement<Type>(
  array: Type[],
  predicate: (value: Type, index: number, obj: Type[]) => boolean
): Type[] | undefined {
  const index = array.findIndex(predicate);
  if (index === -1) {
    return undefined;
  }

  const sortedElements = [...array.slice(index)];
  sortedElements.push(...array.slice(0, index));

  return sortedElements;
}

export function sortByEndingElement<Type>(
  array: Type[],
  predicate: (value: Type, index: number, obj: Type[]) => boolean
): Type[] | undefined {
  const index = array.findIndex(predicate);
  if (index === -1) {
    return undefined;
  }
  if (index === array.length - 1) {
    return array;
  }

  const arrayEnding = [...array.slice(0, index + 1)];
  const sortedElements = [...array.slice(index + 1)];
  sortedElements.push(...arrayEnding);

  return sortedElements;
}

export function findLastIndex<Type>(
  array: Type[],
  predicate: (value: Type, index: number, obj: Type[]) => boolean
): number {
  let length = array.length;
  while (length--) {
    if (predicate(array[length], length, array)) {
      return length;
    }
  }
  return -1;
}

export const removeByIndex = <Type>(array: Type[], index: number) => {
  if (index > -1) {
    array.splice(index, 1);
  }
};

export const removeElement = <Type>(array: Type[], predicate: (value: Type, index: number, obj: Type[]) => boolean) => {
  const index = array.findIndex(predicate);
  if (index === -1) {
    throw Error('Element not found');
  }
  removeByIndex(array, index);
};

export const findOccurrenceCount = <Type>(array: Type[], value: Type): number => {
  return array.filter((x) => x === value).length;
};

export const filterUndefined = <Type>(array: (Type | undefined)[]): Type[] => {
  return array.filter((item): item is Type => !!item);
};

export const generateRandomCyclicSequence = <T>(length: number, distinctItems: T[]): T[] => {
  const result: T[] = [];
  const numOfItems = distinctItems.length;
  const startIdx = getRandomInt(numOfItems);
  for (let i = 0; i < length; i++) {
    result.push(distinctItems[(startIdx + i) % numOfItems]);
  }
  return result;
};

export const addIfNotExists = <Type>(array: Type[], newElement: Type): Type[] =>
  array.includes(newElement) ? array : [...array, newElement];

export const haveSameElements = <Type>(array1: Type[], array2: Type[]): boolean => {
  return array1.length === array2.length && array1.every((element) => array2.includes(element));
};

export type Identity = {
  id: string;
};

export const filterById = <Type extends Identity>(array1: Type[], array2: Type[]): Type[] => {
  return array1.filter((elementFrom1) => !array2.some((elementFrom2) => elementFrom2.id === elementFrom1.id));
};
