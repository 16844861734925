import { zIndexes } from '../../constants/components';

export const sx = {
  box: {
    top: 0,
    left: 0,
    position: 'fixed',
    zIndex: zIndexes.movingCardAnimation,
  },
};
