import { ProjectColors } from '../../../themes/mainTheme';
import { Keyframes, keyframes } from '@emotion/react';
import { isMobile } from 'react-device-detect';

const playerStateAnimationKeyframe: Keyframes = keyframes({
  to: { transform: 'scale(1.2);' },
});

const playerBoxSx = {
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 3,
  pb: 2,
};
const playerStateTextSx = {
  textAlign: 'center',
  fontSize: '28px',
  animation: `.5s ${playerStateAnimationKeyframe} .4s infinite alternate`,
};

const mainStatBox = {
  pt: 1,
};

export const sx = {
  mainBox: {
    width: isMobile ? '450px' : '715px',
  },
  stateBox: {
    ...playerBoxSx,
  },
  playerWonText: {
    ...playerStateTextSx,
    color: ProjectColors.GREEN,
  },
  playerLostText: {
    ...playerStateTextSx,
    color: ProjectColors.RED,
  },
  gameFinishedText: {
    textAlign: 'center',
    fontSize: '20px',
  },
  mainStatBox: {
    ...mainStatBox,
  },
  gameStatBox: {
    ...mainStatBox,
    display: 'flex',
    justifyContent: 'space-around',
  },
  roleImage: { height: '75px' },
};
