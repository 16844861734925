import { ProjectColors } from '../../themes/mainTheme';
import { isMobile } from 'react-device-detect';
import { zIndexes } from '../../constants/components';

const borderSx = {
  border: `3px solid ${ProjectColors.GREY}`,
  borderStyle: 'ridge',
  backgroundColor: ProjectColors.PURPLE_VERY_DARK_TRANSPARENT,
};

const defaultBoxSx = {
  mt: '3%',
  ml: 'auto',
  mr: 'auto',
  justifyContent: 'center',
  width: isMobile ? '90%' : '50%',
  minWidth: '300px',
  ...borderSx,
};

export const sx = {
  mainBox: {
    ...defaultBoxSx,
  },
  notificationBox: {
    ...defaultBoxSx,
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
    border: `4px solid ${ProjectColors.PURPLE}`,
  },
  gameIdBox: {
    ...borderSx,
    backgroundColor: ProjectColors.ACTIVE_PLAYER_BACKGROUND,
    display: 'flex',
    alignItems: 'center',
    boxShadow: `1px 1px 5px ${ProjectColors.ACTIVE_PLAYER_BOARDER}`,
    border: `4px solid ${ProjectColors.GREEN}`,
    m: 'auto',
    mt: '3%',
  },
  playersText: { display: 'flex', justifyContent: 'center', mt: '1%' },
  cardGroupInnerBox: {
    mb: '3%',
    mt: '1%',
  },
  storyBox: {
    ...defaultBoxSx,
    mt: 4,
    p: '1%',
    minHeight: '100px',
  },
  playerNameBox: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: 'background.paper',
    boxShadow: 24,
    p: 3,
  },
  cardBox: {
    backgroundColor: 'transparent',
    width: '100%',
    zIndex: zIndexes.one,
  },
  cardNotification: {
    '&:last-child': {
      pb: '16px',
    },
  },
};
