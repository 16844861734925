import React from 'react';
import styles from './installButton.module.css';
import cn from 'classnames';
import { AppContext } from '../../../global/context/appContext';
import InstallDesktopIcon from '@mui/icons-material/InstallDesktop';
import Button from '@mui/material/Button';
import { wrap, WrapperWithProps } from '../../../helpers/wrappers';
import { ListItemButton } from '@mui/material';

declare global {
  interface WindowEventMap {
    beforeinstallprompt: BeforeInstallPromptEvent;
  }
}

interface BeforeInstallPromptEvent extends Event {
  prompt: () => Promise<void>;
  userChoice: Promise<{
    outcome: 'accepted' | 'dismissed';
    platform: string;
  }>;
}

const BEFORE_INSTALL_PROMPT = 'beforeinstallprompt';

export const InstallButton: React.FC = () => {
  const { isMobile } = React.useContext(AppContext);
  const [isVisible, setIsVisible] = React.useState<boolean>(false);
  const [deferredPrompt, setDeferredPrompt] = React.useState<BeforeInstallPromptEvent | null>(null);

  React.useEffect(() => {
    const handleBeforeInstallPrompt = (e: BeforeInstallPromptEvent) => {
      setDeferredPrompt(e);
      setIsVisible(true);
    };

    window.addEventListener(BEFORE_INSTALL_PROMPT, handleBeforeInstallPrompt);

    return () => window.removeEventListener(BEFORE_INSTALL_PROMPT, handleBeforeInstallPrompt);
  }, []);

  const handleInstallClick = () => {
    if (!deferredPrompt) return;

    void deferredPrompt.prompt();

    deferredPrompt.userChoice.then(() => {
      setDeferredPrompt(null);
      setIsVisible(false);
    });
  };

  if (!isVisible) return null;

  const wrappers: WrapperWithProps = isMobile
    ? [ListItemButton, { className: styles.listItemButton }]
    : [Button, { className: cn(styles.container, { [styles.isMobileContainer]: isMobile }) }];

  return wrap(
    <>
      <InstallDesktopIcon fontSize={'small'} />
      <a className={cn(styles.button, { [styles.isMobileButton]: isMobile })} onClick={handleInstallClick}>
        Install
      </a>
    </>,
    [wrappers]
  );
};
