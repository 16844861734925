import React from 'react';
import { Props } from './playerMobile.types';
import { Arsenal } from './arsenal';
import { Radiances } from './radiances';
import styles from './playerMobile.module.css';

export const PlayerMobile: React.FC<Props> = ({
  player,
  blocksDirection,
  roleComponent,
  children,
  isDraggable = false,
}) => {
  const { id, playerName, cardsOnTable, activeRadiances } = player;

  return (
    <div className={styles.mainBox}>
      {roleComponent}
      <Arsenal
        playerId={id}
        playerName={playerName}
        cardsOnTable={cardsOnTable}
        blocksDirection={blocksDirection}
        isDraggable={isDraggable}
      />
      <Radiances
        playerId={id}
        playerName={playerName}
        activeRadiances={activeRadiances}
        blocksDirection={blocksDirection}
        isDraggable={isDraggable}
      />
      {children}
    </div>
  );
};
