import { ProjectColors } from '../../themes/mainTheme';
import {
  AVERAGE_ESSENCE_LIMIT_END,
  AVERAGE_ESSENCE_LIMIT_START,
  DANGEROUS_ESSENCE_LIMIT_END,
  DANGEROUS_ESSENCE_LIMIT_START,
} from './essenceBar.types';
import { zIndexes } from '../../constants/components';
import { SxStyles } from '../../helpers/styles';
import { SxProps } from '@mui/material';
import { isMobile } from 'react-device-detect';
import { BOX_SHADOW_ELEMENT_LOWER_LAYER, TYPOGRAPHY_SHADOW_SX } from '../../constants/styles';

const getEssenceLevelColor = (count: number) => {
  if (count >= DANGEROUS_ESSENCE_LIMIT_START && count <= DANGEROUS_ESSENCE_LIMIT_END) {
    return ProjectColors.PINK;
  } else if (count >= AVERAGE_ESSENCE_LIMIT_START && count <= AVERAGE_ESSENCE_LIMIT_END) {
    return ProjectColors.BLUE;
  } else {
    return ProjectColors.ESSENCE;
  }
};

export const initSx = (count: number, customSx?: SxProps): SxStyles => {
  const definedCustomSx = customSx ?? {};
  return {
    mainBox: {
      position: 'relative',
      width: '100%',
      height: '100%',
      overflow: 'hidden',
      backgroundColor: `${ProjectColors.PURPLE_VERY_DARK_TRANSPARENT}`,
      border: `2px groove ${getEssenceLevelColor(count)}`,
      borderRadius: '30px',
      boxShadow: BOX_SHADOW_ELEMENT_LOWER_LAYER,
      ...definedCustomSx,
    },
    counterBox: {
      position: 'absolute',
      justifyContent: 'center',
      margin: 0,
      padding: 0,
      display: 'flex',
      zIndex: zIndexes.one,
      textAlign: 'center',
      width: '100%',
      height: '100%',
    },
    counter: {
      margin: 'auto',
      fontWeight: '900',
      px: '0.5rem',
      background: `linear-gradient(90deg, transparent, ${ProjectColors.BLACK}, transparent)`,
      fontSize: isMobile ? '0.65rem' : { xs: '0.5rem', sm: '0.7rem', md: '0.8rem', lg: '0.85rem' },
      lineHeight: 'normal',
      ...TYPOGRAPHY_SHADOW_SX,
    },
    essence: {
      position: 'absolute',
      top: '-40%',
      width: isMobile ? '0.02rem' : '0.094rem',
      backgroundColor: isMobile ? `${ProjectColors.PURPLE}` : `${ProjectColors.ESSENCE}`,
      borderRadius: '15%',
      border: isMobile ? 'none' : `0.01rem ridge ${ProjectColors.PURPLE}`,
      height: '200%',
      transformOrigin: 'center',
    },
  };
};
