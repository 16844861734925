import React from 'react';
import { Props } from './widgetsPanel.types';
import { AudioSettingsWidget } from '../../../global/app/widgets/audioSettingsWidget';
import { WidgetsPanel } from './widgetsPanel';

export const GameWidgetsPanel: React.FC<Props> = ({ widgets }) => {
  const permanentWidgets: React.ReactElement[] = [<AudioSettingsWidget key="audio-settings-widget" />];
  const allWidgets = widgets ? [...permanentWidgets, ...widgets] : permanentWidgets;

  return <WidgetsPanel position="right" widgets={allWidgets} />;
};
