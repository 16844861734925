import React from 'react';
import { UseSpaceDistortionEffect } from './useSpaceDistortionEffect.types';
import { GameContext } from '../../../../game/gameContext';
import { last } from '../../../../../helpers/arrays';
import { CardNames } from '../../../cardTypes';
import { toAppliedAction, toImpactedAction, toInProgressAction } from '../../../../game/utils/turnActions';
import { filterIgnoredDefendingActions } from '../../../../game/rules/rules';
import { getCurrentPlayer } from '../../../../game/gameContextHandler/utils/player';

export const useSpaceDistortionEffect: UseSpaceDistortionEffect = () => {
  const { gameState, addTurnAction, reflectAttackingCard, performFinishDefending } = React.useContext(GameContext);

  return () => {
    const currentPlayer = getCurrentPlayer(gameState);
    const lastTurnAction = last(gameState.turnActions)?.action;

    if (
      lastTurnAction === undefined ||
      currentPlayer.id !== gameState.activeDefendingPlayer ||
      lastTurnAction !== toAppliedAction(CardNames.SPACE_DISTORTION)
    ) {
      return;
    }

    addTurnAction({ player: currentPlayer.id, action: toImpactedAction(CardNames.SPACE_DISTORTION) });

    const lastAttackingAction = last(filterIgnoredDefendingActions(gameState.turnActions))?.action;
    if (lastAttackingAction === toInProgressAction(CardNames.CHALLENGE)) {
      performFinishDefending('successfulDefending');
      return;
    }
    reflectAttackingCard();
  };
};
