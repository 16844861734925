export const DELTARATION_API_URL = `${process.env.REACT_APP_DELTARATION_API_URL}`;
export const DELTARATION_API_CARD_URL = `${DELTARATION_API_URL}/cards`;
export const DELTARATION_API_GAMES_URL = `${DELTARATION_API_URL}/games`;
export const DELTARATION_API_AUTH_URL = `${DELTARATION_API_URL}/auth`;
export const DELTARATION_API_STATS_URL = `${DELTARATION_API_URL}/stats`;
export const DELTARATION_API_PLAYERS_URL = `${DELTARATION_API_URL}/players`;
export const DELTARATION_API_MAIN_STATS_URL = `${DELTARATION_API_STATS_URL}/main`;
export const DELTARATION_API_PLAYERS_STATS_URL = `${DELTARATION_API_STATS_URL}/players`;
export const DELTARATION_API_PLAYERS_MAIN_STATS_URL = `${DELTARATION_API_PLAYERS_STATS_URL}/main`;
export const DELTARATION_API_STATS_FINISHED_GAMES_URL = `${DELTARATION_API_STATS_URL}/finished`;
export const DELTARATION_API_PLAYER_URL = (playerId: string) => `${DELTARATION_API_PLAYERS_URL}/${playerId}`;
export const DELTARATION_API_PLAYER_STATS_URL = (playerId: string) => `${DELTARATION_API_PLAYER_URL(playerId)}/stats`;
export const DELTARATION_API_PLAYER_MAIN_STATS_URL = (playerId: string) =>
  `${DELTARATION_API_PLAYER_STATS_URL(playerId)}/main`;
export const DELTARATION_API_GAME_URL = (gameId: string) => `${DELTARATION_API_GAMES_URL}/${gameId}`;
export const DELTARATION_API_JOIN_GAME_URL = (gameId: string) => `${DELTARATION_API_GAME_URL(gameId)}/join`;
export const DELTARATION_API_START_GAME_URL = (gameId: string) => `${DELTARATION_API_GAME_URL(gameId)}/start`;
export const DELTARATION_API_FINISH_GAME_URL = (gameId: string) => `${DELTARATION_API_GAME_URL(gameId)}/finish`;
export const DELTARATION_API_CREATE_GAME_STATE_URL = (gameId: string) => `${DELTARATION_API_GAME_URL(gameId)}/state`;
export const DELTARATION_API_CURRENT_GAME_STATE_URL = (gameId: string) =>
  `${DELTARATION_API_CREATE_GAME_STATE_URL(gameId)}/current`;
export const DELTARATION_API_CREATE_GAME_LOGS_URL = (gameId: string) => `${DELTARATION_API_GAME_URL(gameId)}/logs`;

export const DELTARATION_PREDICTION_URL = `${process.env.REACT_APP_DELTARATION_PREDICTION_URL}`;
export const DELTARATION_PREDICTION_TARGETS_URL = (gameId: string) =>
  `${DELTARATION_PREDICTION_URL}/predict/targets?game=${gameId}`;

export const GAME_TOKEN_STORAGE_KEY = 'game-token';
export const GAME_ID_KEY = 'game-id';
