import { logoStyles, SxStyles } from '../../helpers/styles';
import { CONTENT_BOX_SX } from '../../constants/styles';

export const sxInit = (isMobile: boolean): SxStyles => {
  const detailsSx = isMobile ? { mt: 5, pl: 5, pr: 5 } : { mt: 10, pl: 20, pr: 20 };

  return {
    detailsBox: {
      ...CONTENT_BOX_SX,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      position: 'relative',
    },
    details: { ...detailsSx },
    button: { mt: 10 },
    logoBox: {
      ...logoStyles(),
    },
  };
};
