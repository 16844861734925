import React from 'react';
import { Props } from './helpUkraineWidget.types';
import { Box, CardMedia, IconButton, Tooltip } from '@mui/material';
import { ImagePath } from '../../../../assets/assets';
import { sx } from './helpUkraineWidget.styles';

export const HelpUkraineWidget: React.FC<Props> = () => {
  const handleButtonClick = () => {
    const popUpWindow = window.open('https://u24.gov.ua/', '_blank', 'width=500,height=700');
    if (popUpWindow) popUpWindow.focus();
  };

  const tooltipText = <React.Fragment>Official fundraising platform of Ukraine 🇺🇦</React.Fragment>;

  return (
    <React.Fragment>
      <Tooltip title={tooltipText} placement={'bottom-end'}>
        <Box>
          <IconButton onClick={handleButtonClick} sx={sx.button}>
            <CardMedia component="img" alt="help-ukraine-widget" image={ImagePath.helpUkraine} sx={sx.cardMedia} />
          </IconButton>
        </Box>
      </Tooltip>
    </React.Fragment>
  );
};
