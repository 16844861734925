import React from 'react';
import { Props } from './cardDrawer.types';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import Button from '@mui/material/Button';
import CloseIcon from '@mui/icons-material/Close';
import { cardToComponent } from '../../helpers/transformers';
import { NORMAL_TO_EXTENDED_SIZE, SizeExtended } from '../../helpers/sizes';
import { Card } from '../card';
import { sxInit } from './cardDrawer.styles';
import { AppContext } from '../../global/context/appContext';
import { useCurrentSize } from '../../features/card/hooks/useCurrentSize';
import { useCardDimension } from '../card/hooks/useCardDimension';

export const CardDrawer: React.FC<Props> = ({ card, isOpen, openDrawer, closeDrawer }) => {
  const { windowSize } = React.useContext(AppContext);
  const cardSize = NORMAL_TO_EXTENDED_SIZE[useCurrentSize()];
  const { dimension } = useCardDimension({ size: cardSize });

  const sx = sxInit(windowSize, dimension);

  return (
    <div>
      <React.Fragment key="card-drawer">
        <SwipeableDrawer
          variant="persistent"
          anchor="bottom"
          open={isOpen}
          onClose={closeDrawer}
          onOpen={openDrawer}
          PaperProps={{ sx: sx.drawer }}
        >
          <Button onClick={closeDrawer} sx={sx.button}>
            <CloseIcon />
          </Button>
          <Card {...cardToComponent(card, SizeExtended.L)} />
        </SwipeableDrawer>
      </React.Fragment>
    </div>
  );
};
