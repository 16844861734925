import { ProjectColors } from '../../../../themes/mainTheme';
import { CURRENT_PLAYER_MOBILE_HEIGHT_MODIFIER } from './currentPlayerMobile.types';
import { zIndexes } from '../../../../constants/components';
import { PLAYER_STATE_BOX_SX } from '../../../../constants/styles';

const defaultSx = { pt: 1, borderTop: `1px solid ${ProjectColors.BLACK}` };

export const sxInit = (cardDimension: number, windowWidth: number) => {
  return {
    outerBox: { width: '100%', bottom: 0, position: 'fixed', zIndex: zIndexes.one },
    innerBox: {
      ...defaultSx,
      display: 'flex',
      width: '100%',
      height: cardDimension * CURRENT_PLAYER_MOBILE_HEIGHT_MODIFIER,
      border: '0.2rem',
    },
    stateBox: {
      ...PLAYER_STATE_BOX_SX,
      zIndex: -1,
    },
    rowsBox: {
      width: windowWidth,
      px: 0.5,
    },
    bottomRow: {
      pt: '0.45rem',
      display: 'flex',
      width: '100%',
      justifyContent: 'space-between',
    },
    button: {
      maxWidth: cardDimension * 1.1,
      height: cardDimension,
      mx: '1.5rem',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
    },
    capsuleBox: { height: cardDimension * 0.22, mb: 0.5 },
    profile: { position: 'inherit', px: '1.5rem' },
  } as const;
};
