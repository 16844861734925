import { ProjectColors } from '../../../../../themes/mainTheme';

export const sx = {
  button: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    ':hover': { background: 'transparent' },
  },
  logo: {
    borderRadius: '100%',
    width: '30px',
    height: '30px',
    backgroundColor: ProjectColors.WHITE,
    mr: 1,
    p: 0.2,
  },
};
