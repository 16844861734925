import React, { useEffect, useState } from 'react';
import { Box, IconButton } from '@mui/material';
import { VolumeDown, VolumeUp } from '@mui/icons-material';
import { sx } from './soundSettingsBlock.styles';
import { getNumberValue, setValue } from '../../helpers/featureFlags';
import {
  DEFAULT_PLAYERS_VOLUME_LEVEL,
  MAXIMUM_VOLUME_LEVEL,
  MINIMUM_VOLUME_LEVEL,
  VOLUME_CHANGE_STEP,
} from '../../constants/components';
import { Props } from './soundsSettingsBlock.types';
import { SoundPath } from '../../assets/assets';
import { usePlaySound } from '../../helpers/hooks/usePlaySound';

export const SoundSettingsBlock: React.FC<Props> = ({ settingsLabel }) => {
  const [volume, setVolume] = useState(getNumberValue(settingsLabel) ?? DEFAULT_PLAYERS_VOLUME_LEVEL);
  const [isVolumeCheckNeeded, setIsVolumeCheckNeeded] = useState(false);

  const { play } = usePlaySound();

  useEffect(() => {
    setValue(settingsLabel, volume.toString());
  }, [volume]);

  useEffect(() => {
    if (!isVolumeCheckNeeded) return;

    play(volume, SoundPath.volumeLevel);
    setIsVolumeCheckNeeded(false);
  }, [isVolumeCheckNeeded]);

  const increaseVolume = () => {
    setVolume((prevVolume) => Math.min(prevVolume + VOLUME_CHANGE_STEP, MAXIMUM_VOLUME_LEVEL));
    setIsVolumeCheckNeeded(true);
  };

  const decreaseVolume = () => {
    setVolume((prevVolume) => Math.max(prevVolume - VOLUME_CHANGE_STEP, MINIMUM_VOLUME_LEVEL));
    setIsVolumeCheckNeeded(true);
  };

  return (
    <Box sx={sx.widgets}>
      <IconButton disabled={volume <= MINIMUM_VOLUME_LEVEL} onClick={decreaseVolume}>
        <VolumeDown />
      </IconButton>
      <IconButton disabled={volume >= MAXIMUM_VOLUME_LEVEL} onClick={increaseVolume}>
        <VolumeUp />
      </IconButton>
    </Box>
  );
};
