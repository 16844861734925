import React from 'react';
import { Props } from './hand.types';
import { DragSource } from '../../../../../components/draggable';
import { getAllowedCardsInHandCount } from '../../../../game/rules/rules';
import { GameContext } from '../../../../game/gameContext';
import { Box } from '@mui/material';
import { initSx } from './hand.styles';
import { Droppable } from '../../../droppable';
import { ApplyRuleGroup, PlayingCard } from '../../../../card/cardTypes';
import { useCardDimension } from '../../../../../components/card/hooks/useCardDimension';
import { ActionDrawerContext } from '../../../../room/mobile/contexts/actionDrawerContext/actionDrawerContext';
import { CardLContent } from '../../../../room/mobile/components/actionDrawer/cardLContent';
import { SelectorType } from '../../../../../components/card';
import { AppContext } from '../../../../../global/context/appContext';
import { getCurrentPlayer } from '../../../../game/gameContextHandler/utils/player';
import { roomItems, useTrackLocation } from '../../../../room/hooks/useTrackLocation';
import { useCardDrop } from '../../hooks/useCardDrop';
import { VerticalLabel } from '../../../../../components/verticalLabel';
import { CardGroup } from '../../../../../components/cardGroup';

export const Hand: React.FC<Props> = ({ size }) => {
  const { windowSize } = React.useContext(AppContext);
  const { gameState, isDraggable, isAllowedToDrag } = React.useContext(GameContext);
  const player = getCurrentPlayer(gameState);

  const { canDropToHand, onDropToHand } = useCardDrop({
    playerId: player.id,
    cardsOnTable: player.cardsOnTable,
  });

  const { setContent, setName } = React.useContext(ActionDrawerContext);

  const onCardShow = (openedCard: PlayingCard) => {
    setContent(<CardLContent card={openedCard} />);
    setName('Card from the hand');
  };

  const isDefendingNow = gameState.activeDefendingPlayer === player.id;
  const isActing = !gameState.isFinished && (gameState.activePlayer === player.id || isDefendingNow);

  const { dimension: cardDimension } = useCardDimension({ size: size });

  const allowedCardInHandCount = getAllowedCardsInHandCount(player, gameState.playersOrdered);
  const cardsInHandCount = Math.max(allowedCardInHandCount, player.cardsInHand.length);
  const sx = initSx(cardDimension, windowSize.width, cardsInHandCount);

  const { ref } = useTrackLocation({ items: [roomItems.playerHand(player.id)] });
  return (
    <Box ref={ref} sx={allowedCardInHandCount >= player.cardsInHand.length ? sx.hand.withinLimit : sx.hand.overLimit}>
      <Droppable
        playerId={player.id}
        applyRuleGroup={ApplyRuleGroup.ALL}
        onItemDropped={onDropToHand}
        canDrop={canDropToHand}
      >
        <Box sx={sx.hand.innerBox}>
          <Box sx={sx.hand.labelBox}>
            <VerticalLabel
              name={'mobile-hand'}
              labelBlocks={[player.cardsInHand.length, '/', allowedCardInHandCount]}
              variant={allowedCardInHandCount < player.cardsInHand.length ? 'red' : 'default'}
            />
          </Box>
          <CardGroup
            isActive={isAllowedToDrag(player.id) && isDraggable(player.id)}
            cards={player.cardsInHand}
            size={size}
            label={undefined}
            placeholdersCount={allowedCardInHandCount}
            onCardClick={onCardShow}
            sourceType={DragSource.PLAYER}
            owningPlayerId={player.id}
            selectorType={isActing ? SelectorType.MULTIPLE_ON_CTRL : undefined}
            styles={{ mainBox: sx.hand.cardGroup }}
          />
        </Box>
      </Droppable>
    </Box>
  );
};
