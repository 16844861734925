import { PlayingCard } from '../../features/card/cardTypes';
import { SizeExtended } from '../../helpers/sizes';
import { DragSource } from '../draggable';
import { SelectorType } from '../card';
import { SxProps } from '@mui/material';
import { Theme } from '@mui/material/styles';

export type CardGroupStyles = {
  mainBox?: SxProps<Theme>;
};

export type Props = {
  isActive: boolean;
  cards: PlayingCard[];
  size: SizeExtended;
  label?: string;
  placeholdersCount: number;
  onCardClick?: (card: PlayingCard) => void;
  onCardMouseEnter?: (card: PlayingCard) => void;
  onCardMouseLeave?: () => void;
  sourceType?: DragSource;
  owningPlayerId?: string;
  selectorType?: SelectorType;
  useCompactGrid?: boolean;
  cardsHaveActiveAnimation?: boolean;
  styles?: CardGroupStyles;
};

export const areSameCards = (cardArray1: PlayingCard[], cardArray2: PlayingCard[]): boolean => {
  if (cardArray1.length !== cardArray2.length) {
    return false;
  }

  for (let i = 0; i < cardArray1.length; i++) {
    if (cardArray1[i].id !== cardArray2[i].id || cardArray1[i].allowedApplyTypes !== cardArray2[i].allowedApplyTypes) {
      return false;
    }
  }

  return true;
};
