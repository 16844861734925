import React from 'react';
import { Props } from './role.types';
import { Box } from '@mui/material';
import { Droppable } from '../../../droppable';
import { ApplyRuleGroup } from '../../../../card/cardTypes';
import { Card } from '../../../../../components/card';
import { cardToComponent } from '../../../../../helpers/transformers';
import { CardLContent } from '../../../../room/mobile/components/actionDrawer/cardLContent';
import { DraggedCardData } from '../../../../../components/draggable';
import { canApplyToOtherPlayer } from '../../../../game/rules/rules';
import { definePlayerStateByGameState } from '../../../../../helpers/player';
import { getPlayerBoxSxFromState } from '../../../../../helpers/styles';
import { DisplayMode } from '../../../player.types';
import { GameContext } from '../../../../game/gameContext';
import { ActionDrawerContext } from '../../../../room/mobile/contexts/actionDrawerContext/actionDrawerContext';
import { LogContext } from '../../../../game/logContextProvider';
import { PLAYER_STATE_BOX_SX } from '../../../../../constants/styles';
import { SkipActionButton } from '../../../../../components/skipActionButton';

export const Role: React.FC<Props> = ({ player, size, onDroppedItem }) => {
  const { id, roleCard, playerName } = player;
  const { gameState, idlePlayer } = React.useContext(GameContext);
  const { setContent, setName } = React.useContext(ActionDrawerContext);
  const { setNotification } = React.useContext(LogContext);

  const canDrop = (item: DraggedCardData): boolean => canApplyToOtherPlayer(item, gameState);

  const playerState = definePlayerStateByGameState(id, gameState);
  const playerBoxSx = getPlayerBoxSxFromState(playerState, DisplayMode.OTHER_PLAYER_MOBILE, true);

  const onRoleShow = () => {
    if (!roleCard.isOpen) return setNotification({ type: 'warning', text: `${playerName}'s role is hidden` });

    setContent(<CardLContent card={roleCard} />);
    setName(`${playerName}'s role`);
  };

  return (
    <>
      {idlePlayer === player.id && <SkipActionButton displayMode={DisplayMode.MOBILE} idlePlayer={player} />}
      <Box onClick={onRoleShow}>
        <Box
          key={'other-player-mobile-animated-box' + id + playerState}
          sx={{
            ...playerBoxSx,
            ...PLAYER_STATE_BOX_SX,
          }}
        />
        <Droppable playerId={id} applyRuleGroup={ApplyRuleGroup.ALL} onItemDropped={onDroppedItem} canDrop={canDrop}>
          <Card {...cardToComponent(roleCard, size)} showImageOnly={true} />
        </Droppable>
      </Box>
    </>
  );
};
