import { ProjectColors } from '../../../../../themes/mainTheme';

export const initSx = (cardDimension: number, windowWidth: number, cardsInHand: number) => {
  const labelReservedWidth = 1.8;

  const hand = {
    px: 0.3,
    borderInline: `1px solid ${ProjectColors.BLACK}`,
    maxWidth: `calc(${windowWidth}px - ${cardDimension * 3.65}px)`,
    width: cardsInHand * cardDimension * 1.1 + labelReservedWidth,
  };
  return {
    hand: {
      withinLimit: hand,
      overLimit: { ...hand, backgroundColor: `${ProjectColors.PINK}` },
      innerBox: {
        position: 'relative',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      },
      labelBox: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        position: 'absolute',
        left: 0,
      },
      cardGroup: {
        pl: labelReservedWidth,
      },
    },
  };
};
