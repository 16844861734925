import React from 'react';

export type Props = {
  children?: React.ReactNode;
};

export type LogType = 'success' | 'info' | 'warning' | 'error';
export type FetchLogsAction = () => Promise<void>;

export type Log = {
  type: LogType;
  text: string;
};

export type LogContextType = {
  logs: Log[];
  createLog: (log: Log) => void;
  createRealtimeLog: (log: Log) => void;
  applyLogs: (logs: Log[]) => void;
  fetchLogs: FetchLogsAction;
  notification: Log | undefined;
  setNotification: (log: Log) => void;
  isLogJournalOpen: boolean;
  setIsLogJournalOpen: (isOpen: boolean) => void;
};

export const LogContext = React.createContext({} as LogContextType);

export const LOG_JOURNAL_NAME = 'Game log journal';
