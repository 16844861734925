import React from 'react';
import { UseReplaceEngagementZoneCards } from './useReplaceEngagementZoneCards.types';
import { GameContext } from '../../../../gameContext';
import { LocationTrackerContext } from '../../../../locationTrackerContextProvider';
import { AnimationContext } from '../../../../animationContextProvider';
import { ATTACKING_CARDS, PlayingCard } from '../../../../../card/cardTypes';
import { filterUndefined, isEmpty } from '../../../../../../helpers/arrays';

export const useReplaceEngagementZoneCards: UseReplaceEngagementZoneCards = () => {
  const { gameState, addTurnAction } = React.useContext(GameContext);
  const { getItemLocation } = React.useContext(LocationTrackerContext);
  const { queueMovingCardsProps } = React.useContext(AnimationContext);

  const replaceEngagementZoneCards = (card: PlayingCard): Promise<void> =>
    new Promise((resolve) => {
      if (isEmpty(gameState.cardsInPlayingArea) || !ATTACKING_CARDS.includes(card.name)) {
        resolve();
        return;
      }

      const cardsToDiscard = [...gameState.cardsInPlayingArea];
      gameState.cardsInDiscardPile.push(...cardsToDiscard);
      gameState.cardsInPlayingArea = [];

      addTurnAction({
        player: gameState.activePlayer,
        action: 'cardsDiscardedFromEngagementZone',
        cardIds: cardsToDiscard.map((cardToDiscard) => cardToDiscard.id),
      });

      const stops = filterUndefined([getItemLocation('engagementZone'), getItemLocation('discardPile')]);

      queueMovingCardsProps([{ stops: stops, cards: cardsToDiscard, runAfter: resolve }]);
    });

  return {
    replaceEngagementZoneCards: replaceEngagementZoneCards,
  };
};
