import React from 'react';
import {
  ANIMATION_CYCLE_DURATION_SECONDS,
  Props,
  ANIMATION_DISTANCE,
  ANIMATION_RESERVE_MULTIPLIER,
} from './animatedStarsBlock.types';
import { keyframes } from '@emotion/react';
import { SECOND_IN_MILLISECONDS } from '../../constants/time';
import { Box } from '@mui/material';
import { sx } from './animatedStarsBlock.styles';
import { AppContext } from '../../global/context/appContext';

export const AnimatedStarsBlock: React.FC<Props> = () => {
  const { windowSize } = React.useContext(AppContext);

  const [style, setStyle] = React.useState({});

  React.useEffect(() => {
    if (windowSize.height === 0) return;

    const timer = setInterval(() => {
      const angle1 = Math.random() * Math.PI * 2;
      const angle2 = Math.random() * Math.PI * 2;
      const animationDurationSeconds = 2 + Math.random() * 3;
      const animationDelaySeconds = ANIMATION_CYCLE_DURATION_SECONDS - animationDurationSeconds;

      const fallingStar = keyframes`
        from {
          opacity: 1;
          transform: translate(0px, 0px) scale(1);
        }
        to {
          opacity: 0;
          transform: translate(${Math.cos(angle1) * ANIMATION_DISTANCE}px, ${
        Math.sin(angle2) * ANIMATION_DISTANCE
      }px) scale(2);
        }
      `;

      const topShift =
        ANIMATION_DISTANCE > windowSize.height
          ? windowSize.height * ANIMATION_RESERVE_MULTIPLIER
          : windowSize.height - ANIMATION_DISTANCE;
      const leftShift =
        ANIMATION_DISTANCE > windowSize.width
          ? windowSize.width * ANIMATION_RESERVE_MULTIPLIER
          : windowSize.width - ANIMATION_DISTANCE;

      setStyle({
        top: `${Math.random() * topShift}px`,
        left: `${Math.random() * leftShift}px`,
        animationDuration: `${animationDurationSeconds}s`,
        animationDelay: `${animationDelaySeconds}s`,
        animationName: `${fallingStar}`,
      });
    }, ANIMATION_CYCLE_DURATION_SECONDS * SECOND_IN_MILLISECONDS);

    return () => clearInterval(timer);
  }, [windowSize]);

  return (
    <Box sx={sx.mainBox}>
      <Box sx={{ ...sx.fillingStars, ...style }}></Box>
    </Box>
  );
};
