import React from 'react';
import { Box } from '@mui/material';
import { sx } from './mobileHeader.styles';
import { Menu } from './menu';
import { Logo } from './logo';

export const MobileHeader: React.FC = () => {
  const [isMenuOpen, setIsMenuOpen] = React.useState<boolean>(false);
  const toggleMenu = () => setIsMenuOpen(!isMenuOpen);

  return (
    <Box sx={sx.outerBox} data-testid="mobile-header">
      <Logo data-testid="mobile-menu-logo-open" isOpen={isMenuOpen} toggleMenu={toggleMenu} isVisible={!isMenuOpen} />
      <Menu data-testid="mobile-menu" isOpen={isMenuOpen} setIsOpen={setIsMenuOpen} />
    </Box>
  );
};
