import React from 'react';
import { DraggedCardData } from '../../../../components/draggable';
import { UseEngagementZoneDrop } from './useEngagementZoneDrop.types';
import { hasOneElement } from '../../../../helpers/arrays';
import { GameContext } from '../../../game/gameContext';
import { canPlayerChooseEnergySource } from '../../../game/rules/rules';
import { getCurrentPlayer } from '../../../game/gameContextHandler/utils/player';
import { AllowedApplyType } from '../../../card/cardTypes';
import { useApplyCardAction } from '../../../game/actions/hooks/useApplyCardAction';

export const useEngagementZoneDrop: UseEngagementZoneDrop = ({ setDroppedCard }) => {
  const { gameState } = React.useContext(GameContext);
  const { applyCardToEngagementZone } = useApplyCardAction();

  const currentPlayer = getCurrentPlayer(gameState);
  if (currentPlayer === undefined) {
    throw Error('Cannot load room without current player.');
  }

  const onDrop = (item: DraggedCardData) => {
    if (
      !item.fromPlayerId ||
      !hasOneElement(item.cards) ||
      !item.cards[0].allowedApplyTypes.includes(AllowedApplyType.PLAYING_AREA)
    ) {
      return;
    }

    if (canPlayerChooseEnergySource(currentPlayer, item.cards[0])) {
      setDroppedCard(item);
      return;
    }

    applyCardToEngagementZone(item.fromPlayerId, item.cards[0], item.dragSource);
  };

  const canDrop = (item: DraggedCardData) =>
    hasOneElement(item.cards) && item.cards[0].allowedApplyTypes.includes(AllowedApplyType.PLAYING_AREA);

  return {
    onDrop: onDrop,
    canDrop: canDrop,
  };
};
