import { ApiAuthRequest } from '../../gameTypes';
import { GAME_TOKEN_STORAGE_KEY } from '../../../../constants/delarationApi';

export const createApiAuthRequest = (): ApiAuthRequest => {
  const gameToken = localStorage.getItem(GAME_TOKEN_STORAGE_KEY);

  if (gameToken === null) {
    throw Error('Current game token is not defined');
  }

  return {
    'game-token': gameToken,
  };
};
