import React from 'react';
import { Box, Typography } from '@mui/material';
import { AppContext } from '../../global/context/appContext';
import { Logo } from '../../components/logo/logo';
import { MotionBlock } from '../../components/motionBlock';
import { sxInit } from './errorPage.styles';
import { ErrorDetails, Props } from './errorPage.types';
import { useNavigate, useParams } from 'react-router-dom';
import Button from '@mui/material/Button';
import { ROOT_ROUTE } from '../../constants/routes';
import { ContentPageWrapper } from '../../components/contentPageWrapper';

export const ErrorPage: React.FC<Props> = () => {
  const { isMobile } = React.useContext(AppContext);
  const { errorType } = useParams();
  const navigate = useNavigate();

  const sx = sxInit(isMobile);

  const resolveDetails = (type: string | undefined): ErrorDetails => {
    switch (type) {
      case 'accessDenied':
        return {
          title: 'Access denied',
          details:
            'Something went wrong with your session. It looks like your token is invalid or has expired. Please try to sign in once again',
        };
      case 'notFound':
        return {
          title: '404 Not Found',
          details: 'It looks like the page you are trying to access does not exist',
        };
      case 'mobileDeviceError':
        return {
          title: 'Limited Mobile Support',
          details: 'Games can only be played on the Desktop devices now. Full mobile support for games coming soon',
        };
      default:
        return {
          title: 'Unexpected error',
          details: 'Oops. Something went wrong. Please try again',
        };
    }
  };

  const { title, details } = resolveDetails(errorType);

  const outerElements = (
    <Box sx={sx.logoBox}>
      <MotionBlock transitionDuration={1}>
        <Logo />
      </MotionBlock>
    </Box>
  );

  const innerElements = (
    <MotionBlock transitionDuration={1.5}>
      <Box sx={sx.detailsBox}>
        <Typography align="center" variant="h4">
          {title}
        </Typography>
        <Typography align="center" sx={sx.details}>
          {details}
        </Typography>
        <Button variant="outlined" onClick={() => navigate(ROOT_ROUTE)} sx={sx.button}>
          <Typography>Go home</Typography>
        </Button>
      </Box>
    </MotionBlock>
  );

  return <ContentPageWrapper outerBoxElements={outerElements} innerBoxElements={innerElements} />;
};
