import React from 'react';

export type Props = {
  children?: React.ReactNode;
};

export type Location = {
  x: number;
  y: number;
};

export type GetItemLocationFunc = (item: string) => Location | undefined;
export type LocationTrackerContextType = {
  getItemLocation: GetItemLocationFunc;
  setItem: (item: string, ref?: React.RefObject<HTMLDivElement>) => void;
};

export const LocationTrackerContext = React.createContext({} as LocationTrackerContextType);
