import { useSearchParams } from 'react-router-dom';
import React from 'react';
import { ApiAuthRequest, AuthProvider } from '../../../auth/authTypes';
import AuthApi from '../../../auth/authApi';
import { DISCORD_REDIRECT_URL, PROFILE_STORAGE_KEY } from '../../../../constants/login';
import { useSnackbar } from 'notistack';
import { AppContext } from '../../../../global/context/appContext';

export const useDiscordLogin = () => {
  const { refreshProfile } = React.useContext(AppContext);
  const [searchParams, setSearchParams] = useSearchParams();
  const { enqueueSnackbar } = useSnackbar();

  const authenticate = async (credential: string, authProvider: AuthProvider) => {
    try {
      const request: ApiAuthRequest = {
        credential: credential,
        authProvider: authProvider,
        redirectUrl: DISCORD_REDIRECT_URL,
      };

      const response = await AuthApi.auth(request);
      localStorage.setItem(PROFILE_STORAGE_KEY, JSON.stringify(response));
      refreshProfile();
      enqueueSnackbar('Successfully signed in with Discord', { variant: 'success' });
    } catch (e) {
      enqueueSnackbar('Failed to login with Discord', { variant: 'error' });
    } finally {
      setSearchParams(new URLSearchParams());
    }
  };

  React.useEffect(() => {
    const searchParamKeys = ['code', 'provider'];
    const [code, provider] = searchParamKeys.map((key) => searchParams.get(key));
    searchParamKeys.forEach((key) => searchParams.delete(key));
    if (!provider || !code || provider !== AuthProvider.DISCORD) return;

    void authenticate(code, provider);
  }, [searchParams]);

  return null;
};
