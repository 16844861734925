import React from 'react';
import { IconButton, Tooltip } from '@mui/material';
import { ListAlt } from '@mui/icons-material';
import { LOG_JOURNAL_NAME, LogContext } from '../../game/logContextProvider';
import styles from './logWidget.module.css';
import { isMobile } from 'react-device-detect';
import cn from 'classnames';

export const LogWidget: React.FC = () => {
  const { isLogJournalOpen, setIsLogJournalOpen } = React.useContext(LogContext);

  const toggle = () => {
    setIsLogJournalOpen(!isLogJournalOpen);
  };

  return (
    <div>
      <React.Fragment>
        <Tooltip title={<p className={styles.tooltip}>{LOG_JOURNAL_NAME}</p>} disableInteractive>
          <IconButton onClick={toggle} className={styles.iconButton}>
            <ListAlt
              className={cn(styles.iconElement, {
                [styles.isMobile]: isMobile,
              })}
            />
          </IconButton>
        </Tooltip>
      </React.Fragment>
    </div>
  );
};
