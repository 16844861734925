import React from 'react';

export type ActionDrawerContextType = {
  content: React.ReactElement | undefined;
  setContent: (content: React.ReactElement | undefined) => void;
  name: string;
  setName: (name: string) => void;
};

export const ActionDrawerContext = React.createContext({} as ActionDrawerContextType);
