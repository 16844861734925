import { DroppableGroup, EnableDragAndDropFunc, UseDragAndDrop } from './useDragAndDrop.types';
import React from 'react';

export const useDragAndDrop: UseDragAndDrop = ({ gameState }) => {
  const isDraggable = (playerId: string): boolean => !gameState.isFinished && gameState.draggable === playerId;
  const isDroppable = (playerId: string): boolean => gameState.droppableList.includes(playerId);
  const isAllowedToDrag = (playerId: string) => !gameState.isFinished && gameState.dragger === playerId;
  const [isDragging, setIsDragging] = React.useState(false);

  const enableDragAndDrop: EnableDragAndDropFunc = (
    draggablePlayerId: string,
    droppableGroup: DroppableGroup,
    draggerId: string
  ) => {
    gameState.draggable = gameState.playersOrdered.filter((id) => id === draggablePlayerId)[0];
    switch (droppableGroup) {
      case DroppableGroup.ALL:
        gameState.droppableList = gameState.playersOrdered;
        break;
      case DroppableGroup.ALL_BUT_DRAGGABLE:
        gameState.droppableList = gameState.playersOrdered.filter((id) => id !== draggablePlayerId);
        break;
      case DroppableGroup.NONE:
        gameState.droppableList = [];
        break;
      case DroppableGroup.DRAGGABLE:
        gameState.droppableList = [draggablePlayerId];
        break;
    }
    gameState.dragger = draggerId;
  };

  return {
    isDraggable: isDraggable,
    isDroppable: isDroppable,
    isAllowedToDrag: isAllowedToDrag,
    enableDragAndDrop: enableDragAndDrop,
    isDragging: isDragging,
    setIsDragging: setIsDragging,
  };
};
