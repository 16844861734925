import { getBackgroundBoxSx, roomCommonStyles } from '../../../helpers/styles';
import { WindowSize } from '../../../helpers/utils';
import { zIndexes } from '../../../constants/components';

export const initSx = (windowSize: WindowSize) => {
  return {
    mainBox: { ...getBackgroundBoxSx(windowSize, true), overflowY: 'hidden', p: 0 },
    widgets: { position: 'relative', zIndex: zIndexes.roomWidgets },
    ...roomCommonStyles(windowSize),
  };
};
