import React from 'react';
import { HelpUkraineWidget } from '../../../global/app/widgets/helpUkraineWidget';
import { WidgetsPanel } from './widgetsPanel';
import { HelpProjectWidget } from '../../../global/app/widgets/helpProjectWidget';
import { OverwritingSx } from '../../../helpers/types';

export const DonationWidgetsPanel: React.FC<OverwritingSx> = ({ newSx }) => {
  const permanentWidgets: React.ReactElement[] = [
    <HelpUkraineWidget key="help-ukraine-widget" />,
    <HelpProjectWidget key="help-project-widget" />,
  ];

  return <WidgetsPanel position="left" widgets={permanentWidgets} newSx={newSx} />;
};
