import React, { useState } from 'react';
import { Props } from './onboardingModal.types';
import { isFlagOn, setFlag } from '../../../../helpers/featureFlags';
import { ONBOARDING_COMPLETE_FLAG } from '../../../../constants/featureFlags';
import { OnboardingVideos } from '../../../../components/onboardingVideos';
import { Modal } from '../../../../components/modal';

export const OnboardingModal: React.FC<Props> = ({ isOpen, onClose }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  React.useEffect(() => {
    setIsModalOpen(isOpen || !isFlagOn(ONBOARDING_COMPLETE_FLAG));
  }, [isOpen]);

  const closeWillingly = () => {
    setFlag(ONBOARDING_COMPLETE_FLAG, true);
    setIsModalOpen(false);
    onClose();
  };

  return (
    <Modal isOpen={isModalOpen} onClose={closeWillingly}>
      <OnboardingVideos />
    </Modal>
  );
};
