import { UseGameStats } from './useActiveGames.types';
import React from 'react';
import { ApiContext } from '../../../../global/app/api/apiContext';
import { GameApiData } from '../../../../features/game/gameContextHandler/hooks/useGameState';

export const useActiveGames: UseGameStats = () => {
  const { gameApi } = React.useContext(ApiContext);

  const [pendingGames, setPendingGames] = React.useState<GameApiData[]>([]);
  const [activeGames, setActiveGames] = React.useState<GameApiData[]>([]);

  const fillActiveGamesData = async () => {
    const allPendingGames = await gameApi.getAllGames('pending');
    setPendingGames(allPendingGames);

    const allActiveGames = await gameApi.getAllGames('active');
    setActiveGames(allActiveGames);
  };

  return {
    fillActiveGamesData: fillActiveGamesData,
    pendingGames: pendingGames,
    activeGames: activeGames,
  };
};
