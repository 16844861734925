import { ProjectColors } from '../../themes/mainTheme';
import { verticalScrollbarStyle } from '../../helpers/styles';
import { SizeNormal } from '../../helpers/sizes';

export const sxInit = (useDarkStyle: boolean) => {
  const boxBackGroundColor = useDarkStyle ? ProjectColors.PURPLE_VERY_DARK_TRANSPARENT : ProjectColors.TRANSPARENT;

  return {
    mainBox: { ...verticalScrollbarStyle(SizeNormal.M) },
    search: {
      box: { display: 'flex', justifyContent: 'center', backgroundColor: boxBackGroundColor },
      textField: { width: '50%', mb: '40px' },
    },
  };
};
