import React from 'react';
import { Props } from './gameStatsBlock.types';
import { Box } from '@mui/material';
import { AppContext } from '../../global/context/appContext';
import { useGameStats } from './hooks/useGameStats';
import { MainGameStatsBlock } from './mainGameStatsBlock';
import { LastPlayedGamesStatsBlock } from './lastPlayedGamesStatsBlock';
import { PlayersMainStatsBlock } from './playersMainStatsBlock';
import { TabbedContent, TabElement, TabHeader } from '../tabbedContent';
import { useActiveGames } from './hooks/useActiveGames';
import { ActiveGamesBlock } from './activeGamesBlock';

export const GameStatsBlock: React.FC<Props> = () => {
  const { isMobile } = React.useContext(AppContext);

  const { fillGamesData, mainStatsData, lastFinishedGames, playersStats } = useGameStats();
  const { fillActiveGamesData, activeGames, pendingGames } = useActiveGames();

  React.useEffect(() => {
    void fillGamesData();
    void fillActiveGamesData();
  }, []);

  const gamesArePlayedNow = activeGames.length + pendingGames.length > 0;

  const tabHeaders: TabHeader[] = [
    { name: 'Playing now', show: gamesArePlayedNow, isDisabled: false },
    { name: 'Main Stats', show: true, isDisabled: false },
    { name: 'Last Games', show: !gamesArePlayedNow || !isMobile, isDisabled: false },
    { name: 'All time Winners', show: true, isDisabled: false },
  ];

  const tabElements: TabElement[] = [
    { show: gamesArePlayedNow, element: <ActiveGamesBlock pendingGames={pendingGames} activeGames={activeGames} /> },
    { show: true, element: <MainGameStatsBlock mainStatsData={mainStatsData} /> },
    { show: true, element: <LastPlayedGamesStatsBlock lastFinishedGames={lastFinishedGames} /> },
    { show: true, element: <PlayersMainStatsBlock playersMainStats={playersStats} /> },
  ];

  return (
    <Box>
      <TabbedContent defaultTabIndex={0} tabHeaders={tabHeaders} tabs={tabElements} />
    </Box>
  );
};
