import { ProjectColors } from '../../../../themes/mainTheme';
import { SxStyles } from '../../../../helpers/styles';

export const sx: SxStyles = {
  nameBlock: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    py: '0.7rem',
    mt: '0.7rem',
    border: `0.1rem solid ${ProjectColors.ESSENCE_TRANSPARENT}`,
    borderRadius: '0.7rem',
  },
  nameAsPart: {
    fontSize: '0.7rem',
    mx: '0.5rem',
  },
  namePart: {
    fontSize: '0.9rem',
    color: ProjectColors.SECONDARY,
    mr: 1,
  },
  editButton: {
    px: 1,
    py: 0.4,
    minWidth: '25px',
  },
};
