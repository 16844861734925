import { CardNames, EnergyColors, PlayingCardType, Role } from '../card/cardTypes';
import { GameMode, NumberOfPlayers } from '../game/gameTypes';
import { ValuesType, WindowSize } from '../../helpers/utils';
import { MEDIUM_SIZE_WIDTH, SMALL_WIDTH } from '../../constants/components';
import { EssenceCount, getEssenceCountByNumberOfPlayers, getRolesByNumberOfPLayers } from '../game/rules/roles';

export type Page = 'Game' | 'Rules';

export type Props = {
  page: Page;
  story: string[];
  rules: Rule[];
  cardDetails: CardTypeDetails[];
  defaultSearch?: string;
  useDarkStyle?: boolean;
};

export type Rule = {
  sectionTitle: string;
  rules: string[];
};

export type CardInfo = {
  name: CardNames;
  colors?: EnergyColors[][];
  imagePaths: string[];
  details: string;
  amountInDeck: number;
  gameModes: GameMode[];
};

export type CardTypeDetails = {
  type: PlayingCardType;
  cards: CardInfo[];
};

export type RoleDistribution = {
  [Role.IMMUNAR]: string;
  [Role.DELTARED]: string;
  [Role.ORTEGONIST]: string;
};

export type RoleDistributionTable = {
  [key in ValuesType<typeof NumberOfPlayers>]: RoleDistribution;
};

export type EssenceDistributionTable = {
  [key in ValuesType<typeof NumberOfPlayers>]: EssenceCount;
};

const createRoleDistribution = (numberOfPlayers: NumberOfPlayers): RoleDistribution => {
  const roles = getRolesByNumberOfPLayers(numberOfPlayers);
  const roleDistribution: RoleDistribution = {} as RoleDistribution;
  roles.forEach((role) => {
    roleDistribution[role.role] = (role.min === role.max ? role.max : role.min + '-' + role.max) as string;
  });
  return roleDistribution;
};

export const DistributionTable: RoleDistributionTable = Object.values(NumberOfPlayers)
  .filter((value) => !isNaN(Number(value)))
  .reduce(
    (table, numberOfPlayers) => ({
      ...table,
      [numberOfPlayers]: createRoleDistribution(numberOfPlayers as NumberOfPlayers),
    }),
    {} as RoleDistributionTable
  );

export const EssenceCountTable = Object.values(NumberOfPlayers)
  .filter((value) => !isNaN(Number(value)))
  .reduce(
    (table, numberOfPlayers) => ({
      ...table,
      [numberOfPlayers]: getEssenceCountByNumberOfPlayers(numberOfPlayers as NumberOfPlayers),
    }),
    {} as EssenceDistributionTable
  );

export const showFullRulesContent = (page: Page, windowSize: WindowSize) => {
  if (page === 'Game') return windowSize.width > MEDIUM_SIZE_WIDTH;

  return windowSize.width > SMALL_WIDTH;
};
