import { PowerGlitchOptions } from 'powerglitch';

export type Props = {
  withLoader?: boolean;
};

export const glitchProperties = {
  timing: {
    duration: 4000,
  },
  glitchTimeSpan: {
    start: 0.6,
    end: 0.7,
  },
} as PowerGlitchOptions;
