import { ProjectColors } from '../../../../../themes/mainTheme';
import { sx as logoSx } from '../logo/logo.styles';
import { isPortraitOrientation } from '../../../../../helpers/utils';

const subItemPl = 5;

export const sx = {
  drawer: {
    width: isPortraitOrientation() ? '65%' : '35%',
    backgroundColor: ProjectColors.ACTION_DRAWER_MOBILE,
    backgroundImage: 'none',
    overflow: 'visible',
  },
  menuList: { overflow: 'auto' },
  gameItem: { flexGrow: 0.1 },
  subItem: { pl: subItemPl },
  subItemFlex: { display: 'flex', justifyContent: 'space-evenly' },
  listItemIcon: { minWidth: '35px' },
  logoRow: {},
  logoBox: { position: 'absolute', right: `-${logoSx.logoBox.width}` },
  line: {
    position: 'absolute',
    height: '100%',
    borderRight: `1px solid ${ProjectColors.WHITE}`,
    right: -0.5,
    top: `calc(${logoSx.logoBox.height} - 0.5px)`,
  },
  divider: { p: 2 },
  modal: { width: '90%' },
  donationWidgets: { display: 'flex', justifyContent: 'center' },
};
