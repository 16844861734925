import React from 'react';
import { GameState } from '../useGameState.types';
import { ApplyTurnActionsFunc, UseApplyTurnActionsType } from './useApplyTurnActions.types';
import { filterEmpty, isEmpty } from '../../../../../../helpers/arrays';
import { AnimationContext } from '../../../../animationContextProvider';
import { isEmpty as isEmptyState } from '../../../../utils/gameState';
import { usePrepareMovingCards } from './usePrepareMovingCards';
import { Props as MovingCardsProps } from '../../../../../../components/movingCards/movingCards.types';
import { ignoreLastAction } from '../../../../utils/turnActions';
import { Action } from '../../../../gameTypes';
import { withFunc } from '../../../../../../helpers/utils';
import { Log, LogContext } from '../../../../logContextProvider';

export const useApplyTurnActions: UseApplyTurnActionsType = ({ gameState }) => {
  const { queueMovingCardsProps } = React.useContext(AnimationContext);
  const { applyLogs } = React.useContext(LogContext);
  const { currentTurnToMovingCards, previousTurnToMovingCards } = usePrepareMovingCards({ gameState });

  const addRunAfterToLast = (movingCardsPropsMatrix: MovingCardsProps[][], runAfter: () => void): void => {
    const matrixLastIndex = movingCardsPropsMatrix.length - 1;
    const lastListLastIndex = movingCardsPropsMatrix[matrixLastIndex].length - 1;
    const currentLastCardsRunAfter = movingCardsPropsMatrix[matrixLastIndex][lastListLastIndex].runAfter;
    movingCardsPropsMatrix[matrixLastIndex][lastListLastIndex].runAfter = currentLastCardsRunAfter
      ? withFunc(currentLastCardsRunAfter, runAfter)
      : runAfter;
  };

  const applyTurnResultedActions = (movingCardsProps: MovingCardsProps[][], logs: Log[], runAfter: () => void) => {
    const updatedRunAfter = !isEmpty(logs) ? withFunc(() => applyLogs(logs), runAfter) : runAfter;

    if (isEmpty(movingCardsProps)) {
      updatedRunAfter();
      return;
    }

    addRunAfterToLast(movingCardsProps, updatedRunAfter);
    queueMovingCardsProps(...movingCardsProps);
  };

  const prepareCurrentTurnMovingCardsProps = (rawGameState: GameState, logs: Log[]) => {
    const initialActionLen = gameState.activePlayer === rawGameState.activePlayer ? gameState.turnActions.length : 0;
    const finalActionLen = rawGameState.turnActions.length;

    if (isEmptyState(gameState) || initialActionLen >= finalActionLen) return [];

    const turnActions = rawGameState.turnActions.slice(initialActionLen);

    const actionTypesToIgnore: Action[] = ['probability capsule randomized'];
    const filteredActions = ignoreLastAction(turnActions, actionTypesToIgnore);

    return filterEmpty(
      filteredActions.map((turnAction) => {
        const turnMovingCards = currentTurnToMovingCards(turnAction, rawGameState.defendingPlayerIds);
        if (isEmpty(turnMovingCards) && turnAction.logs) logs.push(...turnAction.logs);
        return turnMovingCards;
      })
    );
  };

  const preparePreviousTurnMovingCardsProps = (rawGameState: GameState, logs: Log[]) => {
    const oldTurnActionLen = gameState.turnActions.length;
    const oldPreviousTurnActionLen = gameState.previousTurnActions.length;
    const newPreviousActionLen = rawGameState.previousTurnActions.length;

    if (
      !isEmpty(rawGameState.turnActions) ||
      oldPreviousTurnActionLen === newPreviousActionLen ||
      oldTurnActionLen >= newPreviousActionLen
    ) {
      return [];
    }

    const turnActions = rawGameState.previousTurnActions.slice(oldTurnActionLen);

    const actionTypesToIgnore: Action[] = ['probability capsule randomized'];
    const filteredActions = ignoreLastAction(turnActions, actionTypesToIgnore);

    return filterEmpty(
      filteredActions.map((turnAction) => {
        const turnMovingCards = previousTurnToMovingCards(turnAction);
        if (isEmpty(turnMovingCards) && turnAction.logs) logs.push(...turnAction.logs);
        return turnMovingCards;
      })
    );
  };

  const applyGameStateTurnActions: ApplyTurnActionsFunc = (rawGameState: GameState, runAfter: () => void) => {
    const logs: Log[] = [];
    let movingCardsProps: MovingCardsProps[][] = prepareCurrentTurnMovingCardsProps(rawGameState, logs);
    if (isEmpty(movingCardsProps)) movingCardsProps = preparePreviousTurnMovingCardsProps(rawGameState, logs);

    applyTurnResultedActions(movingCardsProps, logs, runAfter);
  };

  return {
    applyGameStateTurnActions: applyGameStateTurnActions,
  };
};
