import { SizeExtended } from '../../helpers/sizes';
import { fromTo } from '../../helpers/transformers';
import { ProjectColors } from '../../themes/mainTheme';
import { CSSProperties } from 'react';

export type Props = {
  isOpen: boolean;
  size: SizeExtended;
  imagePath: string;
  backCoverImagePath: string;
  content: CardContent;
  backgroundColor: ProjectColors;
  rotation: number;
  dragProps?: DragProps;
  selectorProps?: SelectorProps;
  ignoreWindowSize?: boolean;
  hideRuleBookWidget?: boolean;
  isActive?: boolean;
  hasActiveAnimation?: boolean;
  showImageOnly?: boolean;
  customStyles?: CSSProperties;
};

export type SelectorProps = {
  type: SelectorType;
  isSelected: boolean;
};

export type DragProps = {
  addToDragged: () => void;
  removeFromDragged: () => void;
  clearAllAndAddToDragged: () => void;
};

export type CardContent = {
  type: string;
  name: string;
  details: string;
};

export const CARD_SIZE_MODIFIERS: fromTo<SizeExtended, number> = {
  [SizeExtended.L]: 0.8,
  [SizeExtended.M]: 0.3,
  [SizeExtended.S]: 0.25,
  [SizeExtended.XS]: 0.2,
  [SizeExtended.XXS]: 0.09,
};

export const L_SIZE_RADIUS = 15;
export const L_SIZE_BOARDER = 10;
export const MOBILE_CARD_SIZE_MODIFIER = 0.5;
export const L_SIZE_CARD_CONTENT_DETAILS_FONT = 1;

export enum SelectorType {
  SINGLE,
  MULTIPLE_ON_CTRL,
  MULTIPLE_ON_CLICK,
}
