import { getAllowedCardsInHandCount } from '../../../rules/rules';
import { GameState } from '../../../gameContextHandler/hooks/useGameState';
import { Player } from '../../../../player/player.types';

export type UseSkipActionResult = {
  defineSkipAction: () => SkipAction;
};

export type UseSkipActionProps = {
  idlePlayer: Player;
};

export type UseSkipAction = (props: UseSkipActionProps) => UseSkipActionResult;

export interface SkipAction {
  isButtonEnabled: () => boolean;
  finishAction: () => void;
  buttonTooltip: () => string;
}

export class FinishTurn implements SkipAction {
  isButtonEnabled: () => boolean;
  finishAction: () => void;
  buttonTooltip: () => string;

  constructor(props: FinishTurnProps) {
    const player = props.player;

    this.buttonTooltip = () => {
      if (!props.isHandCardsCountWithinLimits) {
        return `Discard random ${
          player.cardsInHand.length - getAllowedCardsInHandCount(player, props.gameState.playersOrdered)
        } cards from hand and finish turn`;
      }
      return 'Finish turn';
    };

    this.isButtonEnabled = () => props.canFinishAction;

    this.finishAction = () => props.finishTurn();
  }
}

export class SkipTurnAction implements SkipAction {
  isButtonEnabled: () => boolean;
  finishAction: () => void;
  buttonTooltip: () => string;

  constructor(props: SkipTurnActionProps) {
    this.buttonTooltip = () => props.buttonTooltip;
    this.isButtonEnabled = () => props.canFinishAction;
    this.finishAction = () => props.finishAction();
  }
}

export type FinishTurnProps = {
  gameState: GameState;
  isHandCardsCountWithinLimits: boolean;
  canFinishAction: boolean;
  finishTurn: () => void;
  player: Player;
};

export type SkipTurnActionProps = {
  canFinishAction: boolean;
  buttonTooltip: string;
  finishAction: () => void;
};
