import React from 'react';

export type EssenceHuntContextType = {
  isEssenceHuntModalOpen: boolean;
  setIsEssenceHuntModalOpen: (isOpen: boolean) => void;
};

export const EssenceHuntContext = React.createContext({} as EssenceHuntContextType);

export type Props = {
  children?: React.ReactNode;
};
