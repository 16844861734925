import { TOOLBAR_HEIGHT } from '../../../global/app/header/header.styles';
import { WidgetPanelPosition } from './widgetsPanel.types';
import { SxStyles } from '../../../helpers/styles';
import { zIndexes } from '../../../constants/components';

export const initSx = (panelPosition: WidgetPanelPosition): SxStyles => ({
  widgets: {
    position: 'fixed',
    display: 'flex',
    [panelPosition]: '10px',
    top: `${TOOLBAR_HEIGHT}px`,
    zIndex: zIndexes.one,
  },
});
