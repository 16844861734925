import React from 'react';
import { Props, tabLabelProps } from './tabbedContent.types';
import { Box, Tab, Tabs } from '@mui/material';
import { AppContext } from '../../global/context/appContext';
import { sxInit } from './tabbedContent.styles';
import { convertToCamelCase } from '../../helpers/utils';

export const TabbedContent: React.FC<Props> = ({ defaultTabIndex, tabHeaders, tabs }) => {
  const { isMobile } = React.useContext(AppContext);

  const [value, setValue] = React.useState(defaultTabIndex);

  const handleChange = (_: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const tabPanel = (children: React.ReactNode, tabValue: number, index: number) => {
    return (
      <div
        key={`tabpanel-${tabValue}-${index}`}
        role="tabpanel"
        hidden={tabValue !== index}
        id={`full-width-tabpanel-${index}`}
        aria-labelledby={`full-width-tab-${index}`}
      >
        {tabValue === index && <Box sx={sx.tabPanelBlock}>{children}</Box>}
      </div>
    );
  };

  const sx = sxInit(isMobile);

  const tabHeadersContent = tabHeaders
    .filter((tab) => tab.show)
    .map((header, index) => (
      <Tab
        key={convertToCamelCase(header.name) + '-tab'}
        label={header.name}
        {...tabLabelProps(index)}
        sx={sx.tabLabel}
        disabled={header.isDisabled}
      />
    ));

  const tabsContent = tabs.filter((tab) => tab.show).map((tab, index) => tabPanel(tab.element, value, index));

  return (
    <Box>
      <Tabs
        value={value}
        onChange={handleChange}
        indicatorColor="primary"
        textColor="inherit"
        variant="fullWidth"
        aria-label="Game Stats tabs"
      >
        {tabHeadersContent}
      </Tabs>
      {tabsContent}
    </Box>
  );
};
