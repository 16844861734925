import React from 'react';
import { Props } from './contentPageWrapper.types';
import { AppContext } from '../../global/context/appContext';
import { initSx } from './contentPageWrapper.styles';
import { Box } from '@mui/material';
import { AnimatedStarsBlock } from '../animatedStarsBlock';
import { SocialNetworks } from '../../features/socialNetworks';

export const ContentPageWrapper: React.FC<Props> = ({ outerBoxElements, innerBoxElements }) => {
  const { windowSize, isMobile } = React.useContext(AppContext);
  const sx = initSx(windowSize, isMobile);

  return (
    <Box sx={sx.mainBox}>
      {outerBoxElements}
      <Box sx={sx.outerDataBoxSx}>
        <Box sx={sx.innerDataBoxSx}>{innerBoxElements}</Box>
        <Box sx={sx.socialNetworksBox}>
          <SocialNetworks />
        </Box>
      </Box>
      <AnimatedStarsBlock />
    </Box>
  );
};
