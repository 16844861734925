import React from 'react';
import {
  FinishAction,
  FinishAndAllowDisintegrator,
  FinishAndAllowTeleport,
  FinishAndLoseEssence,
  FinishAndRevealRole,
  FinishAndConvert,
  FinishChallenge,
  FinishDisorientation,
  FinishDomeCheck,
  FinishTurn,
  HuntEssence,
  Props,
} from './finishActionButton.types';
import { Tooltip } from '@mui/material';
import { GameContext } from '../../features/game/gameContext';
import Button from '@mui/material/Button';
import {
  canDisintegrate,
  canPlayerFinishProbabilityAction,
  canPlayerHuntEssence,
  canTeleport,
  filterIgnoredDefendingActions,
  isCardsInHandCountWithinLimits,
  isChallengeActive,
  isDisorientationInProgress,
  isDomeCheckInProgress,
  isEssenceHuntInProgress,
} from '../../features/game/rules/rules';
import { last } from '../../helpers/arrays';
import {
  cardsAndActionTypesToActions,
  toAppliedAction,
  toInProgressAction,
} from '../../features/game/utils/turnActions';
import { CardNames, ESSENCE_LOSS_CARDS } from '../../features/card/cardTypes';
import { getAttackingPlayerId, getCurrentPlayer } from '../../features/game/gameContextHandler/utils/player';
import { Action, IN_PROGRESS } from '../../features/game/gameTypes';
import { EssenceHuntContext } from '../../features/room/essenceHuntContextProvider/essenceHuntContextProvider.types';
import { useHuntEssenceAction } from '../../features/game/actions/hooks/useHuntEssenceAction';
import styles from './finishActionButton.module.css';
import buttonStyles from '../../css/commonModules/buttons.module.css';
import cn from 'classnames';
import { DisplayMode } from '../../features/player/player.types';

export const FinishActionButton: React.FC<Props> = ({ displayMode }) => {
  const {
    finishTurn,
    performFinishAndLoseEssence,
    performFinishAndRevealRole,
    performFinishAndConvert,
    performFinishAndEnableTeleport,
    performFinishAndEnableDisintegrator,
    finishChallenge,
    finishDisorientationCheck,
    finishDomeCheck,
    withUpdatingState,
    isProbRolling,
    gameState,
  } = React.useContext(GameContext);

  const { setIsEssenceHuntModalOpen } = React.useContext(EssenceHuntContext);
  const { huntEssence } = useHuntEssenceAction();

  const [lastActiveAction, setLastActiveAction] = React.useState<Action>();
  const [showTooltip, setShowTooltip] = React.useState(false);

  React.useEffect(() => {
    setLastActiveAction(last(filterIgnoredDefendingActions(gameState.turnActions))?.action);
  }, [gameState.turnActions]);

  const currentPlayer = getCurrentPlayer(gameState);
  const isEssenceLossActionInProgress =
    lastActiveAction === undefined
      ? false
      : cardsAndActionTypesToActions(ESSENCE_LOSS_CARDS, [IN_PROGRESS]).includes(lastActiveAction);
  const isRevealRoleActionInProgress = lastActiveAction === toInProgressAction(CardNames.SPY);
  const isConversionerActionInProgress = lastActiveAction === toInProgressAction(CardNames.CONVERSIONER);
  const isChallengeInProgress = isChallengeActive(gameState);
  const isDomeCheckActive = isDomeCheckInProgress(gameState);
  const isDefending = gameState.defendingPlayerIds.includes(currentPlayer.id);
  const isAttackingPlayer = getAttackingPlayerId(gameState) === currentPlayer.id;
  const isTeleportInProgress = canTeleport(gameState.turnActions) && isAttackingPlayer;
  const isDisintegratorInProgress = canDisintegrate(gameState.turnActions) && isAttackingPlayer;
  const canFinishDefending =
    gameState.activeDefendingPlayer === currentPlayer.id &&
    !isTeleportInProgress &&
    !isDisintegratorInProgress &&
    !isChallengeInProgress &&
    !isDomeCheckActive;
  const isHandCardsCountWithinLimits = isCardsInHandCountWithinLimits(
    currentPlayer,
    gameState.playersOrdered,
    gameState.turnActions
  );

  const finishTurnAction = new FinishTurn({
    displayMode: displayMode,
    gameState: gameState,
    isHandCardsCountWithinLimits: isHandCardsCountWithinLimits,
    isTeleportInProgress: isTeleportInProgress,
    isDisintegratorInProgress: isDisintegratorInProgress,
    isEssenceHuntInProgress: isEssenceHuntInProgress(gameState.turnActions),
    isChallengeInProgress: isChallengeInProgress,
    finishTurn: withUpdatingState(finishTurn),
  });
  const finishAndLoseEssenceAction = new FinishAndLoseEssence({
    displayMode: displayMode,
    canFinishAction: canFinishDefending,
    finishAction: () => performFinishAndLoseEssence(currentPlayer),
  });
  const finishAndRevealRoleAction = new FinishAndRevealRole({
    displayMode: displayMode,
    canFinishAction: canFinishDefending,
    finishAction: performFinishAndRevealRole,
  });
  const finishAndConvertAction = new FinishAndConvert({
    displayMode: displayMode,
    canFinishAction: canFinishDefending,
    finishAction: performFinishAndConvert,
  });
  const finishAndAllowTeleportAction = new FinishAndAllowTeleport({
    displayMode: displayMode,
    canFinishAction: canFinishDefending,
    finishAction: performFinishAndEnableTeleport,
  });
  const finishAndAllowDisintegratorAction = new FinishAndAllowDisintegrator({
    displayMode: displayMode,
    canFinishAction: canFinishDefending,
    finishAction: performFinishAndEnableDisintegrator,
  });
  const huntEssenceAction = new HuntEssence({
    displayMode: displayMode,
    canFinishAction: true,
    finishAction: () => {
      setIsEssenceHuntModalOpen(true);
      huntEssence();
    },
  });
  const finishChallengeAction = new FinishChallenge({
    displayMode: displayMode,
    canFinishAction: canPlayerFinishProbabilityAction(currentPlayer.id, gameState, isChallengeActive) && !isProbRolling,
    finishAction: finishChallenge,
  });
  const finishDisorientationAction = new FinishDisorientation({
    displayMode: displayMode,
    canFinishAction:
      canPlayerFinishProbabilityAction(currentPlayer.id, gameState, isDisorientationInProgress) && !isProbRolling,
    finishAction: finishDisorientationCheck,
  });
  const finishDomeCheckAction = new FinishDomeCheck({
    displayMode: displayMode,
    canFinishAction:
      canPlayerFinishProbabilityAction(currentPlayer.id, gameState, isDomeCheckInProgress) && !isProbRolling,
    finishAction: finishDomeCheck,
  });

  const defineMainActionBlock = () => {
    if (gameState.isFinished) return undefined;
    if (
      lastActiveAction === toInProgressAction(CardNames.DISORIENTATION) &&
      currentPlayer.id === gameState.activePlayer
    ) {
      return finishActionBlock(finishDisorientationAction);
    }
    if (isDomeCheckActive) {
      return finishActionBlock(finishDomeCheckAction);
    }
    if (
      (lastActiveAction === toAppliedAction(CardNames.CHALLENGE) && currentPlayer.id === gameState.activePlayer) ||
      (lastActiveAction === toInProgressAction(CardNames.CHALLENGE) &&
        currentPlayer.id === gameState.activeDefendingPlayer)
    ) {
      return finishActionBlock(finishChallengeAction);
    }
    if (isDefending && isEssenceLossActionInProgress) {
      return finishActionBlock(finishAndLoseEssenceAction);
    }
    if (isDefending && isRevealRoleActionInProgress) {
      return finishActionBlock(finishAndRevealRoleAction);
    }
    if (isDefending && isConversionerActionInProgress) {
      return finishActionBlock(finishAndConvertAction);
    }
    if (isDefending && lastActiveAction === toInProgressAction(CardNames.TELEPORT)) {
      return finishActionBlock(finishAndAllowTeleportAction);
    }
    if (isDefending && lastActiveAction === toInProgressAction(CardNames.DISINTEGRATOR)) {
      return finishActionBlock(finishAndAllowDisintegratorAction);
    }
    if (canPlayerHuntEssence(gameState)) {
      return finishActionBlock(huntEssenceAction);
    }

    return finishActionBlock(finishTurnAction);
  };

  const handleMouseEnter = () => {
    setShowTooltip(true);
    setTimeout(() => {
      setShowTooltip(false);
    }, 1000);
  };

  const handleMouseLeave = () => {
    setShowTooltip(false);
  };

  const finishActionBlock = (finishAction: FinishAction) => (
    <Tooltip
      title={<p className={buttonStyles.tooltip}>{finishAction.buttonTooltip()}</p>}
      disableInteractive
      open={showTooltip}
      placement="left"
    >
      <div className={styles.buttonContainer} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
        <Button
          disabled={!finishAction.isButtonEnabled()}
          onClick={finishAction.finishAction}
          variant="contained"
          className={cn(buttonStyles.button, {
            [buttonStyles.activeButton]: finishAction.isButtonEnabled(),
            [buttonStyles.mobileButton]: displayMode === DisplayMode.MOBILE,
          })}
        >
          {finishAction.buttonName()}
        </Button>
      </div>
    </Tooltip>
  );

  return (
    <div
      className={cn(styles.container, {
        [styles.mobileContainer]: displayMode === DisplayMode.MOBILE,
        [styles.trayContainer]: displayMode === DisplayMode.TRAY,
      })}
    >
      {defineMainActionBlock()}
    </div>
  );
};
