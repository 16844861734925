import {
  DefaultMainGameStatsData,
  LAST_FINISHED_GAME_MAIN_STATS_LIMIT,
  MainGameStatsData,
  NOT_AVAILABLE_VALUE,
  TOP_PLAYERS_MAIN_STATS_LIMIT,
  UseGameStats,
} from './useGameStats.types';
import React from 'react';
import { ApiContext } from '../../../../global/app/api/apiContext';
import { formatMinutesToVisualFormat } from '../../../../helpers/utils';
import { FinishedGameApiData, GamePlayersMainStatsData } from '../../../../features/stats/statsTypes';

export const useGameStats: UseGameStats = () => {
  const { statsApi } = React.useContext(ApiContext);

  const [mainStatsData, setMainStatsData] = React.useState<MainGameStatsData>(DefaultMainGameStatsData);
  const [lastFinishedGames, setLastFinishedGames] = React.useState<FinishedGameApiData[]>([]);
  const [playersStats, setPlayersStats] = React.useState<GamePlayersMainStatsData[]>([]);

  const fillGamesData = async () => {
    const apiMainStatsData = await statsApi.getMainStats();
    const lastFinishedGamesData = await statsApi.getLastFinishedGames(LAST_FINISHED_GAME_MAIN_STATS_LIMIT);
    const playersStatsData = await statsApi.getPlayersMainStats(TOP_PLAYERS_MAIN_STATS_LIMIT);

    const formattedMainStatsData: MainGameStatsData = {
      gamesPlayed: apiMainStatsData.gamesPlayed,
      mostWinsRole: apiMainStatsData.mostWinsRole ?? NOT_AVAILABLE_VALUE,
      popularPlayersNumber: apiMainStatsData.popularPlayersNumber,
      averageGameDuration: formatMinutesToVisualFormat(apiMainStatsData.averageGameDurationInMinutes),
    };

    setMainStatsData(formattedMainStatsData);
    setLastFinishedGames(lastFinishedGamesData);
    setPlayersStats(playersStatsData);
  };

  return {
    fillGamesData: fillGamesData,
    mainStatsData: mainStatsData,
    lastFinishedGames: lastFinishedGames,
    playersStats: playersStats,
  };
};
