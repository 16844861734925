import React from 'react';
import { HOUR_IN_MINUTES } from '../constants/time';

export type WindowSize = {
  width: number;
  height: number;
};

export const useWindowSize = () => {
  const [size, setSize] = React.useState<WindowSize>({ height: 0, width: 0 });
  React.useLayoutEffect(() => {
    function updateSize() {
      setSize({ width: window.innerWidth, height: window.innerHeight });

      const root = document.documentElement;
      root.style.setProperty('--window-height', `${window.innerHeight}px`);
      root.style.setProperty('--window-width', `${window.innerWidth}px`);
    }

    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);
  return size;
};

export const isPortraitOrientation = () => window.matchMedia('(orientation: portrait)').matches;

export const convertToCamelCase = (input: string): string => {
  const words = input.split(/[-\s]+/);
  const capitalizedWords = words.map((word, index) => {
    if (index === 0) {
      return word.toLowerCase();
    }
    return word.charAt(0).toUpperCase() + word.slice(1);
  });
  return capitalizedWords.join('');
};

export const convertToCapitalCase = (input: string): string => {
  return input[0].toUpperCase() + input.slice(1).toLowerCase();
};

export type AnyFunction = (...args: any[]) => any;
export const withFunc = <T extends AnyFunction>(fn: T, withFn: () => void): T => {
  return ((...args: Parameters<T>) => {
    const result = fn(...args);
    withFn();
    return result;
  }) as T;
};

export const logResponseErr = (e: any) => {
  let errorMessage: any;

  if (e.response?.data?.message) {
    errorMessage = e.response.data.message;
  } else if (e.response?.data) {
    errorMessage = e.response.data;
  } else {
    errorMessage = e;
  }

  console.error(errorMessage);
};

export const getHttpStatusFromErr = (e: any): number | undefined => {
  return e?.response?.data?.status ?? e?.response?.status ?? undefined;
};

export type ValuesType<T> = T[keyof T];

export const formatMinutesToVisualFormat = (minutes: number) => {
  if (minutes < HOUR_IN_MINUTES) {
    const fullMinutes = Math.floor(minutes);
    return fullMinutes % 10 === 1 ? fullMinutes + ' minute' : fullMinutes + ' minutes';
  }

  const fullHours = Math.floor(minutes / HOUR_IN_MINUTES);
  const fullMinutes = Math.floor(minutes - fullHours * HOUR_IN_MINUTES);
  const fullHoursLog = fullHours % 10 === 1 ? fullHours + ' hour ' : fullHours + ' hours ';
  const fullMinutesLog = fullMinutes % 10 === 1 ? fullMinutes + ' minute' : fullMinutes + ' minutes';

  return fullHoursLog + fullMinutesLog;
};

export function getRandomInt(max: number) {
  return Math.floor(Math.random() * max);
}

export function getRandomIntBetween(min: number, max: number) {
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

export const runAsyncCatching = async <T>(fn: () => Promise<T>): Promise<T | null> => {
  try {
    return await fn();
  } catch (e: any) {
    return null;
  }
};
